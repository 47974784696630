import { Box, Flex, Spacer } from "epsy-ui-react"
import { useEffect, useState } from "react"
import { VnsSessionReport } from "src/API"
import { Button } from "src/components/Button"
import { Dialog, DialogHeader } from "src/components/Dialog"
import { ReactComponent as CheckMark } from "src/images/icons/checkmark/checkmark.svg"
import { translateText } from "src/services/translations/textTranslations"
import { addAlphaToHex } from "src/util/addAlphaToHex"
import styled, { useTheme } from "styled-components"
import { dataToRawText } from "./dataToRawText"

type Props = { onClose: () => void; open: boolean; report: VnsSessionReport }

export const SummaryDialog = ({ onClose, open, report }: Props) => {
  const { colors } = useTheme()

  const [value, setValue] = useState(dataToRawText(report))
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    setValue(dataToRawText(report))
  }, [report])

  const handleOnCopyClick = async () => {
    await navigator.clipboard.writeText(value)
    setIsCopied(true)
  }

  const handleOnCloseClick = () => {
    onClose()
    setIsCopied(false)
  }

  return (
    <Dialog onClose={handleOnCloseClick} open={open} $maxWidth={532}>
      <DialogHeader bold size={24} color={colors.blue} showCloseButton>
        {isCopied
          ? translateText("VNS/SESSION_REPORTS/DIALOG/TITLE/COPIED_TO_CLIPBOARD")
          : translateText("VNS/SESSION_REPORTS/DIALOG/TITLE")}
      </DialogHeader>
      <Box overflow="hidden" height="510px" bg={colors.grey7} border={`${2}px solid ${colors.grey5}`} borderRadius={12}>
        <Wrapper height="100%">
          {isCopied && (
            <OverlayBox bg={addAlphaToHex(colors.grey5, 0.8)} position="absolute">
              <Flex height="100%" justifyContent="center" alignItems="center">
                <CheckMark />
              </Flex>
            </OverlayBox>
          )}
          <TextArea value={value} onChange={(e) => setValue(e.target.value)} readOnly />
        </Wrapper>
      </Box>
      <Spacer height={24} />
      <Button size="L" variant="PRIMARY" onClick={isCopied ? handleOnCloseClick : handleOnCopyClick}>
        {isCopied
          ? translateText("VNS/SESSION_REPORTS/DIALOG/BUTTON/OK")
          : translateText("VNS/SESSION_REPORTS/DIALOG/BUTTON/COPY_ALL_TO_CLIPBOARD")}
      </Button>
    </Dialog>
  )
}

const TextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  border: none;
  background: transparent;
  resize: none;
  outline: none;
`

const Wrapper = styled(Flex)`
  position: relative;
`
const OverlayBox = styled(Box)`
  inset: 0;
`
