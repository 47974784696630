/**
 * This Maximum session timeout in minutes is based on the "MyLivaNova.com Portal Security Review" document's
 * "Preliminary Findings" item number 14.
 * @see {@link https://livanova.sharepoint.com/:w:/s/SystemsProductSecurity/Ee1GDKpDv1pNjmE6G0rDIVcB7VMJYRvVbQGk55S1twnQQw?e=4%3AC5yj2j&fromShare=true&at=9}
 *
 * This utility function is a safeguard to ensure that if the session timeout flag is set to a value that is too high,
 * it will be limited with the maximum allowed value. A minimum value of 1 is also enforced to prevent a value of 0 or less.
 */
export const MAX_SESSION_TIMEOUT = 30
export const MIN_SESSION_TIMEOUT = 1

export const guardSessionTimeout = (value?: number) => {
  if (!value || value < MIN_SESSION_TIMEOUT) return MIN_SESSION_TIMEOUT
  return value > MAX_SESSION_TIMEOUT ? MAX_SESSION_TIMEOUT : value
}
