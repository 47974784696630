import { Flex, Text } from "epsy-ui-react"
import { VnsSessionReport } from "src/API"
import { formatDate } from "src/services/dates"
import { translateIpgModel } from "src/services/translations/ipgModelTranslations"
import { translateText } from "src/services/translations/textTranslations"
import { useTheme } from "styled-components"

type ReportHeaderProps = Pick<
  VnsSessionReport,
  "generatorModel" | "generatorSerialNumber" | "implantedDate" | "patientId"
>

export const ReportHeader = ({
  generatorModel,
  generatorSerialNumber,
  implantedDate,
  patientId
}: ReportHeaderProps) => {
  const { colors } = useTheme()

  const date = implantedDate ? formatDate(implantedDate, "DATE_MONTH_YEAR") : translateText("COMMON/UNKNOWN")
  const serial = generatorSerialNumber ?? translateText("COMMON/UNKNOWN")

  return (
    <Flex alignItems="flex-end" justifyContent="space-between" px={32} pt={32}>
      <Flex flexDirection="column">
        <Text tag="h2" size={48} color={colors.primary}>
          {patientId || "--"}
        </Text>
        <Text size={16} color={colors.grey2}>
          {translateText("VNS/SESSION_REPORTS/REPORT_HEADER/IMPLANT_DATE", { date })}
        </Text>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-end">
        <Text size={18} color={colors.grey2}>
          {translateIpgModel(generatorModel)} {generatorModel}
        </Text>
        <Text size={18} color={colors.grey2}>
          {translateText("VNS/SESSION_REPORTS/REPORT_HEADER/SERIAL_NUMBER", { serial })}
        </Text>
      </Flex>
    </Flex>
  )
}
