import { changePassword, getProfile, login, logout, mfaLogin, verifyCustomChallenge } from "./endpoints/auth"
import { getAccessToken, getRefreshToken } from "./tokens"

const api = {
  changePassword,
  getAccessToken,
  getProfile,
  getRefreshToken,
  login,
  mfaLogin,
  verifyCustomChallenge,
  logout
}

export default api
