import { useQuery } from "@tanstack/react-query"
import { PatientProfile, VnsDeviceDetail, VnsDeviceReading, VnsDeviceSummary, VNSReading } from "src/API"
import useFlags from "src/hooks/useFlags"
import appsyncApi from "src/services/appsyncApi"
import { roundStringNumber } from "src/util/math"

export function useListVnsDevice() {
  const { hubUseNewVnsDeviceQueries } = useFlags()

  return useQuery(["listVnsDevice"], async () => {
    if (hubUseNewVnsDeviceQueries) {
      return appsyncApi.listVnsDevice()
    }
    const data = await appsyncApi.listPatientProfiles({})
    return data.map(parsePatientProfileToVnsDeviceSummary)
  })
}

export function useGetVnsDevice(patientProfileId: string) {
  const { hubUseNewVnsDeviceQueries } = useFlags()

  return useQuery(
    ["getVnsDevice", patientProfileId],
    async () => {
      if (hubUseNewVnsDeviceQueries) {
        return appsyncApi.getVnsDevice({ patientProfileId })
      }
      const patientProfile = await appsyncApi.getPatientProfile({ id: patientProfileId })
      const readingHistory = await appsyncApi.listPatientVNSReadingsHistory({ patientProfileId })
      return parsePatientProfileToVnsDeviceDetail(patientProfile, readingHistory)
    },
    {
      enabled: !!patientProfileId
    }
  )
}

const parsePatientProfileToVnsDeviceSummary = (profile: PatientProfile): VnsDeviceSummary => ({
  __typename: "VnsDeviceSummary",
  assignedHcps: profile.assignedHcps,
  blc: profile.eosCalculation?.expectedLifeRemaining?.toString(),
  generatorModel: profile.currentIPG?.model ?? "",
  generatorSerialNumber: Number.parseInt(profile.currentIPG?.serial ?? "-1"),
  implantedDate: profile.currentIPG?.implantingDate,
  lastKnownReading: profile.lastReading?.interrogationDate,
  normalOutputCurrent: profile.lastReading?.normalSettings?.normalOutputCurrent,
  normalPulseWidth: Number.parseFloat(profile.lastReading?.normalSettings?.normalPulseWidth ?? ""),
  patientDob: profile.birthDate,
  patientName: profile.name,
  patientProfileId: profile.id
})

const parsePatientProfileToVnsDeviceDetail = (
  profile: PatientProfile,
  readingHistory: VNSReading[]
): VnsDeviceDetail => ({
  __typename: "VnsDeviceDetail",
  assignedHcps: profile.assignedHcps,
  autostimOnTime: roundStringNumber(profile.lastReading?.normalSettings?.autoStimulationOnTime),
  autostimOutputCurrent: profile.lastReading?.normalSettings?.autoStimulationOutputCurrent,
  autostimPulseWidth: roundStringNumber(profile.lastReading?.normalSettings?.autoStimulationPulseWidth),
  autostimThreshold: roundStringNumber(profile.lastReading?.autoStimulationThreshold),
  blc: profile.eosCalculation?.expectedLifeRemaining?.toString(),
  generatorModel: profile.currentIPG?.model ?? "",
  generatorSerialNumber: Number.parseInt(profile.currentIPG?.serial ?? "-1"),
  implantedDate: profile.currentIPG?.implantingDate,
  lastKnownReading: profile.lastReading?.interrogationDate,
  lowHeartRateThreshold: null,
  magnetOnTime: roundStringNumber(profile.lastReading?.normalSettings?.magnetSignalOnTime),
  magnetOutputCurrent: profile.lastReading?.normalSettings?.magnetOutputCurrent,
  magnetPulseWidth: roundStringNumber(profile.lastReading?.normalSettings?.magnetPulseWidth),
  normalDutyCycle: profile.lastReading?.dutyCycle,
  normalFrequency: roundStringNumber(profile.lastReading?.normalSettings?.normalFrequency),
  normalOffTime: profile.lastReading?.offTime,
  normalOnTime: roundStringNumber(profile.lastReading?.onTime),
  normalOutputCurrent: profile.lastReading?.normalSettings?.normalOutputCurrent,
  normalPulseWidth: roundStringNumber(profile.lastReading?.normalSettings?.normalPulseWidth),
  patientDob: profile.birthDate,
  patientName: profile.name,
  patientProfileId: profile.id,
  pronePositionDetection: null,
  readings: readingHistory.map(parseVNSReadingToVnsDeviceReading),
  tachycardiaDetection: profile.lastReading?.seizureDetection
})

const parseVNSReadingToVnsDeviceReading = (reading: VNSReading): VnsDeviceReading => ({
  __typename: "VnsDeviceReading",
  leadImpedanceValue: roundStringNumber(reading.leadImpedanceValue),
  mode: reading.readingMode,
  normalFrequency: roundStringNumber(reading.normalSettings?.normalFrequency),
  normalOffTime: reading.offTime,
  normalOnTime: roundStringNumber(reading.onTime),
  normalOutputCurrent: reading.normalSettings?.normalOutputCurrent,
  normalPulseWidth: roundStringNumber(reading.normalSettings?.normalPulseWidth),
  readingDate: reading.interrogationDate ?? "",
  tabletSerialNumber: reading.tablet?.serial,
  tabletSoftwareModel: reading.tablet?.softwareVersion
})
