/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id: "us-east-1:1bb6aef8-257b-4613-a049-24ae241ef7e3",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_V9AosCJzu",
  aws_user_pools_web_client_id: "5b5l9r9qhe017kpp9qponk86uq",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS", "TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: "8",
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_appsync_graphqlEndpoint: "https://ayacsgmw7raizcnxxistsdyenm.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_storage_bucket: "hcp-data-portal-app.us-east-1.epsy.app"
}

export default awsmobile
