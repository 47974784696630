import { EventEmitter } from "eventemitter3"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { logout } from "src/redux/auth/actions"

type LogoutEvent = "LOGOUT"

const logoutEvent = new EventEmitter()
const addLogoutEventListener = (event: LogoutEvent, callback: () => void) => logoutEvent.addListener(event, callback)
export const emitLogoutEvent = (event: LogoutEvent) => logoutEvent.emit(event)

export const LogoutEventHandler = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    addLogoutEventListener("LOGOUT", () => dispatch(logout()))
  }, [dispatch])
  return null
}
