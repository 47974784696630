import { Grid } from "@mui/material"
import { Box, Spacer, Text } from "epsy-ui-react"
import { ReactNode } from "react"
import { ReactComponent as BaseBullet } from "src/images/icons/bullet/bullet.svg"
import { Maybe } from "src/util/utilityTypes"
import styled, { useTheme } from "styled-components"
import { columnLayoutMap } from "./utils"

type ParameterTableSimpleTimeHeaderProps = {
  icon: ReactNode
  title?: string
  initial: { from: Maybe<string>; to: Maybe<string> }
  final: { from: Maybe<string>; to: Maybe<string> }
}
export const ParameterTableSimpleTimeHeader = ({
  icon,
  title,
  initial,
  final
}: ParameterTableSimpleTimeHeaderProps) => {
  const { colors } = useTheme()
  const columnLayout = columnLayoutMap["1-1"]
  const changed = initial.from !== final.from || initial.to !== final.to
  return (
    <Grid
      container
      bgcolor={colors.grey6}
      m="36px 0 16px 16px"
      paddingX="24px"
      borderRadius="8px"
      height="52px"
      alignContent="center"
    >
      <Grid item xs={columnLayout.label}>
        <Box display="flex">
          {icon}
          <Spacer width={8} />
          <Text size={18} color={colors.grey2} lineHeight={30}>
            {title}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={columnLayout.initial} textAlign="end">
        <Text size={16} color={colors.grey1} lineHeight={30}>
          {`${initial.from} - ${initial.to}`}
        </Text>
      </Grid>
      <Grid item xs={columnLayout.gap} />
      <Grid item xs={columnLayout.final} paddingLeft={2}>
        <Text size={16} color={colors.grey1} lineHeight={30}>
          <Bullet visibility={changed ? "visible" : "hidden"} fill={colors.blue} />
          {`${final.from} - ${final.to}`}
        </Text>
      </Grid>
    </Grid>
  )
}

const Bullet = styled(BaseBullet)`
  margin-right: 12px;
  margin-left: 4px;
  margin-bottom: 2px;
`
