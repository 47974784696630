import { BetterFetchError } from "src/util/betterFetch"

export function isEmailAlreadyAssignedError(err: any) {
  /**
   * // TODO: Error type checking for AppSync
   * @see {@link https://linear.app/epsy/issue/FE-160}
   */
  return Boolean(err?.errors?.[0]?.message?.includes?.("email is assigned"))
}

export function isUnauthorizedError(err: any) {
  return Boolean(
    (err instanceof BetterFetchError && err.info.status === 401) ||
      err?.code === "UserNotFoundException" ||
      err?.code === "NotAuthorizedException"
  )
}
