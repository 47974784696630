import { Grid } from "@mui/material"
import { Box, Flex, Spacer, Text } from "epsy-ui-react"
import { VnsSessionReport } from "src/API"
import { ReactComponent as NighttimeIcon } from "src/images/icons/daytime/daytime.svg"
import { ReactComponent as DaytimeIcon } from "src/images/icons/nighttime/nighttime.svg"
import { ReactComponent as RightArrow } from "src/images/icons/right-arrow.svg"
import { formatTime } from "src/services/dates"
import { translateText } from "src/services/translations/textTranslations"
import { useTheme } from "styled-components"
import { getTherapyStates } from "./getTherapyStates"
import { ParameterChip } from "./ParameterChip"
import { ParameterTableHeader } from "./ParameterTableHeader"
import { ParameterTableRow } from "./ParameterTableRow"
import { ParameterTableSimpleTimeHeader } from "./ParameterTableSimpleTimeHeader"
import { ParameterTableTimeHeader } from "./ParameterTableTimeHeader"
import { ColumnLayout, columnLayoutMap, getChangeCount, getProgrammingModeChipProps } from "./utils"

type ParametersCardProps = Pick<VnsSessionReport, "initial" | "final"> & { layout: ColumnLayout }
export const ParametersCard = ({ initial, final, layout }: ParametersCardProps) => {
  const { colors } = useTheme()
  const changes = getChangeCount({ initial, final })
  const layoutMap = columnLayoutMap[layout]
  const initialProgrammingModeChipProps = getProgrammingModeChipProps(initial.programmingMode)
  const finalProgrammingModeChipProps = getProgrammingModeChipProps(final.programmingMode)
  const {
    initialTherapyStateInfo: { chipProps: initialStateChipProps },
    finalTherapyStateInfo: { chipProps: finalStateChipProps }
  } = getTherapyStates(initial.therapyRState, final.therapyRState)

  return (
    <Box borderTopLeftRadius={36} borderTopRightRadius={36} bg={colors.white} py={50} px={64}>
      <Flex flexDirection="row" justifyContent="space-between">
        <Text fontSize={40} tag="h1">
          {translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE")}
        </Text>
        {changes > 0 && (
          <ParameterChip color={colors.blue} bullet>
            {translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/CHANGES", { changes })}
          </ParameterChip>
        )}
      </Flex>
      <Spacer height={48} />
      <Grid container spacing={2}>
        <Grid item xs={layoutMap.label + layoutMap.initial + (layoutMap.initial2 || 0)}>
          <Text size={16} bold align="end">
            {translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/INITIAL")}
          </Text>
        </Grid>
        <Grid item xs={layoutMap.gap} textAlign="center">
          <RightArrow fontSize={16} />
        </Grid>
        <Grid item xs={layoutMap.final + (layoutMap.final2 || 0)}>
          <Text size={16} bold>
            {translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/FINAL")}
          </Text>
        </Grid>
        <Grid item xs={layoutMap.label + layoutMap.initial + (layoutMap.initial2 || 0)}>
          <Flex justifyContent="end" gap={14}>
            {initialStateChipProps && <ParameterChip {...initialStateChipProps} />}
            <ParameterChip {...initialProgrammingModeChipProps} />
          </Flex>
        </Grid>
        <Grid item xs={layoutMap.gap} />
        <Grid item xs={layoutMap.final + (layoutMap.final2 || 0)}>
          <Flex justifyContent="start" gap={14}>
            <ParameterChip {...finalProgrammingModeChipProps} />
            {finalStateChipProps && <ParameterChip {...finalStateChipProps} />}
          </Flex>
        </Grid>
        {layout === "2-2" && (
          <ParameterTableSimpleTimeHeader
            icon={<DaytimeIcon />}
            title={translateText("VNS/SESSION_REPORTS/CARD/DAY_NIGHT_HEADER/DAYTIME")}
            initial={{
              from: formatTime(initial.nightModeEndTime),
              to: formatTime(initial.nightModeStartTime)
            }}
            final={{
              from: formatTime(final.nightModeEndTime),
              to: formatTime(final.nightModeStartTime)
            }}
          />
        )}
        {layout !== "2-2" && layout !== "1-1" && (
          <ParameterTableTimeHeader
            layout={layout}
            initial={{ time: formatTime(initial.nightModeEndTime), time2: formatTime(initial.nightModeStartTime) }}
            final={{ time: formatTime(final.nightModeEndTime), time2: formatTime(final.nightModeStartTime) }}
            changed={
              initial.nightModeEndTime !== final.nightModeEndTime ||
              initial.nightModeStartTime !== final.nightModeStartTime
            }
          />
        )}
        <Tables layout={layout} initial={initial} final={final} />
        {layout === "2-2" && (
          <>
            <ParameterTableSimpleTimeHeader
              icon={<NighttimeIcon />}
              title={translateText("VNS/SESSION_REPORTS/CARD/DAY_NIGHT_HEADER/NIGHTTIME")}
              initial={{
                from: formatTime(initial.nightModeStartTime),
                to: formatTime(initial.nightModeEndTime)
              }}
              final={{
                from: formatTime(final.nightModeStartTime),
                to: formatTime(final.nightModeEndTime)
              }}
            />
            <Tables nightMode layout={layout} initial={initial} final={final} />
          </>
        )}
      </Grid>
    </Box>
  )
}

type TablesProps = Pick<VnsSessionReport, "initial" | "final"> & {
  layout: ColumnLayout
  nightMode?: boolean
}

const Tables = ({ initial, final, layout, nightMode }: TablesProps) => {
  const {
    initialTherapyStateInfo: { unit: initialUnit, value: initialValue },
    finalTherapyStateInfo: { unit: finalUnit, value: finalValue }
  } = getTherapyStates(initial.therapyRState, final.therapyRState)

  return (
    /** * Note: * In nightMode `tables` only show the `*Value1` (first column) for both initial and final state in
      both the day and night groups of tables. * This is not an issue for the day tables as the `*Value1` (first column)
      will contain the correct value already. The night tables however, * must render nightMode data to the `*Value1`
      (first column) instead of the `*Value2` (second column). To achieve this ternary operator is * used to determine
      which value to pass to the `*Value1` (first column) and which to pass to the `*Value2` (second column). If
      nightMode is `true` * then the `*Value1` (first column) will be set to `nightMode*` and the `*Value2` (second
      column) will be undefined. If `nightMode` is `false` then * the `*Value1` (first column) will be set to regular
      value and the `*Value2` (second column) will be `nightMode*` values. */
    <>
      <ParameterTableHeader title={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/NORMAL")} />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/OUTPUT_CURRENT")}
        initialUnit={initialUnit ?? translateText("COMMON/UNIT/MA")}
        initialValue1={initialValue ?? (nightMode ? initial.nightModeNormalOutputCurrent : initial.normalOutputCurrent)}
        initialValue2={initialValue ?? (nightMode ? undefined : initial.nightModeNormalOutputCurrent)}
        finalUnit={finalUnit ?? translateText("COMMON/UNIT/MA")}
        finalValue1={finalValue ?? (nightMode ? final.nightModeNormalOutputCurrent : final.normalOutputCurrent)}
        finalValue2={finalValue ?? (nightMode ? undefined : final.nightModeNormalOutputCurrent)}
      />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/FREQUENCY")}
        initialUnit={translateText("COMMON/UNIT/HZ")}
        initialValue1={nightMode ? initial.nightModeNormalFrequency?.toString() : initial.normalFrequency?.toString()}
        initialValue2={nightMode ? undefined : initial.nightModeNormalFrequency?.toString()}
        finalUnit={translateText("COMMON/UNIT/HZ")}
        finalValue1={nightMode ? final.nightModeNormalFrequency?.toString() : final.normalFrequency?.toString()}
        finalValue2={nightMode ? undefined : final.nightModeNormalFrequency?.toString()}
      />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PULSE_WIDTH")}
        initialUnit={translateText("COMMON/UNIT/MS")}
        initialValue1={nightMode ? initial.nightModeNormalPulseWidth?.toString() : initial.normalPulseWidth?.toString()}
        initialValue2={nightMode ? undefined : initial.nightModeNormalPulseWidth?.toString()}
        finalUnit={translateText("COMMON/UNIT/MS")}
        finalValue1={nightMode ? final.nightModeNormalPulseWidth?.toString() : final.normalPulseWidth?.toString()}
        finalValue2={nightMode ? undefined : final.nightModeNormalPulseWidth?.toString()}
      />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE")}
        initialUnit={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/UNIT/DUTY_CYCLE")}
        initialValue1={nightMode ? initial.nightModeNormalDutyCycle : initial.normalDutyCycle}
        initialValue2={nightMode ? undefined : initial.nightModeNormalDutyCycle}
        finalUnit={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/UNIT/DUTY_CYCLE")}
        finalValue1={nightMode ? final.nightModeNormalDutyCycle : final.normalDutyCycle}
        finalValue2={nightMode ? undefined : final.nightModeNormalDutyCycle}
      />
      <ParameterTableRow
        indent
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/ON_TIME")}
        initialUnit={translateText("COMMON/UNIT/SEC")}
        initialValue1={nightMode ? initial.nightModeNormalSignalOnTime?.toString() : initial.normalOnTime?.toString()}
        initialValue2={nightMode ? undefined : initial.nightModeNormalSignalOnTime?.toString()}
        finalUnit={translateText("COMMON/UNIT/SEC")}
        finalValue1={nightMode ? final.nightModeNormalSignalOnTime?.toString() : final.normalOnTime?.toString()}
        finalValue2={nightMode ? undefined : final.nightModeNormalSignalOnTime?.toString()}
      />
      <ParameterTableRow
        indent
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/OFF_TIME")}
        initialUnit={translateText("COMMON/UNIT/MIN")}
        initialValue1={nightMode ? initial.nightModeNormalSignalOffTime : initial.normalOffTime}
        initialValue2={nightMode ? undefined : initial.nightModeNormalSignalOffTime}
        finalUnit={translateText("COMMON/UNIT/MIN")}
        finalValue1={nightMode ? final.nightModeNormalSignalOffTime : final.normalOffTime}
        finalValue2={nightMode ? undefined : final.nightModeNormalSignalOffTime}
      />
      <Spacer height={12} />
      <ParameterTableHeader title={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/AUTOSTIM")} />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/OUTPUT_CURRENT")}
        initialUnit={initialUnit ?? translateText("COMMON/UNIT/MA")}
        initialValue1={
          initialValue ?? (nightMode ? initial.nightModeAutoStimOutputCurrent : initial.autostimOutputCurrent)
        }
        initialValue2={initialValue ?? (nightMode ? undefined : initial.nightModeAutoStimOutputCurrent)}
        finalUnit={finalUnit ?? translateText("COMMON/UNIT/MA")}
        finalValue1={finalValue ?? (nightMode ? final.nightModeAutoStimOutputCurrent : final.autostimOutputCurrent)}
        finalValue2={finalValue ?? (nightMode ? undefined : final.nightModeAutoStimOutputCurrent)}
      />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PULSE_WIDTH")}
        initialUnit={translateText("COMMON/UNIT/MS")}
        initialValue1={
          nightMode ? initial.nightModeAutoStimPulseWidth?.toString() : initial.autostimPulseWidth?.toString()
        }
        initialValue2={nightMode ? undefined : initial.nightModeAutoStimPulseWidth?.toString()}
        finalUnit={translateText("COMMON/UNIT/MS")}
        finalValue1={nightMode ? final.nightModeAutoStimPulseWidth?.toString() : final.autostimPulseWidth?.toString()}
        finalValue2={nightMode ? undefined : final.nightModeAutoStimPulseWidth?.toString()}
      />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/ON_TIME")}
        initialUnit={translateText("COMMON/UNIT/SEC")}
        initialValue1={nightMode ? initial.nightModeAutoStimOnTime?.toString() : initial.autostimOnTime?.toString()}
        initialValue2={nightMode ? undefined : initial.nightModeAutoStimOnTime?.toString()}
        finalValue1={nightMode ? final.nightModeAutoStimOnTime?.toString() : final.autostimOnTime?.toString()}
        finalValue2={nightMode ? undefined : final.nightModeAutoStimOnTime?.toString()}
      />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/AUTOSTIM_THRESHOLD")}
        initialUnit={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/UNIT/AUTOSTIM_THRESHOLD")}
        initialValue1={
          nightMode ? initial.nightModeThresholdForAutoStim?.toString() : initial.autostimThreshold?.toString()
        }
        initialValue2={nightMode ? undefined : initial.nightModeThresholdForAutoStim?.toString()}
        finalUnit={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/UNIT/AUTOSTIM_THRESHOLD")}
        finalValue1={nightMode ? final.nightModeThresholdForAutoStim?.toString() : final.autostimThreshold?.toString()}
        finalValue2={nightMode ? undefined : final.nightModeThresholdForAutoStim?.toString()}
      />
      <Spacer height={12} />
      <ParameterTableHeader title={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/MAGNET")} />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/OUTPUT_CURRENT")}
        initialUnit={initialUnit ?? translateText("COMMON/UNIT/MA")}
        initialValue1={initialValue ?? (nightMode ? initial.nightModeMagnetOutputCurrent : initial.magnetOutputCurrent)}
        initialValue2={initialValue ?? (nightMode ? undefined : initial.nightModeMagnetOutputCurrent)}
        finalUnit={finalUnit ?? translateText("COMMON/UNIT/MA")}
        finalValue1={finalValue ?? (nightMode ? final.nightModeMagnetOutputCurrent : final.magnetOutputCurrent)}
        finalValue2={finalValue ?? (nightMode ? undefined : final.nightModeMagnetOutputCurrent)}
      />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PULSE_WIDTH")}
        initialUnit={translateText("COMMON/UNIT/MS")}
        initialValue1={nightMode ? initial.nightModeMagnetPulseWidth?.toString() : initial.magnetPulseWidth?.toString()}
        initialValue2={nightMode ? undefined : initial.nightModeMagnetPulseWidth?.toString()}
        finalUnit={translateText("COMMON/UNIT/MS")}
        finalValue1={nightMode ? final.nightModeMagnetPulseWidth?.toString() : final.magnetPulseWidth?.toString()}
        finalValue2={nightMode ? undefined : final.nightModeMagnetPulseWidth?.toString()}
      />
      <ParameterTableRow
        layout={layout}
        label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/ON_TIME")}
        initialUnit={translateText("COMMON/UNIT/SEC")}
        initialValue1={nightMode ? initial.nightModeMagnetSignalOnTime?.toString() : initial.magnetOnTime?.toString()}
        initialValue2={nightMode ? undefined : initial.nightModeMagnetSignalOnTime?.toString()}
        finalUnit={translateText("COMMON/UNIT/SEC")}
        finalValue1={nightMode ? final.nightModeMagnetSignalOnTime?.toString() : final.magnetOnTime?.toString()}
        finalValue2={nightMode ? undefined : final.nightModeMagnetSignalOnTime?.toString()}
      />
      <Spacer height={12} />
      {(nightMode || layout !== "2-2") && (
        <>
          <ParameterTableHeader title={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/DETECTION")} />
          <ParameterTableRow
            hideValue2
            layout={layout}
            label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/TACHYCARDIA")}
            initialValue1={initial.tachycardiaDetection}
            finalValue1={final.tachycardiaDetection}
          />
          <ParameterTableRow
            hideValue2
            layout={layout}
            label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/HEARTBEAT_DETECTION")}
            initialValue1={initial.heartbeatDetection?.toString()}
            finalValue1={final.heartbeatDetection?.toString()}
          />
          <ParameterTableRow
            hideValue2
            layout={layout}
            label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/LOW_HEART_RATE")}
            initialValue1={initial.lowHeartRate?.toString()}
            finalValue1={final.lowHeartRate?.toString()}
          />
          <ParameterTableRow
            hideValue2
            layout={layout}
            label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/LHR_THRESHOLD")}
            initialUnit={translateText("COMMON/UNIT/BPM")}
            initialValue1={initial.lowHeartRateThreshold?.toString()}
            finalValue1={final.lowHeartRateThreshold?.toString()}
            finalUnit={translateText("COMMON/UNIT/BPM")}
          />
          <ParameterTableRow
            hideValue2
            layout={layout}
            label={translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PRONE_POSITION")}
            initialValue1={initial.pronePositionDetection}
            finalValue1={final.pronePositionDetection}
          />
        </>
      )}
    </>
  )
}
