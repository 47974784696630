import styled from "styled-components"

const Circle = styled.div<{ size: number }>`
  ${(props) => `
  width: ${props.size}px;
  height: ${props.size}px;
  border-radius: ${0.5 * props.size}px;
  `}
`

export default Circle
