import appConfig from "src/appConfig"

interface UserAttribute {
  Name: string
  Value: string
}

const getStorageKey = (id: string) => {
  return `CognitoIdentityServiceProvider.${appConfig.awsClientId}.${id}.userData`
}

const getAttributes = (id: string) => {
  const userAttributesRaw = localStorage.getItem(getStorageKey(id))
  if (!userAttributesRaw) throw new Error("no user attributes")
  return JSON.parse(userAttributesRaw) as { UserAttributes: UserAttribute[] }
}

type CognitouserAttributes = "name" | "custom:npi_number"

const removeAttribute = (name: CognitouserAttributes, id: string) => {
  const attributes = getAttributes(id)
  const el = attributes.UserAttributes.find((element: UserAttribute) => element.Name === name)
  if (!el) return
  attributes.UserAttributes.splice(attributes.UserAttributes.indexOf(el), 1)
  localStorage.setItem(getStorageKey(id), JSON.stringify(attributes))
}
const setAttribute = (name: CognitouserAttributes, value: string, id: string) => {
  const attributes = getAttributes(id)
  const el = attributes.UserAttributes.find((element: UserAttribute) => element.Name === name)
  if (!el) {
    attributes.UserAttributes.push({ Name: name, Value: value })
  } else {
    el.Value = value
  }
  localStorage.setItem(getStorageKey(id), JSON.stringify(attributes))
}

const removeNPI = (id: string) => {
  removeAttribute("custom:npi_number", id)
}

const setNPI = (npi: string, id: string) => {
  setAttribute("custom:npi_number", npi, id)
}

const setName = (name: string, id: string) => {
  setAttribute("name", name, id)
}

export { removeNPI, setNPI, setName }
