import { analytics } from "src/services/analytics"
import api from "src/services/api"
import { LoginOpts, LogoutOpts, VerifyCustomChallengeOpts } from "src/services/api/endpoints/auth"
import appsyncApi from "src/services/appsyncApi"
import { queryClient } from "src/services/query"
import { removeNPI, setName, setNPI } from "src/util/localCognitoUser"
import LoadStatus from "../../util/LoadStatus"
import { Action, Dispatch, ThunkResult } from "../types"
import { AuthAction, SuccessAction } from "./types"

function createThunk<A extends Action, FuncPayload, SuccessPayload>(
  slice: A["slice"],
  type: A["type"],
  getSuccessPayload: (payload: FuncPayload) => Promise<SuccessPayload>
) {
  return (payload: FuncPayload): ThunkResult<A> => {
    return async (dispatch: Dispatch) => {
      dispatch({ slice, type, status: LoadStatus.PENDING })
      try {
        const result = await getSuccessPayload(payload)
        dispatch({ slice, type, status: LoadStatus.SUCCESS, payload: result })
        return { error: null, result }
      } catch (error) {
        dispatch({ slice, type, status: LoadStatus.FAIL, payload: { error } })
        return { error, result: null }
      }
    }
  }
}

function createAuthThunk<FuncPayload>(
  type: AuthAction["type"],
  service: (payload: FuncPayload) => Promise<SuccessAction["payload"]["profile"]>
) {
  return createThunk<AuthAction, Parameters<typeof service>[0], SuccessAction["payload"]>(
    "AUTH",
    type,
    async (payload) => {
      const profile = await service(payload)
      return { profile }
    }
  )
}

export const login = createAuthThunk("LOGIN", async (payload: LoginOpts) => {
  const profile = await api.login(payload)
  analytics.track("DP_Sign_In_Successful")
  return profile
})

export const verifyCustomChallenge = createAuthThunk("LOGIN", async (payload: VerifyCustomChallengeOpts) => {
  const profile = await api.verifyCustomChallenge(payload)
  analytics.track("DP_Sign_In_Successful")
  return profile
})

export const restore = createAuthThunk("RESTORE", async () => {
  if ((await api.getAccessToken()) || (await api.getRefreshToken())) {
    const profile = api.getProfile()
    return profile
  } else {
    throw new Error("no token")
  }
})

export const logout = (...opts: LogoutOpts): AuthAction => {
  api.logout(...opts)
  setTimeout(() => queryClient.clear(), 100)
  analytics.track("DP_Logout")
  return { slice: "AUTH", type: "LOGOUT" }
}

export const identify = (): AuthAction => {
  return { slice: "AUTH", type: "IDENTIFY" }
}

export const deidentify = (): AuthAction => {
  return { slice: "AUTH", type: "DEIDENTIFY" }
}

export const updateProfileThunk = async (updateProfile: { id: string; name: string; NPI?: string }) => {
  const { id, name, NPI } = updateProfile
  await appsyncApi.updateHcpUser({ id, input: { name, npiNumber: NPI } })
  if (NPI) {
    setNPI(NPI, id)
  } else {
    removeNPI(id)
  }
  setName(name, id)
  return {
    slice: "AUTH",
    type: "UPDATE_PROFILE",
    payload: { profileUpdate: { name, NPI } },
    status: LoadStatus.SUCCESS
  }
}

export const joinOrg = createAuthThunk(
  "JOIN_ORG",
  async (payload: { email: string; password: string; token: string; organizationId: string }) => {
    const { email, password, token } = payload
    await api.changePassword({ email, password, token })
    const profile = await api.login({ email, password })
    analytics.track("DP_Create_Account_Successful")
    return profile
  }
)
