import { livaNovaColors } from "epsy-ui-react"
import { VnsSessionReport, VnsSessionReportSettings } from "src/API"
import { TextTranslationKey, translateText } from "src/services/translations/textTranslations"
import { ParameterChipProps } from "./ParameterChip"

export type ColumnLayout = "1-1" | "1-2" | "2-1" | "2-2"
type ColumnValues = {
  label: number
  initial: number
  initial2?: number
  gap: number
  final: number
  final2?: number
}

export const columnLayoutMap: Record<ColumnLayout, ColumnValues> = {
  "1-1": {
    label: 3.5,
    initial: 4,
    gap: 0.5,
    final: 4
  },
  "1-2": {
    label: 2.5,
    initial: 3,
    gap: 0.5,
    final: 3,
    final2: 3
  },
  "2-1": {
    label: 2.5,
    initial: 3,
    initial2: 3,
    gap: 0.5,
    final: 3
  },
  "2-2": {
    label: 3.5,
    initial: 4,
    gap: 0.5,
    final: 4
  }
}

export type DividerColumnLayout = "initialWithLabel" | "final"
export const getDividerColumnWidth = (layout: ColumnLayout, column: DividerColumnLayout, hideValue2?: boolean) => {
  const { label, initial, initial2, final, final2 } = columnLayoutMap[layout]
  if (column === "initialWithLabel") {
    return label + initial + (layout === "2-1" && hideValue2 && initial2 ? initial2 ?? 0 : 0)
  }
  return final + (layout === "1-2" && hideValue2 && final2 ? final2 ?? 0 : 0)
}

const programmingModes = ["DayNight", "Regular", "Guided Manual", "Guided Scheduled"] as const
type ProgrammingMode = (typeof programmingModes)[number]

export const getLayout = (
  initialProgrammingMode: VnsSessionReportSettings["programmingMode"],
  finalProgrammingMode: VnsSessionReportSettings["programmingMode"]
): ColumnLayout => {
  const initialNightMode = initialProgrammingMode === "DayNight"
  const finalNightMode = finalProgrammingMode === "DayNight"
  return initialNightMode && finalNightMode ? "2-2" : initialNightMode ? "2-1" : finalNightMode ? "1-2" : "1-1"
}

export const getProgrammingModeChipProps = (
  programmingMode: VnsSessionReportSettings["programmingMode"]
): ParameterChipProps => {
  const manual: ParameterChipProps = {
    children: translateText("VNS/SESSION_REPORTS/CHIP/MANUAL"),
    color: "#585F7E",
    bg: livaNovaColors.grey5
  }

  if (!programmingMode || !programmingModes.includes(programmingMode as ProgrammingMode)) return manual

  const programmingModeMap: Record<ProgrammingMode, { key: TextTranslationKey; bg: string; color: string }> = {
    DayNight: { key: "VNS/SESSION_REPORTS/CHIP/DAY_NIGHT", color: "#1D66AA", bg: "#E2EFF7" },
    Regular: { key: "VNS/SESSION_REPORTS/CHIP/MANUAL", color: "#585F7E", bg: livaNovaColors.grey5 },
    "Guided Manual": {
      key: "VNS/SESSION_REPORTS/CHIP/GUIDED",
      color: "#3B5FDE",
      bg: "#E9ECFD"
    },
    "Guided Scheduled": {
      key: "VNS/SESSION_REPORTS/CHIP/SCHEDULED",
      color: livaNovaColors.magenta,
      bg: livaNovaColors.magenta5
    }
  }
  const { key, color, bg } = programmingModeMap[programmingMode as ProgrammingMode]
  return { children: translateText(key), color, bg }
}

export const getChangeCount = (vnsDevice: Pick<VnsSessionReport, "initial" | "final">): number => {
  const { initial, final } = vnsDevice

  let count = 0
  /**
   * // TODO: This is a temporary solution, we need to find a better way to compare the changes,
   * or at least to a compile time check to make sure we are not missing any fields
   * @see {@link https://linear.app/epsy/issue/FE-516}
   *
   */
  const fields: (keyof Omit<VnsSessionReportSettings, "__typename" | "scheduledProgramGuidedPlans">)[] = [
    "autostimOnTime",
    "autostimOutputCurrent",
    "autostimPulseWidth",
    "autostimThreshold",
    "batteryHealth",
    "batteryPercentage",
    "daynightProgrammingModeStatus",
    "heartbeatDetection",
    "leadImpedanceStatus",
    "leadImpedanceValue",
    "lowHeartRate",
    "lowHeartRateThreshold",
    "magnetOnTime",
    "magnetOutputCurrent",
    "magnetPulseWidth",
    "nightModeEndTime",
    "nightModeNormalFrequency",
    "nightModeNormalOutputCurrent",
    "nightModeNormalPulseWidth",
    "nightModeNormalSignalOffTime",
    "nightModeNormalSignalOnTime",
    "nightModeStartTime",
    "normalDutyCycle",
    "normalFrequency",
    "normalOffTime",
    "normalOnTime",
    "normalOutputCurrent",
    "normalOutputCurrentStatus",
    "normalPulseWidth",
    "parametersAutoCorrected",
    "pronePositionDetection",
    "tachycardiaDetection"
  ]
  fields.forEach((field) => {
    const initialValue = initial?.[field]
    const finalValue = final?.[field]
    if (initialValue !== finalValue) {
      count++
    }
  })

  return count
}
