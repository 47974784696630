export type PatientReportInput = {
  aggregation: PatientReportAggregation
  from: string
  patientId: string
  to: string
}

export enum PatientReportAggregation {
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Quarter = "Quarter"
}

export type GetPatientReportQuery = {
  getPatientReport: GetPatientReportResponse
}

export type GetPatientReportResponse = {
  data: PatientReport[]
  medicationPlans: MedicationPlans[]
  summary: PatientReportSummary
}

export type PatientReport = {
  dateRange: DateRange
  auras: number
  events: Event[]
  hearthRate: number
  medications: Medications
  seizures: Seizure[]
  sideEffects: SideEffect[]
  steps: number
  triggers: Trigger[]
  videos: number
}

export type DateRange = {
  from: string
  to: string
}

export type Medications = {
  deviceSwipe: number
  emergencyDose: number
  missed: number
  notLogged: number
  singleDose: number
}

export type Seizure = {
  type: string
  value: number
}

export type Trigger = {
  type: SeizureTrigger
  value: number
}

export type SideEffect = {
  type: SideEffectType
  value: number
}

export type Event = {
  type: EventType
  value: number
}

export type MedicationPlans = {
  from: string
  to?: string
  devices: Device[]
  medications: Medication[]
}

export type Device = {
  id: string
  model: string
}

export type Medication = {
  id: string
  name: string
}

export type PatientReportSummary = {
  missedMedication: MissedMedicationSummary
  seizures: Summary
  sideEffects: Summary
}

export type Summary = {
  change: number
  value: number
}

export type MissedMedicationSummary = {
  percentage: number
  change: number
}

export enum SeizureTrigger {
  Fever = "Fever",
  Sleep = "Sleep",
  Stress = "Stress",
  FlashingLights = "Flashing Lights",
  Weather = "Weather"
}

export enum SideEffectType {
  Anxiety = "Anxiety",
  Depression = "Depression",
  Dizziness = "Dizziness",
  Headache = "Headache",
  MemoryLoss = "Memory Loss",
  MouthIssues = "Mouth Issues",
  Nausea = "Nausea",
  Other = "Other",
  Tiredness = "Tiredness"
  // And others...
}

export enum EventType {
  Driving = "Driving",
  Soda = "Soda",
  Working = "Working",
  Socialising = "Socialising",
  JunkFood = "Junk Food"
}
