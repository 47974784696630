import queryString from "query-string"
import React from "react"
import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import Nav from "src/components/Nav"
import { authSelector } from "src/redux/auth/selectors"
import Footer from "src/views/Footer"
import Loading from "src/views/Loading"
import { paths } from "./paths"

const LoginRedirect = () => {
  const location = useLocation()
  const query = queryString.stringify({ r: location.pathname })
  const to = location.pathname === paths.LOGOUT ? paths.LOGIN : `${paths.LOGIN}?${query}`

  return <Navigate to={to} />
}

export const ProtectedRoute = () => {
  const { profile, initialised, identified } = useSelector(authSelector)

  if (!initialised) return <Loading />
  if (!profile) return <LoginRedirect />
  if (!identified) return <Loading />

  return (
    <React.Suspense fallback={<Loading />}>
      <Nav />
      <React.Suspense fallback={<Loading />}>
        <Outlet />
      </React.Suspense>
      <Footer />
    </React.Suspense>
  )
}
