import React from "react"
import { paths } from "src/app/routing/paths"
import Link from "src/components/Link"
import ViewContainer from "src/components/ViewContainer"
import { TextTranslationKey, translateText } from "src/services/translations/textTranslations"
import { getTheme } from "src/services/whiteLabel"
import styled from "styled-components"
import { Theme } from "../API"

const Container = styled(ViewContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.grey2};
  margin-top: auto;
`
const Bullet = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin: 0 12px;
  background-color: currentColor;
`

const FooterLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
type Link = { label: TextTranslationKey; to: string }

const Footer = () => {
  const theme = getTheme()

  const links = React.useMemo((): Link[] => {
    const links: Link[] = [
      { label: "FOOTER/PRIVACY", to: paths.PRIVACY },
      { label: "FOOTER/TERMS", to: paths.TERMS }
    ]

    if (theme === Theme.LivaNova) {
      return [
        ...links,
        { label: "FOOTER/COOKIE", to: paths.COOKIE },
        { label: "FOOTER/TOMS", to: paths.TOMS },
        { label: "FOOTER/BAA", to: paths.BAA }
      ]
    }
    return links
  }, [theme])

  return (
    <Container>
      {links.map((link, index) => {
        return (
          <React.Fragment key={link.label}>
            {index ? <Bullet /> : null}
            <FooterLink children={translateText(link.label)} to={link.to} data-cy={link.to} />
          </React.Fragment>
        )
      })}
    </Container>
  )
}

export default Footer
