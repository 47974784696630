import { CognitoUser } from "amazon-cognito-identity-js"
import { Auth } from "aws-amplify"
import { User } from "src/models"
import appsyncApi from "src/services/appsyncApi"
import sentry from "src/services/sentry"

export type LoginOpts = { email: string; password: string }
export async function login({ email, password }: LoginOpts): Promise<User> {
  const cognitoUser: CognitoUser = await Auth.signIn(email, password)
  if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") await Auth.completeNewPassword(cognitoUser, password)
  return await getProfile()
}

export async function mfaLogin({ email, password }: LoginOpts): Promise<CognitoUser> {
  const cognitoUser: CognitoUser = await Auth.signIn(email, password, { challengeName: "SRP_A" })
  if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") await Auth.completeNewPassword(cognitoUser, password)
  if (cognitoUser.challengeName === "CUSTOM_CHALLENGE") return cognitoUser
  const error = new Error(`MFA login failed: unexpected challenge name ${cognitoUser.challengeName}`)
  sentry.captureException(error)
  throw error
}

export type VerifyCustomChallengeOpts = { user: CognitoUser; otp: string }
export async function verifyCustomChallenge({ user, otp }: VerifyCustomChallengeOpts): Promise<User> {
  await Auth.sendCustomChallengeAnswer(user, otp)
  return await getProfile()
}

export async function isAuthenticated() {
  return !!(await Auth.currentAuthenticatedUser({ bypassCache: true }))
}

export async function getProfile(): Promise<User> {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  const organizationId = cognitoUser.attributes["custom:org_id"]
  const id = cognitoUser.attributes.sub
  const currentHcpUser = await appsyncApi.getHcpUser({ id })
  const role = currentHcpUser.role ?? undefined
  return {
    email: cognitoUser.attributes.email,
    id,
    name: cognitoUser.attributes.name,
    organizationId,
    NPI: cognitoUser.attributes["custom:npi_number"],
    role
  }
}
export type LogoutOpts = Parameters<typeof Auth.signOut>
export async function logout(...opts: LogoutOpts) {
  await Auth.signOut(...opts)
}

type ChangePasswordErrorCode = "LimitExceededException" | "ExpiredCodeException" | "CodeMismatchException"
export type ChangePasswordError = { code: ChangePasswordErrorCode; message: string }
export async function changePassword(payload: { email: string; token: string; password: string }) {
  const { email, token, password } = payload
  await Auth.forgotPasswordSubmit(email, token, password)
  return
}

type ResetPasswordErrorCode = "LimitExceededException" | "UserNotFoundException"
export type ResetPasswordError = { code: ResetPasswordErrorCode; message: string }
export async function resetPassword(payload: { email: string }) {
  await Auth.forgotPassword(payload.email)
}
