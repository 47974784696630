import dayjs from "dayjs"
import React from "react"
import { randomKey } from "src/util/random"
import { sortBy } from "src/util/sortBy"

type Timeout = ReturnType<typeof setTimeout>

interface UpdateableToastOpts {
  title: string
  body?: string
  icon?: React.ReactNode
  duration?: number | null
}

interface ToastOpts extends UpdateableToastOpts {
  onClose?: () => void
  onClick?: () => void
}

interface ToastModel extends ToastOpts {
  id: string
  createdAt: string
  timeout?: Timeout
}
type ToastsDict = { [id: string]: ToastModel }

interface ToastContextType {
  createToast: (opts: ToastOpts) => string
  updateToast: (id: string, opts: UpdateableToastOpts) => void
  deleteToast: (id: string) => void
  toasts: ToastModel[]
}

export const ToastContext = React.createContext<ToastContextType>({
  createToast: () => randomKey(),
  updateToast: () => {},
  deleteToast: () => {},
  toasts: []
})

export const defaultToastDuration = 5000

export const ToastStateProvider: React.FC<{}> = (props) => {
  const [toastsDict, setToastsDict] = React.useState<ToastsDict>({})

  const handlers = React.useMemo(() => {
    const deleteToast = (idToDelete: string) => {
      setToastsDict((toastsDict) => {
        const toastIds = Object.keys(toastsDict)
        const newToastsDict: ToastsDict = {}
        toastIds.forEach((id) => {
          if (id !== idToDelete) newToastsDict[id] = toastsDict[id]
        })
        return newToastsDict
      })
    }

    const deleteToastInFuture = (idToDelete: string, duration: number | null): Timeout | undefined => {
      let timeout: Timeout | undefined
      if (duration !== null) {
        timeout = setTimeout(() => {
          deleteToast(idToDelete)
        }, duration)
      }
      return timeout
    }

    const createToast = (opts: ToastOpts) => {
      const { duration = defaultToastDuration, onClose } = opts
      const id = randomKey()
      const createdAt = dayjs().toISOString()
      setToastsDict((toastsDict) => {
        let timeout = deleteToastInFuture(id, duration)
        const newToast = {
          ...opts,
          duration,
          onClose: () => {
            if (onClose) onClose()
            deleteToast(id)
          },
          id,
          createdAt,
          timeout
        }
        return {
          ...toastsDict,
          [id]: newToast
        }
      })

      return id
    }

    const updateToast = (idToUpdate: string, opts: UpdateableToastOpts) => {
      setToastsDict((toastsDict) => {
        const toastIds = Object.keys(toastsDict)
        const newToastsDict: ToastsDict = {}
        toastIds.forEach((id) => {
          const toast = toastsDict[id]

          if (id === idToUpdate) {
            let timeout: Timeout | undefined
            if (opts.duration !== undefined) {
              if (toast.timeout) clearTimeout(toast.timeout)
              timeout = deleteToastInFuture(id, opts.duration)
            }
            newToastsDict[id] = {
              ...toast,
              ...opts,
              timeout
            }
          } else {
            newToastsDict[id] = toast
          }
        })
        return newToastsDict
      })
    }
    return { createToast, updateToast, deleteToast }
  }, [])

  const toastsArray = React.useMemo(() => {
    return sortBy(Object.values(toastsDict), "createdAt")
  }, [toastsDict])

  return (
    <ToastContext.Provider
      value={{
        ...handlers,
        toasts: toastsArray
      }}
      children={props.children}
    />
  )
}
