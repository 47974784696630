import { asyncWithLDProvider, useFlags as defaultUseFlags, useLDClient } from "launchdarkly-react-client-sdk"
import React from "react"
import appConfig from "src/appConfig"
import { environment } from "./environment"

export type ShowSectionFlag = {
  all: boolean
  onlyModels?: Array<string>
}

export interface Flags {
  hubDevTest: string
  hubDomainWhitelist: boolean
  hubHcpRoles: boolean
  hubNewPatientsProfile: boolean
  hubPatientAssignment: boolean
  hubPatientAssignmentHub: boolean
  hubPatientReport12MonthViewFrontendAggregation: boolean
  hubPatientReportDayView: boolean
  hubPatientReportLastSeizureInfo: boolean
  hubPatientReportNewMedPlan: boolean
  hubPatients: boolean
  hubQuestionnairesShowSection: boolean
  hubRemoveProgrammingSessions: boolean
  hubSessionTimeout: number
  hubShowInvitePatientBySms: boolean
  hubShowSessionReport: boolean
  hubSinceUpdateVnsFilter: number
  hubUploadFiles: boolean
  hubUseNewVnsDeviceQueries: boolean
  hubVideos: boolean
  hubVnsPatientsShowAutoStimSectionForModels: ShowSectionFlag
  hubVnsPatientsShowDetectionsSectionForModels: ShowSectionFlag
  hubVnsPatientsShowMagnetSectionForModels: ShowSectionFlag
  hubVnsPatientsShowNormalSectionForModels: ShowSectionFlag
  hubVnsShowSection: boolean
  hubVnsTabletConnectViaTicket: boolean
  hubVnsTabletOverdue: number
  hubVnsTablets: boolean
  questionnaireIds: string[]
  hubMfa: boolean
  hubMfaTimeout: number
}

const flags = {
  getProvider: async () => {
    if (appConfig.isCypress) {
      return React.Fragment
    }
    const clientSideID = environment.launchDarklyId
    return asyncWithLDProvider({ clientSideID, options: {} })
  },
  useFlags: (): Flags => {
    const flagsResult = defaultUseFlags() as Flags
    if (appConfig.isCypress) {
      return (window as any)?.Cypress?.env("flags") || {}
    }
    return flagsResult
  },
  useFlagsClient: () => {
    const ldClient = useLDClient()
    return React.useMemo(() => {
      if (appConfig.isCypress || !ldClient) {
        return {
          identify: () => {}
        }
      }
      return { identify: ldClient?.identify }
    }, [ldClient])
  }
}

export default flags
