import { Text } from "epsy-ui-react"
import { environment } from "src/services/environment"
import { translateText } from "src/services/translations/textTranslations"
import styled, { useTheme } from "styled-components"
interface VersionBoxProps {
  $backgroundColor: string
  $color: string
  $environment: string
}

const VersionBox = styled(Text)<VersionBoxProps>`
  @media print {
    display: none;
  }
  z-index: 1;
  position: fixed;
  bottom: 12px;
  left: 12px;
  padding: 6px;
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$backgroundColor};
  border-radius: 6px;

  span {
    overflow: hidden;
    height: 0px;
    width: 0;
    visibility: hidden;
    display: block;
    transition: height 0.8s ease;
  }
  &:hover {
    text-align-last: justify;
    span {
      text-align-last: justify;
      height: 16px;
      width: fit-content;
      visibility: visible;
    }
  }
`
const Version = () => {
  const theme = useTheme()
  const { version, env } = environment
  return (
    <VersionBox
      size={12}
      $backgroundColor={theme.oldColors.primary.purple}
      $color={theme.colors.white}
      $environment={env}
      title={translateText("APP_CONFIG/ENVIRONMENT", { env })}
    >
      {translateText("APP_CONFIG/VERSION", { version })}
      <span>{translateText("APP_CONFIG/ENVIRONMENT", { env })}</span>
    </VersionBox>
  )
}
export default Version
