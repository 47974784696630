import {
  Menu as ReachMenu,
  MenuContextValue,
  MenuItems as ReachMenuItems,
  MenuLink as ReachMenuLink
} from "@reach/menu-button"
import "@reach/menu-button/styles.css"
import { bp } from "epsy-ui-react"
import { Link } from "react-router-dom"
import { useProfile } from "src/redux/auth/useProfile"
import { useMyOrganization } from "src/redux/hooks"
import { translateText } from "src/services/translations/textTranslations"
import styleVars from "src/style/vars"
import LoadStatus from "src/util/LoadStatus"
import styled, { useTheme } from "styled-components"
import Burger from "../Burger"
import useNavConfig from "./useNavConfig"

const Menu = styled(ReachMenu)``

const MenuItems = styled(ReachMenuItems)<{ $isExpanded: boolean }>`
  align-items: center;
  background-color: ${(p) => p.theme.oldColors.primary.purple};
  border: 0;
  bottom: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  left: 0;
  outline: 0;
  ${bp.each("padding-top", styleVars.navHeight, "px", (x) => x + 45)};
  padding-bottom: 45px;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.2s ease-out all;
  z-index: -2;

  ${(props) =>
    props.$isExpanded
      ? `
  transform: translateY(0);
  opacity: 1;
  `
      : `
  transform: translateY(-100%);
  opacity: 0.5;
  `}
`

const NavShadow = styled.div<{ $isExpanded: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  ${bp.each("height", styleVars.navHeight, "px")};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: -2;
  transition: 0.2s ease-out all;
  opacity: ${(props) => (props.$isExpanded ? 1 : 0)};
`

const MenuLink = styled(Link)`
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
  width: 150px;
  text-align: center;
  border-radius: 20px;
  padding: 0;
  margin-bottom: 16px;

  &[data-selected],
  &:hover {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.oldColors.primary.purple};
  }
`

const MobileMenu = () => {
  const theme = useTheme()
  const { profile } = useProfile()
  const { status: orgStatus } = useMyOrganization()
  const { accountMenuConfig } = useNavConfig()

  if (!profile || orgStatus === LoadStatus.PENDING) return null
  return (
    <Menu data-cy="nav-menu">
      {({ isExpanded }: MenuContextValue) => (
        <>
          <Burger active={isExpanded} color={theme.colors.white} />
          <MenuItems $isExpanded={isExpanded}>
            {accountMenuConfig.map(({ text, path }) => {
              return <ReachMenuLink as={MenuLink} children={translateText(text)} to={path} key={path} />
            })}
          </MenuItems>
          <NavShadow $isExpanded={isExpanded} />
        </>
      )}
    </Menu>
  )
}

export default MobileMenu
