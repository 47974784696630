import { VnsSessionReportSettings } from "src/API"
import { translateText } from "src/services/translations/textTranslations"

export const getBatteryValue = ({
  batteryHealth,
  batteryPercentage
}: Pick<VnsSessionReportSettings, "batteryHealth" | "batteryPercentage">) => {
  if (batteryHealth === "EOS") return "Range 0%"
  if (batteryHealth === "NEOS") return "Range 0%-8%"
  if (batteryHealth === "IFI") return "Range 8%-15%"

  if (batteryPercentage === null || batteryPercentage === undefined) return translateText("COMMON/UNKNOWN")

  if (batteryPercentage >= 75) return "Range 75%-100%"
  if (batteryPercentage >= 50) return "Range 50%-75%"
  if (batteryPercentage >= 25) return "Range 25%-50%"

  return "Range 15%-25%"
}
