import { DoseUnit, EventMood, EventType, GetPatientReportDayQuery } from "./patientReportDayQueryMock"

export const patientReportDayQueryResponseMock = (date: string): { data: GetPatientReportDayQuery } => ({
  data: {
    getPatientReportDay: {
      date,
      bpm: 100,
      steps: 8000,
      auras: [{ timestamp: `${date} 08:00`, notes: "Aura description" }],
      seizures: [],
      medications: {
        taken: [{ timestamp: `${date} 10:00`, medication: "Lamictal" }],
        missed: [{ timestamp: `${date} 17:00`, medication: "Lamictal" }],
        notLogged: [{ timestamp: `${date} 08:00`, medication: "Lamictal" }],
        singleDose: [
          {
            timestamp: `${date} 10:00`,
            medication: "Keppra",
            dose: 30,
            unit: DoseUnit.Milligrams,
            notes: "I took it because of the morning seizures."
          }
        ],
        emergencyDose: [],
        deviceSwipe: []
      },
      sideEffects: [],
      events: [{ timestamp: "2023-01-01 13:25", mood: EventMood.Depressed, type: EventType.Driving }],
      videos: []
    }
  }
})
