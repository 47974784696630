import { Box, Flex, Text } from "epsy-ui-react"
import { ReactNode } from "react"
import { translateText } from "src/services/translations/textTranslations"
import { getLogo } from "src/services/whiteLabel"
import Footer from "src/views/Footer"
import styled, { DefaultTheme, useTheme } from "styled-components"

export const AuthLayout = ({ children }: { children: ReactNode }) => {
  const { colors } = useTheme()

  return (
    <Box>
      <Flex minHeight="100vh">
        <FormBackground p="80px 32px 24px 32px" width={["100%", "100%", "50%"]}>
          <Flex flexDirection="column" height="100%" maxWidth={535} margin="auto">
            <img alt={translateText("AUTH/LOGO/ALT")} src={getLogo()} width="220" />
            <Box height={64} />
            {children}
            <Box flexGrow={1} />
            <Footer />
          </Flex>
        </FormBackground>
        <Box display={["none", "none", "block"]} bg={colors.magenta} py="100px" px="56px" width="50%">
          <Flex height="100%" flexDirection="column" gap={48}>
            <Text color={colors.white} size={24}>
              {translateText("LOGIN/DESCRIPTION")}
            </Text>
            <Flex flex={1} mb="48px" alignItems="center" justifyContent="center">
              <AuthImg />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

const authImageSrc: Record<DefaultTheme["mode"], string> = {
  Epsy: "/images/epsy-floating-right-image.svg",
  LivaNova: "/images/hcp-floating-right-image.svg"
}

const FormBackground = styled(Box)`
  background-image: url(/images/hcp-left-bg.svg);
  background-size: cover;
`

const AuthImg = styled.img.attrs(({ theme }) => ({ src: authImageSrc[theme.mode] }))`
  width: 100%;
  max-width: 800px;
  max-height: 50vh;
`
