import { livaNovaColors } from "epsy-ui-react"
import { VnsSessionReportSettings } from "src/API"
import { translateText } from "src/services/translations/textTranslations"
import { ParameterChipProps } from "./ParameterChip"

type TherapyStateSet = {
  initialTherapyStateInfo: {
    chipProps?: ParameterChipProps
    unit?: string
    value?: string
  }
  finalTherapyStateInfo: {
    chipProps?: ParameterChipProps
    unit?: string
    value?: string
  }
}

export const enabledChipProps: ParameterChipProps = {
  children: translateText("VNS/SESSION_REPORTS/CHIP/STATE/ENABLED"),
  color: livaNovaColors.white,
  bg: livaNovaColors.blue
}

export const pausedChipProps: ParameterChipProps = {
  children: translateText("VNS/SESSION_REPORTS/CHIP/STATE/PAUSED"),
  color: livaNovaColors.white,
  bg: livaNovaColors.warning
}

export const disabledChipProps: ParameterChipProps = {
  children: translateText("VNS/SESSION_REPORTS/CHIP/STATE/DISABLED"),
  color: livaNovaColors.white,
  bg: livaNovaColors.error
}

const chipPropsMap: Record<TherapyState | "na", ParameterChipProps | undefined> = {
  enabled: enabledChipProps,
  paused: pausedChipProps,
  disabled: disabledChipProps,
  na: undefined
}

const valueMap: Record<TherapyState, string | undefined> = {
  disabled: translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/VALUE/DISABLED"),
  paused: translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/VALUE/PAUSED"),
  enabled: undefined
}

const unitMap: Record<TherapyState, string | undefined> = {
  disabled: "--",
  paused: "--",
  enabled: undefined
}

export const getTherapyStates = (
  initialTherapyRState: VnsSessionReportSettings["therapyRState"],
  finalTherapyRState: VnsSessionReportSettings["therapyRState"]
): TherapyStateSet => {
  /**
   * Notes:
   *
   * Only an initial state of `disabled` or `paused` is shown. If the initial state is either
   * `disabled` or `paused` and the final state is neither `disabled` nor `paused`, then the
   * final state is assumed to be`enabled`
   *
   * @see {@link: https://miro.com/app/board/uXjVMjzLKLw=/?openComment=3458764564395310269}
   */

  const { initialTherapyState: initialState, finalTherapyState: finalState } = getTherapyState(
    initialTherapyRState,
    finalTherapyRState
  )
  return {
    initialTherapyStateInfo: {
      chipProps: chipPropsMap[initialState ?? "na"],
      unit: unitMap[initialState ?? "enabled"],
      value: valueMap[initialState ?? "enabled"]
    },
    finalTherapyStateInfo: {
      chipProps: chipPropsMap[finalState ?? "na"],
      unit: unitMap[finalState ?? "enabled"],
      value: valueMap[finalState ?? "enabled"]
    }
  }
}

type TherapyState = "enabled" | "paused" | "disabled"

type GetTherapyStateInputs = {
  initialTherapyState?: TherapyState
  finalTherapyState?: TherapyState
}

const getTherapyState = (
  initialTherapyRState: VnsSessionReportSettings["therapyRState"],
  finalTherapyRState: VnsSessionReportSettings["therapyRState"]
): GetTherapyStateInputs => {
  const initialTherapyState = parseTherapyStateValue(initialTherapyRState)
  const finalTherapyState = parseTherapyStateValue(finalTherapyRState)
  if (!initialTherapyState) {
    return { initialTherapyState, finalTherapyState }
  }
  return {
    initialTherapyState,
    finalTherapyState: finalTherapyState ?? "enabled"
  }
}

const parseTherapyStateValue = (therapyRState: VnsSessionReportSettings["therapyRState"]): TherapyState | undefined => {
  /**
   * Notes:
   *
   *  value = description             => TherapyState
   * ------------------------------------------------
   *  0     = Stop                    => disabled
   *  1     = ?                       => undefined
   *  2     = Offtime                 => undefined
   *  3     = Stim                    => undefined
   *  4     = MagnetPresenceDetected  => paused
   *  5     = MagnetHoldEffect        => paused
   *
   * @see {@link: https://miro.com/app/board/uXjVMjzLKLw=/?openComment=3458764564395310269}
   */
  switch (therapyRState) {
    case 0:
      return "disabled"
    case 4:
    case 5:
      return "paused"
    default:
      return
  }
}
