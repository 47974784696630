import { epsyTheme, livaNovaTheme, Theme } from "epsy-ui-react"
import React from "react"
import { getTheme } from "src/services/whiteLabel"
import { createGlobalStyle, DefaultTheme, ThemeProvider } from "styled-components"
import { Theme as ThemeEnum } from "../API"

const themeMap: Record<ThemeEnum, Theme> = { Epsy: epsyTheme, LivaNova: livaNovaTheme }

type CustomTheme = Pick<DefaultTheme, "backgroundColor">

const defaultCustomTheme = (oldColors: DefaultTheme["oldColors"]): CustomTheme => ({
  backgroundColor: oldColors.shade[6]
})

interface ThemeSetters {
  setBackgroundColor: (_: any) => void
}
const defaultThemeSetters = {
  setBackgroundColor: () => {}
}

const ThemeSetterContext = React.createContext<ThemeSetters>(defaultThemeSetters)

export const GlobalStyleProvider: React.FC<{ initialTheme?: CustomTheme }> = (props) => {
  const mainTheme = themeMap[getTheme()]
  const { initialTheme = defaultCustomTheme(mainTheme.oldColors), children } = props

  const [backgroundColor, setBackgroundColor] = React.useState(initialTheme.backgroundColor)

  const theme = React.useMemo(
    () => ({
      ...initialTheme,
      ...mainTheme,
      backgroundColor
    }),
    [backgroundColor, mainTheme, initialTheme]
  )

  const themeSetters = React.useMemo(
    () => ({
      setBackgroundColor
    }),
    [setBackgroundColor]
  )
  return (
    <ThemeSetterContext.Provider value={themeSetters}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </ThemeSetterContext.Provider>
  )
}

export const useBackgroundColor = (color: string) => {
  const mainTheme = themeMap[getTheme()]
  const themeSetters = React.useContext(ThemeSetterContext)
  const { setBackgroundColor } = themeSetters
  React.useEffect(() => {
    setBackgroundColor(color)
    return () => setBackgroundColor(defaultCustomTheme(mainTheme.oldColors).backgroundColor)
  }, [color, mainTheme, setBackgroundColor])
}

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    touch-action: manipulation;
  }

  body {
    /* test >>> */
    font-family: ${(p) => p.theme.fonts.fontFamily}, sans-serif;
    font-weight: 400;
    height: 100%;    
    position: relative;
    color: ${(p) => p.theme.oldColors.shade[1]};
    background-color: ${(props) => props.theme.backgroundColor};
    transition: 0.2s linear background-color;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    @media print {
      background-color: transparent;
      width: 100%;    
    }

  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100vw;
  }

  a {
    color: currentColor;
  }

  img, svg {
    vertical-align: bottom;
  }

  @page {
    margin: 20px;
  }
  
  #beacon-container {
    @media print {
      display: none;
    }
  }

  [data-reach-menu-popover] {
    z-index: 1;
  }

  iframe[data-product="web_widget"] {
    @media print {
      display: none;
      visibility: hidden;
    }
  }
`

export default GlobalStyle
