import styled, { css } from "styled-components"

type Props = { center?: boolean; color?: string; size: string }

export const Loader = (props: Props) => (
  <Positioner {...props}>
    <ActualLoader {...props} />
  </Positioner>
)

const Positioner = styled.div<Props>`
  ${(p) =>
    p.center &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`

const ActualLoader = styled.div<Props>`
  margin: 0 auto;
  font-size: ${(p) => p.size};
  position: relative;
  border-left: 0.2em solid ${(p) => p.color || "currentColor"};
  border-top: 0.2em solid rgba(0, 0, 0, 0.2);
  border-right: 0.2em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.2em solid rgba(0, 0, 0, 0.2);
  animation: rotate 1s infinite linear;

  &,
  &:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
