import { useLDClient } from "launchdarkly-react-client-sdk"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import sentry from "src/services/sentry"
import Loading from "src/views/Loading"

export const FlagsRoute = () => {
  const ldClient = useLDClient()
  const isInitialized = !!ldClient?.getContext().custom

  const [forceRender, setForceRender] = useState(false)

  // LD SDK sometimes takes very long time to initialize, we want to track the frequency
  useEffect(() => {
    setTimeout(() => {
      const context = ldClient?.getContext()

      if (!context?.custom) {
        setForceRender(true)
        sentry.captureException("LD custom context not available", { extra: { context } })
      }
    }, 10000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isInitialized || forceRender ? <Outlet /> : <Loading />
}
