import { Box, Flex, livaNovaColors, Text } from "epsy-ui-react"
import { VnsSessionReportSettings } from "src/API"
import { Chip } from "src/components/Chip"
import { translateText } from "src/services/translations/textTranslations"
import { removeTrailingZeros } from "src/util/removeTrailingZeros"
import { Maybe } from "src/util/utilityTypes"
import { useTheme } from "styled-components"
import { getBatteryValue } from "./getBatteryValue"

type BatteryProps = Pick<VnsSessionReportSettings, "batteryHealth" | "batteryPercentage">
type ImpedanceProps = Pick<VnsSessionReportSettings, "leadImpedanceValue" | "leadImpedanceStatus">
type OutputProps = Pick<VnsSessionReportSettings, "normalOutputCurrent" | "normalOutputCurrentStatus">

type Props = BatteryProps & ImpedanceProps & OutputProps

export const ReportDiagnosticCard = ({
  batteryHealth,
  batteryPercentage,
  leadImpedanceStatus,
  leadImpedanceValue,
  normalOutputCurrent,
  normalOutputCurrentStatus
}: Props) => {
  const { colors } = useTheme()
  const barColor = getTopBarColor({ batteryHealth, leadImpedanceStatus, normalOutputCurrentStatus })

  return (
    <Box borderTop="8px solid" borderColor={barColor} borderRadius={6} bg={colors.white} p={32} mx={32}>
      <Text size={18}>{translateText("VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/TITLE")}</Text>
      <Box height={8} />
      <Flex justifyContent="space-between">
        <StatusSection
          chipColor={batteryHealth && colorsMap[batteryHealth]}
          chipValue={batteryHealth}
          title={translateText("VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/BATTERY_LABEL")}
          value={getBatteryValue({ batteryHealth, batteryPercentage })}
        />
        <SpacerBar />
        <StatusSection
          chipColor={leadImpedanceStatus && colorsMap[leadImpedanceStatus]}
          chipValue={leadImpedanceStatus}
          title={translateText("VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/LEAD_IMPEDANCE_LABEL")}
          value={
            leadImpedanceValue
              ? translateText("VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/LEAD_IMPEDANCE", { value: leadImpedanceValue })
              : translateText("COMMON/UNKNOWN")
          }
        />
        <SpacerBar />
        <StatusSection
          chipColor={normalOutputCurrentStatus && colorsMap[normalOutputCurrentStatus]}
          chipValue={normalOutputCurrentStatus}
          title={translateText("VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/OUTPUT_CURRENT_LABEL")}
          value={
            normalOutputCurrent
              ? translateText("VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/OUTPUT_CURRENT", {
                  value: removeTrailingZeros(normalOutputCurrent) ?? "--"
                })
              : translateText("COMMON/UNKNOWN")
          }
        />
      </Flex>
    </Box>
  )
}

type StatusSectionProps = { chipColor: Maybe<string>; chipValue: Maybe<string>; title: string; value: Maybe<string> }
const StatusSection = ({ chipColor, chipValue, title, value }: StatusSectionProps) => {
  const { colors } = useTheme()

  return (
    <Flex flexDirection="column" justifyContent="space-between" flexGrow={1}>
      <Text size={16} color={colors.grey1}>
        {title}
      </Text>
      <Flex alignItems="center" gap={16}>
        <Text size={18} color={colors.grey2}>
          {value}
        </Text>
        {chipColor && chipValue && (
          <Chip.Root bg={chipColor} shape="rounded" minWidth={65}>
            <Chip.Label align="center">{chipValue.replace("_", " ")}</Chip.Label>
          </Chip.Root>
        )}
      </Flex>
    </Flex>
  )
}

const colorsMap: Record<string, string> = {
  EOS: livaNovaColors.error20,
  NEOS: livaNovaColors.warning,
  IFI: livaNovaColors.grey5,

  OK: livaNovaColors.success20,
  LOW: livaNovaColors.error20,
  HIGH: livaNovaColors.error20,
  VERY_HIGH: livaNovaColors.error20,

  UNKNOWN: livaNovaColors.grey5
}

export const getTopBarColor = ({
  batteryHealth,
  leadImpedanceStatus,
  normalOutputCurrentStatus
}: Pick<VnsSessionReportSettings, "batteryHealth" | "leadImpedanceStatus" | "normalOutputCurrentStatus">) => {
  const { error20, warning, grey5, success20 } = livaNovaColors
  const statuses = [batteryHealth, leadImpedanceStatus, normalOutputCurrentStatus]

  const errorBar = statuses.some((status) => ["EOS", "LOW", "HIGH"].includes(status ?? ""))
  const warningBar = statuses.some((status) => ["NEOS"].includes(status ?? ""))
  const greyBar = statuses.some((status) => ["UNKNOWN", "IFI"].includes(status ?? ""))

  return errorBar ? error20 : warningBar ? warning : greyBar ? grey5 : success20
}

const SpacerBar = () => <Box bg="grey5" width="1px" mx={16} />
