/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type OrganizationDomainInput = {
  acceptedDataSharing: boolean,
  domain: string,
  organizationId: string,
  signatoryUserId: string,
};

export type OrganizationDomain = {
  __typename: "OrganizationDomain",
  domain: string,
  id: string,
  organizationId: string,
  signatoryUserId: string,
};

export type InvitationInput = {
  invitationId?: string | null,
  organizationId: string,
  patientPublicId?: string | null,
  recipientEmail?: string | null,
  recipientName?: string | null,
  recipientPhoneCountryCode?: string | null,
  recipientPhoneNumber?: string | null,
  redemptionLimit?: number | null,
  role?: HcpRole | null,
  senderUserId?: string | null,
  sentAt?: Array< string | null > | null,
  theme: Theme,
  token?: string | null,
  type: InvitationType,
};

export enum HcpRole {
  Admin = "Admin",
  ITAdmin = "ITAdmin",
  Manager = "Manager",
  Owner = "Owner",
  SuperAdmin = "SuperAdmin",
  Viewer = "Viewer",
}


export enum Theme {
  Epsy = "Epsy",
  LivaNova = "LivaNova",
}


export enum InvitationType {
  hcp_inviting_any_patient_to_org_via_emr = "hcp_inviting_any_patient_to_org_via_emr",
  hcp_inviting_specific_patient_to_org_via_email = "hcp_inviting_specific_patient_to_org_via_email",
  hcp_inviting_specific_patient_to_org_via_sms = "hcp_inviting_specific_patient_to_org_via_sms",
  hcp_inviting_specific_staff_to_org_via_email = "hcp_inviting_specific_staff_to_org_via_email",
  org_inviting_any_patient_to_org_via_digital_media_qr = "org_inviting_any_patient_to_org_via_digital_media_qr",
  org_inviting_any_patient_to_org_via_print_media_qr = "org_inviting_any_patient_to_org_via_print_media_qr",
}


export type Invitation = {
  __typename: "Invitation",
  conversions?: Array< string | null > | null,
  createdAt?: string | null,
  expiresAt?: string | null,
  invitationId: string,
  organization?: Organization | null,
  organizationId: string,
  recipientEmail?: string | null,
  recipientName?: string | null,
  recipientPhoneCountryCode?: string | null,
  recipientPhoneNumber?: string | null,
  redemptionLimit?: number | null,
  role?: HcpRole | null,
  senderUserId?: string | null,
  sentAt?: Array< string | null > | null,
  token?: string | null,
  type?: InvitationType | null,
  updatedAt?: string | null,
};

export type Organization = {
  __typename: "Organization",
  addressLine1?: string | null,
  addressLine2?: string | null,
  city?: string | null,
  country?: string | null,
  department?: string | null,
  email?: string | null,
  logo?: string | null,
  name?: string | null,
  organizationId: string,
  phoneNumbers?: Array< string | null > | null,
  state?: string | null,
  zipCode?: string | null,
};

export type SessionDetails = {
  __typename: "SessionDetails",
  attendee: Attendee,
  id: string,
  meeting: Meeting,
  programmer: Programmer,
};

export type Attendee = {
  __typename: "Attendee",
  capabilities: Capabilities,
  externalUserId: string,
  id: string,
  joinToken: string,
};

export type Capabilities = {
  __typename: "Capabilities",
  audio?: string | null,
  content?: string | null,
  video?: string | null,
};

export type Meeting = {
  __typename: "Meeting",
  externalMeetingId?: string | null,
  id: string,
  mediaPlacement: MediaPlacement,
  mediaRegion: string,
  meetingArn: string,
  meetingHostId?: string | null,
  primaryMeetingId?: string | null,
  tenantIds?: string | null,
};

export type MediaPlacement = {
  __typename: "MediaPlacement",
  audioFallbackUrl: string,
  audioHostUrl: string,
  eventIngestionUrl: string,
  screenDataUrl: string,
  screenSharingUrl: string,
  screenViewingUrl: string,
  signalingUrl: string,
  turnControlUrl: string,
};

export type Programmer = {
  __typename: "Programmer",
  auth: string,
  endpoint: string,
};

export type Hcp = {
  __typename: "Hcp",
  invitation?: Invitation | null,
  invitationId?: string | null,
  memberId: string,
  organizationId: string,
  user?: HcpUser | null,
  userId?: string | null,
};

export type HcpUser = {
  __typename: "HcpUser",
  email: string,
  id: string,
  name?: string | null,
  npiNumber?: string | null,
  phoneNumber?: string | null,
  role?: HcpRole | null,
};

export type Patient = {
  __typename: "Patient",
  assignedHcps:  Array<Hcp >,
  invitation?: Invitation | null,
  invitationId?: string | null,
  lastSeizureDate?: string | null,
  medications?:  Array<Medication | null > | null,
  memberId: string,
  organizationId: string,
  user?: User | null,
  userId?: string | null,
};

export type Medication = {
  __typename: "Medication",
  archivedAt?: string | null,
  compound?: string | null,
  createdAt?: string | null,
  dosage?: string | null,
  frequency?: number | null,
  id: string,
  loggedAt: string,
  parentId?: string | null,
  remindersActive: boolean,
  scheduledAt?:  Array<ScheduledTime | null > | null,
  updatedAt?: string | null,
  userId?: string | null,
};

export type ScheduledTime = {
  __typename: "ScheduledTime",
  key: string,
  time: string,
};

export type User = {
  __typename: "User",
  createdAt?: string | null,
  dob?: string | null,
  email: string,
  gender?: string | null,
  id: string,
  lastSentAt?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
};

export type HcpAcceptInvitationInput = {
  email?: string | null,
  name?: string | null,
  npiNumber?: string | null,
  password: string,
  phoneNumber?: string | null,
  token: string,
};

export type HcpUserInput = {
  name?: string | null,
  npiNumber?: string | null,
  phoneNumber?: string | null,
};

export type OrganizationInput = {
  addressLine1?: string | null,
  addressLine2?: string | null,
  city?: string | null,
  country?: string | null,
  department?: string | null,
  email?: string | null,
  logo?: string | null,
  name?: string | null,
  organizationId: string,
  phoneNumbers?: Array< string | null > | null,
  state?: string | null,
  zipCode?: string | null,
};

export type UpdateBlcTabletInput = {
  nickname?: string | null,
  serialNumber: string,
};

export type BlcTablet = {
  __typename: "BlcTablet",
  createdAt?: string | null,
  id: string,
  lastInterrogationDate?: string | null,
  nickname?: string | null,
  organizationId: string,
  serialNumber: string,
  totalPatients?: number | null,
  totalReadings?: number | null,
  updatedAt?: string | null,
};

export type OrganizationStats = {
  __typename: "OrganizationStats",
  lastKnownReading?: string | null,
  lastUploadedDate?: string | null,
  newImplants?: number | null,
  patientsEOSNo?: number | null,
};

export type PatientProfile = {
  __typename: "PatientProfile",
  IPGs?:  Array<IPG | null > | null,
  assignedHcps:  Array<Hcp >,
  birthDate?: string | null,
  currentIPG?: IPG | null,
  deathDate?: string | null,
  disDate?: string | null,
  eosCalculation?: EOSCalculation | null,
  expDate?: string | null,
  gender?: string | null,
  id: string,
  indication?: string | null,
  lastReading?: VNSReading | null,
  managingPhysician?: Physician | null,
  msid?: string | null,
  name?: string | null,
  part?: string | null,
  patientId?: string | null,
  referringPhysician?: Physician | null,
  surgeon?: Physician | null,
};

export type IPG = {
  __typename: "IPG",
  implantingDate?: string | null,
  implantingHospitalSortName?: string | null,
  model?: string | null,
  replacementReason?: string | null,
  returnDate?: string | null,
  serial?: string | null,
  softwareVersion?: string | null,
};

export type EOSCalculation = {
  __typename: "EOSCalculation",
  calculationDate?: string | null,
  expectedLifeRemaining?: number | null,
  maximumLifeRemaining?: number | null,
  minimalLifeRemaining?: number | null,
};

export type VNSReading = {
  __typename: "VNSReading",
  autoStimulationBlackoutTime?: string | null,
  autoStimulationOn?: boolean | null,
  autoStimulationThreshold?: string | null,
  autoStimulationTimePercent?: string | null,
  beatsPerMin?: string | null,
  chargePerDay?: number | null,
  dutyCycle?: string | null,
  fetDate?: string | null,
  heartbeatDetection?: number | null,
  id: string,
  importDate?: string | null,
  interrogationDate?: string | null,
  ipg?: IPG | null,
  isEOS?: boolean | null,
  isNEOS?: boolean | null,
  leadImpedanceStatus?: string | null,
  leadImpedanceValue?: string | null,
  magnetStimulationTime?: string | null,
  msid?: string | null,
  nightMode?: Flag | null,
  nightSettings?: Settings | null,
  normalSettings?: Settings | null,
  normalStimulationTime?: string | null,
  offTime?: string | null,
  onTime?: string | null,
  organizationId?: string | null,
  programmingMode?: string | null,
  readingMode?: VNSMode | null,
  scheduledPrograming?: Flag | null,
  seizureDetection?: Flag | null,
  sessionId?: number | null,
  tablet?: Tablet | null,
  therapyProtocolType?: string | null,
  time?: number | null,
  totalOnTimeHours?: string | null,
  totalOnTimeMinutes?: string | null,
  uniqueOfficeVisit?: string | null,
  wand?: Wand | null,
};

export enum Flag {
  OFF = "OFF",
  ON = "ON",
}


export type Settings = {
  __typename: "Settings",
  autoStimulationOnTime?: string | null,
  autoStimulationOutputCurrent?: string | null,
  autoStimulationPulseWidth?: string | null,
  endTime?: string | null,
  magnetOutputCurrent?: string | null,
  magnetPulseWidth?: string | null,
  magnetSignalOnTime?: string | null,
  normalFrequency?: string | null,
  normalOutputCurrent?: string | null,
  normalPulseWidth?: string | null,
  normalSignalOffTime?: string | null,
  normalSignalOnTime?: string | null,
  startTime?: string | null,
};

export enum VNSMode {
  INTERROGATE = "INTERROGATE",
  PROGRAM = "PROGRAM",
  SCHEDULE = "SCHEDULE",
}


export type Tablet = {
  __typename: "Tablet",
  serial?: string | null,
  softwareVersion?: string | null,
};

export type Wand = {
  __typename: "Wand",
  serial?: string | null,
  softwareVersion?: string | null,
};

export type Physician = {
  __typename: "Physician",
  NPI?: string | null,
  name?: string | null,
};

export type QuestionnaireResponse = {
  __typename: "QuestionnaireResponse",
  answers:  Array<QuestionAnswer | null >,
  completedAt?: string | null,
  createdAt: string,
  id: string,
  updatedAt?: string | null,
  user?: User | null,
  userId?: string | null,
};

export type QuestionAnswer = {
  __typename: "QuestionAnswer",
  answer?: string | null,
  question: Question,
};

export type Question = {
  __typename: "Question",
  options?:  Array<QuestionOption | null > | null,
  questionType: QuestionType,
  title: string,
};

export type QuestionOption = {
  __typename: "QuestionOption",
  id: string,
  label: string,
};

export enum QuestionType {
  MultipleChoice = "MultipleChoice",
  SingleChoice = "SingleChoice",
  Text = "Text",
}


export type PatientFilterInput = {
  organizationId: string,
  userId: string,
};

export type SeizureTypeAlias = {
  __typename: "SeizureTypeAlias",
  archivedAt?: string | null,
  aware: string,
  id: string,
  name: string,
  parentId?: string | null,
  seizureType: string,
  userId?: string | null,
};

export type SingleDoseMedication = {
  __typename: "SingleDoseMedication",
  archivedAt?: string | null,
  compound?: string | null,
  createdAt?: string | null,
  dosage?: string | null,
  emergency: boolean,
  id: string,
  loggedAt: string,
  parentId?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
};

export type VnsDeviceDetail = {
  __typename: "VnsDeviceDetail",
  assignedHcps:  Array<Hcp >,
  autostimOnTime?: number | null,
  autostimOutputCurrent?: string | null,
  autostimPulseWidth?: number | null,
  autostimThreshold?: number | null,
  blc?: string | null,
  generatorModel: string,
  generatorSerialNumber: number,
  implantedDate?: string | null,
  lastKnownReading?: string | null,
  lowHeartRateThreshold?: number | null,
  magnetOnTime?: number | null,
  magnetOutputCurrent?: string | null,
  magnetPulseWidth?: number | null,
  normalDutyCycle?: string | null,
  normalFrequency?: number | null,
  normalOffTime?: string | null,
  normalOnTime?: number | null,
  normalOutputCurrent?: string | null,
  normalPulseWidth?: number | null,
  patientDob?: string | null,
  patientName?: string | null,
  patientProfileId: string,
  pronePositionDetection?: string | null,
  readings:  Array<VnsDeviceReading >,
  tachycardiaDetection?: string | null,
};

export type VnsDeviceReading = {
  __typename: "VnsDeviceReading",
  leadImpedanceValue?: number | null,
  mode?: string | null,
  normalFrequency?: number | null,
  normalOffTime?: string | null,
  normalOnTime?: number | null,
  normalOutputCurrent?: string | null,
  normalPulseWidth?: number | null,
  readingDate: string,
  tabletSerialNumber?: string | null,
  tabletSoftwareModel?: string | null,
};

export type VnsSessionReport = {
  __typename: "VnsSessionReport",
  appModelAndVer?: string | null,
  final: VnsSessionReportSettings,
  generatorModel?: string | null,
  generatorSerialNumber?: number | null,
  implantedDate?: string | null,
  initial: VnsSessionReportSettings,
  patientId?: string | null,
  readingDateTime?: string | null,
  sessionEndDateTime?: string | null,
  sessionStartDateTime?: string | null,
  tabletSerialNumber?: string | null,
  tabletSoftwareModel?: string | null,
};

export type VnsSessionReportSettings = {
  __typename: "VnsSessionReportSettings",
  autostimOnTime?: number | null,
  autostimOutputCurrent?: string | null,
  autostimPulseWidth?: number | null,
  autostimThreshold?: number | null,
  batteryHealth?: string | null,
  batteryPercentage?: number | null,
  daynightProgrammingModeStatus?: string | null,
  heartbeatDetection?: number | null,
  leadImpedanceStatus?: string | null,
  leadImpedanceValue?: number | null,
  lowHeartRate?: string | null,
  lowHeartRateThreshold?: number | null,
  magnetOnTime?: number | null,
  magnetOutputCurrent?: string | null,
  magnetPulseWidth?: number | null,
  nightModeAutoStimOnTime?: number | null,
  nightModeAutoStimOutputCurrent?: string | null,
  nightModeAutoStimPulseWidth?: number | null,
  nightModeEndTime?: string | null,
  nightModeMagnetOutputCurrent?: string | null,
  nightModeMagnetPulseWidth?: number | null,
  nightModeMagnetSignalOnTime?: number | null,
  nightModeNormalDutyCycle?: string | null,
  nightModeNormalFrequency?: number | null,
  nightModeNormalOutputCurrent?: string | null,
  nightModeNormalPulseWidth?: number | null,
  nightModeNormalSignalOffTime?: string | null,
  nightModeNormalSignalOnTime?: number | null,
  nightModeStartTime?: string | null,
  nightModeThresholdForAutoStim?: number | null,
  normalDutyCycle?: string | null,
  normalFrequency?: number | null,
  normalOffTime?: string | null,
  normalOnTime?: number | null,
  normalOutputCurrent?: string | null,
  normalOutputCurrentStatus?: string | null,
  normalPulseWidth?: number | null,
  parametersAutoCorrected?: number | null,
  programmingMode?: string | null,
  pronePositionDetection?: string | null,
  scheduledProgramGuidedPlans:  Array<VnsSessionReportStep >,
  tachycardiaDetection?: string | null,
  therapyRState?: number | null,
};

export type VnsSessionReportStep = {
  __typename: "VnsSessionReportStep",
  guidedStepAutostimOutputCurrent?: string | null,
  guidedStepMagnetOutputCurrent?: string | null,
  guidedStepNormalOutputCurrent?: string | null,
  schedProgStepDate?: string | null,
  stepId: number,
};

export type EventFilterInput = {
  date?: string | null,
  endAt?: string | null,
  patient?: PatientFilterInput | null,
  startAt?: string | null,
};

export type AuraEventList = {
  __typename: "AuraEventList",
  items?:  Array<AuraEvent | null > | null,
  nextToken?: string | null,
};

export type AuraEvent = {
  __typename: "AuraEvent",
  createdAt?: string | null,
  eventAt: string,
  id: string,
  loggedAt: string,
  rescueMedsDosage?: string | null,
  rescueMedsName?: string | null,
  rescueMedsUsed: boolean,
  source?: EventSource | null,
  updatedAt?: string | null,
  userId?: string | null,
  userNotes?: string | null,
};

export enum EventSource {
  PHONE = "PHONE",
  WATCH = "WATCH",
}


export type BLCFileUploadList = {
  __typename: "BLCFileUploadList",
  items?:  Array<BLCFileUpload | null > | null,
  nextToken?: string | null,
};

export type BLCFileUpload = {
  __typename: "BLCFileUpload",
  createdAt?: string | null,
  errorMessage?: string | null,
  files?:  Array<BLCFile | null > | null,
  id: string,
  organizationId: string,
  overallStatus?: BLCFileUploadStatus | null,
  packageName?: string | null,
  updatedAt?: string | null,
  userId: string,
};

export type BLCFile = {
  __typename: "BLCFile",
  key?: string | null,
  source?: string | null,
  status?: BLCFileStatus | null,
};

export enum BLCFileStatus {
  DONE = "DONE",
  ERROR = "ERROR",
  NEW = "NEW",
  PROCESSING = "PROCESSING",
}


export enum BLCFileUploadStatus {
  DONE = "DONE",
  ERROR = "ERROR",
  PROCESSING = "PROCESSING",
}


export type HcpList = {
  __typename: "HcpList",
  items?:  Array<Hcp | null > | null,
  nextToken?: string | null,
};

export type MediaFileFilterInput = {
  fileStatus?: Array< MediaFileStatus | null > | null,
  ownerModelName?: Array< MediaFileOwnerModelName | null > | null,
  patient: PatientFilterInput,
};

export enum MediaFileStatus {
  ERROR_PROCESSING_TIMED_OUT = "ERROR_PROCESSING_TIMED_OUT",
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  READY = "READY",
  UPLOADING = "UPLOADING",
}


export enum MediaFileOwnerModelName {
  SEIZURE_EVENT = "SEIZURE_EVENT",
}


export type MediaFileList = {
  __typename: "MediaFileList",
  items:  Array<MediaFile | null >,
  nextToken?: string | null,
};

export type MediaFile = {
  __typename: "MediaFile",
  androidUrl?: string | null,
  createdAt?: string | null,
  fileStatus: MediaFileStatus,
  iOSUrl?: string | null,
  id: string,
  metaData?: string | null,
  mimeType: string,
  ownerModelId: string,
  ownerModelName: MediaFileOwnerModelName,
  sourceS3Key: string,
  thumbnailUrl?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  webUrl?: string | null,
};

export type MedicalDeviceList = {
  __typename: "MedicalDeviceList",
  items?:  Array<MedicalDevice | null > | null,
  nextToken?: string | null,
};

export type MedicalDevice = {
  __typename: "MedicalDevice",
  archivedAt?: string | null,
  cardPictureBack?: string | null,
  cardPictureFront?: string | null,
  deviceModel?: string | null,
  healthcareContactName?: string | null,
  healthcareContactPhoneNumber?: string | null,
  id: string,
  implantedAt?: string | null,
  lead?: string | null,
  loggedAt: string,
  modelName?: string | null,
  originalMedicalDeviceId?: string | null,
  otherTypeName?: string | null,
  reImplanted?: boolean | null,
  reImplantedAt?: string | null,
  serialNumber?: string | null,
  type: MedicalDeviceType,
  userId?: string | null,
};

export enum MedicalDeviceType {
  DBS = "DBS",
  OTHER = "OTHER",
  RNS = "RNS",
  VNS = "VNS",
}


export type MedicationList = {
  __typename: "MedicationList",
  items?:  Array<Medication | null > | null,
  nextToken?: string | null,
};

export type MedicationEventList = {
  __typename: "MedicationEventList",
  items?:  Array<MedicationEvent | null > | null,
  nextToken?: string | null,
};

export type MedicationEvent = {
  __typename: "MedicationEvent",
  createdAt?: string | null,
  eventAt: string,
  id: string,
  loggedAt: string,
  medication?: Medication | null,
  medicationId: string,
  scheduledAt?: string | null,
  scheduledTimeId: string,
  source?: EventSource | null,
  updatedAt?: string | null,
  userId?: string | null,
  userResponse: MedicationEventUserResponse,
};

export enum MedicationEventUserResponse {
  MEDICATION_NOT_TAKEN = "MEDICATION_NOT_TAKEN",
  MEDICATION_TAKEN = "MEDICATION_TAKEN",
}


export type MoodEventList = {
  __typename: "MoodEventList",
  items?:  Array<MoodEvent | null > | null,
  nextToken?: string | null,
};

export type MoodEvent = {
  __typename: "MoodEvent",
  createdAt?: string | null,
  eventAt: string,
  id: string,
  loggedAt: string,
  mood?: Mood | null,
  source?: EventSource | null,
  type?: MoodEventType | null,
  updatedAt?: string | null,
  userId?: string | null,
  userNotes?: string | null,
};

export enum Mood {
  ANGRY = "ANGRY",
  ANXIOUS = "ANXIOUS",
  CONFUSED = "CONFUSED",
  DEPRESSED = "DEPRESSED",
  EXCITED = "EXCITED",
  HAPPY = "HAPPY",
  MEH = "MEH",
  NERVOUS = "NERVOUS",
  OTHER = "OTHER",
  PAIN = "PAIN",
  SAD = "SAD",
  SCARED = "SCARED",
  SICK = "SICK",
  STRESSED = "STRESSED",
  TIRED = "TIRED",
}


export enum MoodEventType {
  ACTIVITIES_DRIVING = "ACTIVITIES_DRIVING",
  ACTIVITIES_EXERCISE = "ACTIVITIES_EXERCISE",
  ACTIVITIES_HOLIDAY = "ACTIVITIES_HOLIDAY",
  ACTIVITIES_SOCIALIZING = "ACTIVITIES_SOCIALIZING",
  ACTIVITIES_TRAVELING = "ACTIVITIES_TRAVELING",
  ACTIVITIES_WORKING = "ACTIVITIES_WORKING",
  CARE_BAD_SLEEP = "CARE_BAD_SLEEP",
  CARE_ILLNESS = "CARE_ILLNESS",
  CARE_INJURY = "CARE_INJURY",
  CARE_MENSTRUAL_CYCLE = "CARE_MENSTRUAL_CYCLE",
  CARE_PHYSICAL_PAIN = "CARE_PHYSICAL_PAIN",
  CARE_RESCUE_MED = "CARE_RESCUE_MED",
  DIETARY_ALCOHOL = "DIETARY_ALCOHOL",
  DIETARY_ALLERGY = "DIETARY_ALLERGY",
  DIETARY_CAFFEINE = "DIETARY_CAFFEINE",
  DIETARY_DEHYDRATION = "DIETARY_DEHYDRATION",
  DIETARY_JUNK_FOOD = "DIETARY_JUNK_FOOD",
  DIETARY_SKIPPED_MEAL = "DIETARY_SKIPPED_MEAL",
  DIETARY_SODA = "DIETARY_SODA",
  MEDICATION_MISSED = "MEDICATION_MISSED",
  OTHER = "OTHER",
}


export type PatientList = {
  __typename: "PatientList",
  items?:  Array<Patient | null > | null,
  nextToken?: string | null,
};

export type PatientProfileList = {
  __typename: "PatientProfileList",
  items?:  Array<PatientProfile | null > | null,
  nextToken?: string | null,
};

export type VNSReadingsList = {
  __typename: "VNSReadingsList",
  items?:  Array<VNSReading | null > | null,
  nextToken?: string | null,
};

export type QuestionnaireResponseList = {
  __typename: "QuestionnaireResponseList",
  items?:  Array<QuestionnaireResponse | null > | null,
  nextToken?: string | null,
};

export type SeizureEventList = {
  __typename: "SeizureEventList",
  items?:  Array<SeizureEvent | null > | null,
  nextToken?: string | null,
};

export type SeizureEvent = {
  __typename: "SeizureEvent",
  aware?: YesNo | null,
  convulsive?: YesNo | null,
  createdAt?: string | null,
  duration?: number | null,
  eventAt: string,
  eventLength?: number | null,
  id: string,
  intensity?: SeizureEventIntensity | null,
  intensityNotes?: string | null,
  loggedAt: string,
  recoveryTime?: number | null,
  rescueMedsDosage?: string | null,
  rescueMedsName?: string | null,
  rescueMedsUsed: boolean,
  seizureType?: string | null,
  seizureTypeAlias?: SeizureTypeAlias | null,
  seizureTypeAliasId?: string | null,
  source?: EventSource | null,
  timePickerUsed: boolean,
  triggers?: Array< SeizureEventTrigger | null > | null,
  updatedAt?: string | null,
  userId?: string | null,
  userNotes?: string | null,
};

export enum YesNo {
  NO = "NO",
  NOT_SURE = "NOT_SURE",
  YES = "YES",
}


export enum SeizureEventIntensity {
  MEDIUM = "MEDIUM",
  MILD = "MILD",
  SEVERE = "SEVERE",
}


export enum SeizureEventTrigger {
  ALCOHOL = "ALCOHOL",
  ANXIETY = "ANXIETY",
  CAFFEINE = "CAFFEINE",
  DEHYDRATION = "DEHYDRATION",
  FEVER = "FEVER",
  FLASHING_LIGHT = "FLASHING_LIGHT",
  MENSTRUATION = "MENSTRUATION",
  MISSED_MEAL = "MISSED_MEAL",
  MISSED_MEDICATION = "MISSED_MEDICATION",
  NOT_SURE = "NOT_SURE",
  OTHER = "OTHER",
  SLEEP = "SLEEP",
  STRESS = "STRESS",
  WEATHER = "WEATHER",
}


export type Session = {
  __typename: "Session",
  hcpConnected: boolean,
  hcpName?: string | null,
  id: string,
  patientConnected: boolean,
  patientName?: string | null,
};

export type SideEffectEventList = {
  __typename: "SideEffectEventList",
  items?:  Array<SideEffectEvent | null > | null,
  nextToken?: string | null,
};

export type SideEffectEvent = {
  __typename: "SideEffectEvent",
  createdAt?: string | null,
  eventAt: string,
  id: string,
  loggedAt: string,
  sideEffects?: Array< SideEffects | null > | null,
  source?: EventSource | null,
  updatedAt?: string | null,
  userId?: string | null,
  userNotes?: string | null,
};

export enum SideEffects {
  ANXIETY = "ANXIETY",
  DEPRESSION = "DEPRESSION",
  DIZZINESS = "DIZZINESS",
  DOUBLE_VISION = "DOUBLE_VISION",
  HEADACHE = "HEADACHE",
  IRRITABILITY = "IRRITABILITY",
  MEMORY_LOSS = "MEMORY_LOSS",
  MOUTH_PROBLEMS = "MOUTH_PROBLEMS",
  NOT_SURE = "NOT_SURE",
  OTHER = "OTHER",
  SKIN_PROBLEMS = "SKIN_PROBLEMS",
  SLEEP_ISSUES = "SLEEP_ISSUES",
  TIREDNESS = "TIREDNESS",
  UPSET_STOMACH = "UPSET_STOMACH",
  WEIGHT_CHANGE = "WEIGHT_CHANGE",
}


export type SingleDoseImplantEventList = {
  __typename: "SingleDoseImplantEventList",
  items?:  Array<SingleDoseImplantEvent | null > | null,
  nextToken?: string | null,
};

export type SingleDoseImplantEvent = {
  __typename: "SingleDoseImplantEvent",
  createdAt?: string | null,
  eventAt: string,
  id: string,
  loggedAt: string,
  updatedAt?: string | null,
  userId?: string | null,
};

export type SingleDoseMedicationEventList = {
  __typename: "SingleDoseMedicationEventList",
  items?:  Array<SingleDoseMedicationEvent | null > | null,
  nextToken?: string | null,
};

export type SingleDoseMedicationEvent = {
  __typename: "SingleDoseMedicationEvent",
  compound?: string | null,
  createdAt?: string | null,
  dosage?: string | null,
  emergency: boolean,
  eventAt: string,
  id: string,
  loggedAt: string,
  medicationId?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  userNotes?: string | null,
};

export type BlcTabletList = {
  __typename: "BlcTabletList",
  items:  Array<BlcTablet >,
  nextToken?: string | null,
};

export type VnsDeviceSummaryList = {
  __typename: "VnsDeviceSummaryList",
  items:  Array<VnsDeviceSummary >,
};

export type VnsDeviceSummary = {
  __typename: "VnsDeviceSummary",
  assignedHcps:  Array<Hcp >,
  blc?: string | null,
  generatorModel: string,
  generatorSerialNumber: number,
  implantedDate?: string | null,
  lastKnownReading?: string | null,
  normalOutputCurrent?: string | null,
  normalPulseWidth?: number | null,
  patientDob?: string | null,
  patientName?: string | null,
  patientProfileId: string,
};

export type VnsTabletList = {
  __typename: "VnsTabletList",
  items:  Array<VnsTablet >,
};

export type VnsTablet = {
  __typename: "VnsTablet",
  id: string,
  lastReading?: string | null,
  nickname: string,
  patients: number,
  readings: number,
  serialNumber: string,
};

export type AddDomainToOrganizationMutationVariables = {
  input: OrganizationDomainInput,
};

export type AddDomainToOrganizationMutation = {
  addDomainToOrganization?:  {
    __typename: "OrganizationDomain",
    domain: string,
    id: string,
    organizationId: string,
    signatoryUserId: string,
  } | null,
};

export type AssignHcpPatientMutationVariables = {
  hcpMemberId: string,
  patientMemberId: string,
};

export type AssignHcpPatientMutation = {
  assignHcpPatient?: boolean | null,
};

export type AssignHcpPatientProfileMutationVariables = {
  hcpMemberId: string,
  patientProfileId: string,
};

export type AssignHcpPatientProfileMutation = {
  assignHcpPatientProfile?: boolean | null,
};

export type AssignHcpVnsDeviceMutationVariables = {
  hcpMemberId: string,
  patientProfileId: string,
};

export type AssignHcpVnsDeviceMutation = {
  assignHcpVnsDevice?: boolean | null,
};

export type CreateInvitationMutationVariables = {
  input: InvitationInput,
};

export type CreateInvitationMutation = {
  createInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organization?:  {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      zipCode?: string | null,
    } | null,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateSessionMutationVariables = {
  patientId: string,
  patientName: string,
};

export type CreateSessionMutation = {
  createSession?:  {
    __typename: "SessionDetails",
    attendee:  {
      __typename: "Attendee",
      capabilities:  {
        __typename: "Capabilities",
        audio?: string | null,
        content?: string | null,
        video?: string | null,
      },
      externalUserId: string,
      id: string,
      joinToken: string,
    },
    id: string,
    meeting:  {
      __typename: "Meeting",
      externalMeetingId?: string | null,
      id: string,
      mediaPlacement:  {
        __typename: "MediaPlacement",
        audioFallbackUrl: string,
        audioHostUrl: string,
        eventIngestionUrl: string,
        screenDataUrl: string,
        screenSharingUrl: string,
        screenViewingUrl: string,
        signalingUrl: string,
        turnControlUrl: string,
      },
      mediaRegion: string,
      meetingArn: string,
      meetingHostId?: string | null,
      primaryMeetingId?: string | null,
      tenantIds?: string | null,
    },
    programmer:  {
      __typename: "Programmer",
      auth: string,
      endpoint: string,
    },
  } | null,
};

export type CreateTabletZendeskTicketMutationVariables = {
  serialNumber: string,
};

export type CreateTabletZendeskTicketMutation = {
  createTabletZendeskTicket: boolean,
};

export type DeleteHcpMutationVariables = {
  memberId: string,
};

export type DeleteHcpMutation = {
  deleteHcp?:  {
    __typename: "Hcp",
    invitation?:  {
      __typename: "Invitation",
      conversions?: Array< string | null > | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      invitationId: string,
      organization?:  {
        __typename: "Organization",
        addressLine1?: string | null,
        addressLine2?: string | null,
        city?: string | null,
        country?: string | null,
        department?: string | null,
        email?: string | null,
        logo?: string | null,
        name?: string | null,
        organizationId: string,
        phoneNumbers?: Array< string | null > | null,
        state?: string | null,
        zipCode?: string | null,
      } | null,
      organizationId: string,
      recipientEmail?: string | null,
      recipientName?: string | null,
      recipientPhoneCountryCode?: string | null,
      recipientPhoneNumber?: string | null,
      redemptionLimit?: number | null,
      role?: HcpRole | null,
      senderUserId?: string | null,
      sentAt?: Array< string | null > | null,
      token?: string | null,
      type?: InvitationType | null,
      updatedAt?: string | null,
    } | null,
    invitationId?: string | null,
    memberId: string,
    organizationId: string,
    user?:  {
      __typename: "HcpUser",
      email: string,
      id: string,
      name?: string | null,
      npiNumber?: string | null,
      phoneNumber?: string | null,
      role?: HcpRole | null,
    } | null,
    userId?: string | null,
  } | null,
};

export type DeleteInvitationMutationVariables = {
  invitationId: string,
};

export type DeleteInvitationMutation = {
  deleteInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organization?:  {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      zipCode?: string | null,
    } | null,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type DeletePatientMutationVariables = {
  memberId: string,
};

export type DeletePatientMutation = {
  deletePatient?:  {
    __typename: "Patient",
    assignedHcps:  Array< {
      __typename: "Hcp",
      invitation?:  {
        __typename: "Invitation",
        conversions?: Array< string | null > | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        invitationId: string,
        organization?:  {
          __typename: "Organization",
          addressLine1?: string | null,
          addressLine2?: string | null,
          city?: string | null,
          country?: string | null,
          department?: string | null,
          email?: string | null,
          logo?: string | null,
          name?: string | null,
          organizationId: string,
          phoneNumbers?: Array< string | null > | null,
          state?: string | null,
          zipCode?: string | null,
        } | null,
        organizationId: string,
        recipientEmail?: string | null,
        recipientName?: string | null,
        recipientPhoneCountryCode?: string | null,
        recipientPhoneNumber?: string | null,
        redemptionLimit?: number | null,
        role?: HcpRole | null,
        senderUserId?: string | null,
        sentAt?: Array< string | null > | null,
        token?: string | null,
        type?: InvitationType | null,
        updatedAt?: string | null,
      } | null,
      invitationId?: string | null,
      memberId: string,
      organizationId: string,
      user?:  {
        __typename: "HcpUser",
        email: string,
        id: string,
        name?: string | null,
        npiNumber?: string | null,
        phoneNumber?: string | null,
        role?: HcpRole | null,
      } | null,
      userId?: string | null,
    } >,
    invitation?:  {
      __typename: "Invitation",
      conversions?: Array< string | null > | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      invitationId: string,
      organization?:  {
        __typename: "Organization",
        addressLine1?: string | null,
        addressLine2?: string | null,
        city?: string | null,
        country?: string | null,
        department?: string | null,
        email?: string | null,
        logo?: string | null,
        name?: string | null,
        organizationId: string,
        phoneNumbers?: Array< string | null > | null,
        state?: string | null,
        zipCode?: string | null,
      } | null,
      organizationId: string,
      recipientEmail?: string | null,
      recipientName?: string | null,
      recipientPhoneCountryCode?: string | null,
      recipientPhoneNumber?: string | null,
      redemptionLimit?: number | null,
      role?: HcpRole | null,
      senderUserId?: string | null,
      sentAt?: Array< string | null > | null,
      token?: string | null,
      type?: InvitationType | null,
      updatedAt?: string | null,
    } | null,
    invitationId?: string | null,
    lastSeizureDate?: string | null,
    medications?:  Array< {
      __typename: "Medication",
      archivedAt?: string | null,
      compound?: string | null,
      createdAt?: string | null,
      dosage?: string | null,
      frequency?: number | null,
      id: string,
      loggedAt: string,
      parentId?: string | null,
      remindersActive: boolean,
      scheduledAt?:  Array< {
        __typename: "ScheduledTime",
        key: string,
        time: string,
      } | null > | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null > | null,
    memberId: string,
    organizationId: string,
    user?:  {
      __typename: "User",
      createdAt?: string | null,
      dob?: string | null,
      email: string,
      gender?: string | null,
      id: string,
      lastSentAt?: string | null,
      name?: string | null,
      phoneNumber?: string | null,
    } | null,
    userId?: string | null,
  } | null,
};

export type HcpAcceptInvitationMutationVariables = {
  input: HcpAcceptInvitationInput,
};

export type HcpAcceptInvitationMutation = {
  hcpAcceptInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organization?:  {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      zipCode?: string | null,
    } | null,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type RemoveDomainFromOrganizationMutationVariables = {
  domainId: string,
};

export type RemoveDomainFromOrganizationMutation = {
  removeDomainFromOrganization: boolean,
};

export type ResendInvitationMutationVariables = {
  invitationId: string,
};

export type ResendInvitationMutation = {
  resendInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organization?:  {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      zipCode?: string | null,
    } | null,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type UnassignHcpPatientMutationVariables = {
  hcpMemberId: string,
  patientMemberId: string,
};

export type UnassignHcpPatientMutation = {
  unassignHcpPatient?: boolean | null,
};

export type UnassignHcpPatientProfileMutationVariables = {
  hcpMemberId: string,
  patientProfileId: string,
};

export type UnassignHcpPatientProfileMutation = {
  unassignHcpPatientProfile?: boolean | null,
};

export type UnassignHcpVnsDeviceMutationVariables = {
  hcpMemberId: string,
  patientProfileId: string,
};

export type UnassignHcpVnsDeviceMutation = {
  unassignHcpVnsDevice?: boolean | null,
};

export type UpdateHcpUserMutationVariables = {
  id: string,
  input?: HcpUserInput | null,
};

export type UpdateHcpUserMutation = {
  updateHcpUser?:  {
    __typename: "HcpUser",
    email: string,
    id: string,
    name?: string | null,
    npiNumber?: string | null,
    phoneNumber?: string | null,
    role?: HcpRole | null,
  } | null,
};

export type UpdateInvitationMutationVariables = {
  input: InvitationInput,
};

export type UpdateInvitationMutation = {
  updateInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organization?:  {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      zipCode?: string | null,
    } | null,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: OrganizationInput,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    country?: string | null,
    department?: string | null,
    email?: string | null,
    logo?: string | null,
    name?: string | null,
    organizationId: string,
    phoneNumbers?: Array< string | null > | null,
    state?: string | null,
    zipCode?: string | null,
  } | null,
};

export type UpdateTabletMutationVariables = {
  input: UpdateBlcTabletInput,
};

export type UpdateTabletMutation = {
  updateTablet?:  {
    __typename: "BlcTablet",
    createdAt?: string | null,
    id: string,
    lastInterrogationDate?: string | null,
    nickname?: string | null,
    organizationId: string,
    serialNumber: string,
    totalPatients?: number | null,
    totalReadings?: number | null,
    updatedAt?: string | null,
  } | null,
};

export type GetAppUserQueryVariables = {
  id: string,
};

export type GetAppUserQuery = {
  getAppUser?:  {
    __typename: "User",
    createdAt?: string | null,
    dob?: string | null,
    email: string,
    gender?: string | null,
    id: string,
    lastSentAt?: string | null,
    name?: string | null,
    phoneNumber?: string | null,
  } | null,
};

export type GetHcpQueryVariables = {
  memberId: string,
};

export type GetHcpQuery = {
  getHcp?:  {
    __typename: "Hcp",
    invitation?:  {
      __typename: "Invitation",
      conversions?: Array< string | null > | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      invitationId: string,
      organization?:  {
        __typename: "Organization",
        addressLine1?: string | null,
        addressLine2?: string | null,
        city?: string | null,
        country?: string | null,
        department?: string | null,
        email?: string | null,
        logo?: string | null,
        name?: string | null,
        organizationId: string,
        phoneNumbers?: Array< string | null > | null,
        state?: string | null,
        zipCode?: string | null,
      } | null,
      organizationId: string,
      recipientEmail?: string | null,
      recipientName?: string | null,
      recipientPhoneCountryCode?: string | null,
      recipientPhoneNumber?: string | null,
      redemptionLimit?: number | null,
      role?: HcpRole | null,
      senderUserId?: string | null,
      sentAt?: Array< string | null > | null,
      token?: string | null,
      type?: InvitationType | null,
      updatedAt?: string | null,
    } | null,
    invitationId?: string | null,
    memberId: string,
    organizationId: string,
    user?:  {
      __typename: "HcpUser",
      email: string,
      id: string,
      name?: string | null,
      npiNumber?: string | null,
      phoneNumber?: string | null,
      role?: HcpRole | null,
    } | null,
    userId?: string | null,
  } | null,
};

export type GetHcpUserQueryVariables = {
  id: string,
};

export type GetHcpUserQuery = {
  getHcpUser?:  {
    __typename: "HcpUser",
    email: string,
    id: string,
    name?: string | null,
    npiNumber?: string | null,
    phoneNumber?: string | null,
    role?: HcpRole | null,
  } | null,
};

export type GetInvitationQueryVariables = {
  invitationId: string,
};

export type GetInvitationQuery = {
  getInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organization?:  {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      zipCode?: string | null,
    } | null,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type GetInvitationBySenderUserIdAndTypeQueryVariables = {
  senderUserId: string,
  type: InvitationType,
};

export type GetInvitationBySenderUserIdAndTypeQuery = {
  getInvitationBySenderUserIdAndType?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organization?:  {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      zipCode?: string | null,
    } | null,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type GetInvitationByTokenQueryVariables = {
  token: string,
};

export type GetInvitationByTokenQuery = {
  getInvitationByToken?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organization?:  {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      zipCode?: string | null,
    } | null,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type GetInvitationByorganizationIdAndTypeQueryVariables = {
  organizationId: string,
  type: InvitationType,
};

export type GetInvitationByorganizationIdAndTypeQuery = {
  getInvitationByorganizationIdAndType?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organization?:  {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      zipCode?: string | null,
    } | null,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type GetMyOrganizationQuery = {
  getMyOrganization?:  {
    __typename: "Organization",
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    country?: string | null,
    department?: string | null,
    email?: string | null,
    logo?: string | null,
    name?: string | null,
    organizationId: string,
    phoneNumbers?: Array< string | null > | null,
    state?: string | null,
    zipCode?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  organizationId: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    country?: string | null,
    department?: string | null,
    email?: string | null,
    logo?: string | null,
    name?: string | null,
    organizationId: string,
    phoneNumbers?: Array< string | null > | null,
    state?: string | null,
    zipCode?: string | null,
  } | null,
};

export type GetOrganizationStatsQuery = {
  getOrganizationStats?:  {
    __typename: "OrganizationStats",
    lastKnownReading?: string | null,
    lastUploadedDate?: string | null,
    newImplants?: number | null,
    patientsEOSNo?: number | null,
  } | null,
};

export type GetPatientQueryVariables = {
  memberId: string,
};

export type GetPatientQuery = {
  getPatient?:  {
    __typename: "Patient",
    assignedHcps:  Array< {
      __typename: "Hcp",
      invitation?:  {
        __typename: "Invitation",
        conversions?: Array< string | null > | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        invitationId: string,
        organization?:  {
          __typename: "Organization",
          addressLine1?: string | null,
          addressLine2?: string | null,
          city?: string | null,
          country?: string | null,
          department?: string | null,
          email?: string | null,
          logo?: string | null,
          name?: string | null,
          organizationId: string,
          phoneNumbers?: Array< string | null > | null,
          state?: string | null,
          zipCode?: string | null,
        } | null,
        organizationId: string,
        recipientEmail?: string | null,
        recipientName?: string | null,
        recipientPhoneCountryCode?: string | null,
        recipientPhoneNumber?: string | null,
        redemptionLimit?: number | null,
        role?: HcpRole | null,
        senderUserId?: string | null,
        sentAt?: Array< string | null > | null,
        token?: string | null,
        type?: InvitationType | null,
        updatedAt?: string | null,
      } | null,
      invitationId?: string | null,
      memberId: string,
      organizationId: string,
      user?:  {
        __typename: "HcpUser",
        email: string,
        id: string,
        name?: string | null,
        npiNumber?: string | null,
        phoneNumber?: string | null,
        role?: HcpRole | null,
      } | null,
      userId?: string | null,
    } >,
    invitation?:  {
      __typename: "Invitation",
      conversions?: Array< string | null > | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      invitationId: string,
      organization?:  {
        __typename: "Organization",
        addressLine1?: string | null,
        addressLine2?: string | null,
        city?: string | null,
        country?: string | null,
        department?: string | null,
        email?: string | null,
        logo?: string | null,
        name?: string | null,
        organizationId: string,
        phoneNumbers?: Array< string | null > | null,
        state?: string | null,
        zipCode?: string | null,
      } | null,
      organizationId: string,
      recipientEmail?: string | null,
      recipientName?: string | null,
      recipientPhoneCountryCode?: string | null,
      recipientPhoneNumber?: string | null,
      redemptionLimit?: number | null,
      role?: HcpRole | null,
      senderUserId?: string | null,
      sentAt?: Array< string | null > | null,
      token?: string | null,
      type?: InvitationType | null,
      updatedAt?: string | null,
    } | null,
    invitationId?: string | null,
    lastSeizureDate?: string | null,
    medications?:  Array< {
      __typename: "Medication",
      archivedAt?: string | null,
      compound?: string | null,
      createdAt?: string | null,
      dosage?: string | null,
      frequency?: number | null,
      id: string,
      loggedAt: string,
      parentId?: string | null,
      remindersActive: boolean,
      scheduledAt?:  Array< {
        __typename: "ScheduledTime",
        key: string,
        time: string,
      } | null > | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null > | null,
    memberId: string,
    organizationId: string,
    user?:  {
      __typename: "User",
      createdAt?: string | null,
      dob?: string | null,
      email: string,
      gender?: string | null,
      id: string,
      lastSentAt?: string | null,
      name?: string | null,
      phoneNumber?: string | null,
    } | null,
    userId?: string | null,
  } | null,
};

export type GetPatientProfileQueryVariables = {
  id: string,
};

export type GetPatientProfileQuery = {
  getPatientProfile?:  {
    __typename: "PatientProfile",
    IPGs?:  Array< {
      __typename: "IPG",
      implantingDate?: string | null,
      implantingHospitalSortName?: string | null,
      model?: string | null,
      replacementReason?: string | null,
      returnDate?: string | null,
      serial?: string | null,
      softwareVersion?: string | null,
    } | null > | null,
    assignedHcps:  Array< {
      __typename: "Hcp",
      invitation?:  {
        __typename: "Invitation",
        conversions?: Array< string | null > | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        invitationId: string,
        organization?:  {
          __typename: "Organization",
          addressLine1?: string | null,
          addressLine2?: string | null,
          city?: string | null,
          country?: string | null,
          department?: string | null,
          email?: string | null,
          logo?: string | null,
          name?: string | null,
          organizationId: string,
          phoneNumbers?: Array< string | null > | null,
          state?: string | null,
          zipCode?: string | null,
        } | null,
        organizationId: string,
        recipientEmail?: string | null,
        recipientName?: string | null,
        recipientPhoneCountryCode?: string | null,
        recipientPhoneNumber?: string | null,
        redemptionLimit?: number | null,
        role?: HcpRole | null,
        senderUserId?: string | null,
        sentAt?: Array< string | null > | null,
        token?: string | null,
        type?: InvitationType | null,
        updatedAt?: string | null,
      } | null,
      invitationId?: string | null,
      memberId: string,
      organizationId: string,
      user?:  {
        __typename: "HcpUser",
        email: string,
        id: string,
        name?: string | null,
        npiNumber?: string | null,
        phoneNumber?: string | null,
        role?: HcpRole | null,
      } | null,
      userId?: string | null,
    } >,
    birthDate?: string | null,
    currentIPG?:  {
      __typename: "IPG",
      implantingDate?: string | null,
      implantingHospitalSortName?: string | null,
      model?: string | null,
      replacementReason?: string | null,
      returnDate?: string | null,
      serial?: string | null,
      softwareVersion?: string | null,
    } | null,
    deathDate?: string | null,
    disDate?: string | null,
    eosCalculation?:  {
      __typename: "EOSCalculation",
      calculationDate?: string | null,
      expectedLifeRemaining?: number | null,
      maximumLifeRemaining?: number | null,
      minimalLifeRemaining?: number | null,
    } | null,
    expDate?: string | null,
    gender?: string | null,
    id: string,
    indication?: string | null,
    lastReading?:  {
      __typename: "VNSReading",
      autoStimulationBlackoutTime?: string | null,
      autoStimulationOn?: boolean | null,
      autoStimulationThreshold?: string | null,
      autoStimulationTimePercent?: string | null,
      beatsPerMin?: string | null,
      chargePerDay?: number | null,
      dutyCycle?: string | null,
      fetDate?: string | null,
      heartbeatDetection?: number | null,
      id: string,
      importDate?: string | null,
      interrogationDate?: string | null,
      ipg?:  {
        __typename: "IPG",
        implantingDate?: string | null,
        implantingHospitalSortName?: string | null,
        model?: string | null,
        replacementReason?: string | null,
        returnDate?: string | null,
        serial?: string | null,
        softwareVersion?: string | null,
      } | null,
      isEOS?: boolean | null,
      isNEOS?: boolean | null,
      leadImpedanceStatus?: string | null,
      leadImpedanceValue?: string | null,
      magnetStimulationTime?: string | null,
      msid?: string | null,
      nightMode?: Flag | null,
      nightSettings?:  {
        __typename: "Settings",
        autoStimulationOnTime?: string | null,
        autoStimulationOutputCurrent?: string | null,
        autoStimulationPulseWidth?: string | null,
        endTime?: string | null,
        magnetOutputCurrent?: string | null,
        magnetPulseWidth?: string | null,
        magnetSignalOnTime?: string | null,
        normalFrequency?: string | null,
        normalOutputCurrent?: string | null,
        normalPulseWidth?: string | null,
        normalSignalOffTime?: string | null,
        normalSignalOnTime?: string | null,
        startTime?: string | null,
      } | null,
      normalSettings?:  {
        __typename: "Settings",
        autoStimulationOnTime?: string | null,
        autoStimulationOutputCurrent?: string | null,
        autoStimulationPulseWidth?: string | null,
        endTime?: string | null,
        magnetOutputCurrent?: string | null,
        magnetPulseWidth?: string | null,
        magnetSignalOnTime?: string | null,
        normalFrequency?: string | null,
        normalOutputCurrent?: string | null,
        normalPulseWidth?: string | null,
        normalSignalOffTime?: string | null,
        normalSignalOnTime?: string | null,
        startTime?: string | null,
      } | null,
      normalStimulationTime?: string | null,
      offTime?: string | null,
      onTime?: string | null,
      organizationId?: string | null,
      programmingMode?: string | null,
      readingMode?: VNSMode | null,
      scheduledPrograming?: Flag | null,
      seizureDetection?: Flag | null,
      sessionId?: number | null,
      tablet?:  {
        __typename: "Tablet",
        serial?: string | null,
        softwareVersion?: string | null,
      } | null,
      therapyProtocolType?: string | null,
      time?: number | null,
      totalOnTimeHours?: string | null,
      totalOnTimeMinutes?: string | null,
      uniqueOfficeVisit?: string | null,
      wand?:  {
        __typename: "Wand",
        serial?: string | null,
        softwareVersion?: string | null,
      } | null,
    } | null,
    managingPhysician?:  {
      __typename: "Physician",
      NPI?: string | null,
      name?: string | null,
    } | null,
    msid?: string | null,
    name?: string | null,
    part?: string | null,
    patientId?: string | null,
    referringPhysician?:  {
      __typename: "Physician",
      NPI?: string | null,
      name?: string | null,
    } | null,
    surgeon?:  {
      __typename: "Physician",
      NPI?: string | null,
      name?: string | null,
    } | null,
  } | null,
};

export type GetQuestionnaireResponseQueryVariables = {
  responseId: string,
};

export type GetQuestionnaireResponseQuery = {
  getQuestionnaireResponse?:  {
    __typename: "QuestionnaireResponse",
    answers:  Array< {
      __typename: "QuestionAnswer",
      answer?: string | null,
      question:  {
        __typename: "Question",
        options?:  Array< {
          __typename: "QuestionOption",
          id: string,
          label: string,
        } | null > | null,
        questionType: QuestionType,
        title: string,
      },
    } | null >,
    completedAt?: string | null,
    createdAt: string,
    id: string,
    updatedAt?: string | null,
    user?:  {
      __typename: "User",
      createdAt?: string | null,
      dob?: string | null,
      email: string,
      gender?: string | null,
      id: string,
      lastSentAt?: string | null,
      name?: string | null,
      phoneNumber?: string | null,
    } | null,
    userId?: string | null,
  } | null,
};

export type GetSeizureTypeAliasQueryVariables = {
  id: string,
  patient?: PatientFilterInput | null,
};

export type GetSeizureTypeAliasQuery = {
  getSeizureTypeAlias?:  {
    __typename: "SeizureTypeAlias",
    archivedAt?: string | null,
    aware: string,
    id: string,
    name: string,
    parentId?: string | null,
    seizureType: string,
    userId?: string | null,
  } | null,
};

export type GetSessionQueryVariables = {
  id: string,
};

export type GetSessionQuery = {
  getSession?:  {
    __typename: "SessionDetails",
    attendee:  {
      __typename: "Attendee",
      capabilities:  {
        __typename: "Capabilities",
        audio?: string | null,
        content?: string | null,
        video?: string | null,
      },
      externalUserId: string,
      id: string,
      joinToken: string,
    },
    id: string,
    meeting:  {
      __typename: "Meeting",
      externalMeetingId?: string | null,
      id: string,
      mediaPlacement:  {
        __typename: "MediaPlacement",
        audioFallbackUrl: string,
        audioHostUrl: string,
        eventIngestionUrl: string,
        screenDataUrl: string,
        screenSharingUrl: string,
        screenViewingUrl: string,
        signalingUrl: string,
        turnControlUrl: string,
      },
      mediaRegion: string,
      meetingArn: string,
      meetingHostId?: string | null,
      primaryMeetingId?: string | null,
      tenantIds?: string | null,
    },
    programmer:  {
      __typename: "Programmer",
      auth: string,
      endpoint: string,
    },
  } | null,
};

export type GetSingleDoseMedicationQueryVariables = {
  id: string,
  patient?: PatientFilterInput | null,
};

export type GetSingleDoseMedicationQuery = {
  getSingleDoseMedication?:  {
    __typename: "SingleDoseMedication",
    archivedAt?: string | null,
    compound?: string | null,
    createdAt?: string | null,
    dosage?: string | null,
    emergency: boolean,
    id: string,
    loggedAt: string,
    parentId?: string | null,
    updatedAt?: string | null,
    userId?: string | null,
  } | null,
};

export type GetVnsDeviceQueryVariables = {
  patientProfileId: string,
};

export type GetVnsDeviceQuery = {
  getVnsDevice?:  {
    __typename: "VnsDeviceDetail",
    assignedHcps:  Array< {
      __typename: "Hcp",
      invitation?:  {
        __typename: "Invitation",
        conversions?: Array< string | null > | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        invitationId: string,
        organization?:  {
          __typename: "Organization",
          addressLine1?: string | null,
          addressLine2?: string | null,
          city?: string | null,
          country?: string | null,
          department?: string | null,
          email?: string | null,
          logo?: string | null,
          name?: string | null,
          organizationId: string,
          phoneNumbers?: Array< string | null > | null,
          state?: string | null,
          zipCode?: string | null,
        } | null,
        organizationId: string,
        recipientEmail?: string | null,
        recipientName?: string | null,
        recipientPhoneCountryCode?: string | null,
        recipientPhoneNumber?: string | null,
        redemptionLimit?: number | null,
        role?: HcpRole | null,
        senderUserId?: string | null,
        sentAt?: Array< string | null > | null,
        token?: string | null,
        type?: InvitationType | null,
        updatedAt?: string | null,
      } | null,
      invitationId?: string | null,
      memberId: string,
      organizationId: string,
      user?:  {
        __typename: "HcpUser",
        email: string,
        id: string,
        name?: string | null,
        npiNumber?: string | null,
        phoneNumber?: string | null,
        role?: HcpRole | null,
      } | null,
      userId?: string | null,
    } >,
    autostimOnTime?: number | null,
    autostimOutputCurrent?: string | null,
    autostimPulseWidth?: number | null,
    autostimThreshold?: number | null,
    blc?: string | null,
    generatorModel: string,
    generatorSerialNumber: number,
    implantedDate?: string | null,
    lastKnownReading?: string | null,
    lowHeartRateThreshold?: number | null,
    magnetOnTime?: number | null,
    magnetOutputCurrent?: string | null,
    magnetPulseWidth?: number | null,
    normalDutyCycle?: string | null,
    normalFrequency?: number | null,
    normalOffTime?: string | null,
    normalOnTime?: number | null,
    normalOutputCurrent?: string | null,
    normalPulseWidth?: number | null,
    patientDob?: string | null,
    patientName?: string | null,
    patientProfileId: string,
    pronePositionDetection?: string | null,
    readings:  Array< {
      __typename: "VnsDeviceReading",
      leadImpedanceValue?: number | null,
      mode?: string | null,
      normalFrequency?: number | null,
      normalOffTime?: string | null,
      normalOnTime?: number | null,
      normalOutputCurrent?: string | null,
      normalPulseWidth?: number | null,
      readingDate: string,
      tabletSerialNumber?: string | null,
      tabletSoftwareModel?: string | null,
    } >,
    tachycardiaDetection?: string | null,
  } | null,
};

export type GetVnsSessionReportQueryVariables = {
  patientProfileId: string,
  readingDateTime: string,
  tabletSerialNumber: string,
};

export type GetVnsSessionReportQuery = {
  getVnsSessionReport?:  {
    __typename: "VnsSessionReport",
    appModelAndVer?: string | null,
    final:  {
      __typename: "VnsSessionReportSettings",
      autostimOnTime?: number | null,
      autostimOutputCurrent?: string | null,
      autostimPulseWidth?: number | null,
      autostimThreshold?: number | null,
      batteryHealth?: string | null,
      batteryPercentage?: number | null,
      daynightProgrammingModeStatus?: string | null,
      heartbeatDetection?: number | null,
      leadImpedanceStatus?: string | null,
      leadImpedanceValue?: number | null,
      lowHeartRate?: string | null,
      lowHeartRateThreshold?: number | null,
      magnetOnTime?: number | null,
      magnetOutputCurrent?: string | null,
      magnetPulseWidth?: number | null,
      nightModeAutoStimOnTime?: number | null,
      nightModeAutoStimOutputCurrent?: string | null,
      nightModeAutoStimPulseWidth?: number | null,
      nightModeEndTime?: string | null,
      nightModeMagnetOutputCurrent?: string | null,
      nightModeMagnetPulseWidth?: number | null,
      nightModeMagnetSignalOnTime?: number | null,
      nightModeNormalDutyCycle?: string | null,
      nightModeNormalFrequency?: number | null,
      nightModeNormalOutputCurrent?: string | null,
      nightModeNormalPulseWidth?: number | null,
      nightModeNormalSignalOffTime?: string | null,
      nightModeNormalSignalOnTime?: number | null,
      nightModeStartTime?: string | null,
      nightModeThresholdForAutoStim?: number | null,
      normalDutyCycle?: string | null,
      normalFrequency?: number | null,
      normalOffTime?: string | null,
      normalOnTime?: number | null,
      normalOutputCurrent?: string | null,
      normalOutputCurrentStatus?: string | null,
      normalPulseWidth?: number | null,
      parametersAutoCorrected?: number | null,
      programmingMode?: string | null,
      pronePositionDetection?: string | null,
      scheduledProgramGuidedPlans:  Array< {
        __typename: "VnsSessionReportStep",
        guidedStepAutostimOutputCurrent?: string | null,
        guidedStepMagnetOutputCurrent?: string | null,
        guidedStepNormalOutputCurrent?: string | null,
        schedProgStepDate?: string | null,
        stepId: number,
      } >,
      tachycardiaDetection?: string | null,
      therapyRState?: number | null,
    },
    generatorModel?: string | null,
    generatorSerialNumber?: number | null,
    implantedDate?: string | null,
    initial:  {
      __typename: "VnsSessionReportSettings",
      autostimOnTime?: number | null,
      autostimOutputCurrent?: string | null,
      autostimPulseWidth?: number | null,
      autostimThreshold?: number | null,
      batteryHealth?: string | null,
      batteryPercentage?: number | null,
      daynightProgrammingModeStatus?: string | null,
      heartbeatDetection?: number | null,
      leadImpedanceStatus?: string | null,
      leadImpedanceValue?: number | null,
      lowHeartRate?: string | null,
      lowHeartRateThreshold?: number | null,
      magnetOnTime?: number | null,
      magnetOutputCurrent?: string | null,
      magnetPulseWidth?: number | null,
      nightModeAutoStimOnTime?: number | null,
      nightModeAutoStimOutputCurrent?: string | null,
      nightModeAutoStimPulseWidth?: number | null,
      nightModeEndTime?: string | null,
      nightModeMagnetOutputCurrent?: string | null,
      nightModeMagnetPulseWidth?: number | null,
      nightModeMagnetSignalOnTime?: number | null,
      nightModeNormalDutyCycle?: string | null,
      nightModeNormalFrequency?: number | null,
      nightModeNormalOutputCurrent?: string | null,
      nightModeNormalPulseWidth?: number | null,
      nightModeNormalSignalOffTime?: string | null,
      nightModeNormalSignalOnTime?: number | null,
      nightModeStartTime?: string | null,
      nightModeThresholdForAutoStim?: number | null,
      normalDutyCycle?: string | null,
      normalFrequency?: number | null,
      normalOffTime?: string | null,
      normalOnTime?: number | null,
      normalOutputCurrent?: string | null,
      normalOutputCurrentStatus?: string | null,
      normalPulseWidth?: number | null,
      parametersAutoCorrected?: number | null,
      programmingMode?: string | null,
      pronePositionDetection?: string | null,
      scheduledProgramGuidedPlans:  Array< {
        __typename: "VnsSessionReportStep",
        guidedStepAutostimOutputCurrent?: string | null,
        guidedStepMagnetOutputCurrent?: string | null,
        guidedStepNormalOutputCurrent?: string | null,
        schedProgStepDate?: string | null,
        stepId: number,
      } >,
      tachycardiaDetection?: string | null,
      therapyRState?: number | null,
    },
    patientId?: string | null,
    readingDateTime?: string | null,
    sessionEndDateTime?: string | null,
    sessionStartDateTime?: string | null,
    tabletSerialNumber?: string | null,
    tabletSoftwareModel?: string | null,
  } | null,
};

export type ListAuraEventQueryVariables = {
  filter?: EventFilterInput | null,
};

export type ListAuraEventQuery = {
  listAuraEvent?:  {
    __typename: "AuraEventList",
    items?:  Array< {
      __typename: "AuraEvent",
      createdAt?: string | null,
      eventAt: string,
      id: string,
      loggedAt: string,
      rescueMedsDosage?: string | null,
      rescueMedsName?: string | null,
      rescueMedsUsed: boolean,
      source?: EventSource | null,
      updatedAt?: string | null,
      userId?: string | null,
      userNotes?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListBLCFileUploadsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBLCFileUploadsQuery = {
  listBLCFileUploads?:  {
    __typename: "BLCFileUploadList",
    items?:  Array< {
      __typename: "BLCFileUpload",
      createdAt?: string | null,
      errorMessage?: string | null,
      files?:  Array< {
        __typename: "BLCFile",
        key?: string | null,
        source?: string | null,
        status?: BLCFileStatus | null,
      } | null > | null,
      id: string,
      organizationId: string,
      overallStatus?: BLCFileUploadStatus | null,
      packageName?: string | null,
      updatedAt?: string | null,
      userId: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDomainsQueryVariables = {
  organizationId: string,
};

export type ListDomainsQuery = {
  listDomains:  Array< {
    __typename: "OrganizationDomain",
    domain: string,
    id: string,
    organizationId: string,
    signatoryUserId: string,
  } | null >,
};

export type ListHcpQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  organizationId: string,
};

export type ListHcpQuery = {
  listHcp?:  {
    __typename: "HcpList",
    items?:  Array< {
      __typename: "Hcp",
      invitation?:  {
        __typename: "Invitation",
        conversions?: Array< string | null > | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        invitationId: string,
        organization?:  {
          __typename: "Organization",
          addressLine1?: string | null,
          addressLine2?: string | null,
          city?: string | null,
          country?: string | null,
          department?: string | null,
          email?: string | null,
          logo?: string | null,
          name?: string | null,
          organizationId: string,
          phoneNumbers?: Array< string | null > | null,
          state?: string | null,
          zipCode?: string | null,
        } | null,
        organizationId: string,
        recipientEmail?: string | null,
        recipientName?: string | null,
        recipientPhoneCountryCode?: string | null,
        recipientPhoneNumber?: string | null,
        redemptionLimit?: number | null,
        role?: HcpRole | null,
        senderUserId?: string | null,
        sentAt?: Array< string | null > | null,
        token?: string | null,
        type?: InvitationType | null,
        updatedAt?: string | null,
      } | null,
      invitationId?: string | null,
      memberId: string,
      organizationId: string,
      user?:  {
        __typename: "HcpUser",
        email: string,
        id: string,
        name?: string | null,
        npiNumber?: string | null,
        phoneNumber?: string | null,
        role?: HcpRole | null,
      } | null,
      userId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListMediaFileQueryVariables = {
  filter: MediaFileFilterInput,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaFileQuery = {
  listMediaFile?:  {
    __typename: "MediaFileList",
    items:  Array< {
      __typename: "MediaFile",
      androidUrl?: string | null,
      createdAt?: string | null,
      fileStatus: MediaFileStatus,
      iOSUrl?: string | null,
      id: string,
      metaData?: string | null,
      mimeType: string,
      ownerModelId: string,
      ownerModelName: MediaFileOwnerModelName,
      sourceS3Key: string,
      thumbnailUrl?: string | null,
      updatedAt?: string | null,
      userId?: string | null,
      webUrl?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMedicalDeviceQueryVariables = {
  patient?: PatientFilterInput | null,
};

export type ListMedicalDeviceQuery = {
  listMedicalDevice?:  {
    __typename: "MedicalDeviceList",
    items?:  Array< {
      __typename: "MedicalDevice",
      archivedAt?: string | null,
      cardPictureBack?: string | null,
      cardPictureFront?: string | null,
      deviceModel?: string | null,
      healthcareContactName?: string | null,
      healthcareContactPhoneNumber?: string | null,
      id: string,
      implantedAt?: string | null,
      lead?: string | null,
      loggedAt: string,
      modelName?: string | null,
      originalMedicalDeviceId?: string | null,
      otherTypeName?: string | null,
      reImplanted?: boolean | null,
      reImplantedAt?: string | null,
      serialNumber?: string | null,
      type: MedicalDeviceType,
      userId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListMedicationQueryVariables = {
  patient?: PatientFilterInput | null,
};

export type ListMedicationQuery = {
  listMedication?:  {
    __typename: "MedicationList",
    items?:  Array< {
      __typename: "Medication",
      archivedAt?: string | null,
      compound?: string | null,
      createdAt?: string | null,
      dosage?: string | null,
      frequency?: number | null,
      id: string,
      loggedAt: string,
      parentId?: string | null,
      remindersActive: boolean,
      scheduledAt?:  Array< {
        __typename: "ScheduledTime",
        key: string,
        time: string,
      } | null > | null,
      updatedAt?: string | null,
      userId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListMedicationEventQueryVariables = {
  filter?: EventFilterInput | null,
};

export type ListMedicationEventQuery = {
  listMedicationEvent?:  {
    __typename: "MedicationEventList",
    items?:  Array< {
      __typename: "MedicationEvent",
      createdAt?: string | null,
      eventAt: string,
      id: string,
      loggedAt: string,
      medication?:  {
        __typename: "Medication",
        archivedAt?: string | null,
        compound?: string | null,
        createdAt?: string | null,
        dosage?: string | null,
        frequency?: number | null,
        id: string,
        loggedAt: string,
        parentId?: string | null,
        remindersActive: boolean,
        scheduledAt?:  Array< {
          __typename: "ScheduledTime",
          key: string,
          time: string,
        } | null > | null,
        updatedAt?: string | null,
        userId?: string | null,
      } | null,
      medicationId: string,
      scheduledAt?: string | null,
      scheduledTimeId: string,
      source?: EventSource | null,
      updatedAt?: string | null,
      userId?: string | null,
      userResponse: MedicationEventUserResponse,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListMoodEventQueryVariables = {
  filter?: EventFilterInput | null,
};

export type ListMoodEventQuery = {
  listMoodEvent?:  {
    __typename: "MoodEventList",
    items?:  Array< {
      __typename: "MoodEvent",
      createdAt?: string | null,
      eventAt: string,
      id: string,
      loggedAt: string,
      mood?: Mood | null,
      source?: EventSource | null,
      type?: MoodEventType | null,
      updatedAt?: string | null,
      userId?: string | null,
      userNotes?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPatientQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  organizationId: string,
};

export type ListPatientQuery = {
  listPatient?:  {
    __typename: "PatientList",
    items?:  Array< {
      __typename: "Patient",
      assignedHcps:  Array< {
        __typename: "Hcp",
        invitation?:  {
          __typename: "Invitation",
          conversions?: Array< string | null > | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          invitationId: string,
          organization?:  {
            __typename: "Organization",
            addressLine1?: string | null,
            addressLine2?: string | null,
            city?: string | null,
            country?: string | null,
            department?: string | null,
            email?: string | null,
            logo?: string | null,
            name?: string | null,
            organizationId: string,
            phoneNumbers?: Array< string | null > | null,
            state?: string | null,
            zipCode?: string | null,
          } | null,
          organizationId: string,
          recipientEmail?: string | null,
          recipientName?: string | null,
          recipientPhoneCountryCode?: string | null,
          recipientPhoneNumber?: string | null,
          redemptionLimit?: number | null,
          role?: HcpRole | null,
          senderUserId?: string | null,
          sentAt?: Array< string | null > | null,
          token?: string | null,
          type?: InvitationType | null,
          updatedAt?: string | null,
        } | null,
        invitationId?: string | null,
        memberId: string,
        organizationId: string,
        user?:  {
          __typename: "HcpUser",
          email: string,
          id: string,
          name?: string | null,
          npiNumber?: string | null,
          phoneNumber?: string | null,
          role?: HcpRole | null,
        } | null,
        userId?: string | null,
      } >,
      invitation?:  {
        __typename: "Invitation",
        conversions?: Array< string | null > | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        invitationId: string,
        organization?:  {
          __typename: "Organization",
          addressLine1?: string | null,
          addressLine2?: string | null,
          city?: string | null,
          country?: string | null,
          department?: string | null,
          email?: string | null,
          logo?: string | null,
          name?: string | null,
          organizationId: string,
          phoneNumbers?: Array< string | null > | null,
          state?: string | null,
          zipCode?: string | null,
        } | null,
        organizationId: string,
        recipientEmail?: string | null,
        recipientName?: string | null,
        recipientPhoneCountryCode?: string | null,
        recipientPhoneNumber?: string | null,
        redemptionLimit?: number | null,
        role?: HcpRole | null,
        senderUserId?: string | null,
        sentAt?: Array< string | null > | null,
        token?: string | null,
        type?: InvitationType | null,
        updatedAt?: string | null,
      } | null,
      invitationId?: string | null,
      lastSeizureDate?: string | null,
      medications?:  Array< {
        __typename: "Medication",
        archivedAt?: string | null,
        compound?: string | null,
        createdAt?: string | null,
        dosage?: string | null,
        frequency?: number | null,
        id: string,
        loggedAt: string,
        parentId?: string | null,
        remindersActive: boolean,
        scheduledAt?:  Array< {
          __typename: "ScheduledTime",
          key: string,
          time: string,
        } | null > | null,
        updatedAt?: string | null,
        userId?: string | null,
      } | null > | null,
      memberId: string,
      organizationId: string,
      user?:  {
        __typename: "User",
        createdAt?: string | null,
        dob?: string | null,
        email: string,
        gender?: string | null,
        id: string,
        lastSentAt?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
      } | null,
      userId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPatientProfilesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientProfilesQuery = {
  listPatientProfiles?:  {
    __typename: "PatientProfileList",
    items?:  Array< {
      __typename: "PatientProfile",
      IPGs?:  Array< {
        __typename: "IPG",
        implantingDate?: string | null,
        implantingHospitalSortName?: string | null,
        model?: string | null,
        replacementReason?: string | null,
        returnDate?: string | null,
        serial?: string | null,
        softwareVersion?: string | null,
      } | null > | null,
      assignedHcps:  Array< {
        __typename: "Hcp",
        invitation?:  {
          __typename: "Invitation",
          conversions?: Array< string | null > | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          invitationId: string,
          organization?:  {
            __typename: "Organization",
            addressLine1?: string | null,
            addressLine2?: string | null,
            city?: string | null,
            country?: string | null,
            department?: string | null,
            email?: string | null,
            logo?: string | null,
            name?: string | null,
            organizationId: string,
            phoneNumbers?: Array< string | null > | null,
            state?: string | null,
            zipCode?: string | null,
          } | null,
          organizationId: string,
          recipientEmail?: string | null,
          recipientName?: string | null,
          recipientPhoneCountryCode?: string | null,
          recipientPhoneNumber?: string | null,
          redemptionLimit?: number | null,
          role?: HcpRole | null,
          senderUserId?: string | null,
          sentAt?: Array< string | null > | null,
          token?: string | null,
          type?: InvitationType | null,
          updatedAt?: string | null,
        } | null,
        invitationId?: string | null,
        memberId: string,
        organizationId: string,
        user?:  {
          __typename: "HcpUser",
          email: string,
          id: string,
          name?: string | null,
          npiNumber?: string | null,
          phoneNumber?: string | null,
          role?: HcpRole | null,
        } | null,
        userId?: string | null,
      } >,
      birthDate?: string | null,
      currentIPG?:  {
        __typename: "IPG",
        implantingDate?: string | null,
        implantingHospitalSortName?: string | null,
        model?: string | null,
        replacementReason?: string | null,
        returnDate?: string | null,
        serial?: string | null,
        softwareVersion?: string | null,
      } | null,
      deathDate?: string | null,
      disDate?: string | null,
      eosCalculation?:  {
        __typename: "EOSCalculation",
        calculationDate?: string | null,
        expectedLifeRemaining?: number | null,
        maximumLifeRemaining?: number | null,
        minimalLifeRemaining?: number | null,
      } | null,
      expDate?: string | null,
      gender?: string | null,
      id: string,
      indication?: string | null,
      lastReading?:  {
        __typename: "VNSReading",
        autoStimulationBlackoutTime?: string | null,
        autoStimulationOn?: boolean | null,
        autoStimulationThreshold?: string | null,
        autoStimulationTimePercent?: string | null,
        beatsPerMin?: string | null,
        chargePerDay?: number | null,
        dutyCycle?: string | null,
        fetDate?: string | null,
        heartbeatDetection?: number | null,
        id: string,
        importDate?: string | null,
        interrogationDate?: string | null,
        ipg?:  {
          __typename: "IPG",
          implantingDate?: string | null,
          implantingHospitalSortName?: string | null,
          model?: string | null,
          replacementReason?: string | null,
          returnDate?: string | null,
          serial?: string | null,
          softwareVersion?: string | null,
        } | null,
        isEOS?: boolean | null,
        isNEOS?: boolean | null,
        leadImpedanceStatus?: string | null,
        leadImpedanceValue?: string | null,
        magnetStimulationTime?: string | null,
        msid?: string | null,
        nightMode?: Flag | null,
        nightSettings?:  {
          __typename: "Settings",
          autoStimulationOnTime?: string | null,
          autoStimulationOutputCurrent?: string | null,
          autoStimulationPulseWidth?: string | null,
          endTime?: string | null,
          magnetOutputCurrent?: string | null,
          magnetPulseWidth?: string | null,
          magnetSignalOnTime?: string | null,
          normalFrequency?: string | null,
          normalOutputCurrent?: string | null,
          normalPulseWidth?: string | null,
          normalSignalOffTime?: string | null,
          normalSignalOnTime?: string | null,
          startTime?: string | null,
        } | null,
        normalSettings?:  {
          __typename: "Settings",
          autoStimulationOnTime?: string | null,
          autoStimulationOutputCurrent?: string | null,
          autoStimulationPulseWidth?: string | null,
          endTime?: string | null,
          magnetOutputCurrent?: string | null,
          magnetPulseWidth?: string | null,
          magnetSignalOnTime?: string | null,
          normalFrequency?: string | null,
          normalOutputCurrent?: string | null,
          normalPulseWidth?: string | null,
          normalSignalOffTime?: string | null,
          normalSignalOnTime?: string | null,
          startTime?: string | null,
        } | null,
        normalStimulationTime?: string | null,
        offTime?: string | null,
        onTime?: string | null,
        organizationId?: string | null,
        programmingMode?: string | null,
        readingMode?: VNSMode | null,
        scheduledPrograming?: Flag | null,
        seizureDetection?: Flag | null,
        sessionId?: number | null,
        tablet?:  {
          __typename: "Tablet",
          serial?: string | null,
          softwareVersion?: string | null,
        } | null,
        therapyProtocolType?: string | null,
        time?: number | null,
        totalOnTimeHours?: string | null,
        totalOnTimeMinutes?: string | null,
        uniqueOfficeVisit?: string | null,
        wand?:  {
          __typename: "Wand",
          serial?: string | null,
          softwareVersion?: string | null,
        } | null,
      } | null,
      managingPhysician?:  {
        __typename: "Physician",
        NPI?: string | null,
        name?: string | null,
      } | null,
      msid?: string | null,
      name?: string | null,
      part?: string | null,
      patientId?: string | null,
      referringPhysician?:  {
        __typename: "Physician",
        NPI?: string | null,
        name?: string | null,
      } | null,
      surgeon?:  {
        __typename: "Physician",
        NPI?: string | null,
        name?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPatientVNSReadingsHistoryQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  patientProfileId: string,
};

export type ListPatientVNSReadingsHistoryQuery = {
  listPatientVNSReadingsHistory?:  {
    __typename: "VNSReadingsList",
    items?:  Array< {
      __typename: "VNSReading",
      autoStimulationBlackoutTime?: string | null,
      autoStimulationOn?: boolean | null,
      autoStimulationThreshold?: string | null,
      autoStimulationTimePercent?: string | null,
      beatsPerMin?: string | null,
      chargePerDay?: number | null,
      dutyCycle?: string | null,
      fetDate?: string | null,
      heartbeatDetection?: number | null,
      id: string,
      importDate?: string | null,
      interrogationDate?: string | null,
      ipg?:  {
        __typename: "IPG",
        implantingDate?: string | null,
        implantingHospitalSortName?: string | null,
        model?: string | null,
        replacementReason?: string | null,
        returnDate?: string | null,
        serial?: string | null,
        softwareVersion?: string | null,
      } | null,
      isEOS?: boolean | null,
      isNEOS?: boolean | null,
      leadImpedanceStatus?: string | null,
      leadImpedanceValue?: string | null,
      magnetStimulationTime?: string | null,
      msid?: string | null,
      nightMode?: Flag | null,
      nightSettings?:  {
        __typename: "Settings",
        autoStimulationOnTime?: string | null,
        autoStimulationOutputCurrent?: string | null,
        autoStimulationPulseWidth?: string | null,
        endTime?: string | null,
        magnetOutputCurrent?: string | null,
        magnetPulseWidth?: string | null,
        magnetSignalOnTime?: string | null,
        normalFrequency?: string | null,
        normalOutputCurrent?: string | null,
        normalPulseWidth?: string | null,
        normalSignalOffTime?: string | null,
        normalSignalOnTime?: string | null,
        startTime?: string | null,
      } | null,
      normalSettings?:  {
        __typename: "Settings",
        autoStimulationOnTime?: string | null,
        autoStimulationOutputCurrent?: string | null,
        autoStimulationPulseWidth?: string | null,
        endTime?: string | null,
        magnetOutputCurrent?: string | null,
        magnetPulseWidth?: string | null,
        magnetSignalOnTime?: string | null,
        normalFrequency?: string | null,
        normalOutputCurrent?: string | null,
        normalPulseWidth?: string | null,
        normalSignalOffTime?: string | null,
        normalSignalOnTime?: string | null,
        startTime?: string | null,
      } | null,
      normalStimulationTime?: string | null,
      offTime?: string | null,
      onTime?: string | null,
      organizationId?: string | null,
      programmingMode?: string | null,
      readingMode?: VNSMode | null,
      scheduledPrograming?: Flag | null,
      seizureDetection?: Flag | null,
      sessionId?: number | null,
      tablet?:  {
        __typename: "Tablet",
        serial?: string | null,
        softwareVersion?: string | null,
      } | null,
      therapyProtocolType?: string | null,
      time?: number | null,
      totalOnTimeHours?: string | null,
      totalOnTimeMinutes?: string | null,
      uniqueOfficeVisit?: string | null,
      wand?:  {
        __typename: "Wand",
        serial?: string | null,
        softwareVersion?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListQuestionnaireResponsesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  userId?: string | null,
};

export type ListQuestionnaireResponsesQuery = {
  listQuestionnaireResponses?:  {
    __typename: "QuestionnaireResponseList",
    items?:  Array< {
      __typename: "QuestionnaireResponse",
      answers:  Array< {
        __typename: "QuestionAnswer",
        answer?: string | null,
        question:  {
          __typename: "Question",
          options?:  Array< {
            __typename: "QuestionOption",
            id: string,
            label: string,
          } | null > | null,
          questionType: QuestionType,
          title: string,
        },
      } | null >,
      completedAt?: string | null,
      createdAt: string,
      id: string,
      updatedAt?: string | null,
      user?:  {
        __typename: "User",
        createdAt?: string | null,
        dob?: string | null,
        email: string,
        gender?: string | null,
        id: string,
        lastSentAt?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
      } | null,
      userId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSeizureEventQueryVariables = {
  filter?: EventFilterInput | null,
};

export type ListSeizureEventQuery = {
  listSeizureEvent?:  {
    __typename: "SeizureEventList",
    items?:  Array< {
      __typename: "SeizureEvent",
      aware?: YesNo | null,
      convulsive?: YesNo | null,
      createdAt?: string | null,
      duration?: number | null,
      eventAt: string,
      eventLength?: number | null,
      id: string,
      intensity?: SeizureEventIntensity | null,
      intensityNotes?: string | null,
      loggedAt: string,
      recoveryTime?: number | null,
      rescueMedsDosage?: string | null,
      rescueMedsName?: string | null,
      rescueMedsUsed: boolean,
      seizureType?: string | null,
      seizureTypeAlias?:  {
        __typename: "SeizureTypeAlias",
        archivedAt?: string | null,
        aware: string,
        id: string,
        name: string,
        parentId?: string | null,
        seizureType: string,
        userId?: string | null,
      } | null,
      seizureTypeAliasId?: string | null,
      source?: EventSource | null,
      timePickerUsed: boolean,
      triggers?: Array< SeizureEventTrigger | null > | null,
      updatedAt?: string | null,
      userId?: string | null,
      userNotes?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSessionsQuery = {
  listSessions?:  Array< {
    __typename: "Session",
    hcpConnected: boolean,
    hcpName?: string | null,
    id: string,
    patientConnected: boolean,
    patientName?: string | null,
  } | null > | null,
};

export type ListSideEffectEventQueryVariables = {
  filter?: EventFilterInput | null,
};

export type ListSideEffectEventQuery = {
  listSideEffectEvent?:  {
    __typename: "SideEffectEventList",
    items?:  Array< {
      __typename: "SideEffectEvent",
      createdAt?: string | null,
      eventAt: string,
      id: string,
      loggedAt: string,
      sideEffects?: Array< SideEffects | null > | null,
      source?: EventSource | null,
      updatedAt?: string | null,
      userId?: string | null,
      userNotes?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSingleDoseImplantEventQueryVariables = {
  filter?: EventFilterInput | null,
};

export type ListSingleDoseImplantEventQuery = {
  listSingleDoseImplantEvent?:  {
    __typename: "SingleDoseImplantEventList",
    items?:  Array< {
      __typename: "SingleDoseImplantEvent",
      createdAt?: string | null,
      eventAt: string,
      id: string,
      loggedAt: string,
      updatedAt?: string | null,
      userId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSingleDoseMedicationEventQueryVariables = {
  filter?: EventFilterInput | null,
};

export type ListSingleDoseMedicationEventQuery = {
  listSingleDoseMedicationEvent?:  {
    __typename: "SingleDoseMedicationEventList",
    items?:  Array< {
      __typename: "SingleDoseMedicationEvent",
      compound?: string | null,
      createdAt?: string | null,
      dosage?: string | null,
      emergency: boolean,
      eventAt: string,
      id: string,
      loggedAt: string,
      medicationId?: string | null,
      updatedAt?: string | null,
      userId?: string | null,
      userNotes?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTabletsQuery = {
  listTablets?:  {
    __typename: "BlcTabletList",
    items:  Array< {
      __typename: "BlcTablet",
      createdAt?: string | null,
      id: string,
      lastInterrogationDate?: string | null,
      nickname?: string | null,
      organizationId: string,
      serialNumber: string,
      totalPatients?: number | null,
      totalReadings?: number | null,
      updatedAt?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListVnsDeviceQuery = {
  listVnsDevice:  {
    __typename: "VnsDeviceSummaryList",
    items:  Array< {
      __typename: "VnsDeviceSummary",
      assignedHcps:  Array< {
        __typename: "Hcp",
        invitation?:  {
          __typename: "Invitation",
          conversions?: Array< string | null > | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          invitationId: string,
          organization?:  {
            __typename: "Organization",
            addressLine1?: string | null,
            addressLine2?: string | null,
            city?: string | null,
            country?: string | null,
            department?: string | null,
            email?: string | null,
            logo?: string | null,
            name?: string | null,
            organizationId: string,
            phoneNumbers?: Array< string | null > | null,
            state?: string | null,
            zipCode?: string | null,
          } | null,
          organizationId: string,
          recipientEmail?: string | null,
          recipientName?: string | null,
          recipientPhoneCountryCode?: string | null,
          recipientPhoneNumber?: string | null,
          redemptionLimit?: number | null,
          role?: HcpRole | null,
          senderUserId?: string | null,
          sentAt?: Array< string | null > | null,
          token?: string | null,
          type?: InvitationType | null,
          updatedAt?: string | null,
        } | null,
        invitationId?: string | null,
        memberId: string,
        organizationId: string,
        user?:  {
          __typename: "HcpUser",
          email: string,
          id: string,
          name?: string | null,
          npiNumber?: string | null,
          phoneNumber?: string | null,
          role?: HcpRole | null,
        } | null,
        userId?: string | null,
      } >,
      blc?: string | null,
      generatorModel: string,
      generatorSerialNumber: number,
      implantedDate?: string | null,
      lastKnownReading?: string | null,
      normalOutputCurrent?: string | null,
      normalPulseWidth?: number | null,
      patientDob?: string | null,
      patientName?: string | null,
      patientProfileId: string,
    } >,
  },
};

export type ListVnsTabletQuery = {
  listVnsTablet:  {
    __typename: "VnsTabletList",
    items:  Array< {
      __typename: "VnsTablet",
      id: string,
      lastReading?: string | null,
      nickname: string,
      patients: number,
      readings: number,
      serialNumber: string,
    } >,
  },
};
