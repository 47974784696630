import { bp } from "epsy-ui-react"
import styled from "styled-components"

const paddingBp = {
  mobile: 16,
  medium: 24,
  large: 48
}

export const viewContainerMaxWidth = 1334

const ViewContainer = styled.div`
  ${bp.each("padding", paddingBp, "px")};
  max-width: ${viewContainerMaxWidth}px;
  width: 100%;
  margin: 0 auto;
`

export default ViewContainer
