import { ResponsiveValue } from "src/style/breakpoints"

const defaultGutter = {
  mobile: 16,
  medium: 32,
  large: 32
}

const navActiveLineHeight = 4
const navHeight: ResponsiveValue<number> = {
  mobile: 72,
  medium: 72,
  large: 72
}

const pageHorizontalMargin: ResponsiveValue<number> = {
  mobile: 16,
  medium: 48,
  large: 48
}
const mainVerticalMargin: ResponsiveValue<number> = {
  mobile: 32,
  medium: 48,
  large: 48
}

export const styleVars = {
  defaultGutter,
  navHeight,
  pageHorizontalMargin,
  mainVerticalMargin,
  navActiveLineHeight
}
export default styleVars
