import { template } from "src/util/template"
import { Theme } from "../../API"
import sentry from "../sentry"
import { getTheme } from "../whiteLabel"

const textTranslation = {
  "APP_CONFIG/ENVIRONMENT": "Environment: {{env}}",
  "APP_CONFIG/VERSION": "Version: {{version}}",
  "AUTH/LOGO/ALT": "Epsy Hub Logo",
  "LIVANOVA/AUTH/LOGO/ALT": "LivaNova Logo",

  "ACCOUNT/PROFILE_FORM/FIELD/NAME": "Name",
  "ACCOUNT/PROFILE_FORM/FIELD/EMAIL": "Email Address",
  "ACCOUNT/PROFILE_FORM/FIELD/NPI": "NPI",
  "ACCOUNT/PROFILE_FORM/FIELD/PASSWORD": "Password",
  "ACCOUNT/PROFILE_FORM/FIELD/PASSWORD/VALUE": "●●●●●●●●",
  "ACCOUNT/PROFILE_FORM/FIELD/PASSWORD/BUTTON/CHANGE": "Change",
  "ACCOUNT/PROFILE_FORM/BUTTON/SUBMIT": "Save changes",
  "ACCOUNT/PROFILE_FORM/SUBMIT/SUCCESS": "Your profile has been updated",

  "ACCOUNT/PASSWORD_FORM/TITLE": "Change your password",
  "ACCOUNT/PASSWORD_FORM/FIELD/CURRENT_PASSWORD": "Current password",
  "ACCOUNT/PASSWORD_FORM/FIELD/CONFIRM_NEW_PASSWORD": "Confirm new password",
  "ACCOUNT/PASSWORD_FORM/FIELD/PASSWORD": "Password",
  "ACCOUNT/PASSWORD_FORM/BUTTON/SUBMIT": "Change password",
  "ACCOUNT/PASSWORD_FORM/SUCCESS/SUBMIT": "Your password has been changed!",

  "ADD_PATIENTS/TITLE": "Invite your patients to Epsy",
  "ADD_PATIENTS/BODY":
    "Choose a method below to connect with your patients.\nThe invitation will come from you, but we won't share your contact details.",
  "ADD_PATIENTS/SUBMIT_BUTTON": "Invite",
  "ADD_PATIENTS/ADD_ROW_BUTTON": "Add another patient",
  "ADD_PATIENTS/SUCCESS/MODAL/TITLE": "Your patients have been invited to join Epsy",
  "ADD_PATIENTS/SUCCESS/MODAL/ICON_TEXT": "Sent",
  "ADD_PATIENTS/SUCCESS/MODAL/BODY":
    "Once they’ve accepted your invite in the app,\nyou’ll be able to view their tracked progress.",
  "ADD_PATIENTS/FIELD/INVITE_MEDIUM/OPTION/SMS": "Send an SMS",
  "ADD_PATIENTS/FIELD/INVITE_MEDIUM/OPTION/EMAIL": "Send an email",
  "ADD_PATIENTS/FIELD/INVITE_MEDIUM/OPTION/EMR": "Copy for EMR",
  "ADD_PATIENTS/FIELD/INVITE_MEDIUM/OPTION/CODE": "Invite code",
  "ADD_PATIENTS/EMR/HCP_INSTRUCTIONS": "Copy and send the following instructions via EMR to your patients",
  "ADD_PATIENTS/EMR/INVITATION_CONTENT":
    "I’m inviting you to join Epsy, the top rated app to manage your epilepsy.\n\nAccept my invite so we can track your treatment plan, together.\n\n1. Get the app at the following link:\n{{appLink}}\n\n2. Create your account and enter my invite code {{token}} by going to your\nProfile > Data Sharing\n\nThank you,\n{{name}}",
  "ADD_PATIENTS/EMR/BUTTON/COPY": "Copy",
  "ADD_PATIENTS/EMR/BUTTON/COPY/SUCCESSFULLY_COPIED": "Copied",
  "ADD_PATIENTS/CODE/BUTTON/COPY": "Copy invite code",
  "ADD_PATIENTS/CODE/BUTTON/COPY/SUCCESSFULLY_COPIED": "Invite code copied",
  "ADD_PATIENTS/CODE/INSTRUCTIONS":
    "This is your personal Epsy invite code. Patients can connect with you by entering the code in their Epsy app (tap Profile, then Data Sharing).",

  "ADD_TEAM/TITLE": "Invite your colleagues to Epsy Hub",
  "LIVANOVA/ADD_TEAM/TITLE": "Invite your colleagues to My VNS Therapy™ Portal",
  "ADD_TEAM/BODY": "Add your colleagues' details below to invite them to join {{orgName}}",
  "ADD_TEAM/ADD_ROW_BUTTON": "Add another member",
  "ADD_TEAM/SUBMIT_BUTTON": "Invite",
  "ADD_TEAM/ERROR/DUPLICATE_EMAILS": "This email is already invited.",
  "ADD_TEAM/ERROR/EMAIL_ALREADY_REGISTERED": "An account with that email already exists.",
  "ADD_TEAM/ERROR/EMAIL_NOT_TRUSTED": "You are not allowed to invite users from not trusted domains.",

  "ADD_TEAM/SUCCESS/MODAL/TITLE": "Your team members have been invited to join Epsy Hub",
  "LIVANOVA/ADD_TEAM/SUCCESS/MODAL/TITLE": "Your team members have been invited to join My VNS Therapy™ Portal",
  "ADD_TEAM/SUCCESS/MODAL/LABEL": "Invitation sent confirmation",
  "ADD_TEAM/SUCCESS/MODAL/ICON_TEXT": "Sent",
  "ADD_TEAM/SUCCESS/MODAL/BODY": "Once they’ve accepted your invite they'll show up in the Team page",

  "CHANGE_PASSWORD/ERROR/FAILED": "Failed to change password.",
  "CHANGE_PASSWORD/ERROR/EXPIRED_LINK": "Please use the link in the most recent email.",
  "CHANGE_PASSWORD/ERROR/TOO_MANY_TRIES": "Too many tries, please try again later.",
  "CHANGE_PASSWORD/SUBMIT_BUTTON": "Reset password",
  "CHANGE_PASSWORD/TITLE": "Reset your password",

  "COMMON/DAILY": "daily",
  "COMMON/CONVULSIVE": "convulsive",
  "COMMON/NOT_CONVULSIVE": "not convulsive",
  "COMMON/AWARE": "aware",
  "COMMON/NOT_AWARE": "not aware",
  "COMMON/TODAY": "today",
  "COMMON/YEARS": "years",

  "COMMON/GENERIC_ERROR": "Something went wrong",
  "COMMON/UNKNOWN": "Unknown",

  "COMMON/LESS_THAN_PREVIOUS_TIME_PERIOD": "{{value}} less than\nprevious {{timePeriod}}",
  "COMMON/MORE_THAN_PREVIOUS_TIME_PERIOD": "{{value}} more than\nprevious {{timePeriod}}",
  "COMMON/SAME_AS_PREVIOUS_TIME_PERIOD": "{{value}} same as\nprevious {{timePeriod}}",
  "COMMON/UP_FROM_ZERO_IN_PREVIOUS_TIME_PERIOD": "up from zero in previous {{timePeriod}}",
  "COMMON/NO_COMPARISON_DATA": "Not enough data to compare",
  "COMMON/TRY_AGAIN_LATER": "Please, try again later.",
  "COMMON/N_A": "N/A",

  "COMMON/UNIT/BPM": "bpm",
  "COMMON/UNIT/HZ": "Hz",
  "COMMON/UNIT/MA": "mA",
  "COMMON/UNIT/MS": "μSec",
  "COMMON/UNIT/SEC": "sec",
  "COMMON/UNIT/MIN": "min",

  "COMMON/AND": "and",

  "CONFIRMATION/MODAL/LABEL": "Confirmation",
  "PENDING/MODAL/LABEL": "Pending",

  "DOMAINS/WHITELIST": "Domain Whitelist",
  "DOMAINS/EXPLANATION": "Only users with the following email domains will be able to join this organisation",
  "DOMAINS/ADD_BUTTON": "Add trusted domains",
  "DOMAINS/EMPTY": "Empty domain whitelist, add trusted domains below",

  "DOMAINS/ADD/TITLE": "Add to whitelist",
  "DOMAINS/ADD/INPUT_LABEL": "Email domain",
  "DOMAINS/ADD/DISCLAIMER/TITLE": "Tick to confirm you've read the below",
  "DOMAINS/ADD/DISCLAIMER":
    "As entity administrator for your institution, this confirms that you are requesting the addition of the above new domain to the list of your institution’s approved domains. Users that are invited and match this domain will have access to the health information within your institution's environment.",
  "DOMAINS/ADD/SUBMIT": "Add domain to whitelist",

  "DOMAINS/DELETE/CONFIRMATION": "Are you sure you want to delete this domain from the list?",
  "DOMAINS/DELETE/CANCEL": "Cancel",
  "DOMAINS/DELETE/DELETE": "Delete",

  "FIELD/ACCEPT_POLICIES/LABEL": "By ticking this box I accept the ",
  "FIELD/ACCEPT_POLICIES/LABEL/TERMS_LINK": "Terms & Conditions",
  "FIELD/ACCEPT_POLICIES/LABEL/PRIVACY_LINK": "Privacy Policy",
  "FIELD/ACCEPT_POLICIES/LABEL/COOKIE_LINK": "Cookie Policy",
  "FIELD/ACCEPT_POLICIES/LABEL/BAA_LINK": "BAA",
  "FIELD/ACCEPT_POLICIES/LABEL/TOMS_LINK": "TOMS",
  "FIELD/ACCEPT_POLICIES/VALIDATION/REQUIRED": "Please accept our T&Cs and Privacy Policy to continue",
  "FIELD/DOMAIN/VALIDATION/AT": "Domain should not contain '@' sign",
  "FIELD/DOMAIN/VALIDATION/DOT": "Domain should not start or end with '.' sign",
  "FIELD/DOMAIN/VALIDATION/LENGTH": "Domain should contain at least 3 characters",
  "FIELD/DOMAIN/MATCH": "Please, enter a valid domain, like: 'example.com'",
  "FIELD/EMAIL/LABEL": "Email address",
  "FIELD/EMAIL/VALIDATION/INVALID": "Please enter a valid email address",
  "FIELD/EMAIL/VALIDATION/REQUIRED": "Please enter a valid email address",
  "FIELD/PHONE/VALIDATION/INTERNATIONAL_CODE/REQUIRED": "Please enter a valid international code",
  "FIELD/PHONE/VALIDATION/NATIONAL/REQUIRED": "Please enter a valid phone number",
  "FIELD/GENERIC/HINT/REQUIRED": "Required",
  "FIELD/NAME/LABEL": "Name",
  "FIELD/NAME/VALIDATION/REQUIRED": "Please enter your full name",
  "FIELD/PATIENT_NAME/LABEL": "Name",
  "FIELD/PATIENT_NAME/VALIDATION/REQUIRED": "Please enter the patient's name",
  "FIELD/NEW_PASSWORD/LABEL": "New password",
  "FIELD/ORGANIZATION/VALIDATION/REQUIRED": "Please enter the name of your organization",
  "FIELD/DEPARTMENT/VALIDATION/REQUIRED": "Please enter the Department",
  "FIELD/ADDRESSLINE1/VALIDATION/REQUIRED": "Please enter Address Line 1",
  "FIELD/ADDRESSLINE2/VALIDATION/REQUIRED": "Please enter Address Line 2",
  "FIELD/CITY/VALIDATION/REQUIRED": "Please enter City",
  "FIELD/STATE/VALIDATION/REQUIRED": "Please enter State",
  "FIELD/ZIPCODE/VALIDATION/REQUIRED": "Please enter Zip Code",
  "FIELD/NPI/HINT": "Optional",
  "FIELD/NPI/LABEL": "NPI Number",
  "FIELD/NPI/VALIDATION/DIGITS": "Your NPI number must only contain digits 0-9",
  "FIELD/NPI/VALIDATION/LENGTH": "Your NPI number must be a 10 digit number",
  "FIELD/NPI/VALIDATION/INVALID": "Invalid NPI number",
  "FIELD/OTP/VALIDATION/DIGITS": "Your OTP must only contain digits 0-9",
  "FIELD/OTP/VALIDATION/LENGTH": "Your OTP must be a 6 numeric digit",
  "FIELD/OTP/LABEL": "One time password",
  "FIELD/ORGANIZATION_NAME/VALIDATION/REQUIRED": "Please enter a name",
  "FIELD/PASSWORD/HINT": "15+ characters",
  "FIELD/PASSWORD/LABEL": "Password",
  "FIELD/PASSWORD/VALIDATION/REQUIRED": "Please enter a password",
  "FIELD/PASSWORD/VALIDATION/LENGTH": "Your password must contain at least 15 characters",
  "FIELD/PASSWORD/VALIDATION/NUMBER": "Your password must contain at least one number",
  "FIELD/PASSWORD/VALIDATION/SPECIAL": "Your password must contain at least one special character",
  "FIELD/PASSWORD/VALIDATION/UPPERCASE": "Your password must contain at least one uppercase letter",
  "FIELD/PASSWORD/VALIDATION/LOWERCASE": "Your password must contain at least one lowercase letter",
  "FIELD/REPEAT_PASSWORD/LABEL": "Repeat password",
  "FIELD/REPEAT_PASSWORD/VALIDATION/NO_MATCH": "Passwords don't match.",
  "FIELD/ROLE/LABEL": "Access level",
  "FIELD/ROLE/VALIDATION/REQUIRED": "Please select an access level",
  "FIELD/TABLET_SERIAL/VALIDATION/REQUIRED": "Please enter a valid serial number",
  "FIELD/TABLET_NICKNAME/VALIDATION/REQUIRED": "Please enter a nickname",

  "JOIN_ORG/TITLE": "Sign up to the Epsy Hub",
  "LIVANOVA/JOIN_ORG/TITLE": "Sign up to the VNS Portal",
  "JOIN_ORG/SUBTITLE": "Create an account for email",
  "JOIN_ORG/NOT_ME": "Not",
  "JOIN_ORG/RETURN_HOME": "Return home",
  "JOIN_ORG/SUBMIT_BUTTON": "Sign up",
  "JOIN_ORG/NEW_PASSWORD_MUST": "New passwords must:",
  "JOIN_ORG/NEW_PASSWORD_REQUIREMENTS":
    "Be 15+ characters long, contain at least one number, one special character, one uppercase letter, and one lowercase letter",

  "LOGIN/DONT_HAVE_ACCOUNT": "Don’t have an account?",
  "LOGIN/REQUEST_ACCESS": "Request access",
  "LOGIN/SIGN_UP": "Sign up",

  "LOGIN/ERROR/UNAUTHORIZED":
    "Sorry, we don't recognise those details. You may have entered these incorrectly or your password has been routinely reset to ensure your account is secure. Please try again or reset your password.",
  "LOGIN/FORGOT_PASSWORD": "Forgot password?",
  "LOGIN/SUBMIT_BUTTON": "Sign in",
  "LOGIN/TITLE": "Sign in",

  "LOGIN/DESCRIPTION": "Improving the lives of people with epilepsy, together.",
  "LIVANOVA/LOGIN/DESCRIPTION": "VNS Therapy™ data at your fingertips",

  "LOGIN/MFA/RESEND_CODE": "Resend verification code",
  "LOGIN/MFA/NEXT": "Next",
  "LOGIN/MFA/TITLE": "Confirm your identity",
  "LOGIN/MFA/SUBTITLE": "Enter the code sent to your email inbox.",

  "NAV/BUTTONS/ACCOUNT": "Account",
  "NAV/BUTTONS/LOGOUT": "Log out",
  "NAV/BUTTONS/PATIENTS": "Patients",
  "NAV/BUTTONS/PATIENTS/REPORT": "Profile",
  "NAV/BUTTONS/PATIENTS/VIDEOS": "Videos",
  "NAV/BUTTONS/TEAM": "Team",
  "NAV/BUTTONS/QUESTIONNAIRES": "Questionnaires",
  "NAV/BUTTONS/QUESTIONNAIRES/SUMMARY": "Summary",
  "NAV/BUTTONS/QUESTIONNAIRES/RESPONSES": "Responses",
  "NAV/BUTTONS/VNS": "VNS",
  "NAV/BUTTONS/VNS/PATIENTS": "Patients",
  "NAV/BUTTONS/VNS/REPORTS": "Reports",
  "NAV/BUTTONS/VNS/RESOURCES": "Resources",
  "NAV/BUTTONS/VNS/UPLOADS": "Uploads",
  "NAV/BUTTONS/VNS/TABLETS": "Tablets",
  "NAV/BUTTONS/VNS/REMOTE_PROGRAMMING": "Remote Programming",

  "NOT_FOUND/TITLE": "Oops",
  "NOT_FOUND/BODY": "The page you were looking for doesn't exist",
  "NOT_FOUND/BUTTON/HOME": "Go to home",

  "NO_ACCESS/TITLE": "Oops",
  "NO_ACCESS/BODY": "You do not have access to this page",
  "NO_ACCESS/BUTTON/HOME": "Go to home",

  "PATIENT/BUTTON/VIEW_REPORT": "View report",
  "PATIENT/BUTTON/RESEND_INVITE": "Re-send invite",
  "PATIENT/LABEL/CURRENT_MEDICATION": "Current medication",
  "PATIENT/LABEL/LAST_SEIZURE_DATE": "Last seizure",
  "PATIENT/LABEL/DATE_OF_BIRTH": "DOB",
  "PATIENT/LABEL/ASSIGNED_HCPS": "Assigned",
  "PATIENT/LABEL/DATE_OF_BIRTH/SHORT": "dob",
  "PATIENT/LABEL/GENDER_AND_AGE": "Sex & age",
  "PATIENT/LABEL/GENDER": "Sex",
  "PATIENT/LABEL/EMAIL": "Email",
  "PATIENT/LABEL/PHONE": "Phone",
  "PATIENT/LABEL/STATUS": "Status",
  "PATIENT/LABEL/NAME": "Name",
  "PATIENT/WIDGET/TITLE": "See questionnaires",
  "PATIENT/WIDGET/DESCRIPTION": "View {{name}}'s completed questionnaires.",
  "PATIENT/VALUE/PAST_MEDICATIONS_COUNT/NOT_SURE": "Not sure",
  "PATIENT/VALUE/STATUS/ACTIVE": "Accepted",
  "PATIENT/VALUE/STATUS/NOT_ACTIVE": "Not accepted",
  "PATIENT/VALUE/SEIZURE_FREQUENCY/NOT_VERY_OFTEN": "4 or less per year",
  "PATIENT/VALUE/SEIZURE_FREQUENCY/SOMETIMES": "1-2 per month",
  "PATIENT/VALUE/SEIZURE_FREQUENCY/OFTEN": "1-2 per week",
  "PATIENT/VALUE/SEIZURE_FREQUENCY/VERY_OFTEN": "3+ per week",
  "PATIENT/HEADING/PERSONAL_INFO": "Personal details",
  "PATIENT/HEADING/MEDICAL_INFO": "Medical overview",
  "PATIENT/NOT_ACTIVE_BANNER": "Not accepted",
  "PATIENT/NOT_ACTIVE_MEDICAL_INFO": "Your invitation hasn't been accepted yet.",
  "PATIENT/NO_ACCESS_MEDICAL_INFO":
    "You cannot view medical information. If you need access, please contact team owner on {{teamPageLink}}.",
  "PATIENT/NO_ACCESS_MEDICAL_INFO/TEAM_PAGE_LINK": "team page",
  "PATIENT/INVITE_SENT/SUCCESS": "Invite sent {{date}}",
  "PATIENT/INVITE_SENT/FAIL": "Invite failed.",
  "PATIENT/VIEW_ALL_MEDS": "View all",
  "PATIENT/BUTTON/VIEW_SUMMARY": "View summary",
  "PATIENT/BUTTON/DELETE_PATIENT": "Remove patient",
  "PATIENT/MODAL/DELETE_PATIENT/TEXT/CONFIRM":
    "Are you sure you want to remove {{name}}? You won't be able to access their medical data.",
  "PATIENT/MODAL/DELETE_PATIENT/TEXT/CONFIRM/DEFAULT_NAME": "this patient",
  "PATIENT/MODAL/DELETE_PATIENT/BUTTON/CONFIRM": "Remove",
  "PATIENT/MODAL/DELETE_PATIENT/BUTTON/CANCEL": "Cancel",
  "PATIENT/MODAL/DELETE_PATIENT/BUTTON/RETRY": "Try again",
  "PATIENT/MODAL/DELETE_PATIENT/TEXT/PENDING": "Loading",

  "PATIENT/PROFILE/ABOUT/TITLE": "About",
  "PATIENT/PROFILE/ABOUT/GENDER": "Gender",
  "PATIENT/PROFILE/ABOUT/DATE_OF_BIRTH": "DoB",
  "PATIENT/PROFILE/ABOUT/PATIENT_ID": "Patient ID",

  "PATIENT/PROFILE/PLAN": "Treatment plan",
  "PATIENT/PROFILE/PLAN/MEDICATION_LABEL": "{{count}} Medication",
  "PATIENT/PROFILE/PLAN/MEDICATION_LABEL_PLURAL": "{{count}} Medications",
  "PATIENT/PROFILE/PLAN/DEVICE_LABEL": "{{count}} Medical device",
  "PATIENT/PROFILE/PLAN/DEVICE_LABEL_PLURAL": "{{count}} Medical devices",

  "PATIENT/PROFILE/PLAN/MODAL/LABEL": "Current treatment plan",
  "PATIENT/PROFILE/PLAN/MODAL/FOR": "For {{name}}",

  "PATIENT/PROFILE/PLAN/EMPTY/LABEL": "Empty",
  "PATIENT/PROFILE/PLAN/EMPTY/DESCRIPTION": "This user has not added any treatment information in Epsy.",

  "PATIENT/PROFILE/QUESTIONNAIRES/LABEL": "Questionnaires",
  "PATIENT/PROFILE/QUESTIONNAIRES/COMPLETED": "Completed {{completedDate}}",
  "PATIENT/PROFILE/QUESTIONNAIRES/NO_RESPONSE": "No response",
  "PATIENT/PROFILE/QUESTIONNAIRES/EMPTY/DESCRIPTION": "Invite {{name}} to complete a questionnaire",

  "PATIENT/PROFILE/VIDEOS/LABEL": "Videos",
  "PATIENT/PROFILE/VIDEOS/EMPTY/LABEL": "None added",
  "PATIENT/PROFILE/VIDEOS/EMPTY/DESCRIPTION":
    "When this user adds a video of an event in the Epsy app, you will see it here.",

  "PATIENT/PROFILE/CHART/OVERVIEW": "Overview",
  "PATIENT/PROFILE/CHART/SEIZURE": "Seizure",
  "PATIENT/PROFILE/CHART/QUALITY_OF_LIFE": "Quality of life",
  "PATIENT/PROFILE/CHART/MEDICATION": "Medication",

  "PATIENT/PROFILE/CHART/TODAY": "Today",
  "PATIENT/PROFILE/CHART/THIS_WEEK": "This week",
  "PATIENT/PROFILE/CHART/PRESENT": "Present",
  "PATIENT/PROFILE/CHART/PLAN": "Plan",
  "PATIENT/PROFILE/CHART/OTHER": "Other",

  "PATIENT/PROFILE/PREVIEW/1_MONTH": "1 month",
  "PATIENT/PROFILE/PREVIEW/3_MONTHS": "3 months",
  "PATIENT/PROFILE/PREVIEW/6_MONTHS": "6 months",
  "PATIENT/PROFILE/PREVIEW/1_YEAR": "1 year",
  "PATIENT/PROFILE/PREVIEW/3_YEARS": "3 years",
  "PATIENT/PROFILE/PREVIEW/7_YEARS": "7 years",

  "PATIENT/PROFILE/CHART/AURAS": "Aura",
  "PATIENT/PROFILE/CHART/AURAS_PLURAL": "Auras",
  "PATIENT/PROFILE/CHART/DEVICE_SWIPE": "Device swipe",
  "PATIENT/PROFILE/CHART/DEVICE_SWIPE_PLURAL": "Device swipes",
  "PATIENT/PROFILE/CHART/EMERGENCY_DOSE": "Emergency dose",
  "PATIENT/PROFILE/CHART/EMERGENCY_DOSE_PLURAL": "Emergency doses",
  "PATIENT/PROFILE/CHART/MEDS_MISSED": "Med missed",
  "PATIENT/PROFILE/CHART/MEDS_MISSED_PLURAL": "Meds missed",
  "PATIENT/PROFILE/CHART/MEDS_PLAN_CHANGE": "Medication plan change",
  "PATIENT/PROFILE/CHART/POTENTIAL_TRIGGERS": "Potential trigger",
  "PATIENT/PROFILE/CHART/POTENTIAL_TRIGGERS_PLURAL": "Potential triggers",
  "PATIENT/PROFILE/CHART/LIFE_EVENT": "Life event",
  "PATIENT/PROFILE/CHART/LIFE_EVENT_PLURAL": "Life events",
  "PATIENT/PROFILE/CHART/SEIZURES": "Seizure",
  "PATIENT/PROFILE/CHART/SEIZURES_PLURAL": "Seizures",
  "PATIENT/PROFILE/CHART/SIDE_EFFECTS": "Side effect",
  "PATIENT/PROFILE/CHART/SIDE_EFFECTS_PLURAL": "Side effects",
  "PATIENT/PROFILE/CHART/SINGLE_DOSE": "Single dose",
  "PATIENT/PROFILE/CHART/SINGLE_DOSE_PLURAL": "Single doses",
  "PATIENT/PROFILE/CHART/VIDEOS": "Video",
  "PATIENT/PROFILE/CHART/VIDEOS_PLURAL": "Videos",

  "PATIENT/PROFILE/CHART/PREVIEW/SEIZURES": "Seizures",
  "PATIENT/PROFILE/CHART/PREVIEW/MISSED_MEDS": "Missed meds",
  "PATIENT/PROFILE/CHART/PREVIEW/SIDE_EFFECTS": "Side effects",
  "PATIENT/PROFILE/CHART/PREVIEW/JUMP_TO_CHART": "Jump to chart",
  "PATIENT/PROFILE/CHART/PREVIEW/JUMP_TO_CHART_RESPONSIVE": "Chart",

  "PATIENT/PROFILE/DAY_VIEW/MEDS_TAKEN": "Meds taken",
  "PATIENT/PROFILE/DAY_VIEW/SEIZURES": "Seizures",
  "PATIENT/PROFILE/DAY_VIEW/EMERGENCY": "Emergency doses",
  "PATIENT/PROFILE/DAY_VIEW/SIDE_EFFECTS": "Side effects",

  "PATIENT/VIDEOS/EMPTY/DESCRIPTION":
    "Your patients can upload videos of their seizures in the Epsy app for you to view here",
  "PATIENT/VIDEOS/EMPTY/USERNAME": "{{name}} has no videos uploaded",
  "PATIENT/VIDEOS/THUMBNAIL_ERROR": "Couldn't download the image. Please, try again later.",
  "PATIENT/VIDEOS/VIDEO_ERROR": "Couldn't download the video. Please, try again later.",
  "PATIENT/VIDEOS/REPORT/TITLE": "On this day in Epsy",
  "PATIENT/VIDEOS/REPORT/DESCRIPTION": "Logs when this video was uploaded",
  "PATIENT/VIDEOS/REPORT/OPEN_FULL": "Open full",

  "PATIENT/VIDEOS/REPORT/LOG/SEIZURES": "{{amount}} Seizures",
  "PATIENT/VIDEOS/REPORT/LOG/AURA": "{{amount}} Aura",
  "PATIENT/VIDEOS/REPORT/LOG/SIDE_EFFECTS": "{{amount}} Side effects",
  "PATIENT/VIDEOS/REPORT/LOG/MEDICATIONS_MISSED": "{{amount}} Medication missed",

  "ROLE/ADMIN": "Admin",
  "ROLE/IT_ADMIN": "IT Admin",
  "ROLE/MANAGER": "Manager",
  "ROLE/OWNER": "Owner",
  "ROLE/SUPER_ADMIN": "Super Admin",
  "ROLE/VIEWER": "View only",

  "TEAM_MEMBER/BUTTON/RESEND_INVITE": "Resend invite",
  "TEAM_MEMBER/BUTTON/RESEND_INVITE/SUCCESS": "Invitation sent",
  "TEAM_MEMBER/BUTTON/RESEND_INVITE/ERROR": "Error, try again later",
  "TEAM_MEMBER/BUTTON/DELETE_INVITED_MEMBER": "Remove this invite",
  "TEAM_MEMBER/BUTTON/DELETE_ACTIVE_MEMBER": "Remove this team member",
  "TEAM_MEMBER/MODAL/DELETE_MEMBER/TEXT/CONFIRM": "Are you sure you want to remove {{name}} from your organization?",
  "TEAM_MEMBER/MODAL/DELETE_MEMBER/BUTTON/CONFIRM": "Confirm",
  "TEAM_MEMBER/MODAL/DELETE_MEMBER/BUTTON/CANCEL": "Cancel",
  "TEAM_MEMBER/MODAL/DELETE_MEMBER/BUTTON/RETRY": "Try again",
  "TEAM_MEMBER/MODAL/DELETE_MEMBER/TEXT/PENDING": "Loading",
  "TEAM_MEMBER/LABEL/EMAIL": "Email",
  "TEAM_MEMBER/STATUS/INVITED": "Invited",
  "TEAM_MEMBER/STATUS/ACTIVE": "Member",
  "TEAM_MEMBER/STATUS/YOU": "You",
  "TEAM_MEMBER/HEADING/PERSONAL_INFO": "Personal details",
  "TEAM_MEMBER/HEADING/ACCESS_LEVEL": "Access level",
  "TEAM_MEMBER/NOT_ACTIVE_BANNER": "Invited",

  "PATIENTS/RESULTS/EMPTY": "No patients yet",
  "PATIENTS/RESULTS/EMPTY/BODY": "Click the button to start inviting",
  "PATIENTS/RESULTS/EMPTY_SEARCH": 'No patients found matching your search term "{{searchTerm}}"',
  "PATIENTS/RESULTS/FAIL": "Something went wrong. Couldn't retrieve patients.",
  "PATIENTS/SEARCH/LABEL": "Search",
  "PATIENTS/TITLE": "My patients",
  "PATIENTS/BUTTON/INVITE": "Invite new patients",
  "PATIENTS/YOUR_INVITE_CODE": "Your personal invite code:",
  "PATIENTS/ACTIVE_COUNT": "{{totalCount}} invited, {{activeCount}} accepted",
  "PATIENTS/TOAST/RESEND_INVITE/SUCCESS": "Invitation re-sent",
  "PATIENTS/TOAST/RESEND_INVITE/PENDING": "Re-sending invitation",
  "PATIENTS/INVITED_PATIENT//MODAL/LABEL": "Patient",

  "REPORT/BUTTON/DOWNLOAD": "Download",

  "RESET_PASSWORD/TITLE": "Forgot password?",
  "RESET_PASSWORD/SUBMIT_BUTTON": "Reset password",

  "RESET_PASSWORD/ERROR/TOO_MANY_TRIES": "Too many tries, please try again later.",
  "RESET_PASSWORD/ERROR/FAILED": "Reset password failed.",

  "RESET_PASSWORD/SUCCESS/MODAL/LABEL": "Sent a password\nreset link to your email",
  "RESET_PASSWORD/SUCCESS/MODAL/CONFIRM_BUTTON": "Ok",
  "RESET_PASSWORD/SUCCESS/MODAL/TEXT": "We've sent a password\nreset link to your provided email.",
  "RESET_PASSWORD/SUCCESS/MODAL/TITLE": "We've sent you the link",

  "REPORT/PERIOD_FRAME/DAY": "Days",
  "REPORT/PERIOD_FRAME/MONTH": "Months",
  "REPORT/AXIS/MEDICATIONS": "Medication",
  "REPORT/AXIS/SEIZURES": "Seizures count",
  "REPORT/TITLE/MEDPLAN_HISTORY": "Change in treatment plan",
  "REPORT/BUTTON/DOWNLOAD_PDF": "Download PDF",
  "REPORT/BUTTON/REFETCH": "Try again",
  "REPORT/OVERLAY/FAIL": "Unfortunately we couldn't retrieve this data",
  "REPORT/OVERLAY/PENDING": "Loading",
  "REPORT/OVERLAY/EMPTY": "No data to display",
  "REPORT/PDF/TITLE": "Epsy Report - {{name}} ({{dateRange}})",
  "REPORT/SUMMARY_CARD/SEIZURE/TITLE": "Seizure frequency (average per month)",
  "REPORT/SUMMARY_CARD/SEIZURE/LAST_SEIZURE/TITLE": "Last seizure:",
  "REPORT/SUMMARY_CARD/MEDICATION/TITLE": "Medication (percentage taken)",
  "REPORT/SUMMARY_CARD/MEDICATION/LIST_TITLE": "Emergency meds ({{count}}):",
  "REPORT/SUMMARY_CARD/SIDE_EFFECT/TITLE": "Side effects (total count)",
  "REPORT/SIDE_LEGEND/ITEM/SEIZURE_AURA": "Aura",
  "REPORT/SIDE_LEGEND/ITEM/SEIZURE_NOT_SURE_TYPE": "Not sure",
  "REPORT/SIDE_LEGEND/ITEM/SEIZURE_MILD_INTENSITY": "Mild",
  "REPORT/SIDE_LEGEND/ITEM/SEIZURE_MEDIUM_INTENSITY": "Medium",
  "REPORT/SIDE_LEGEND/ITEM/SEIZURE_SEVERE_INTENSITY": "Severe",
  "REPORT/SIDE_LEGEND/ITEM/SEIZURE_NOT_SURE_INTENSITY": "Not sure",
  "REPORT/SIDE_LEGEND/ITEM/SEIZURE_AWARE": "Aware",
  "REPORT/SIDE_LEGEND/ITEM/SEIZURE_NOT_AWARE": "Not aware",
  "REPORT/SIDE_LEGEND/ITEM/SEIZURE_NOT_SURE_AWARE": "Not sure",
  "REPORT/SIDE_LEGEND/ITEM/MEDICATION_TAKEN": "Taken",
  "REPORT/SIDE_LEGEND/ITEM/MEDICATION_NOT_TAKEN": "Not taken",
  "REPORT/SIDE_LEGEND/ITEM/MEDICATION_NOT_LOGGED": "Not logged",
  "REPORT/SIDE_LEGEND/ITEM/MEDICATION_RESCUE": "Emergency",
  "REPORT/SIDE_LEGEND/ITEM/SIDE_EFFECT": "Side effects",
  "REPORT/SIDE_LEGEND/ITEM/HEART_RATE": "Heart rate",
  "REPORT/SIDE_LEGEND/ITEM/SLEEP": "Sleep",
  "REPORT/SIDE_LEGEND/ITEM/STEPS": "Steps",
  "REPORT/SIDE_LEGEND/ITEM/MENSTRUATION": "Menstruation",
  "REPORT/SIDE_LEGEND/ITEM/OTHER": "All other logged events",
  "REPORT/SIDE_LEGEND/SECTION_TITLE/SEIZURE": "Seizures",
  "REPORT/SIDE_LEGEND/SECTION_TITLE/MEDICATION": "Medication",
  "REPORT/SIDE_LEGEND/SECTION_TITLE/SIDE_EFFECTS": "Side effects",
  "REPORT/SIDE_LEGEND/SECTION_TITLE/HEALTH": "Health",
  "REPORT/SIDE_LEGEND/TAB/SEIZURE_TYPE": "Type",
  "REPORT/SIDE_LEGEND/TAB/AWARENESS": "Awareness",
  "REPORT/SIDE_LEGEND/TAB/INTENSITY": "Intensity",
  "REPORT/TOOLTIP/SECTION_TITLE/SEIZURE": "Total seizures",
  "REPORT/TOOLTIP/SECTION_TITLE/MEDICATION": "Medication",
  "REPORT/TOOLTIP/NOTHING_LOGGED": "No logs",
  "REPORT/MED_PLAN_NAME": "PLAN {{planNumber}}",
  "REPORT/MED_PLAN_IS_CURRENT": "CURRENT",
  "REPORT/MED_PLAN_IS_EMPTY": "No medication",

  "REPORT/DAY_VIEW/KEY_STAT/MEDICATIONS_TAKEN": "Meds taken",
  "REPORT/DAY_VIEW/KEY_STAT/SEIZURES": "Seizures",
  "REPORT/DAY_VIEW/KEY_STAT/STEPS_TAKEN": "Steps taken",
  "REPORT/DAY_VIEW/KEY_STAT/HOURS_SLEPT": "Hours of sleep",
  "REPORT/DAY_VIEW/EVENT/TITLE/SEIZURE": "{{type}} Seizure",
  "REPORT/DAY_VIEW/EVENT/TITLE/SEIZURE_AURA": "Aura",
  "REPORT/DAY_VIEW/EVENT/TITLE/SEIZURE_NOT_SURE": "Seizure",
  "REPORT/DAY_VIEW/EVENT/TITLE/MEDICATION_TAKEN": "Medication Taken",
  "REPORT/DAY_VIEW/EVENT/TITLE/MEDICATION_TAKEN_LATE": "Medication Taken Late",
  "REPORT/DAY_VIEW/EVENT/TITLE/MEDICATION_NOT_TAKEN": "Medication Not Taken",
  "REPORT/DAY_VIEW/EVENT/TITLE/MEDICATION_NOT_LOGGED": "Medication Not Logged",
  "REPORT/DAY_VIEW/EVENT/TITLE/MEDICATION_RESCUE": "Emergency Single Dose",
  "REPORT/DAY_VIEW/EVENT/TITLE/SIDE_EFFECT": "{{count}} Side Effect",
  "REPORT/DAY_VIEW/EVENT/TITLE/SIDE_EFFECT_PLURAL": "{{count}} Side Effects",
  "REPORT/DAY_VIEW/EVENT/TITLE/SINGLE_DOSE_MEDICATION": "Single Dose",
  "REPORT/DAY_VIEW/EVENT/TITLE/SINGLE_DOSE_IMPLANT": "Medical Device",
  "REPORT/DAY_VIEW/EVENT/TITLE/MOOD": "Mood",
  "REPORT/DAY_VIEW/EVENT/TITLE/UNKNOWN": "?",
  "REPORT/DAY_VIEW/NO_EVENTS": "No data for this day",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/AWARE/YES": "Aware",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/AWARE/NO": "Not Aware",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/AWARE/NOT_SURE": "",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/AWARE/NULL": "",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/INTENSITY/MILD": "Mild intensity",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/INTENSITY/MEDIUM": "Medium intensity",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/INTENSITY/SEVERE": "Severe intensity",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/INTENSITY/NULL": "Unknown intensity",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/TRIGGERS/COUNT": "{{count}} Trigger",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/TRIGGERS/COUNT_PLURAL": "{{count}} Triggers",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/TRIGGERS/LIST_HEADING": "Triggers",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SEIZURE/RESCUE_MED_ADDED": "Emergency medication added",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/CARE_RESCUE_MED": "Rescue Med",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/CARE_PHYSICAL_PAIN": "Physical Pain",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/CARE_INJURY": "Injury",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/CARE_MENSTRUAL_CYCLE": "Menstrual Cycle",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/CARE_BAD_SLEEP": "Bad Sleep",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/CARE_ILLNESS": "Illness",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/ACTIVITIES_DRIVING": "Driving",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/ACTIVITIES_EXERCISE": "Exercise",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/ACTIVITIES_SOCIALIZING": "Socializing",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/ACTIVITIES_TRAVELING": "Traveling",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/ACTIVITIES_WORKING": "Working",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/ACTIVITIES_HOLIDAY": "Holiday",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/DIETARY_ALLERGY": "Allergy",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/DIETARY_ALCOHOL": "Alcohol",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/DIETARY_DEHYDRATION": "Dehydration",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/DIETARY_JUNK_FOOD": "Junk Food",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/DIETARY_SKIPPED_MEAL": "Skipped Meal",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/DIETARY_SODA": "Soda",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/DIETARY_CAFFEINE": "Caffeine",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/MEDICATION_MISSED": "Medication Missed",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MOOD/TYPE/OTHER": "Other",
  "REPORT/DAY_VIEW/EVENT/DETAILS/SINGLE_DOSE_IMPLANT/BODY": "Single Dose",
  "REPORT/DAY_VIEW/EVENT/DETAILS/MEDICATION/SCHEDULED_AT": "Scheduled medication time was {{time}}",

  "TEAM/TEAM_MEMBER/MODAL/LABEL": "Team member",
  "TEAM/TITLE": "Your team",
  "TEAM/ORGANIZATION/MODAL/LABEL": "Organization",
  "TEAM/LABEL/ORGANIZATION": "Organization",
  "TEAM/BUTTON/ADD": "Add team member",
  "TEAM/BUTTON/INVITE_COLLEAGUES": "Invite colleagues",
  "TEAM/BUTTON/INVITE_COLLEAGUES/CONTACT": "Please contact an admin to invite users to My VNS TherapyTM Portal",

  "ORGANIZATION/TITLE": "Organization details",
  "ORGANIZATION/LABEL/LOGO": "Organization logo",
  "ORGANIZATION/LABEL/NAME": "Name",
  "ORGANIZATION/LABEL/DEPARTMENT": "Department",
  "ORGANIZATION/LABEL/ADDRESS": "Address",
  "ORGANIZATION/LABEL/EMAIL": "Email",
  "ORGANIZATION/LABEL/ADDRESS_LINE_1": "Address Line 1",
  "ORGANIZATION/LABEL/ADDRESS_LINE_2": "Address Line 2",
  "ORGANIZATION/LABEL/CITY": "City",
  "ORGANIZATION/LABEL/STATE": "State",
  "ORGANIZATION/LABEL/COUNTRY": "Country",
  "ORGANIZATION/LABEL/ZIP_CODE": "Zip Code",
  "ORGANIZATION/LABEL/PHONE_1": "Phone 1",
  "ORGANIZATION/LABEL/PHONE_2": "Phone 2",
  "ORGANIZATION/BUTTON/UPLOAD_LOGO": "Change\nlogo",
  "ORGANIZATION/BUTTON/CANCEL": "Cancel",
  "ORGANIZATION/BUTTON/SUBMIT": "Save changes",
  "ORGANIZATION/UPDATE/SUCCESS": "Successfully Updated Organization",
  "ORGANIZATION/LABEL/CLINICTELEPHONE": "Clinic telephone",
  "ORGANIZATION/CHANGE_ADDRESS_FORM/TITLE": "Change Address",

  "CONNECT_ORG/TITLE": "Join Epsy and\nconnect with your\nhealthcare team",
  "CONNECT_ORG/INVITE_CODE": "Invite code: {{code}}",
  "CONNECT_ORG/BUTTON/DOWNLOAD_APP": "Download now",
  "CONNECT_ORG/DOWNLOAD_SECTION/HEADING": "Get Epsy on your phone",
  "CONNECT_ORG/DOWNLOAD_SECTION/BODY":
    "Download Epsy from the App Store if you have an iPhone, or Google Play if you have an Android.",
  "CONNECT_ORG/INVITE_SECTION/HEADING": "Enter invite code",
  "CONNECT_ORG/INVITE_SECTION/BODY":
    "In your Epsy app, go to Profile > Data Sharing and enter your doctor's invite code.",
  "CONNECT_ORG/PROGRESS_SECTION/HEADING": "Track your progress",
  "CONNECT_ORG/PROGRESS_SECTION/BODY":
    "Your doctor will now be able to track and manage your epilepsy treatment plan with you. You can stop sharing anytime.",

  "LEGAL/EPSY_APP/DISCLAIMER":
    "Epsy does not provide medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health care provider. Epsy, Epsy Health, Epsy App, Epsy Hub and their respective logos are either registered trademarks or trademarks in the United States and/or other countries. iPhone, iPad, Apple Watch are trademarks of Apple Inc. registered in the US and other countries. Android and Google Play are trademarks of Alphabet Inc. Other trademarks and trade names are those of their respective owners.",
  "LEGAL/EPSY_APP/COPYRIGHT": "Copyright © 2020 LivaNova PLC. All rights reserved.",
  "SESSION_TIMEOUT/TOAST/TITLE": "Your session has expired",
  "SESSION_TIMEOUT/TOAST/BODY": "Please login again",
  "QUESTIONNAIRES/RESULTS/TITLE": "Questionnaire responses",
  "QUESTIONNAIRES/RESULTS/BUTTON/RESEND": "Send again",
  "QUESTIONNAIRES/RESULTS/TEXT/COMPLETED": "Done",
  "QUESTIONNAIRES/RESULTS/TABLE/NAME": "Name",
  "QUESTIONNAIRES/RESULTS/TABLE/TYPE": "Questionnaire type",
  "QUESTIONNAIRES/RESULTS/TABLE/DATE": "Date completed",
  "QUESTIONNAIRES/RESULTS/TABLE/SEARCH": "Search",
  "QUESTIONNAIRES/RESULTS/PREVIEW/ANSWERED": "{{answered}} of {{questions}} questions answered",
  "QUESTIONNAIRES/RESULTS/EMPTY/BUTTON": "Go to questionnaires",
  "QUESTIONNAIRES/RESULTS/EMPTY/DESCRIPTION":
    "Get to know your patients even better by viewing their responses to questionnaires here",

  "QUESTIONNAIRES/LAST_SENT": "Last sent {{date}}",
  "QUESTIONNAIRES/SEND_MENU/COPY_LINK": "Copy link to send via your EMR",
  "QUESTIONNAIRES/SEND_MENU/EMAIL_OR_SMS": "Send via email or SMS",
  "QUESTIONNAIRES/SEND_MENU/COPY_LINK/SUCCESS_TOAST": "Link copied to clipboard",

  "QUESTIONNAIRE_RESULT/COMPLETED_DATE": "Completed {{date}}",
  "FOOTER/PRIVACY": "Privacy Policy",
  "FOOTER/TERMS": "Terms & Conditions",
  "FOOTER/COOKIE": "Cookie Policy",
  "FOOTER/TOMS": "TOMS",
  "FOOTER/BAA": "BAA",

  "VNS/LIST/TITLE": "Currently managing {{count}} VNS Therapy™ patient",
  "VNS/LIST/TITLE_PLURAL": "Currently managing {{count}} VNS Therapy™ patients",
  "VNS/LIST/DISCLAIMER":
    "The years to Near End of Service (NEOS) calculation is an estimate and may be more or less than actual time to NEOS.  These estimates can be impacted by any gaps in available programming history data and other factors that are not included in the calculations such as magnet activity, future parameter changes or interrogation events. These estimates are informational only. LivaNova recommends continued monitoring of the Near End of Service indicator during the patient's follow up visits and monitoring of signs/symptoms for EOS.",
  "VNS/LIST/HEADER/CHIP/ONBOARDING": "No data uploaded",
  "VNS/LIST/HEADER/CHIP/DEFAULT": "Last upload {{date}}",
  "VNS/LIST/HEADER/BUTTON/UPLOAD": "Upload data",

  "VNS/UPLOAD/TITLE": "Upload History",

  "VNS/LIST/TABLE/TITLE": "All VNS Therapy Patients",
  "VNS/LIST/TABLE/FILTER/TITLE": "Showing {{filteredCount}} of {{totalCount}} VNS Therapy Patients",
  "VNS/LIST/TABLE/COLUMN_LABEL/PATIENT_NAME": "Name",
  "VNS/LIST/TABLE/COLUMN_LABEL/DOB": "Date of birth",
  "VNS/LIST/TABLE/COLUMN_LABEL/ASSIGNED": "Assigned",
  "VNS/LIST/TABLE/COLUMN_LABEL/MODEL": "Model",
  "VNS/LIST/TABLE/COLUMN_LABEL/CURRENT": "Current (mA)",
  "VNS/LIST/TABLE/COLUMN_LABEL/PULSE_WIDTH": "Pulse width (μSec)",
  "VNS/LIST/TABLE/COLUMN_LABEL/LAST_READ_AT": "Last known reading",
  "VNS/LIST/TABLE/COLUMN_LABEL/YEARS_TO_NEOS": "Years to NEOS",

  "VNS/LIST/TABLE/ASSIGNED/ASSIGN_USER": "Assign User",
  "VNS/LIST/TABLE/ASSIGNED/SEARCH": "Search",

  "VNS/LIST/FILTER/MODEL/LABEL": "Model",
  "VNS/LIST/FILTER/LAST_READ_AT/LABEL": "Last known reading",
  "VNS/LIST/FILTER/YEARS_TO_NEOS/LABEL": "Years to NEOS",
  "VNS/LIST/FILTER/IMPLANTED_AT/LABEL": "Implant date",
  "VNS/LIST/FILTER/ASSIGNED/LABEL": "Assigned",
  "VNS/LIST/FILTER/SEARCH/PLACEHOLDER": "Search for a patient",
  "VNS/LIST/FILTER/SEARCH/NO_RESULT": "Unable to find records matching given criteria",

  "VNS/LIST/FILTER/MODEL/EMPTY_LABEL": "No model data",
  "VNS/LIST/FILTER/LAST_READ_AT/EMPTY_LABEL": "No last read data",
  "VNS/LIST/FILTER/YEARS_TO_NEOS/EMPTY_LABEL": "No NEOS data",
  "VNS/LIST/FILTER/IMPLANTED_AT/EMPTY_LABEL": "No implanted dates",
  "VNS/LIST/FILTER/ASSIGNED/EMPTY_LABEL": "No assigned patients",

  "VNS/LIST/SUMMARY_CARD/NEOS_PATIENTS/TITLE": "{{count}} Patient approaching NEOS",
  "VNS/LIST/SUMMARY_CARD/NEOS_PATIENTS/TITLE_PLURAL": "{{count}} Patients approaching NEOS",
  "VNS/LIST/SUMMARY_CARD/NEOS_PATIENTS/BODY":
    "These patients are estimated to have devices near end of service (<1 year)",
  "VNS/LIST/SUMMARY_CARD/NEOS_PATIENTS/FILTER": "NEOS",
  "VNS/LIST/SUMMARY_CARD/LOW_CURRENT/TITLE": "{{count}} Patient below 1.5mA",
  "VNS/LIST/SUMMARY_CARD/LOW_CURRENT/TITLE_PLURAL": "{{count}} Patients below 1.5mA",
  "VNS/LIST/SUMMARY_CARD/LOW_CURRENT/BODY": "These patients are potentially below recommended therapeutic dosage range",
  "VNS/LIST/SUMMARY_CARD/LOW_CURRENT/FILTER": "below 1.5mA",
  "VNS/LIST/SUMMARY_CARD/SINCE_UPDATE/TITLE": "{{count}} Patient >{{months}}m since update",
  "VNS/LIST/SUMMARY_CARD/SINCE_UPDATE/TITLE_PLURAL": "{{count}} Patients >{{months}}m since update",
  "VNS/LIST/SUMMARY_CARD/SINCE_UPDATE/BODY":
    "These patients haven't had their data updated in {{months}} months or more",
  "VNS/LIST/SUMMARY_CARD/SINCE_UPDATE/FILTER": ">{{months}}m since update",
  "VNS/LIST/SUMMARY_CARD/URGENT_DEVICES/TITLE": "{{count}} patient need attention",
  "VNS/LIST/SUMMARY_CARD/URGENT_DEVICES/TITLE_PLURAL": "{{count}} patients need attention",
  "VNS/LIST/SUMMARY_CARD/URGENT_DEVICES/BODY": "These patients have not had their data updated in the past year.",
  "VNS/LIST/SUMMARY_CARD/URGENT_DEVICES/FILTER": "outdated",
  "VNS/LIST/SUMMARY_CARD/FILTER/SHOW": "Show {{filter}}",
  "VNS/LIST/SUMMARY_CARD/FILTER/HIDE": "Hide {{filter}}",

  "VNS/LIST/SUMMARY_CARD/HELP_UPLOAD/TITLE": "Upload your data",
  "VNS/LIST/SUMMARY_CARD/HELP_UPLOAD/BODY": "Upload VNS programming tablet data to keep track of your devices.",
  "VNS/LIST/SUMMARY_CARD/HELP_UPLOAD/ACTION": "How to upload data",
  "VNS/LIST/SUMMARY_CARD/HELP_INSIGHTS/TITLE": "See key insights",
  "VNS/LIST/SUMMARY_CARD/HELP_INSIGHTS/BODY": "You’ll see device battery life calculations for your patients here.",
  "VNS/LIST/SUMMARY_CARD/HELP_INSIGHTS/ACTION": "Need help?",
  "VNS/LIST/SUMMARY_CARD/HELP_HIPAA/TITLE": "Stay HIPAA compliant",
  "VNS/LIST/SUMMARY_CARD/HELP_HIPAA/BODY": "The entire Epsy Hub platform is HIPAA compliant.",
  "LIVANOVA/VNS/LIST/SUMMARY_CARD/HELP_HIPAA/BODY": "The entire My VNS Therapy™ Portal platform is HIPAA compliant.",
  "VNS/LIST/SUMMARY_CARD/HELP_HIPAA/ACTION": "HIPAA",

  "VNS/UPLOAD/MODAL/LABEL": "File upload",

  "VNS/TABLETS/TITLE": "1 tablet is currently linked to this organization",
  "VNS/TABLETS/TITLE_PLURAL": "{{count}} tablets are currently linked to this organization",
  "VNS/TABLETS/ADD_TABLET": "Connect a new tablet",
  "VNS/TABLETS/NO_DATA": "No tablets have been connected to this organization yet",

  "VNS/TABLETS/CONNECT/INPUT_LABEL": "Enter serial number",
  "VNS/TABLETS/CONNECT/INPUT_HINT": "A valid serial number consists of only numbers and letters",
  "VNS/TABLETS/CONNECT/TITLE": "Connect a new tablet",
  "VNS/TABLETS/CONNECT/SUBMIT": "Request to connect this tablet",
  "VNS/TABLETS/CONNECT/DESCRIPTION":
    "Does your organisation own devices not registered on this list?\nEnter the serial number below to request connection to your organisation, and to start seeing your patient data.",

  "VNS/TABLETS/CONNECT/ZENDESK_TICKET/SUBJECT":
    "({{env}}) New tablet connection request from {{userName}} of {{organizationName}}",
  "VNS/TABLETS/CONNECT/ZENDESK_TICKET/BODY":
    "{{userName}} ({{email}}) from {{organizationName}} (organization id: {{organizationId}}) has requested to add a tablet with the serial number {{serialNumber}} to their organization. This can be done by visiting  - {{adminUrl}}/tablet/create?organizationId={{organizationId}}&serialNumber={{serialNumber}}",

  "VNS/TABLETS/SUCCESS/TITLE": "Request received!",
  "VNS/TABLETS/SUCCESS/SUBMIT": "Done",

  "VNS/TABLETS/EDIT_TABLE/SERIAL_LABEL": "For serial number: ",
  "VNS/TABLETS/EDIT_TABLET/INPUT_LABEL": "Device Nickname",
  "VNS/TABLETS/EDIT_TABLET/INPUT_HINT": "Please enter a valid device nickname",
  "VNS/TABLETS/EDIT_TABLET/TITLE": "Edit this device",
  "VNS/TABLETS/EDIT_TABLET/SUBMIT": "Save changes",

  "VNS/TABLETS/TOTAL/PATIENT": "{{count}} patient",
  "VNS/TABLETS/TOTAL/PATIENT_PLURAL": "{{count}} patients",
  "VNS/TABLETS/TOTAL/READING": "{{count}} reading",
  "VNS/TABLETS/TOTAL/READING_PLURAL": "{{count}} readings",
  "VNS/TABLETS/BANNER/CURRENT": "Latest reading {{date}}",
  "VNS/TABLETS/BANNER/OVERDUE": "Latest reading > a month ago ({{date}}) ",
  "VNS/TABLETS/BANNER/OVERDUE_PLURAL": "Latest reading > {{count}} months ago ({{date}}) ",
  "VNS/TABLETS/PLACEHOLDER/NICKNAME": "Set a nickname",
  "VNS/RESOURCES/TITLE": "Resources",

  "VNS/REMOTE_PROGRAMMING/TITLE": "Remote Programming Sessions",
  "VNS/REMOTE_PROGRAMMING/COPY_INVITE": "COPY INVITE LINK",
  "VNS/REMOTE_PROGRAMMING/COPY_INVITE/SUCCESS": "Successfully copied invite link",
  "VNS/REMOTE_PROGRAMMING/JOIN": "JOIN",

  "VNS/DETAILS/TITLE": "{{serial}}",
  "VNS/DETAILS/HEADER/CHIP/DEFAULT": "Last known reading {{date}}",
  "VNS/DETAILS/HEADER/BUTTON/UPLOAD": "Upload data",
  "VNS/DETAILS/HEADER/BUTTON/PROGRAMMING": "Start remote programming",
  "VNS/DETAILS/SETTINGS/TITLE": "Current settings",

  "VNS/DETAILS/SERIAL/NUMBER": "Serial Number",
  "VNS/DETAILS/TABLE/TITLE": "{{count}} known reading",
  "VNS/DETAILS/TABLE/TITLE_PLURAL": "{{count}} known readings",
  "VNS/DETAILS/TABLE/COLUMN_LABEL/CREATED_AT": "Reading date",
  "VNS/DETAILS/TABLE/COLUMN_LABEL/MODE": "Mode",
  "VNS/DETAILS/TABLE/COLUMN_LABEL/CURRENT": "Current (mA)",
  "VNS/DETAILS/TABLE/COLUMN_LABEL/FREQUENCY": "Frequency (Hz)",
  "VNS/DETAILS/TABLE/COLUMN_LABEL/PULSE_WIDTH": "Pulse width (μ sec)",
  "VNS/DETAILS/TABLE/COLUMN_LABEL/ONTIME": "On time (sec)",
  "VNS/DETAILS/TABLE/COLUMN_LABEL/OFFTIME": "Off time (min)",
  "VNS/DETAILS/TABLE/COLUMN_LABEL/IMPEDANCE": "Impedance (Ω)",
  "VNS/DETAILS/TABLE/OPEN_SESSION_REPORT": "Open session report",

  "VNS/DETAILS/SUMMARY_CARD/ABOUT/TITLE": "About this patient",
  "VNS/DETAILS/SUMMARY_CARD/BATTERY/TITLE": "Battery life estimation",
  "VNS/DETAILS/SUMMARY_CARD/BATTERY/TIME_TO_NEOS": "{{time}} to NEOS",
  "VNS/DETAILS/SUMMARY_CARD/BATTERY/DISCLAIMER":
    "Calculated estimate of the time remaining until the battery in this device nears end of service. This value is recalculated regularly. These estimates are informational only. LivaNova recommends continued monitoring of the Near End of Service indicator during the patient's follow up visits and monitoring of signs/symptoms for EOS.",
  "VNS/DETAILS/SUMMARY_CARD/BATTERY/TIME_TO_NEOS/YEARS": "{{years}} years",
  "VNS/DETAILS/SUMMARY_CARD/ASSIGNED/TITLE": "Assigned ({{assigned}})",
  "VNS/DETAILS/SUMMARY_CARD/ASSIGNED/EMPTY":
    "You can assign this patient to a user of this organisation. Select manage assigned to get started.",
  "VNS/DETAILS/SUMMARY_CARD/ASSIGNED/BUTTON": "Manage Assigned",

  "VNS/SESSION_REPORTS/HEADER/COPY": "Copy summary",
  "VNS/SESSION_REPORTS/HEADER/DOB": "DOB: {{date}}",
  "VNS/SESSION_REPORTS/CARD/HEADER/STEP_START_TIME": "Step start time:",
  "VNS/SESSION_REPORTS/CARD/DAY_NIGHT_HEADER/DAYTIME": "Daytime",
  "VNS/SESSION_REPORTS/CARD/DAY_NIGHT_HEADER/NIGHTTIME": "Nighttime",
  "VNS/SESSION_REPORTS/HEADER/DOWNLOAD": "Download report PDF",

  "VNS/SESSION_REPORTS/REPORT_HEADER/IMPLANT_DATE": "Implant date: {{date}}",
  "VNS/SESSION_REPORTS/REPORT_HEADER/SERIAL_NUMBER": "SN {{serial}}",

  "VNS/SESSION_REPORTS/TABLE/TITLE": "Session report",
  "VNS/SESSION_REPORTS/TABLE/DATE/LABEL": "Session start time",
  "VNS/SESSION_REPORTS/TABLE/ON": "ON",
  "VNS/SESSION_REPORTS/TABLE/OFF": "OFF",
  "VNS/SESSION_REPORTS/TABLE/NOT_AVAILABLE": "Not available",

  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE": "Parameters",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/CHANGES": "{{changes}} Parameter Changes",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/INITIAL": "Initial",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/FINAL": "Final",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/MANUAL": "Manual",

  "VNS/SESSION_REPORTS/TABLE/PARAMETERS/TIME_HEADER/DAYTIME": "DAYTIME",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS/TIME_HEADER/NIGHTTIME": "NIGHTTIME",

  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/NORMAL": "Normal",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/AUTOSTIM": "AutoStim",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/MAGNET": "Magnet",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/DETECTION": "Detections",

  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/OUTPUT_CURRENT": "Output Current",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/FREQUENCY": "Frequency",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PULSE_WIDTH": "Pulse Width",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE": "Duty Cycle",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/ON_TIME": "On Time",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/OFF_TIME": "Off Time",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/AUTOSTIM_THRESHOLD": "AutoStim Threshold",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/TACHYCARDIA": "Tachycardia",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/HEARTBEAT_DETECTION": "Heartbeat Detection",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/LOW_HEART_RATE": "Low Heart Rate",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/LHR_THRESHOLD": "LHR Threshold",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PRONE_POSITION": "Prone Position",

  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/UNIT/DUTY_CYCLE": "%",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/UNIT/AUTOSTIM_THRESHOLD": "%",

  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/VALUE/DISABLED": "Therapy disabled",
  "VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/VALUE/PAUSED": "Therapy paused",

  "VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/TITLE": "System Diagnostics",
  "VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/BATTERY_LABEL": "Battery",
  "VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/LEAD_IMPEDANCE_LABEL": "Lead Impedance",
  "VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/LEAD_IMPEDANCE": "{{value}} Ohms",
  "VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/OUTPUT_CURRENT_LABEL": "Output Current",
  "VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/OUTPUT_CURRENT": "{{value}} mA",
  "VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/CHIP/OK": "OK",
  "VNS/SESSION_REPORTS/TABLE/DIAGNOSTICS/CHIP/EOS": "EOS",

  "VNS/SESSION_REPORTS/CHIP/DAY_NIGHT": "Day-Night",
  "VNS/SESSION_REPORTS/CHIP/GUIDED": "Guided Titration Plan",
  "VNS/SESSION_REPORTS/CHIP/SCHEDULED": "Scheduled Titration Plan",
  "VNS/SESSION_REPORTS/CHIP/MANUAL": "Manual",
  "VNS/SESSION_REPORTS/CHIP/REGULAR": "Regular",

  "VNS/SESSION_REPORTS/CHIP/STATE/ENABLED": "Therapy enabled",
  "VNS/SESSION_REPORTS/CHIP/STATE/PAUSED": "Therapy paused",
  "VNS/SESSION_REPORTS/CHIP/STATE/DISABLED": "Therapy disabled",

  "VNS/SESSION_REPORTS/TITRATION_CARD/GUIDED_TITLE": "Final guided titration plan",
  "VNS/SESSION_REPORTS/TITRATION_CARD/SCHEDULED_TITLE": "Final scheduled titration plan",
  "VNS/SESSION_REPORTS/TITRATION_CARD/STEP_START_TIME": "Step start time: ",

  "VNS/SESSION_REPORTS/TITRATION_CARD/STEP": "Step",
  "VNS/SESSION_REPORTS/TITRATION_CARD/DATE": "Date",
  "VNS/SESSION_REPORTS/TITRATION_CARD/NORMAL": "Normal",
  "VNS/SESSION_REPORTS/TITRATION_CARD/AUTOSTIM": "AutoStim",
  "VNS/SESSION_REPORTS/TITRATION_CARD/MAGNET": "Magnet",

  "VNS/SESSION_REPORTS/TITRATION_CARD/CURRENT": "Current",
  "VNS/SESSION_REPORTS/TITRATION_CARD/TARGET": "Target",

  "VNS/SESSION_REPORTS/DIALOG/TITLE": "Copy report summary",
  "VNS/SESSION_REPORTS/DIALOG/TITLE/COPIED_TO_CLIPBOARD": "Summary copied to clipboard",
  "VNS/SESSION_REPORTS/DIALOG/BUTTON/OK": "OK",
  "VNS/SESSION_REPORTS/DIALOG/BUTTON/COPY_ALL_TO_CLIPBOARD": "Copy all to clipboard",

  "VNS/SESSION_REPORTS/DIALOG/TEXT/TITLE": "VNS Therapy Session report",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/UNKNOWN_READING_DATE": "Reading date time: Unknown",

  "VNS/SESSION_REPORTS/DIALOG/TEXT/PATIENT": "Patient: {{patient}}",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/IMPLANT_DATE": "Implant date: {{date}}",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/SERIAL_NUMBER": "SN {{serial}}",

  "VNS/SESSION_REPORTS/DIALOG/TEXT/SYSTEM_DIAGNOSTICS": "System Diagnostics",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/BATTERY": "Battery: {{value}} {{health}}",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/LEAD_IMPEDANCE": "Lead Impedance: {{value}} Ohms {{status}}",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/UNKNOWN_LEAD_IMPEDANCE": "Lead Impedance: Unknown",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/OUTPUT_CURRENT": "Output Current: {{value}} mA {{status}}",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/UNKNOWN_OUTPUT_CURRENT": "Output Current: Unknown",

  "VNS/SESSION_REPORTS/DIALOG/TEXT/STEP": "Step {{stepId}}{{date}}{{status}}",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/STEP_TITLE": "Output currents",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/STEP_NORMAL": "Normal: {{value}} mA",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/STEP_AUTOSTIM": "AutoStim: {{value}} mA",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/STEP_MAGNET": "Magnet: {{value}} mA",

  "VNS/SESSION_REPORTS/DIALOG/TEXT/PARAMETER_DAYTIME": "DAYTIME",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/PARAMETER_NIGHTTIME": "NIGHTTIME",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/PARAMETER_CHANGES": "Parameters - {{count}} change",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/PARAMETER_CHANGES_PLURAL": "Parameters - {{count}} changes",
  "VNS/SESSION_REPORTS/DIALOG/TEXT/PARAMETERS_MODE":
    "Initial {{initialTherapy}}({{initial}}) -> Final ({{final}}){{finalTherapy}}",

  "VNS/LABEL/DOB": "DOB",
  "VNS/LABEL/IMPLANTED_AT": "Date of implant",
  "VNS/LABEL/GENERATOR_MODEL": "Generator model",

  "VNS/SETTINGS/HEADER/AUTOSTIM": "AutoStim",
  "VNS/SETTINGS/HEADER/DETECTION": "Detections",
  "VNS/SETTINGS/HEADER/MAGNET": "Magnet",
  "VNS/SETTINGS/HEADER/NORMAL": "Normal",

  "VNS/SETTINGS/LABEL/AUTO_SIM_THRESHOLD": "AutoStim threshold",
  "VNS/SETTINGS/LABEL/DUTY_CYCLE": "Duty cycle",
  "VNS/SETTINGS/LABEL/FREQUENCY": "Frequency",
  "VNS/SETTINGS/LABEL/HEART_BEAT_DETECTION": "Heartbeat detection",
  "VNS/SETTINGS/LABEL/TACHYCARDIA_DETECTION": "Tachycardia detection",
  "VNS/SETTINGS/LABEL/OFF_TIME": "Off time",
  "VNS/SETTINGS/LABEL/ON_TIME": "On time",
  "VNS/SETTINGS/LABEL/OUTPUT_CURRENT": "Output current",
  "VNS/SETTINGS/LABEL/PULSE_WIDTH": "Pulse width",

  "VNS/LIST/FILEUPLOAD/UPLOADED": "Uploaded {{uploadedDate}}",
  "VNS/LIST/FILEUPLOAD/ERROR/FILE/TYPE/MESSAGE": "File Type not supported",
  "VNS/LIST/FILEUPLOAD/ERROR/FILE/TYPE/DESCRIPTION": "Please upload the correct files, .zip or a folder",
  "VNS/LIST/FILEUPLOAD/ERROR/FILE/SIZE/MESSAGE": "File cannot be larger than {{fileSize}}MB",
  "VNS/LIST/FILEUPLOAD/ERROR/FILE/SIZE/DESCRIPTION": "Please try again",

  "VNS/MODAL/ALL_DEVICES_INFO/MODAL/LABEL": "Using Epsy Hub for patients with VNS Therapy",
  "LIVANOVA/VNS/MODAL/ALL_DEVICES_INFO/MODAL/LABEL": "Using VNS Therapy™ My Portal for patients with VNS Therapy",

  "VNS/MODAL/ALL_DEVICES_INFO/HEADER_INFO": `This tool is intended to provide an insight into the status of VNS Therapy devices implanted in patients within your organization. The information displayed is a reflection of the most recent data uploaded from a programming device. This is not intended to replace the instructions found in the VNS Therapy Physician's manual. \n\n This tool also provides an estimation of the remaining battery life* of each device, explained below. This tool is not for providing medical advice.`,
  "VNS/MODAL/ALL_DEVICES_INFO/YEARS_TO_NEOS_INFO/START":
    "This calculation estimates the time remaining until the pulse generator is near end of service (NEOS). Device longevity is dependent on stimulation parameters, impedance, duration of implant, and battery voltage. Higher stimulation parameters as well as higher duty cycles may affect the longevity of the device, making the projection shorter. Additional information can be found in the ",
  "VNS/MODAL/ALL_DEVICES_INFO/YEARS_TO_NEOS_INFO/LINK": "VNS Therapy Physician’s Manual",
  "VNS/MODAL/ALL_DEVICES_INFO/YEARS_TO_NEOS_INFO/END": ".",
  "VNS/MODAL/ALL_DEVICES_INFO/DISCLAIMER": "*Disclaimer",
  "VNS/MODAL/ALL_DEVICES_INFO/DISCLAIMER_INFO":
    "This calculation is an estimate and may be impacted by gaps in available data. Other factors (including magnet activity, additional parameter changes not available to LivaNova, etc.) were not accounted for in this calculation and could impact NEOS date. These estimates are informational only. LivaNova recommends continued monitoring of the Near End of Service indicator during the patient's follow up visits and monitoring of signs/symptoms for EOS.",
  "VNS/UPLOAD/HELPER/TEXT":
    "Below are the instructions for exporting, importing, and sending tablet data from the VNS Therapy™ Programming System. These instructions are applicable for the Dell and HP tablets (purple covers)"
} as const

export type TextTranslationKey = keyof typeof textTranslation

function isTextTranslationKey<T>(input: TextTranslationKey | T): input is TextTranslationKey {
  return Boolean(
    typeof input === "string" &&
      ((textTranslation as any)[input] || typeof (textTranslation as any)[input] === "string")
  )
}

const _pluralSuffix = "_PLURAL"
const _LivaNovaPrefix = "LIVANOVA/"

// NB: It is likely in the future that we will need localization and server-side rendering.
// These two things combined can be a difficult problem to solve. In the past I've ended up
// having to get the translation function from a react hook in order to have the correct
// language used both in browser and in server requests. So I'm trying to only directly
// use translate inside of react components / render functions, and passing it to any
// non-react functions that need it.
export function translateText(key: TextTranslationKey, params?: Record<string, string | number>): string {
  function whiteLabelKey(key: TextTranslationKey): TextTranslationKey {
    switch (getTheme()) {
      case Theme.Epsy:
        return key
      case Theme.LivaNova:
        return isTextTranslationKey(_LivaNovaPrefix + key) ? ((_LivaNovaPrefix + key) as TextTranslationKey) : key
    }
  }

  if (typeof key !== "string") return ""
  let translated: string = ""
  let keyToUse = whiteLabelKey(key)

  if (params) {
    // WITH PARAMS
    const keyWithParams =
      "count" in params && params.count !== 1 && keyToUse + _pluralSuffix in textTranslation
        ? keyToUse + _pluralSuffix
        : keyToUse
    //@ts-ignore
    translated = template(textTranslation[keyWithParams], params)
  } else {
    // SIMPLE
    translated = textTranslation[keyToUse]
  }
  if (translated === undefined) {
    sentry.captureException(new Error(`Missing translation: ${keyToUse}`))
    translated = ""
  }
  return translated
}
