export const paths = {
  HOME: "/",
  LOGIN: "/login",
  CHANGE_PASSWORD: "/change-password",
  RESET_PASSWORD: "/forgot-password",
  CONNECT_ORG: "/connect-org",
  JOIN_ORG: "/join-org",

  BAA: "/baa",
  COOKIE: "/cookie-policy",
  PRIVACY: "/privacy-policy",
  TOMS: "/toms",
  TERMS: "/terms-and-conditions",

  PATIENT_LIST: "/patients",
  ADD_PATIENTS: "/patients/add",
  PATIENT_REPORT: "/patients/:id/report",
  PATIENT_VIDEO_PREVIEW: "/patients/:id/videos/:videoId",
  PATIENT_VIDEO_LIST: "/patients/:id/videos",

  QUESTIONNAIRES: "/questionnaires",
  QUESTIONNAIRES_RESPONSE_LIST: "/questionnaires/responses",
  QUESTIONNAIRES_RESPONSE: "/questionnaires/responses/:id",
  QUESTIONNAIRES_RESPONSE_EMPTY: "/questionnaires/empty/:id",

  VNS: "/vns",
  VNS_DETAILS: "/vns/patients/:id",
  VNS_SESSION_REPORT: "/vns/patients/:patientId/session-report/:readingDate/tablet/:tabletSerialNumber",
  VNS_LIST: "/vns/patients",
  VNS_RESOURCES: "/vns/resources",
  VNS_UPLOAD: "/vns/uploads",
  VNS_TABLETS: "/vns/tablets",
  VNS_REMOTE_PROGRAMMING: "/vns/remote-programming",

  TEAM: "/team",
  ADD_TEAM: "/team/add",

  ACCOUNT: "/account",
  LOGOUT: "/logout"
}
