export type PatientReportDayInput = {
  date: string
  patientId: string
}

export type GetPatientReportDayQuery = {
  getPatientReportDay: PatientReportDay
}

export type PatientReportDay = {
  date: string
  auras: Aura[]
  bpm?: number
  events: Event[]
  medications: MedicationReport
  seizures: SeizureDay[]
  sideEffects: SideEffectDay[]
  steps?: number
  videos: Video[]
}

export type Aura = {
  timestamp: string
  notes?: string
}

export type Event = {
  timestamp: string
  mood: EventMood
  type: EventType
  notes?: string
}

export type MedicationReport = {
  taken: MedicationEvent[]
  missed: MedicationEvent[]
  notLogged: MedicationEvent[]
  singleDose: MedicationDose[]
  emergencyDose: MedicationDose[]
  deviceSwipe: MedicationDeviceSwipe[]
}

export type MedicationEvent = {
  timestamp: string
  medication: string
}

export type MedicationDose = {
  timestamp: string
  medication?: string
  dose?: number
  unit?: DoseUnit
  notes?: string
}

export type MedicationDeviceSwipe = {
  timestamp: string
}

export type SeizureDay = {
  awareness: SeizureAwareness
  timestamp: string
  triggers: SeizureTrigger[]
  type: string
  intensity?: SeizureIntensity
  notes?: string
}

export type SideEffectDay = {
  timestamp: string
  types: SideEffectType[]
  notes?: string
}

export type Video = {
  timestamp: string
}

export enum SeizureAwareness {
  Aware,
  NotAware,
  NotSure
}

export enum SeizureIntensity {
  Mild,
  Medium,
  Severe
}

export enum SeizureTrigger {
  Fever = "Fever",
  Sleep = "Sleep",
  Stress = "Stress",
  FlashingLights = "Flashing Lights",
  Weather = "Weather"
}

export enum DoseUnit {
  Milligrams,
  Millilitres,
  Grams,
  Pills,
  Units
}

export enum SideEffectType {
  Anxiety,
  MemoryLoss,
  Other
  // And others like: Dizziness, Tiredness...
}

export enum EventType {
  Driving,
  Soda,
  Working
  // And others like: Socialising, JunkFood...
}

export enum EventMood {
  Stressed,
  Depressed,
  Nervous
  // And others like: Sad, Angry...
}
