import { Flex, Spacer } from "epsy-ui-react"
import { useRef, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { paths } from "src/app/routing/paths"
import ViewContainer from "src/components/ViewContainer"
import ViewErrorMessage from "src/components/ViewErrorMessage"
import { useAnalyticsPage, useAnalyticsTrack } from "src/hooks/useAnalytics"
import useFlags from "src/hooks/useFlags"
import { analytics } from "src/services/analytics"
import sentry from "src/services/sentry"
import { translateText } from "src/services/translations/textTranslations"
import Loading from "src/views/Loading"
import { useGetVnsDevice } from "../data"
import { Header } from "./Header"
import { ParametersCard } from "./Parameters/ParametersCard"
import { getLayout } from "./Parameters/utils"
import { useGetVnsSessionReport } from "./queries"
import { ReportContainer } from "./ReportContainer"
import { ReportDiagnosticCard } from "./ReportDiagnosticCard"
import { ReportHeader } from "./ReportHeader"
import { SummaryDialog } from "./SummaryDialog"
import { TitrationPlan } from "./Titration/TitrationPlan"

export const VNSSessionReport = () => {
  const { patientId = "", readingDate = "", tabletSerialNumber = "" } = useParams()
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
  const { hubShowSessionReport } = useFlags()

  useAnalyticsPage("DP_VNS_View_Session_Report_Viewed")
  useAnalyticsTrack("DP_VNS_View_Session_Report_Copy_Clicked", undefined, [isCopyModalOpen], isCopyModalOpen)

  const printRef = useRef<HTMLInputElement>(null)
  const handlePrint = useReactToPrint({ content: () => printRef.current || null })

  const { data: vnsDevice, isLoading: isVnsDeviceLoading, error: vnsDeviceError } = useGetVnsDevice(patientId)
  const {
    data: vnsSessionReport,
    isLoading: isVnsSessionReportLoading,
    error: vnsSessionReportError
  } = useGetVnsSessionReport({
    patientProfileId: patientId,
    readingDateTime: readingDate,
    tabletSerialNumber
  })

  const isLoading = isVnsDeviceLoading || isVnsSessionReportLoading
  const error = vnsDeviceError || vnsSessionReportError

  if (!hubShowSessionReport) return <Navigate to={paths.VNS_LIST} />

  if (isLoading) return <Loading />

  if (error || !vnsDevice || !vnsSessionReport) {
    sentry.captureException("VNSSessionReport/VNSDevice query error", {
      extra: { vnsDeviceError, vnsSessionReportError, vnsDevice, vnsSessionReport }
    })
    return (
      <ViewContainer>
        <ViewErrorMessage>{translateText("COMMON/GENERIC_ERROR")}</ViewErrorMessage>
      </ViewContainer>
    )
  }

  return (
    <ViewContainer>
      <SummaryDialog onClose={() => setIsCopyModalOpen(false)} open={isCopyModalOpen} report={vnsSessionReport} />
      <Header
        onCopySummaryClick={() => {
          setIsCopyModalOpen(true)
        }}
        onDownloadPdfClick={() => {
          analytics.track("DP_VNS_View_Session_Report_Download_PDF_Clicked")
          handlePrint()
        }}
        patientDob={vnsDevice.patientDob}
        patientName={vnsDevice.patientName || vnsDevice.generatorSerialNumber.toString()}
        patientId={patientId}
      />
      <Spacer height={24} />
      <ReportContainer ref={printRef} reportDate={vnsSessionReport.sessionStartDateTime}>
        <Flex flexDirection="column">
          <ReportHeader
            generatorModel={vnsSessionReport.generatorModel}
            generatorSerialNumber={vnsSessionReport.generatorSerialNumber}
            implantedDate={vnsSessionReport.implantedDate}
            patientId={vnsSessionReport.patientId}
          />
          <Spacer height={24} />
          <ReportDiagnosticCard
            batteryHealth={vnsSessionReport.final.batteryHealth}
            batteryPercentage={vnsSessionReport.final.batteryPercentage}
            leadImpedanceStatus={vnsSessionReport.final.leadImpedanceStatus}
            leadImpedanceValue={vnsSessionReport.final.leadImpedanceValue}
            normalOutputCurrent={vnsSessionReport.final.normalOutputCurrent}
            normalOutputCurrentStatus={vnsSessionReport.final.normalOutputCurrentStatus}
          />
          <Spacer height={32} />
          <TitrationPlan />
          <Spacer height={32} />
          <ParametersCard
            initial={vnsSessionReport.initial}
            final={vnsSessionReport.final}
            layout={getLayout(vnsSessionReport.initial.programmingMode, vnsSessionReport.final.programmingMode)}
          />
        </Flex>
      </ReportContainer>
    </ViewContainer>
  )
}
