import { Box, BoxProps, Flex, Text, TextProps } from "epsy-ui-react"
import { ReactNode } from "react"

type Shape = "default" | "rounded"
type RootProps = BoxProps & { children: ReactNode; shape?: Shape }

const Root = ({ children, shape = "default", ...props }: RootProps) => (
  <Box borderRadius={borderRadiusMap[shape]} px={2} {...props}>
    <Flex alignItems="center" minHeight={24} gap={4}>
      {children}
    </Flex>
  </Box>
)

const Dot = ({ bg }: Pick<BoxProps, "bg">) => <Box width={16} height={16} flexShrink={0} borderRadius={40} bg={bg} />

const Label = (props: TextProps) => <Text lines={1} {...props} />

const borderRadiusMap: Record<Shape, number> = {
  default: 4,
  rounded: 12
}

export const Chip = { Root, Dot, Label }
