import React from "react"
import { Outlet } from "react-router-dom"
import { HcpRole } from "src/API"
import { useProfile } from "src/redux/auth/useProfile"

const NoAccess = React.lazy(() => import("src/app/routing/NoAccess"))

export const ProtectedPatientRoute = () => {
  const { profile } = useProfile()

  if (profile.role === HcpRole.ITAdmin) return <NoAccess />

  return <Outlet />
}
