import { useSelector } from "react-redux"
import { authSelector } from "./selectors"

export const useProfile = () => {
  const { profile } = useSelector(authSelector)
  if (!profile) throw new Error("No profile found")
  const { organizationId } = profile
  if (!organizationId) throw new Error("No organizationId found")
  return { profile: { ...profile, organizationId } }
}
