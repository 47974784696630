import { configureStore } from "@reduxjs/toolkit"
import thunkMiddleware from "redux-thunk"
import appConfig from "src/appConfig"
import rootReducer from "./rootReducer"

const middleware = [thunkMiddleware]
if (appConfig.isCypress) {
  const { createLogger } = require("redux-logger")
  middleware.push(createLogger())
}

const store = configureStore({ reducer: rootReducer })

if (appConfig.isCypress) {
  const win = window as any
  win.store = store
}

export default store
