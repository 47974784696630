import * as RadixDialog from "@radix-ui/react-dialog"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import { Box, Flex, Text, TextProps } from "epsy-ui-react"
import React from "react"
import { ReactComponent as CloseSvg } from "src/images/icons/close/model-close.svg"
import styled, { css } from "styled-components"

export type DialogProps = {
  children: React.ReactNode
  onClose: () => void
  open: boolean
  $centred?: boolean
  $maxWidth?: number
  $noPadding?: boolean
}

export const Dialog = ({ children, onClose, open, $centred, $maxWidth = 900, $noPadding }: DialogProps) => (
  <RadixDialog.Root onOpenChange={(open) => !open && onClose()} open={open}>
    <RadixDialog.Portal>
      <Overlay />
      <DialogContent aria-describedby={undefined} $centred={$centred} $maxWidth={$maxWidth} $noPadding={$noPadding}>
        {children}
      </DialogContent>
    </RadixDialog.Portal>
  </RadixDialog.Root>
)

type DialogHeaderProps = TextProps & {
  children: string
  center?: boolean
  hideTitle?: boolean
  showCloseButton?: boolean
}

export const DialogHeader = ({ children, center, hideTitle, showCloseButton, ...textProps }: DialogHeaderProps) => (
  <Box position="relative" minHeight={32} mb={3} pr={48} pl={center ? 48 : 0} width="100%">
    <Flex justifyContent={center ? "center" : "unset"} gap={8} width="100%">
      {hideTitle ? (
        <Box>
          <VisuallyHidden.Root asChild>
            <DialogTitle {...textProps}>{children}</DialogTitle>
          </VisuallyHidden.Root>
        </Box>
      ) : (
        <DialogTitle {...textProps}>{children}</DialogTitle>
      )}
      {showCloseButton && <DialogClose />}
    </Flex>
  </Box>
)

type DialogTitleProps = TextProps & { children: string }

export const DialogTitle = ({ children, ...textProps }: DialogTitleProps) => (
  <RadixDialog.Title asChild>
    <TextForwardRef {...textProps}>{children}</TextForwardRef>
  </RadixDialog.Title>
)

export const DialogClose = () => (
  <Box position="absolute" right={0}>
    <RadixDialog.Close asChild>
      <CloseIcon />
    </RadixDialog.Close>
  </Box>
)

const showAnimation = css`
  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Overlay = styled(RadixDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0;
  animation: show 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 2;
  ${showAnimation};
`

const DialogContent = styled(RadixDialog.Content)<Pick<DialogProps, "$centred" | "$maxWidth" | "$noPadding">>`
  display: flex;
  flex-direction: column;

  position: fixed;
  top: ${(p) => (p.$centred ? "50%" : "88px")};
  left: 50%;
  transform: translate(-50%, ${(p) => (p.$centred ? "-50%" : "0")});
  z-index: 2;

  width: 90vw;
  max-width: ${(p) => p.$maxWidth}px;
  max-height: calc(100% - 176px);
  overflow-y: auto;

  padding: ${(p) => (p.$noPadding ? "0" : "24px 40px")};
  border-radius: 6px;

  background-color: white;
  animation: show 150ms cubic-bezier(0.16, 1, 0.3, 1);
  ${showAnimation};

  &:focus {
    outline: none;
  }
`

const CloseIcon = styled(CloseSvg)`
  cursor: pointer;
`

// Our Text component doesn't handle refs
const TextForwardRef = React.forwardRef<HTMLDivElement, TextProps>((props, ref) => <Text {...props} />)
