import { QueryCache, QueryClient } from "@tanstack/react-query"
import { emitLogoutEvent } from "../redux/auth/LogoutEventHandler"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    },
    mutations: {
      retry: false
    }
  },
  queryCache: new QueryCache({
    onError: (error) => {
      // Logout user if doesn't contain valid token
      if (error instanceof Error && error.message === "No current user") {
        emitLogoutEvent("LOGOUT")
      }
    }
  })
})
