import qs from "query-string"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { authSelector } from "src/redux/auth/selectors"
import { useLandingPage } from "src/services/whiteLabel"

export const useRedirectIfLoggedIn = () => {
  const landingPage = useLandingPage()
  const { profile } = useSelector(authSelector)
  const { search } = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (profile) {
      const { r: qsRedirect } = qs.parse(search)
      const redirectTo = qsRedirect && typeof qsRedirect === "string" ? qsRedirect : landingPage
      navigate(redirectTo, { replace: true })
    }
  }, [navigate, profile, search, landingPage])
}
