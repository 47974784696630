import { Maybe } from "epsy-ui-react/dist/util/utilityTypes"
import sentry from "../sentry"

const translations: Record<string, string> = {
  "1000": "SenTiva™",
  "1000D": "SenTiva™ Duo",
  "1000-D": "SenTiva™ Duo",
  "106": "Aspire SR™",
  "105": "Aspire HC™",
  "103": "Demipulse™",
  "104": "Demipulse Duo™",
  "102": "Pulse™",
  "102R": "Pulse Duo™",
  "101 VER. 1": "101 ver. 1",
  "8103": "Symmetry"
}

export const translateIpgModel = (maybeKey: Maybe<string>): string => {
  const key = maybeKey ?? ""
  const translated = translations[key.toUpperCase()]

  if (!translated) {
    sentry.captureException(new Error(`Missing translation for IPG Model: ${maybeKey}`))
    return key
  }

  return translated
}
