import { bp, ResponsiveValue } from "epsy-ui-react/dist/style/breakpoints"
import { loadingPulseAnimation } from "epsy-ui-react/dist/style/mixins"
import React from "react"
import { Link, LinkProps } from "react-router-dom"
import mapValues from "src/util/mapValues"
import styled, { css } from "styled-components"

const sizes = {
  10: {
    fontSize: 10,
    lineHeight: 18,
    letterSpacing: 0.01
  },
  12: {
    fontSize: 12,
    lineHeight: 18,
    letterSpacing: 0.01
  },
  14: {
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.01
  },
  16: {
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.01
  },
  18: {
    fontSize: 18,
    lineHeight: 28,
    letterSpacing: 0.01
  },
  24: {
    fontSize: 24,
    lineHeight: 36,
    letterSpacing: 0
  },
  32: {
    fontSize: 32,
    lineHeight: 40,
    letterSpacing: 0
  },
  48: {
    fontSize: 48,
    lineHeight: 64,
    letterSpacing: -0.01
  },
  72: {
    fontSize: 72,
    lineHeight: 92,
    letterSpacing: -0.02
  }
} as const

function generateBase(Comp: React.ElementType) {
  return (props: any) => {
    // filter style props from reaching DOM
    const { bold, color, underline, lines, fontSize, lineHeight, letterSpacing, loading, loaderChars, ...rest } = props
    return <Comp {...rest} />
  }
}

interface PassedProps {
  bold?: boolean
  color?: string
  underline?: boolean
  lines?: number
  fontSize: ResponsiveValue<number>
  lineHeight: ResponsiveValue<number>
  letterSpacing: ResponsiveValue<number>
  loading?: boolean
  loaderChars?: number
  align?: string
  noPulse?: boolean
  maxWidth?: number
}
const baseStyle = css<PassedProps>`
  font-family: ${(p) => p.theme.fonts.fontFamily};
  margin: 0;
  white-space: pre-line;
  ${(props) => css`
    ${bp.each("font-size", props.fontSize, "px")}
    ${bp.each("line-height", props.lineHeight, "px")}
  ${bp.each("letter-spacing", props.letterSpacing, "em")}
  font-weight: ${props.bold ? "bold" : "normal"};
    ${props.underline ? `text-decoration: underline;` : props.underline === false ? `text-decoration: none;` : ``}
    ${props.color ? `color: ${props.color};` : ``}
  ${props.align ? `text-align: ${props.align};` : ``}
  ${props.maxWidth ? `max-width: ${props.maxWidth}px` : ``}
  ${props.lines ? bp.each("height", props.lineHeight, "px", (x) => x * Number(props.lines)) : ``}
  ${props.lines === 1
      ? `
  width: 100%;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  `
      : ``}
  ${props.loading
      ? css`
          width: ${props.loaderChars ? `${props.loaderChars * 0.5}em` : `100%`};
          ${bp.each("height", props.lineHeight, "px", (x) => x * (props.lines || 1))}
          color: transparent;
          position: relative;
          user-select: none;
          &:before {
            content: "x";
            background-color: ${(p) => p.theme.oldColors.shade[5]};
            position: absolute;
            ${bp.each("top", props.lineHeight, "px", (x) => x * 0.15)}
            left: 0;
            ${bp.each("height", props.lineHeight, "px", (x) => x * 0.7)}
            max-width: 100%;
            width: ${props.loaderChars ? `${props.loaderChars * 0.5}em` : `100%`};
            border-radius: 3px;
            pointer-events: none;
            ${props.noPulse ? "" : loadingPulseAnimation}
          }
        `
      : ``}
  `}
`

// this should be "keyof JSX.IntrinsicElement" but that breaks TS because of a bug
type Tag =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "a"
  | "ul"
  | "ol"
  | "li"
  | "span"
  | "dl"
  | "dd"
  | "dt"
  | "label"

function getSizeProps(size?: ResponsiveValue<keyof typeof sizes>): {
  fontSize: ResponsiveValue<number>
  lineHeight: ResponsiveValue<number>
  letterSpacing: ResponsiveValue<number>
} {
  if (!size) return sizes[16]
  if (typeof size === "object") {
    return {
      fontSize: mapValues(size, (x) => sizes[x].fontSize),
      lineHeight: mapValues(size, (x) => sizes[x].lineHeight),
      letterSpacing: mapValues(size, (x) => sizes[x].letterSpacing)
    }
  } else {
    return sizes[size]
  }
}

const Base = styled(generateBase("div"))`
  ${baseStyle}
`
export interface TextProps extends Partial<PassedProps>, React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string
  size?: ResponsiveValue<keyof typeof sizes>
  tag?: Tag
}
const Text = (props: TextProps) => {
  const { tag, size, ...rest } = props
  const sizeProps = React.useMemo(() => getSizeProps(size), [size])
  const baseProps = { as: tag, ...sizeProps, ...rest }
  return <Base {...baseProps} />
}

// ---- hacky workaround to get Text styles and React Router Link working together ----
// ---- preferably DO NOT import this anywhere except components/Link -----------------
// const TextLinkBase = styled(Link as Link<{} | null | undefined>)`
//   ${baseStyle}
// `
const TextLinkBase = styled(generateBase(Link))`
  ${baseStyle}
`
// @ts-ignore
export interface TextLinkProps extends TextProps, LinkProps {}
// @ts-ignore
export const TextLink = (props: TextLinkProps) => {
  const { size, ...rest } = props
  const sizeProps = React.useMemo(() => getSizeProps(size), [size])
  const baseProps = { ...sizeProps, ...rest }
  return <TextLinkBase {...baseProps} />
}
// ---- end hacky workaround

export default React.memo(Text)
