import { EventType, GetPatientReportQuery, SeizureTrigger, SideEffectType } from "./patientReportQueryMock"

export const patientReportQueryResponseMock: { data: GetPatientReportQuery } = {
  data: {
    getPatientReport: {
      data: [
        {
          dateRange: { from: "2023-01-08", to: "2023-01-08" },
          auras: 1,
          events: [{ value: 1, type: EventType.Driving }],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 1, notLogged: 1, singleDose: 1 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-09", to: "2023-01-09" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 1, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Convulsive" }],
          sideEffects: [
            { value: 1, type: SideEffectType.Anxiety },
            { value: 2, type: SideEffectType.MemoryLoss },
            { value: 1, type: SideEffectType.Nausea },
            { value: 1, type: SideEffectType.Tiredness },
            { value: 1, type: SideEffectType.Depression }
          ],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-10", to: "2023-01-10" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [
            { value: 1, type: "Convulsive" },
            { value: 2, type: "Non convulsive" }
          ],
          sideEffects: [{ value: 1, type: SideEffectType.Depression }],
          steps: 8000,
          triggers: [
            { value: 1, type: SeizureTrigger.Stress },
            { value: 2, type: SeizureTrigger.Fever }
          ],
          videos: 1
        },
        {
          dateRange: { from: "2023-01-11", to: "2023-01-11" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-12", to: "2023-01-12" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-13", to: "2023-01-13" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [{ value: 1, type: SideEffectType.Dizziness }],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-14", to: "2023-01-14" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-15", to: "2023-01-15" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-16", to: "2023-01-16" },
          auras: 2,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 2, missed: 0, notLogged: 0, singleDose: 1 },
          seizures: [
            { value: 1, type: "Non convulsive" },
            { value: 1, type: "Not sure" }
          ],
          sideEffects: [],
          steps: 8000,
          triggers: [
            { value: 1, type: SeizureTrigger.Stress },
            { value: 2, type: SeizureTrigger.Fever },
            { value: 2, type: SeizureTrigger.Sleep },
            { value: 2, type: SeizureTrigger.FlashingLights },
            { value: 2, type: SeizureTrigger.Weather }
          ],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-17", to: "2023-01-17" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Non convulsive" }],
          sideEffects: [
            { value: 1, type: SideEffectType.MouthIssues },
            { value: 1, type: SideEffectType.Anxiety }
          ],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-18", to: "2023-01-18" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-19", to: "2023-01-19" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Non convulsive" }],
          sideEffects: [{ value: 3, type: SideEffectType.Dizziness }],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-20", to: "2023-01-20" },
          auras: 1,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-21", to: "2023-01-21" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 1, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Non convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-22", to: "2023-01-22" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 1, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-23", to: "2023-01-23" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Non convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 1
        },
        {
          dateRange: { from: "2023-01-24", to: "2023-01-24" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-25", to: "2023-01-25" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Non convulsive" }],
          sideEffects: [
            { value: 1, type: SideEffectType.Dizziness },
            { value: 1, type: SideEffectType.Headache }
          ],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-26", to: "2023-01-26" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-27", to: "2023-01-27" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 1, emergencyDose: 1, missed: 0, notLogged: 0, singleDose: 1 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-28", to: "2023-01-28" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-29", to: "2023-01-29" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Non convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-30", to: "2023-01-30" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 1, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-31", to: "2023-01-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Non convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-01", to: "2023-02-01" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [{ value: 1, type: SideEffectType.Headache }],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-02", to: "2023-02-02" },
          auras: 1,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Non convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-03", to: "2023-02-03" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-04", to: "2023-02-04" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 1, emergencyDose: 1, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Non convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-05", to: "2023-02-05" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 1, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 3, type: "Non convulsive" }],
          sideEffects: [{ value: 1, type: SideEffectType.Headache }],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-06", to: "2023-02-06" },
          auras: 0,
          events: [{ value: 1, type: EventType.Driving }],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        }
      ],
      medicationPlans: [
        {
          from: "2023-01-01",
          to: "2023-01-21",
          devices: [],
          medications: [
            { id: "med-1", name: "Lamictal 500mg x2 Daily" },
            { id: "med-2", name: "Oxcarbazepine 250mg x1 Daily" }
          ]
        },
        {
          from: "2023-01-22",
          devices: [{ id: "device-1", model: "VNS Therapy Device, M1000" }],
          medications: [{ id: "med-1", name: "Lamictal 500mg x2 Daily" }]
        }
      ],
      summary: {
        seizures: { value: 3, change: -23 },
        missedMedication: { percentage: 15, change: 50 },
        sideEffects: { value: 1, change: 0 }
      }
    }
  }
}

export const patientReportQueryResponseWeekMock: { data: GetPatientReportQuery } = {
  data: {
    getPatientReport: {
      data: [
        {
          dateRange: { from: "2023-01-02", to: "2023-01-08" },
          auras: 1,
          events: [{ value: 1, type: EventType.Driving }],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 1, notLogged: 1, singleDose: 1 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-09", to: "2023-01-15" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 1, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Convulsive" }],
          sideEffects: [
            { value: 1, type: SideEffectType.Anxiety },
            { value: 2, type: SideEffectType.MemoryLoss },
            { value: 1, type: SideEffectType.Nausea },
            { value: 1, type: SideEffectType.Tiredness },
            { value: 1, type: SideEffectType.Depression }
          ],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-16", to: "2023-01-22" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [
            { value: 1, type: "Convulsive" },
            { value: 2, type: "Non convulsive" }
          ],
          sideEffects: [{ value: 1, type: SideEffectType.Depression }],
          steps: 8000,
          triggers: [
            { value: 1, type: SeizureTrigger.Stress },
            { value: 2, type: SeizureTrigger.Fever }
          ],
          videos: 1
        },
        {
          dateRange: { from: "2023-01-23", to: "2023-01-29" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-30", to: "2023-02-05" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-06", to: "2023-02-12" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [{ value: 1, type: SideEffectType.Dizziness }],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-13", to: "2023-02-19" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Non convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-20", to: "2023-02-26" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-27", to: "2023-03-05" },
          auras: 2,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 2, missed: 0, notLogged: 0, singleDose: 1 },
          seizures: [
            { value: 1, type: "Non convulsive" },
            { value: 1, type: "Not sure" }
          ],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-03-06", to: "2023-03-12" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Non convulsive" }],
          sideEffects: [
            { value: 1, type: SideEffectType.MouthIssues },
            { value: 1, type: SideEffectType.Anxiety }
          ],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-03-13", to: "2023-03-19" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-03-20", to: "2023-03-26" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Non convulsive" }],
          sideEffects: [{ value: 3, type: SideEffectType.Dizziness }],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-06-12", to: "2023-06-18" },
          auras: 1,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        }
      ],
      medicationPlans: [
        {
          from: "2023-01-01",
          to: "2023-01-21",
          devices: [],
          medications: [
            { id: "med-1", name: "Lamictal 500mg x2 Daily" },
            { id: "med-2", name: "Oxcarbazepine 250mg x1 Daily" }
          ]
        },
        {
          from: "2023-01-22",
          devices: [{ id: "device-1", model: "VNS Therapy Device, M1000" }],
          medications: [{ id: "med-1", name: "Lamictal 500mg x2 Daily" }]
        }
      ],
      summary: {
        seizures: { value: 3, change: -23 },
        missedMedication: { percentage: 15, change: 50 },
        sideEffects: { value: 1, change: 0 }
      }
    }
  }
}

export const patientReportQueryResponseMonthMock: { data: GetPatientReportQuery } = {
  data: {
    getPatientReport: {
      data: [
        {
          dateRange: { from: "2022-07-01", to: "2022-07-31" },
          auras: 1,
          events: [{ value: 1, type: EventType.Driving }],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 1, notLogged: 1, singleDose: 1 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2022-08-01", to: "2022-08-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 1, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Convulsive" }],
          sideEffects: [
            { value: 1, type: SideEffectType.Anxiety },
            { value: 2, type: SideEffectType.MemoryLoss },
            { value: 1, type: SideEffectType.Nausea },
            { value: 1, type: SideEffectType.Tiredness },
            { value: 1, type: SideEffectType.Depression }
          ],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2022-09-01", to: "2022-09-30" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [
            { value: 1, type: "Convulsive" },
            { value: 2, type: "Non convulsive" }
          ],
          sideEffects: [{ value: 1, type: SideEffectType.Depression }],
          steps: 8000,
          triggers: [
            { value: 1, type: SeizureTrigger.Stress },
            { value: 2, type: SeizureTrigger.Fever }
          ],
          videos: 1
        },
        {
          dateRange: { from: "2022-10-01", to: "2022-10-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2022-11-01", to: "2022-11-30" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2022-12-01", to: "2022-12-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [{ value: 1, type: SideEffectType.Dizziness }],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-01", to: "2023-01-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Non convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-02-01", to: "2023-02-28" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-03-01", to: "2023-03-31" },
          auras: 2,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 2, missed: 0, notLogged: 0, singleDose: 1 },
          seizures: [
            { value: 1, type: "Non convulsive" },
            { value: 1, type: "Not sure" }
          ],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-04-01", to: "2023-04-30" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Non convulsive" }],
          sideEffects: [
            { value: 1, type: SideEffectType.MouthIssues },
            { value: 1, type: SideEffectType.Anxiety }
          ],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-05-01", to: "2023-05-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-06-01", to: "2023-06-30" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Non convulsive" }],
          sideEffects: [{ value: 3, type: SideEffectType.Dizziness }],
          steps: 8000,
          triggers: [],
          videos: 0
        }
      ],
      medicationPlans: [
        {
          from: "2023-01-01",
          to: "2023-01-21",
          devices: [],
          medications: [
            { id: "med-1", name: "Lamictal 500mg x2 Daily" },
            { id: "med-2", name: "Oxcarbazepine 250mg x1 Daily" }
          ]
        },
        {
          from: "2023-01-22",
          devices: [{ id: "device-1", model: "VNS Therapy Device, M1000" }],
          medications: [{ id: "med-1", name: "Lamictal 500mg x2 Daily" }]
        }
      ],
      summary: {
        seizures: { value: 3, change: -23 },
        missedMedication: { percentage: 15, change: 50 },
        sideEffects: { value: 1, change: 0 }
      }
    }
  }
}

export const patientReportQueryResponseQuartersMock: { data: GetPatientReportQuery } = {
  data: {
    getPatientReport: {
      data: [
        {
          dateRange: { from: "2020-07-01", to: "2022-09-30" },
          auras: 1,
          events: [{ value: 1, type: EventType.Driving }],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 1, notLogged: 1, singleDose: 1 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2020-10-01", to: "2022-12-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 1, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Convulsive" }],
          sideEffects: [
            { value: 1, type: SideEffectType.Anxiety },
            { value: 2, type: SideEffectType.MemoryLoss },
            { value: 1, type: SideEffectType.Nausea },
            { value: 1, type: SideEffectType.Tiredness },
            { value: 1, type: SideEffectType.Depression }
          ],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2021-01-01", to: "2021-03-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [
            { value: 1, type: "Convulsive" },
            { value: 2, type: "Non convulsive" }
          ],
          sideEffects: [{ value: 1, type: SideEffectType.Depression }],
          steps: 8000,
          triggers: [
            { value: 1, type: SeizureTrigger.Stress },
            { value: 2, type: SeizureTrigger.Fever }
          ],
          videos: 1
        },
        {
          dateRange: { from: "2021-04-01", to: "2021-06-30" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2021-07-01", to: "2021-09-30" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2021-10-01", to: "2021-12-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [{ value: 1, type: SideEffectType.Dizziness }],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2022-01-01", to: "2022-03-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Non convulsive" }],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2022-04-01", to: "2022-06-30" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2022-07-01", to: "2022-09-30" },
          auras: 2,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 2, missed: 0, notLogged: 0, singleDose: 1 },
          seizures: [
            { value: 1, type: "Non convulsive" },
            { value: 1, type: "Not sure" }
          ],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2022-10-01", to: "2022-12-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 2, type: "Non convulsive" }],
          sideEffects: [
            { value: 1, type: SideEffectType.MouthIssues },
            { value: 1, type: SideEffectType.Anxiety }
          ],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-01-01", to: "2023-03-31" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [],
          sideEffects: [],
          steps: 8000,
          triggers: [],
          videos: 0
        },
        {
          dateRange: { from: "2023-04-01", to: "2023-06-30" },
          auras: 0,
          events: [],
          hearthRate: 100,
          medications: { deviceSwipe: 0, emergencyDose: 0, missed: 0, notLogged: 0, singleDose: 0 },
          seizures: [{ value: 1, type: "Non convulsive" }],
          sideEffects: [{ value: 3, type: SideEffectType.Dizziness }],
          steps: 8000,
          triggers: [],
          videos: 0
        }
      ],
      medicationPlans: [
        {
          from: "2023-01-01",
          to: "2023-01-21",
          devices: [],
          medications: [
            { id: "med-1", name: "Lamictal 500mg x2 Daily" },
            { id: "med-2", name: "Oxcarbazepine 250mg x1 Daily" }
          ]
        },
        {
          from: "2023-01-22",
          devices: [{ id: "device-1", model: "VNS Therapy Device, M1000" }],
          medications: [{ id: "med-1", name: "Lamictal 500mg x2 Daily" }]
        }
      ],
      summary: {
        seizures: { value: 3, change: -23 },
        missedMedication: { percentage: 15, change: 50 },
        sideEffects: { value: 1, change: 0 }
      }
    }
  }
}
