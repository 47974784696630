import { CognitoUser } from "amazon-cognito-identity-js"
import { Amplify } from "aws-amplify"
import { Box, Flex, Text } from "epsy-ui-react"
import { Form, Formik, FormikHelpers } from "formik"
import { useEffect, useState } from "react"
import { Theme } from "src/API"
import { AuthLayout } from "src/app/layouts/AuthLayout"
import { paths } from "src/app/routing/paths"
import { Button } from "src/components/Button"
import EntryField from "src/components/EntryField"
import Link from "src/components/Link"
import SuggestedLink from "src/components/SuggestedLink"
import { useAnalyticsPage } from "src/hooks/useAnalytics"
import useFlags from "src/hooks/useFlags"
import { useRedirectIfLoggedIn } from "src/hooks/useRedirectIfLoggedIn"
import { login, verifyCustomChallenge } from "src/redux/auth/actions"
import { useDispatch } from "src/redux/hooks"
import { LoginOpts, mfaLogin, VerifyCustomChallengeOpts } from "src/services/api/endpoints/auth"
import { isUnauthorizedError } from "src/services/api/errors"
import { translateText } from "src/services/translations/textTranslations"
import { validators } from "src/services/validate"
import { guardSessionTimeout } from "src/util/guardSessionTimeout"
import styled, { useTheme } from "styled-components"
import { object } from "yup"

const validationSchema = object().shape({ email: validators.email, password: validators.password })
const mfaValidationSchema = object().shape({ otp: validators.otp })

type LoginFormValues = LoginOpts
type MfaFormValues = Omit<VerifyCustomChallengeOpts, "user">

const initialMfaValues: MfaFormValues = { otp: "" }
const initialLoginValues: LoginFormValues = { email: "", password: "" }

const Login = () => {
  useRedirectIfLoggedIn()
  useAnalyticsPage("DP_Initiate_Sign_In")
  const { colors, mode } = useTheme()
  const dispatch = useDispatch()

  const [user, setUser] = useState<CognitoUser>()
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout>()
  const [error, setError] = useState("")

  const { hubMfa, hubMfaTimeout } = useFlags()
  useEffect(() => {
    if (!user) return
    const mfaTimeout =
      guardSessionTimeout(hubMfaTimeout) * // Minutes
      60 * // Seconds (in a minute)
      1000 // Milliseconds (in a second)
    const clearUserData = () => {
      setUser(undefined)
      setTimeoutRef(undefined)
    }
    setTimeoutRef(setTimeout(clearUserData, mfaTimeout))
  }, [user, hubMfaTimeout])

  if (hubMfa) Amplify.configure({ Auth: { authenticationFlowType: "CUSTOM_AUTH" } })

  const parseError = (error: unknown) =>
    isUnauthorizedError(error)
      ? setError(translateText("LOGIN/ERROR/UNAUTHORIZED"))
      : setError(translateText("COMMON/GENERIC_ERROR"))

  const handleLoginSubmit = async (values: LoginFormValues, helpers: FormikHelpers<LoginFormValues>) => {
    setError("")
    if (!hubMfa) {
      const { error } = await dispatch(login(values))
      return error ? parseError(error) : null
    }
    try {
      setUser(await mfaLogin(values))
      helpers.setFieldValue("password", initialLoginValues.password)
    } catch (error) {
      /**
       * TODO: Handle MFA specific errors
       * @see {@link https://linear.app/epsy/issue/FEP-568}
       * @see {@link https://epsy-workspace.slack.com/archives/C03PK89ELBF/p1696837095402319?thread_ts=1696448245.522369&cid=C03PK89ELBF}
       */
      return parseError(error)
    }
  }

  const handleMfaSubmit = async ({ otp, user }: VerifyCustomChallengeOpts) => {
    setError("")
    const { error } = await dispatch(verifyCustomChallenge({ user, otp }))
    if (error) return parseError(error)
    if (!timeoutRef) return
    clearTimeout(timeoutRef)
    setTimeoutRef(undefined)
  }

  if (user && user.challengeName === "CUSTOM_CHALLENGE") {
    return (
      <AuthLayout>
        <Box border={`1px solid ${colors.grey5}`} borderRadius={8} bg={colors.white} p="40px">
          <Flex flexDirection="column" gap={24}>
            <Text tag="h2" color={colors.primary} size={32}>
              {translateText("LOGIN/MFA/TITLE")}
            </Text>

            <Text tag="p" color={colors.grey1} size={16}>
              {translateText("LOGIN/MFA/SUBTITLE")}
            </Text>

            <Formik
              validationSchema={mfaValidationSchema}
              initialValues={initialMfaValues}
              onSubmit={({ otp }) => handleMfaSubmit({ otp, user })}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  <EntryField
                    disabled={isSubmitting}
                    hint={translateText("FIELD/GENERIC/HINT/REQUIRED")}
                    label={translateText("FIELD/OTP/LABEL")}
                    name="otp"
                    type="text"
                    value={values.otp}
                  />
                  <Box height={24} />
                  <Text tag="p" align="center" color={colors.error} size={12}>
                    {error}
                  </Text>
                  <Button disabled={isSubmitting} fullWidth size="L" type="submit" variant="PRIMARY">
                    {translateText("LOGIN/MFA/NEXT")}
                  </Button>
                </Form>
              )}
            </Formik>
          </Flex>
        </Box>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout>
      <Box border={`1px solid ${colors.grey5}`} borderRadius={8} bg={colors.white} p="40px">
        <Flex flexDirection="column" gap={24}>
          <Text tag="h2" color={colors.primary} size={32}>
            {translateText("LOGIN/TITLE")}
          </Text>

          <Formik validationSchema={validationSchema} initialValues={initialLoginValues} onSubmit={handleLoginSubmit}>
            {({ values, isSubmitting }) => (
              <Form>
                <EntryField
                  disabled={isSubmitting}
                  hint={translateText("FIELD/GENERIC/HINT/REQUIRED")}
                  label={translateText("FIELD/EMAIL/LABEL")}
                  name="email"
                  type="text"
                  value={values.email}
                />
                <EntryField
                  disabled={isSubmitting}
                  hint={translateText("FIELD/PASSWORD/HINT")}
                  label={translateText("FIELD/PASSWORD/LABEL")}
                  name="password"
                  type="password"
                  value={values.password}
                />

                <ForgotPassword
                  data-cy="reset-password"
                  to={paths.RESET_PASSWORD}
                  children={translateText("LOGIN/FORGOT_PASSWORD")}
                />

                <Box height={24} />

                <Button disabled={isSubmitting} fullWidth size="L" type="submit" variant="PRIMARY">
                  {translateText("LOGIN/SUBMIT_BUTTON")}
                </Button>
              </Form>
            )}
          </Formik>
          {mode === Theme.Epsy ? (
            <SuggestedLink
              to="https://www.epsyhealth.com/hcp/sign-up-to-epsy-hub"
              trackEvent="DP_Create_Account_Link_From_Login"
              text={translateText("LOGIN/DONT_HAVE_ACCOUNT")}
              linkText={translateText("LOGIN/SIGN_UP")}
            />
          ) : null}
        </Flex>
      </Box>
    </AuthLayout>
  )
}

export default Login

const ForgotPassword = styled(Link).attrs(({ theme: { mode, oldColors } }) => ({
  tag: "p",
  size: 14,
  color: mode === Theme.LivaNova ? oldColors.text.suggestLink : oldColors.primary.pink,
  underline: false
}))`
  display: block;
  margin: 15px;
  margin-bottom: 0;
  text-align: left;
`
