import { Theme } from "../API"
import sentry from "./sentry"
import { getTheme } from "./whiteLabel"

const envSuffix = [
  "API_URL",
  "ENV",
  "INSIGHTS_BASE_URL",
  "LAUNCHDARKLY_ID",
  "SENTRY_DSN",
  "SENTRY_TRACES_SAMPLE_RATE",
  "VERSION",
  "ZENDESK_KEY"
] as const
type EnvSuffix = (typeof envSuffix)[number]

export function craEnv(suffix: EnvSuffix): string {
  switch (getTheme()) {
    case Theme.Epsy:
      const epsyVariable = process.env[`REACT_APP_${suffix}`] || ""
      return epsyVariable
    case Theme.LivaNova:
      const livaNovaVariable = process.env[`REACT_APP_LIVANOVA_${suffix}`] || process.env[`REACT_APP_${suffix}`] || ""
      return livaNovaVariable
    default: {
      const error = new Error(`No environment variable is specified for 'REACT_APP_${suffix}'.`)
      sentry.captureException(error)
      throw error
    }
  }
}

const envs = ["play", "dev", "staging", "prod"] as const
export type Env = (typeof envs)[number]
interface Environment {
  api: string
  env: Env
  insightsBaseURL: string
  launchDarklyId: string
  sentryDSN?: string
  sentryTracesSampleRate: number
  version: string
  zendeskKey: string
  isLocalEnv: boolean
}
function guardEnv(env: string): Env {
  // workaround for storybook issue
  // @see {@link https://github.com/storybookjs/storybook/issues/17336#issuecomment-1082823103}
  if (process.env.STORYBOOK) return "play"

  const throwError = () => {
    const error = new Error(
      `As invlaid value '${env}' has been set for environment variable 'REACT_APP_ENV' (valid values are: ${envs.join(
        " |"
      )})`
    )
    sentry.captureException(error)
    throw error
  }
  return envs.includes(env as Env) ? (env as Env) : throwError()
}
const environment: Environment = {
  api: craEnv("API_URL"),
  env: guardEnv(craEnv("ENV")),
  insightsBaseURL: craEnv("INSIGHTS_BASE_URL"),
  launchDarklyId: craEnv("LAUNCHDARKLY_ID"),
  sentryDSN: craEnv("SENTRY_DSN"),
  sentryTracesSampleRate: Number(craEnv("SENTRY_TRACES_SAMPLE_RATE")) || 0,
  version: craEnv("VERSION"),
  zendeskKey: craEnv("ZENDESK_KEY"),
  isLocalEnv: process.env.NODE_ENV === "development"
}
export { environment }
