import { API } from "aws-amplify"
import {
  AddDomainToOrganizationMutation,
  AddDomainToOrganizationMutationVariables,
  AssignHcpPatientMutation,
  AssignHcpPatientMutationVariables,
  AssignHcpPatientProfileMutation,
  AssignHcpPatientProfileMutationVariables,
  AssignHcpVnsDeviceMutation,
  AssignHcpVnsDeviceMutationVariables,
  BLCFileUpload,
  BlcTablet,
  CreateInvitationMutationVariables,
  CreateSessionMutation,
  CreateSessionMutationVariables,
  CreateTabletZendeskTicketMutationVariables,
  DeleteHcpMutationVariables,
  DeleteInvitationMutationVariables,
  DeletePatientMutationVariables,
  GetHcpQuery,
  GetHcpQueryVariables,
  GetHcpUserQuery,
  GetHcpUserQueryVariables,
  GetInvitationBySenderUserIdAndTypeQuery,
  GetInvitationBySenderUserIdAndTypeQueryVariables,
  GetInvitationByTokenQuery,
  GetInvitationByTokenQueryVariables,
  GetInvitationQuery,
  GetInvitationQueryVariables,
  GetMyOrganizationQuery,
  GetPatientProfileQuery,
  GetPatientProfileQueryVariables,
  GetPatientQuery,
  GetPatientQueryVariables,
  GetQuestionnaireResponseQuery,
  GetQuestionnaireResponseQueryVariables,
  GetVnsDeviceQuery,
  GetVnsDeviceQueryVariables,
  GetVnsSessionReportQuery,
  GetVnsSessionReportQueryVariables,
  Hcp,
  HcpAcceptInvitationMutationVariables,
  HcpUser,
  Invitation,
  InvitationInput,
  ListBLCFileUploadsQuery,
  ListDomainsQuery,
  ListDomainsQueryVariables,
  ListHcpQuery,
  ListHcpQueryVariables,
  ListMediaFileQuery,
  ListMediaFileQueryVariables,
  ListPatientProfilesQuery,
  ListPatientProfilesQueryVariables,
  ListPatientQuery,
  ListPatientQueryVariables,
  ListPatientVNSReadingsHistoryQuery,
  ListPatientVNSReadingsHistoryQueryVariables,
  ListQuestionnaireResponsesQuery,
  ListQuestionnaireResponsesQueryVariables,
  ListSessionsQuery,
  ListTabletsQuery,
  ListVnsDeviceQuery,
  ListVnsTabletQuery,
  MediaFileList,
  Organization,
  OrganizationDomain,
  OrganizationInput,
  Patient,
  PatientProfile,
  QuestionnaireResponse,
  RemoveDomainFromOrganizationMutation,
  RemoveDomainFromOrganizationMutationVariables,
  ResendInvitationMutationVariables,
  Session,
  SessionDetails,
  UnassignHcpPatientMutationVariables,
  UnassignHcpPatientProfileMutationVariables,
  UnassignHcpVnsDeviceMutation,
  UnassignHcpVnsDeviceMutationVariables,
  UpdateHcpUserMutationVariables,
  UpdateOrganizationMutationVariables,
  UpdateTabletMutationVariables,
  VnsDeviceDetail,
  VnsDeviceSummary,
  VNSReading,
  VnsSessionReport,
  VnsTablet
} from "src/API"
import { customGql } from "src/graphql-custom/custom-gql"
import gql from "src/graphql/gql"
import { GetPatientReportDayQuery, PatientReportDay } from "src/modules/patientReport/patientReportDayQueryMock"
import { patientReportDayQueryResponseMock } from "src/modules/patientReport/patientReportDayQueryResponseMock"
import {
  GetPatientReportQuery,
  GetPatientReportResponse,
  PatientReportAggregation
} from "src/modules/patientReport/patientReportQueryMock"
import {
  patientReportQueryResponseMock,
  patientReportQueryResponseMonthMock,
  patientReportQueryResponseQuartersMock,
  patientReportQueryResponseWeekMock
} from "src/modules/patientReport/patientReportQueryResponseMock"
import { delay } from "src/util/delay"
import { existGuard } from "src/util/existGuard"

import api from "./api"

// ------------------------------------------------------------
// ERRORS
// ------------------------------------------------------------

export const malformedRespError = new Error("Malformed GQL response")

// ------------------------------------------------------------
// FUNCTIONS TO GET DATA = GQL REQUEST(S) & SHAPING & VALIDATING
// ------------------------------------------------------------

async function listQuestionnaireResponses(
  variables: ListQuestionnaireResponsesQueryVariables
): Promise<QuestionnaireResponse[]> {
  const _resp: any = await API.graphql({
    query: gql.queries.listQuestionnaireResponses,
    variables
  })
  const resp: ListQuestionnaireResponsesQuery = _resp?.data
  if (!resp.listQuestionnaireResponses?.items) throw malformedRespError
  return resp.listQuestionnaireResponses.items.filter(existGuard)
}

async function listPatient(variables: ListPatientQueryVariables): Promise<Patient[]> {
  const _resp: any = await API.graphql({
    query: gql.queries.listPatient,
    variables: {
      ...variables,
      /**
       * // TODO: Add pagination to GraphQL queries: listPatient
       * @see {@link https://linear.app/epsy/issue/FE-162}
       * @see {@link https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js#pagination}
       */
      limit: 100
    }
  })
  const resp: ListPatientQuery = _resp?.data
  if (!resp.listPatient?.items) throw malformedRespError
  return resp.listPatient.items.filter(existGuard)
}

async function listBLCFileUploads(): Promise<BLCFileUpload[]> {
  const _resp: any = await API.graphql({
    query: gql.queries.listBLCFileUploads,
    variables: {
      /**
       * // TODO: Add pagination to GraphQL queries: listBLCFileUploads
       * @see {@link https://linear.app/epsy/issue/FE-163}
       * @see {@link https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js#pagination}
       */
      limit: 100
    }
  })

  const resp: ListBLCFileUploadsQuery = _resp?.data

  if (!resp.listBLCFileUploads?.items) throw malformedRespError

  return resp.listBLCFileUploads.items.filter(existGuard)
}

async function listPatientVNSReadingsHistory(
  variables: ListPatientVNSReadingsHistoryQueryVariables
): Promise<VNSReading[]> {
  const _resp: any = await API.graphql({
    query: gql.queries.listPatientVNSReadingsHistory,
    variables: {
      ...variables,
      /**
       * // TODO: Add pagination to GraphQL queries: listPatientVNSReadingsHistory
       * @see {@link https://linear.app/epsy/issue/FE-164}
       * @see {@link https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js#pagination}
       */
      limit: 100
    }
  })

  const resp: ListPatientVNSReadingsHistoryQuery = _resp?.data

  if (!resp.listPatientVNSReadingsHistory?.items) throw malformedRespError

  return resp.listPatientVNSReadingsHistory.items.filter(existGuard)
}

async function listPatientProfiles(variables: ListPatientProfilesQueryVariables): Promise<PatientProfile[]> {
  const _resp: any = await API.graphql({
    query: gql.queries.listPatientProfiles,
    variables: { ...variables, limit: 500 }
  })
  const resp: ListPatientProfilesQuery = _resp?.data
  if (!resp.listPatientProfiles?.items) throw malformedRespError
  return resp.listPatientProfiles.items.filter(existGuard)
}

async function getPatientProfile(variables: GetPatientProfileQueryVariables): Promise<PatientProfile> {
  const _resp: any = await API.graphql({
    query: gql.queries.getPatientProfile,
    variables
  })
  const resp: GetPatientProfileQuery = _resp?.data
  const patientProfile = resp.getPatientProfile
  if (!patientProfile) throw malformedRespError
  return patientProfile
}

async function listVnsDevice(): Promise<VnsDeviceSummary[]> {
  const _resp: any = await API.graphql({ query: gql.queries.listVnsDevice })

  const resp: ListVnsDeviceQuery = _resp?.data
  if (!resp.listVnsDevice?.items) throw malformedRespError

  return resp.listVnsDevice.items
}

async function getVnsDevice(variables: GetVnsDeviceQueryVariables): Promise<VnsDeviceDetail> {
  const _response: any = await API.graphql({ query: gql.queries.getVnsDevice, variables })
  const response: GetVnsDeviceQuery = _response?.data

  const vnsDevice = response.getVnsDevice
  if (!vnsDevice) throw malformedRespError

  return vnsDevice
}

async function getPatient(variables: GetPatientQueryVariables): Promise<Patient> {
  const _resp: any = await API.graphql({
    query: gql.queries.getPatient,
    variables
  })
  const resp: GetPatientQuery = _resp?.data
  if (!resp.getPatient) throw malformedRespError
  return resp.getPatient
}

async function listTeamMember(variables: ListHcpQueryVariables): Promise<Hcp[]> {
  const _resp: any = await API.graphql({
    query: gql.queries.listHcp,
    variables: {
      ...variables,
      /**
       * // TODO: Add pagination to GraphQL queries: listTeamMember
       * @see {@link https://linear.app/epsy/issue/FE-165}
       * @see {@link https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js#pagination}
       */
      limit: 100
    }
  })
  const resp: ListHcpQuery = _resp?.data
  if (!resp.listHcp?.items) throw malformedRespError
  return resp.listHcp.items.filter(existGuard)
}

async function getTeamMember(variables: GetHcpQueryVariables): Promise<Hcp> {
  const _resp: any = await API.graphql({
    query: gql.queries.getHcp,
    variables
  })
  const resp: GetHcpQuery = _resp?.data
  if (!resp.getHcp) throw malformedRespError
  return resp.getHcp
}
async function getHcpUser(variables: GetHcpUserQueryVariables): Promise<HcpUser> {
  const _resp: any = await API.graphql({
    query: gql.queries.getHcpUser,
    variables
  })
  const resp: GetHcpUserQuery = _resp?.data
  if (!resp.getHcpUser) throw malformedRespError
  return resp.getHcpUser
}

async function getMyOrganization(): Promise<Organization> {
  const _resp: any = await API.graphql({
    query: gql.queries.getMyOrganization
  })
  const resp: GetMyOrganizationQuery = _resp?.data
  if (!resp.getMyOrganization) throw malformedRespError

  return resp.getMyOrganization
}

async function getInvitationByToken(variables: GetInvitationByTokenQueryVariables): Promise<Invitation> {
  const _resp: any = await API.graphql({
    query: customGql.getInvitationByToken,
    variables,
    authMode: "AWS_IAM"
  })
  const resp: GetInvitationByTokenQuery = _resp?.data
  if (!resp.getInvitationByToken) throw malformedRespError
  return resp.getInvitationByToken
}

async function getInvitation(variables: GetInvitationQueryVariables): Promise<Invitation> {
  const _resp: any = await API.graphql({
    query: gql.queries.getInvitation,
    variables
  })
  const resp: GetInvitationQuery = _resp?.data
  if (!resp.getInvitation) throw malformedRespError
  return resp.getInvitation
}

async function getInvitationBySenderUserIdAndType(
  variables: GetInvitationBySenderUserIdAndTypeQueryVariables
): Promise<Invitation> {
  const _resp: any = await API.graphql({
    query: gql.queries.getInvitationBySenderUserIdAndType,
    variables
  })
  const resp: GetInvitationBySenderUserIdAndTypeQuery = _resp?.data
  if (!resp.getInvitationBySenderUserIdAndType) throw malformedRespError
  return resp.getInvitationBySenderUserIdAndType
}

async function getQuestionnaireResponse(
  variables: GetQuestionnaireResponseQueryVariables
): Promise<QuestionnaireResponse> {
  const response: any = await API.graphql({
    query: gql.queries.getQuestionnaireResponse,
    variables
  })

  const data: GetQuestionnaireResponseQuery = response?.data
  const questionnaireResponse = data.getQuestionnaireResponse

  if (!questionnaireResponse) throw malformedRespError

  return questionnaireResponse
}

async function getMediaFiles(variables: ListMediaFileQueryVariables): Promise<MediaFileList> {
  const response: any = await API.graphql({
    query: gql.queries.listMediaFile,
    variables
  })

  const data: ListMediaFileQuery = response?.data
  const mediaFiles = data.listMediaFile

  if (!mediaFiles) throw malformedRespError

  return mediaFiles
}

async function getPatientReport(
  patientId: string,
  from: string,
  to: string,
  interval: PatientReportAggregation
): Promise<GetPatientReportResponse> {
  await delay(300)
  const response =
    interval === PatientReportAggregation.Day
      ? patientReportQueryResponseMock
      : interval === PatientReportAggregation.Week
      ? patientReportQueryResponseWeekMock
      : interval === PatientReportAggregation.Month
      ? patientReportQueryResponseMonthMock
      : patientReportQueryResponseQuartersMock

  const { getPatientReport }: GetPatientReportQuery = response.data
  if (!getPatientReport) throw malformedRespError

  return getPatientReport
}

async function getPatientReportDay(date: string, patientId: string): Promise<PatientReportDay> {
  await delay(300)
  const response = patientReportDayQueryResponseMock(date)

  const { getPatientReportDay }: GetPatientReportDayQuery = response.data
  if (!getPatientReportDay) throw malformedRespError

  return getPatientReportDay
}

async function listTablets(): Promise<BlcTablet[]> {
  const response: any = await API.graphql({
    query: gql.queries.listTablets
  })
  const { listTablets }: ListTabletsQuery = response.data
  if (!listTablets) throw malformedRespError

  return listTablets.items
}

async function listVnsTablet(): Promise<VnsTablet[]> {
  const response: any = await API.graphql({ query: gql.queries.listVnsTablet })
  const { listVnsTablet }: ListVnsTabletQuery = response.data
  if (!listVnsTablet) throw malformedRespError

  return listVnsTablet.items
}

async function listSessions(): Promise<Session[]> {
  const response: any = await API.graphql({ query: gql.queries.listSessions })
  const { listSessions }: ListSessionsQuery = response.data
  if (!listSessions) throw malformedRespError

  return listSessions as Session[]
}

async function getVnsSessionReport(variables: GetVnsSessionReportQueryVariables): Promise<VnsSessionReport> {
  const _response: any = await API.graphql({ query: gql.queries.getVnsSessionReport, variables })
  const response: GetVnsSessionReportQuery = _response?.data

  const VnsSessionReport = response.getVnsSessionReport
  if (!VnsSessionReport) throw malformedRespError

  return VnsSessionReport
}

async function listDomains(variables: ListDomainsQueryVariables): Promise<OrganizationDomain[]> {
  const response: any = await API.graphql({ query: gql.queries.listDomains, variables })
  const { listDomains }: ListDomainsQuery = response.data
  if (!listDomains) throw malformedRespError

  return listDomains.filter(existGuard)
}

/**
 * Mutations
 */
async function hcpAcceptInvitation(variables: HcpAcceptInvitationMutationVariables) {
  await API.graphql({
    query: customGql.hcpAcceptInvitation,
    variables,
    authMode: "AWS_IAM"
  })
}

async function updateOrganization(variables: UpdateOrganizationMutationVariables) {
  await API.graphql({
    query: gql.mutations.updateOrganization,
    variables
  })
}

async function updateMyOrganization(variables: { input: Omit<OrganizationInput, "organizationId"> }) {
  const user = await api.getProfile()
  const organizationId = user.organizationId
  if (!organizationId) throw new Error("No organization ID")
  await updateOrganization({ input: { ...variables.input, organizationId } })
}

async function deletePatient(variables: DeletePatientMutationVariables) {
  await API.graphql({
    query: gql.mutations.deletePatient,
    variables
  })
}

async function assignHcpPatientProfile(variables: AssignHcpPatientProfileMutationVariables) {
  const response: any = await API.graphql({
    query: gql.mutations.assignHcpPatientProfile,
    variables
  })
  const { assignHcpPatientProfile }: AssignHcpPatientProfileMutation = response?.data
  if (typeof assignHcpPatientProfile !== "boolean") throw malformedRespError

  return assignHcpPatientProfile
}

async function assignHcpVnsDevice(variables: AssignHcpVnsDeviceMutationVariables) {
  const response: any = await API.graphql({
    query: gql.mutations.assignHcpVnsDevice,
    variables
  })
  const { assignHcpVnsDevice }: AssignHcpVnsDeviceMutation = response?.data
  if (typeof assignHcpVnsDevice !== "boolean") throw malformedRespError

  return assignHcpVnsDevice
}

async function unassignHcpPatientProfile(variables: UnassignHcpPatientProfileMutationVariables) {
  await API.graphql({
    query: gql.mutations.unassignHcpPatientProfile,
    variables
  })
}

async function unassignHcpVnsDevice(variables: UnassignHcpVnsDeviceMutationVariables) {
  const response: any = await API.graphql({
    query: gql.mutations.unassignHcpVnsDevice,
    variables
  })
  const { unassignHcpVnsDevice }: UnassignHcpVnsDeviceMutation = response?.data
  if (typeof unassignHcpVnsDevice !== "boolean") throw malformedRespError

  return unassignHcpVnsDevice
}
async function assignHcpPatient(variables: AssignHcpPatientMutationVariables) {
  const response: any = await API.graphql({
    query: gql.mutations.assignHcpPatient,
    variables
  })
  const data: AssignHcpPatientMutation = response?.data
  const assignHcpPatient = data.assignHcpPatient

  if (!assignHcpPatient) throw malformedRespError

  return assignHcpPatient
}

async function unassignHcpPatient(variables: UnassignHcpPatientMutationVariables) {
  await API.graphql({
    query: gql.mutations.unassignHcpPatient,
    variables
  })
}

async function deleteHcp(variables: DeleteHcpMutationVariables) {
  await API.graphql({
    query: gql.mutations.deleteHcp,
    variables
  })
}

async function updateHcpUser(variables: UpdateHcpUserMutationVariables) {
  await API.graphql({
    query: gql.mutations.updateHcpUser,
    variables
  })
  return
}

async function createInvitation(variables: CreateInvitationMutationVariables) {
  await API.graphql({ query: gql.mutations.createInvitation, variables })
  return
}

async function createInvitations(inputs: InvitationInput[]) {
  await Promise.all(inputs.map((input) => createInvitation({ input })))
}

async function resendInvitation(variables: ResendInvitationMutationVariables) {
  await API.graphql({
    query: gql.mutations.resendInvitation,
    variables
  })
  return
}

async function deleteInvitation(variables: DeleteInvitationMutationVariables) {
  await API.graphql({ query: gql.mutations.deleteInvitation, variables })
}

async function updateTablet(variables: UpdateTabletMutationVariables) {
  await API.graphql({ query: gql.mutations.updateTablet, variables })
}

async function connectTablet(variables: CreateTabletZendeskTicketMutationVariables) {
  await API.graphql({ query: gql.mutations.createTabletZendeskTicket, variables })
}

async function createSession(variables: CreateSessionMutationVariables): Promise<SessionDetails> {
  const response: any = await API.graphql({
    query: gql.mutations.createSession,
    variables
  })

  const data: CreateSessionMutation = response.data
  if (!data.createSession) throw new Error("Malformed GQL response")

  return data.createSession
}

async function addDomainToOrganization(
  variables: AddDomainToOrganizationMutationVariables
): Promise<OrganizationDomain> {
  const response: any = await API.graphql({ query: gql.mutations.addDomainToOrganization, variables })

  const data: AddDomainToOrganizationMutation = response.data
  if (!data.addDomainToOrganization) throw new Error("Malformed GQL response")

  return data.addDomainToOrganization
}

async function removeDomainFromOrganization(
  variables: RemoveDomainFromOrganizationMutationVariables
): Promise<boolean> {
  const response: any = await API.graphql({ query: gql.mutations.removeDomainFromOrganization, variables })

  const data: RemoveDomainFromOrganizationMutation = response.data
  if (!data.removeDomainFromOrganization) throw new Error("Malformed GQL response")

  return data.removeDomainFromOrganization
}

const appsyncApi = {
  // queries
  listQuestionnaireResponses,
  listPatient,
  listBLCFileUploads,
  listPatientVNSReadingsHistory,
  listPatientProfiles,
  getPatientProfile,
  listVnsDevice,
  getVnsDevice,
  getPatient,
  listTeamMember,
  getTeamMember,
  getHcpUser,
  getMyOrganization,
  getInvitationByToken,
  getInvitation,
  getInvitationBySenderUserIdAndType,
  getQuestionnaireResponse,
  getMediaFiles,
  getPatientReport,
  getPatientReportDay,
  listTablets,
  listVnsTablet,
  listSessions,
  getVnsSessionReport,
  listDomains,

  // mutations
  hcpAcceptInvitation,
  updateOrganization,
  updateMyOrganization,
  deletePatient,
  assignHcpPatientProfile,
  assignHcpVnsDevice,
  unassignHcpPatientProfile,
  unassignHcpVnsDevice,
  assignHcpPatient,
  unassignHcpPatient,
  deleteHcp,
  updateHcpUser,
  createInvitation,
  createInvitations,
  resendInvitation,
  deleteInvitation,
  updateTablet,
  connectTablet,
  createSession,
  addDomainToOrganization,
  removeDomainFromOrganization
}

export default appsyncApi
