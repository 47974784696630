import { Params } from "react-router-dom"

export function template(templateString: string, params: { [key: string]: string | number }) {
  const replaced = templateString.replace(/{{([\s\S]+?)}}/g, (match: string, key: string) => {
    return String(params[key] === undefined ? "" : params[key])
  })
  return replaced
}

export function pathTemplate(path: string, params: Params<string>) {
  const replaced = path.replace(/:([\w]+)/g, (match: string, key: string) => {
    return params[key] || ""
  })
  return replaced
}
