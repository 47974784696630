import dayjs, { Dayjs } from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isToday from "dayjs/plugin/isToday"
import localizedFormat from "dayjs/plugin/localizedFormat"
import timezonePlugin from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { translateText } from "src/services/translations/textTranslations"

dayjs.extend(isToday)
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezonePlugin)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)

export type DateInput = null | undefined | Dayjs | string | number
const TIME_FORMAT = "hh:mm:ss A"

type TimeFormats = "TIME_ONLY"
type Formats =
  | "DATE_MONTH_YEAR"
  | "DATE_MONTH_YEAR_SHORT"
  | "DATE_MONTH_YEAR_COMPACT"
  | "DOTW_DATE_MONTH"
  | "ISO_DATE_ONLY"
  | "TIME_DATE_MONTH_YEAR"
  | "TODAY_OR_TIME_DATE_MONTH_YEAR"
  | TimeFormats

function parseStringDate(str: string) {
  const num = Number(str)
  if (isNaN(num)) {
    return dayjs.utc(str)
  } else {
    return dayjs.utc(num)
  }
}

export function parseDayjs(date: DateInput): Dayjs | undefined {
  return date instanceof dayjs
    ? (date as Dayjs)
    : typeof date === "number"
    ? dayjs(date)
    : typeof date === "string"
    ? parseStringDate(date)
    : undefined
}

export function formatDate(date: DateInput, format: Formats, opts?: { fallback?: string; local?: boolean }): string {
  const { fallback } = opts ?? {}
  let parsedDayjs = parseDayjs(date)
  const parsedDate = opts?.local ? parsedDayjs?.local() : parsedDayjs
  if (parsedDate) {
    switch (format) {
      case "DATE_MONTH_YEAR":
        return parsedDate.format("MMM DD, YYYY")
      case "DATE_MONTH_YEAR_SHORT":
        return parsedDate.format("MM/DD/YYYY")
      case "DATE_MONTH_YEAR_COMPACT":
        return parsedDate.format("M/D/YY")
      case "DOTW_DATE_MONTH":
        return parsedDate.format("ddd MMM Do")
      case "ISO_DATE_ONLY":
        return parsedDate.format("YYYY-MM-DD")
      case "TIME_DATE_MONTH_YEAR":
        return parsedDate.format("MMM DD, YYYY LT")
      case "TODAY_OR_TIME_DATE_MONTH_YEAR":
        if (parsedDate.isToday()) {
          return `${translateText("COMMON/TODAY")}, ${parsedDate.format("LT")}`
        } else {
          return parsedDate.format("MMM DD, YYYY LT")
        }
      case "TIME_ONLY":
        return parsedDate.format("LT")
    }
  } else {
    return fallback === undefined ? "-" : fallback
  }
}

type RangeFormats = "DATE_RANGE"

export function formatDateRange(date1: DateInput, date2: DateInput, format: RangeFormats): string {
  if (date1 && date2) {
    const d1 = dayjs(date1)
    const d2 = dayjs(date2)

    switch (format) {
      case "DATE_RANGE":
        const yearFormat = "YYYY"
        const monthFormat = "MMM"
        const dayFormat = "D"
        const dayAndMonthFormat = `${monthFormat} ${dayFormat}`
        const fullFormat = `${dayAndMonthFormat}, ${yearFormat}`
        if (d1.year() === d2.year()) {
          if (d1.month() === d2.month()) {
            return `${d1.format(dayAndMonthFormat)} - ${d2.format(`${dayFormat}, ${yearFormat}`)}`
          } else {
            return `${d1.format(dayAndMonthFormat)} - ${d2.format(fullFormat)}`
          }
        } else {
          return `${d1.format(fullFormat)} - ${d2.format(fullFormat)}`
        }
    }
  } else {
    return "-"
  }
}

export const formatTime = (date: DateInput, format: TimeFormats = "TIME_ONLY", opts?: { fallback?: string }) => {
  const { fallback } = opts ?? {}
  const time = dayjs.utc(date, TIME_FORMAT, true)
  if (!time.isValid()) return fallback ?? "-"
  switch (format) {
    case "TIME_ONLY":
    default:
      return time.format("LT")
  }
}
