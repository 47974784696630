import { Grid } from "@mui/material"
import { Flex, Spacer, Text } from "epsy-ui-react"
import { ReactComponent as Bullet } from "src/images/icons/bullet/bullet.svg"
import { translateText } from "src/services/translations/textTranslations"
import { removeTrailingZeros } from "src/util/removeTrailingZeros"
import { Maybe } from "src/util/utilityTypes"
import styled, { useTheme } from "styled-components"
import { ColumnLayout, columnLayoutMap, getDividerColumnWidth } from "./utils"

type ParameterTableRowProps = {
  hideValue2?: boolean
  indent?: boolean
  label: string
  initialUnit?: string
  initialValue1?: Maybe<string>
  initialValue2?: Maybe<string>
  finalUnit?: string
  finalValue1?: Maybe<string>
  finalValue2?: Maybe<string>
  layout: ColumnLayout
}
export const ParameterTableRow = ({
  hideValue2,
  label,
  initialUnit,
  initialValue1,
  initialValue2,
  finalUnit,
  finalValue1,
  finalValue2,
  indent,
  layout
}: ParameterTableRowProps) => {
  const value1Changed = initialValue1 !== finalValue1
  const value2Changed = initialValue2 !== finalValue2
  const columnLayout = columnLayoutMap[layout]

  return (
    <>
      <Grid item xs={columnLayout.label}>
        <ParameterLabel $indent={indent}>{label}</ParameterLabel>
      </Grid>
      <ParameterValueCell
        xs={columnLayout.initial}
        changed={value1Changed}
        value={removeTrailingZeros(initialValue1) || translateText("VNS/SESSION_REPORTS/TABLE/NOT_AVAILABLE")}
        unit={initialValue1 ? initialUnit : "--"}
        state="initial"
      />
      {layout === "2-1" && hideValue2 && <Grid item xs={columnLayout.initial} />}
      {layout === "2-1" && !hideValue2 && (
        <ParameterValueCell
          xs={columnLayout.initial}
          changed={value1Changed}
          value={removeTrailingZeros(initialValue2) || translateText("VNS/SESSION_REPORTS/TABLE/NOT_AVAILABLE")}
          unit={initialValue2 ? initialUnit : "--"}
          state="initial"
        />
      )}
      <Grid item xs={columnLayout.gap} />
      <ParameterValueCell
        xs={columnLayout.final}
        changed={value1Changed}
        value={removeTrailingZeros(finalValue1) || translateText("VNS/SESSION_REPORTS/TABLE/NOT_AVAILABLE")}
        unit={finalValue1 ? finalUnit : "--"}
        state="final"
      />
      {layout === "1-2" && hideValue2 && <Grid item xs={columnLayout.initial} />}
      {layout === "1-2" && !hideValue2 && (
        <ParameterValueCell
          xs={columnLayout.final}
          changed={value2Changed}
          value={removeTrailingZeros(finalValue2) || translateText("VNS/SESSION_REPORTS/TABLE/NOT_AVAILABLE")}
          unit={finalValue2 ? finalUnit : "--"}
          state="final"
        />
      )}
      <ParameterDividerRow layout={layout} hideValue2={hideValue2} />
    </>
  )
}

type ParameterValueCellProps = {
  changed?: boolean
  state: "initial" | "final"
  value?: string | number | boolean
  unit?: string
  xs: number
}

const ParameterValueCell = ({ changed, state, value, unit, xs }: ParameterValueCellProps) => {
  const { colors } = useTheme()
  return (
    <Grid item xs={xs} paddingRight={1}>
      <Flex justifyContent={state === "initial" ? "end" : "start"} alignItems="center">
        <Bullet
          visibility={state === "final" && changed ? "visible" : "hidden"}
          fill={colors.blue}
          height={8}
          width={8}
        />
        <Spacer width={8} />
        {value === undefined ? (
          <>
            <ParameterValue>{translateText("VNS/SESSION_REPORTS/TABLE/NOT_AVAILABLE")}</ParameterValue>
            <Spacer width={8} />
            <ParameterUnit>{"--"}</ParameterUnit>
          </>
        ) : typeof value === "boolean" ? (
          <ParameterValue color={state === "final" && changed ? colors.blue : undefined}>
            {!!value ? translateText("VNS/SESSION_REPORTS/TABLE/ON") : translateText("VNS/SESSION_REPORTS/TABLE/OFF")}
          </ParameterValue>
        ) : (
          <>
            <ParameterValue color={state === "final" && changed ? colors.blue : undefined}>{value}</ParameterValue>
            <Spacer width={8} />
            <ParameterUnit>{unit}</ParameterUnit>
          </>
        )}
      </Flex>
    </Grid>
  )
}

type ParameterDividerRowProps = { layout: ColumnLayout; hideValue2?: boolean }

const ParameterDividerRow = ({ layout, hideValue2 }: ParameterDividerRowProps) => {
  const columnLayout = columnLayoutMap[layout]
  return (
    <>
      <ParameterDividerCell item xs={getDividerColumnWidth(layout, "initialWithLabel", hideValue2)}>
        <ParameterDivider />
      </ParameterDividerCell>
      {layout === "2-1" && !hideValue2 && (
        <ParameterDividerCell item xs={columnLayout.initial2}>
          {!hideValue2 && <ParameterDivider />}
        </ParameterDividerCell>
      )}
      <ParameterDividerCell item xs={columnLayout.gap} />
      <ParameterDividerCell item xs={getDividerColumnWidth(layout, "final", hideValue2)}>
        <ParameterDivider />
      </ParameterDividerCell>
      {layout === "1-2" && !hideValue2 && (
        <ParameterDividerCell item xs={columnLayout.final2}>
          {!hideValue2 && <ParameterDivider />}
        </ParameterDividerCell>
      )}
    </>
  )
}

type ParameterLabelProps = {
  $indent?: boolean
}

const ParameterLabel = styled(Text).attrs({ size: 16 })<ParameterLabelProps>`
  color: ${({ theme }) => theme.colors.grey2};
  padding-left: ${({ $indent: indent }) => (indent ? "52px" : "0")};
`

const ParameterUnit = styled(Text).attrs({ size: 16 })`
  color: ${({ theme }) => theme.colors.grey2};
  width: 46px;
`

const ParameterDivider = styled(Spacer).attrs({ height: 1 })`
  background-color: ${({ theme }) => theme.colors.blue20};
`

const ParameterDividerCell = styled(Grid).attrs({ height: 1, marginTop: 1 })`
  &&& {
    padding-top: 0;
  }
`

type ParameterValueProps = {
  color?: string
}

const ParameterValue = styled(Text).attrs({ size: 16 })<ParameterValueProps>`
  color: ${({ theme, color }) => color || theme.colors.grey1};
`
