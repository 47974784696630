import { InputAdornment, TextField, TextFieldProps } from "@mui/material"
import { useState } from "react"
import { Theme } from "src/API"
import { useField } from "src/hooks/useField"
import { ReactComponent as CircleCheckIcon } from "src/images/icons/circle-check/circle-check-m.svg"
import { ReactComponent as CircleCrossIcon } from "src/images/icons/circle-cross/circle-cross-m.svg"
import { TextTranslationKey as TK, translateText } from "src/services/translations/textTranslations"
import styled, { useTheme } from "styled-components"

const formMargin = 20
export const StyledTextField = styled(TextField)<{
  theme: Theme
  host: string
  error?: boolean
  inline?: boolean
}>`
  margin-top: ${(p) => (p.inline ? 0 : formMargin)}px !important;
  width: 100%;
  & fieldset {
    border: ${(p) =>
      p.host === Theme.LivaNova || p.error
        ? `1px solid ${p.error ? `${p.theme.oldColors.traffic.alertRed}` : `${p.theme.oldColors.shade[8]}`}!important`
        : "0"};
    border-radius: 8px !important;
    height: 60px;
  }
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${(p) => `1px solid ${p.theme.oldColors.primary.purple} !important`};
  }
  & .MuiInputBase-formControl {
    background-color: ${(p) =>
      p.host === Theme.Epsy
        ? `${p.theme.oldColors.shade[5]} !important`
        : `${p.theme.oldColors.shade["white"]} !important`};
    border-radius: 8px !important;
  }
  & label {
    color: ${(p) => `${p.theme.oldColors.shade[11]} !important`};
    font-family: ${(p) => p.theme.fonts.fontFamily} !important;
    font-size: 15px !important;
    font-weight: 500 !important;
  }
  & label.MuiInputLabel-shrink {
    font-family: ${(p) => p.theme.fonts.fontFamily} !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.01em !important;
    top: 16px;
  }
  & input {
    color: ${(p) => p.host === Theme.LivaNova && `${p.theme.oldColors.shade[9]}`};
    padding-top: 17.5px;
    padding-bottom: 14.5px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: ${(p) => p.theme.fonts.fontFamily};
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
  & legend > span {
    display: none;
  }
`

const CheckIcon = styled(CircleCheckIcon)``
const CrossIcon = styled(CircleCrossIcon)``

export type EntryFieldProps = TextFieldProps & {
  hasValidationIcon?: boolean
  hint?: string
  inline?: boolean
}

const EntryField = (props: EntryFieldProps) => {
  const theme = useTheme()

  const { hint, hasValidationIcon, type } = props

  const [visible, setVisible] = useState(false)
  const toggleVisible = () => setVisible((x) => !x)

  const [field, meta] = useField<string>(props)
  const { error } = meta
  const showError = Boolean(error)

  const iconStyle = visible ? {} : { color: theme.oldColors.primary.purple }

  let icon = (
    <div onClick={toggleVisible}>
      <span style={iconStyle}>show</span>
    </div>
  )
  if (hasValidationIcon && type === "password") {
    icon = error ? <CrossIcon /> : <CheckIcon />
  }

  return (
    <StyledTextField
      {...props}
      theme={theme}
      value={field.value}
      onChange={field.onChange}
      host={theme.mode}
      error={showError}
      helperText={showError && hint ? translateText(error as TK) : null}
      type={type === "password" ? (visible ? "text" : "password") : "text"}
      InputProps={{
        endAdornment: <InputAdornment position="end">{type === "password" && icon}</InputAdornment>
      }}
    />
  )
}

export default EntryField
