import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import { VnsSessionReportStep } from "src/API"
import { formatDate } from "src/services/dates"
import { translateText } from "src/services/translations/textTranslations"
import { removeTrailingZeros } from "src/util/removeTrailingZeros"
import { Maybe } from "src/util/utilityTypes"
import { Column, Mode, ProgrammingMode, Row } from "./types"

dayjs.extend(isBetween)

export const modeMap: Record<ProgrammingMode, Mode> = {
  "Guided Scheduled": "SCHEDULED",
  "Guided Manual": "GUIDED"
}

type GetCurrentStepArgs = {
  autostimOutputCurrent: Maybe<string>
  magnetOutputCurrent: Maybe<string>
  normalOutputCurrent: Maybe<string>
  steps: VnsSessionReportStep[]
}

export const getCurrentStep = ({
  autostimOutputCurrent,
  magnetOutputCurrent,
  normalOutputCurrent,
  steps
}: GetCurrentStepArgs): Maybe<VnsSessionReportStep> =>
  steps.find(
    (step) =>
      step.guidedStepAutostimOutputCurrent === autostimOutputCurrent &&
      step.guidedStepMagnetOutputCurrent === magnetOutputCurrent &&
      step.guidedStepNormalOutputCurrent === normalOutputCurrent
  )

export const getStepStartTime = (step: Maybe<VnsSessionReportStep>, programmingMode: string) =>
  programmingMode === "Guided Scheduled" ? step?.schedProgStepDate : undefined

export const mapStepsToRow = (steps: VnsSessionReportStep[], currentStepId: Maybe<number>): Row[] =>
  steps.map((step, index) => {
    const date = step.schedProgStepDate ? formatDate(step.schedProgStepDate, "DATE_MONTH_YEAR") : "--"

    const isCurrent = currentStepId === step.stepId
    const isLast = index === steps.length - 1

    const status = isCurrent ? "CURRENT" : isLast ? "TARGET" : undefined

    return {
      step: step.stepId,
      date,
      normal: removeTrailingZeros(step.guidedStepNormalOutputCurrent),
      autoStim: removeTrailingZeros(step.guidedStepAutostimOutputCurrent),
      magnet: removeTrailingZeros(step.guidedStepMagnetOutputCurrent),
      status
    }
  })

export const columns: Column[] = [
  {
    title: translateText("VNS/SESSION_REPORTS/TITRATION_CARD/STEP"),
    field: "step"
  },
  {
    title: translateText("VNS/SESSION_REPORTS/TITRATION_CARD/DATE"),
    field: "date"
  },
  {
    title: translateText("VNS/SESSION_REPORTS/TITRATION_CARD/NORMAL"),
    unit: translateText("COMMON/UNIT/MA"),
    field: "normal"
  },
  {
    title: translateText("VNS/SESSION_REPORTS/TITRATION_CARD/AUTOSTIM"),
    unit: translateText("COMMON/UNIT/MA"),
    field: "autoStim"
  },
  {
    title: translateText("VNS/SESSION_REPORTS/TITRATION_CARD/MAGNET"),
    unit: translateText("COMMON/UNIT/MA"),
    field: "magnet"
  },
  {
    title: "",
    field: "status"
  }
]
