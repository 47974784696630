import { Box, epsyColors, livaNovaColors } from "epsy-ui-react"
import styled, { css, DefaultTheme, FlattenInterpolation, ThemedStyledProps } from "styled-components"
import { Loader } from "./Loader"

type HTMLButtonElementProps = React.ButtonHTMLAttributes<HTMLButtonElement>
type Stylesheet = FlattenInterpolation<ThemedStyledProps<ButtonCustomProps, DefaultTheme>>

type ButtonCustomProps = {
  disabled?: boolean
  fullWidth?: boolean
  loading?: boolean
  size: "S" | "M" | "L"
  variant: "PRIMARY" | "SECONDARY" | "CTA"
}

export type ButtonProps = ButtonCustomProps & { children: string }

export const Button = ({ children, loading, ...props }: HTMLButtonElementProps & ButtonProps) => (
  <StyledButton type="button" {...props} disabled={props.disabled || loading}>
    <ButtonSpan $loading={!!loading}>{children}</ButtonSpan>
    <AnimatedLoaderBox $loading={!!loading}>
      <Loader center size="18px" />
    </AnimatedLoaderBox>
  </StyledButton>
)

const StyledButton = styled.button<ButtonCustomProps>`
  position: relative;
  outline: none;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

  width: ${(p) => (p.fullWidth ? "100%" : "auto")};
  border: 1px solid;
  border-radius: 200px;

  font-family: ${(p) => p.theme.fonts.fontFamily};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.01em;
  line-height: 28px;

  transition-property: background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;

  ${(p) => sizeMap[p.size]}
  ${(p) => !p.disabled && variantMap[p.variant]}
  ${(p) => p.disabled && disabledVariantMap[p.variant]}
`

const ButtonSpan = styled.span<{ $loading: boolean }>`
  opacity: ${(p) => (p.$loading ? 0 : 1)};
`

const SSize = css`
  min-height: 32px;
  padding: 1px 16px;
`
const MSize = css`
  min-height: 40px;
  padding: 1px 24px;
`
const LSize = css`
  min-height: 56px;
  padding: 1px 32px;
`
const sizeMap: Record<ButtonCustomProps["size"], Stylesheet> = {
  S: SSize,
  M: MSize,
  L: LSize
}

const hoverSSize = css`
  padding: 0 15px;
`
const hoverMSize = css`
  padding: 0 23px;
`
const hoverLSize = css`
  padding: 0 31px;
`
const hoverSizeMap: Record<ButtonCustomProps["size"], Stylesheet> = {
  S: hoverSSize,
  M: hoverMSize,
  L: hoverLSize
}

const primaryVariant = css<ButtonCustomProps>`
  background-color: ${(p) => p.theme.colors.primary};
  border-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};

  &:hover,
  &:focus {
    background-color: ${(p) => p.theme.colors.primaryPressed};
    border-color: ${(p) => p.theme.colors.primaryPressed};
  }
`

const secondaryVariant = css<ButtonCustomProps>`
  color: ${(p) => p.theme.colors.primary};
  background-color: ${(p) => p.theme.colors.white};
  border-color: ${(p) => p.theme.colors.primary};

  &:hover,
  &:focus {
    color: ${(p) => p.theme.colors.primaryPressed};
    border-color: ${(p) => p.theme.colors.primaryPressed};
    border-width: 2px;
    ${(p) => hoverSizeMap[p.size]}// manipulate paddings to compensate the border-width change
  }
`

const CTAColorsMap: Record<DefaultTheme["mode"], string> = {
  Epsy: epsyColors.pink,
  LivaNova: livaNovaColors.violet
}
const CTAHoverColorsMap: Record<DefaultTheme["mode"], string> = {
  Epsy: epsyColors.pink120,
  LivaNova: livaNovaColors.violet120
}
const CTAVariant = css<ButtonCustomProps>`
  background-color: ${(p) => CTAColorsMap[p.theme.mode]};
  border-color: ${(p) => CTAColorsMap[p.theme.mode]};
  color: ${(p) => p.theme.colors.white};

  &:hover,
  &:focus {
    background-color: ${(p) => CTAHoverColorsMap[p.theme.mode]};
    border-color: ${(p) => CTAHoverColorsMap[p.theme.mode]};
  }
`

const variantMap: Record<ButtonCustomProps["variant"], Stylesheet> = {
  PRIMARY: primaryVariant,
  SECONDARY: secondaryVariant,
  CTA: CTAVariant
}

const primaryDisabledBackgroundMap: Record<DefaultTheme["mode"], string> = {
  Epsy: epsyColors.grey6,
  LivaNova: livaNovaColors.grey4
}
const primaryDisabledColorsMap: Record<DefaultTheme["mode"], string> = {
  Epsy: epsyColors.grey3,
  LivaNova: livaNovaColors.grey2
}
const primaryDisabledVariant = css<ButtonCustomProps>`
  background-color: ${(p) => primaryDisabledBackgroundMap[p.theme.mode]};
  border-color: ${(p) => primaryDisabledBackgroundMap[p.theme.mode]};
  color: ${(p) => primaryDisabledColorsMap[p.theme.mode]};
`

const secondaryDisabledVariant = css<ButtonCustomProps>`
  background-color: ${(p) => p.theme.colors.white};
  border-color: ${(p) => p.theme.colors.grey3};
  color: ${(p) => p.theme.colors.grey3};
`

const disabledVariantMap: Record<ButtonCustomProps["variant"], Stylesheet> = {
  PRIMARY: primaryDisabledVariant,
  SECONDARY: secondaryDisabledVariant,
  CTA: primaryDisabledVariant
}

const AnimatedLoaderBox = styled(Box)<{ $loading: boolean }>`
  transition: opacity 0.2s 0.2s;

  ${(p) =>
    p.$loading
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          transition: visibility 0s 0.2s;
          visibility: hidden;
          opacity: 0;
        `};
`
