import React from "react"
import { HcpRole } from "src/API"
import { paths } from "src/app/routing/paths"
import useFlags from "src/hooks/useFlags"
import { useProfile } from "src/redux/auth/useProfile"
import { TextTranslationKey } from "src/services/translations/textTranslations"

export interface NavConfigItem {
  path: string
  text: TextTranslationKey
  imageSrc?: any
}

export interface subConfigItem {
  path: string
  text: TextTranslationKey
}

function useNavConfig() {
  const { profile } = useProfile()
  const flags = useFlags()

  const showPatientsData = profile.role !== HcpRole.ITAdmin

  return React.useMemo(() => {
    const accountMenuConfig: NavConfigItem[] = [
      { text: "NAV/BUTTONS/ACCOUNT", path: paths.ACCOUNT },
      { text: "NAV/BUTTONS/LOGOUT", path: paths.LOGOUT }
    ]
    const topLevelLinksConfig: NavConfigItem[] = [
      ...(flags.hubPatients && showPatientsData
        ? [
            {
              text: "NAV/BUTTONS/PATIENTS",
              path: paths.PATIENT_LIST,
              imageSrc: "/images/patient.svg"
            } as const
          ]
        : []),
      ...(flags.hubQuestionnairesShowSection && showPatientsData
        ? [
            {
              text: "NAV/BUTTONS/QUESTIONNAIRES",
              path: paths.QUESTIONNAIRES,
              imageSrc: "/images/questionnaires.svg"
            } as const
          ]
        : []),
      ...(flags.hubVnsShowSection && showPatientsData
        ? [
            {
              text: "NAV/BUTTONS/VNS",
              path: paths.VNS,
              imageSrc: "/images/vns.svg"
            } as const
          ]
        : []),
      {
        text: "NAV/BUTTONS/TEAM",
        path: paths.TEAM,
        imageSrc: "/images/team.svg"
      }
    ]

    const tabsConfig: NavConfigItem[][] = [
      [
        {
          text: "NAV/BUTTONS/PATIENTS/REPORT",
          path: paths.PATIENT_REPORT
        },
        ...(flags.hubVideos
          ? [
              {
                text: "NAV/BUTTONS/PATIENTS/VIDEOS",
                path: paths.PATIENT_VIDEO_LIST
              } as const
            ]
          : [])
      ],
      [
        {
          text: "NAV/BUTTONS/VNS/PATIENTS",
          path: paths.VNS_LIST
        },
        {
          text: "NAV/BUTTONS/VNS/RESOURCES",
          path: paths.VNS_RESOURCES
        },
        ...(flags.hubUploadFiles
          ? [
              {
                text: "NAV/BUTTONS/VNS/UPLOADS",
                path: paths.VNS_UPLOAD
              } as const
            ]
          : []),
        ...(flags.hubVnsTablets
          ? [
              {
                text: "NAV/BUTTONS/VNS/TABLETS",
                path: paths.VNS_TABLETS
              } as const
            ]
          : []),
        ...(flags.hubRemoveProgrammingSessions
          ? [
              {
                text: "NAV/BUTTONS/VNS/REMOTE_PROGRAMMING",
                path: paths.VNS_REMOTE_PROGRAMMING
              } as const
            ]
          : [])
      ]
    ]
    return { accountMenuConfig, topLevelLinksConfig, tabsConfig }
  }, [flags, showPatientsData])
}

export default useNavConfig
