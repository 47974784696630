import { Container as BaseContainer } from "@mui/material"
import { Box } from "epsy-ui-react"
import { forwardRef } from "react"
import { formatDate } from "src/services/dates"
import { translateText } from "src/services/translations/textTranslations"
import { Maybe } from "src/util/utilityTypes"
import styled, { useTheme } from "styled-components"

type Props = {
  children: React.ReactNode
  reportDate: Maybe<string>
}

export const ReportContainer = forwardRef<HTMLInputElement, Props>(({ children, reportDate }, ref) => {
  const { colors } = useTheme()

  return (
    <BaseContainer>
      <Box bg={colors.grey6} ref={ref}>
        <Title>{translateText("VNS/SESSION_REPORTS/TABLE/TITLE")}</Title>
        <Date title={translateText("VNS/SESSION_REPORTS/TABLE/DATE/LABEL")}>
          {formatDate(reportDate, "TIME_DATE_MONTH_YEAR")}
        </Date>
        {children}
      </Box>
    </BaseContainer>
  )
})

const Title = styled.h4`
  padding-top: 42px;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`
const Date = styled.p`
  padding-bottom: 17px;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`
