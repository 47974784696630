import { useContext } from "react"
import { useIdleTimer } from "react-idle-timer"
import { useDispatch } from "react-redux"
import { ToastContext } from "src/components/Toast/ToastContext"
import { ReactComponent as WarningTriangleIcon } from "src/images/icons/warning-triangle/warning-triangle-m.svg"
import { isAuthenticated } from "src/services/api/endpoints/auth"
import { translateText } from "src/services/translations/textTranslations"
import { guardSessionTimeout } from "src/util/guardSessionTimeout"
import { logout } from "../redux/auth/actions"

export const useSessionTimeout = (minutes: number) => {
  const dispatch = useDispatch()
  const { createToast } = useContext(ToastContext)
  useIdleTimer({
    timeout: 1000 * 60 * guardSessionTimeout(minutes),
    onIdle: async () => {
      if (await isAuthenticated()) {
        createToast({
          title: translateText("SESSION_TIMEOUT/TOAST/TITLE"),
          body: translateText("SESSION_TIMEOUT/TOAST/BODY"),
          icon: <WarningTriangleIcon />,
          duration: null
        })
        dispatch(logout({ global: true }))
      }
    }
  })
}
