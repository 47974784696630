import "@reach/menu-button/styles.css"
import { Box, Flex, Text } from "epsy-ui-react"
import { ReactNode } from "react"
import { ReactComponent as AccountSvg } from "src/images/icons/account.svg"
import { useProfile } from "src/redux/auth/useProfile"
import { useMyOrganization } from "src/redux/hooks"
import LoadStatus from "src/util/LoadStatus"
import styled, { useTheme } from "styled-components"

const accountIconWidth = 37
const outerPadding = 12

export const ProfileBox = ({ children }: { children: ReactNode }) => {
  const { oldColors } = useTheme()
  const { profile } = useProfile()
  const { data: org, status: orgStatus } = useMyOrganization()

  if (!profile || orgStatus === LoadStatus.PENDING) {
    return null
  }

  return (
    <Box color={oldColors.primary.purple} p={outerPadding}>
      <Flex alignItems="center" gap={16}>
        <StyledAccountSvg aria-hidden width={accountIconWidth} />
        <Flex alignItems="center" display={["none", "none", "flex"]} gap={8}>
          <Box>
            <Name align="left" size={16} bold lines={1}>
              {profile.name}
            </Name>
            <Text align="left" size={14} lines={1}>
              {org?.name || "-"}
            </Text>
          </Box>
          <Box flexShrink={0}>{children}</Box>
        </Flex>
      </Flex>
    </Box>
  )
}

const Name = styled(Text)`
  margin-bottom: -2px;
`

const StyledAccountSvg = styled(AccountSvg)`
  flex-shrink: 0;
`
