import { ErrorBoundary } from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { AnalyticsContext } from "epsy-ui-react"
import { Helmet, HelmetData } from "react-helmet-async"
import { Provider } from "react-redux"
import { Router } from "src/app/routing/Router"
import store from "src/redux/store"
import { analytics } from "src/services/analytics"
import helpWidgetService from "src/services/helpWidget"
import sentry from "src/services/sentry"
import { GlobalStyleProvider } from "src/style/global"
import { useTheme } from "styled-components"
import { ToastProvider } from "./components/Toast"
import Version from "./components/Version"
import useFlags from "./hooks/useFlags"
import { useSessionTimeout } from "./hooks/useSessionTimeout"
import { LogoutEventHandler } from "./redux/auth/LogoutEventHandler"
import { useIdentify } from "./redux/auth/useIdentify"
import { environment } from "./services/environment"
import { preloadImages } from "./services/image"
import { queryClient } from "./services/query"
import { getTheme } from "./services/whiteLabel"

sentry.init()

preloadImages(
  [
    "email-sent.svg",
    "iphone-epsy.png",
    "iphone-epsy-epic.png",
    "logo.svg",
    "registered-tick.svg",
    "not-found-page.svg"
  ].map((fileName) => `${process.env.PUBLIC_URL}/images/${fileName}`)
)
const helmetData = new HelmetData({})
const App = () => {
  const theme = getTheme()
  return (
    <>
      <Helmet helmetData={helmetData}>
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/images/${theme.toLowerCase()}/manifest.json`} />
        <link rel="icon" href={`${process.env.PUBLIC_URL}/images/${theme.toLowerCase()}/favicon.ico`} />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/images/${theme.toLowerCase()}/logo192.png`} />
        <meta name="description" content={theme === "LivaNova" ? "LivaNova Portal" : "Epsy Hub"} />
        <meta name="theme-color" content={theme === "LivaNova" ? "#783393" : "#400198"} />
        <title>{theme === "LivaNova" ? "My LivaNova" : "Epsy Hub"}</title>
      </Helmet>
      <ErrorBoundary showDialog>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <AnalyticsContext.Provider value={{ track: analytics.track }}>
              <GlobalStyleProvider>
                <ToastProvider>
                  <SessionTimeout />
                  <Identify />
                  <LogoutEventHandler />
                  <Router />
                  {environment.env !== "prod" && <Version />}
                  <HelpWidget />
                </ToastProvider>
              </GlobalStyleProvider>
            </AnalyticsContext.Provider>
          </QueryClientProvider>
        </Provider>
      </ErrorBoundary>
    </>
  )
}

const SessionTimeout = () => {
  const { hubSessionTimeout } = useFlags()
  useSessionTimeout(hubSessionTimeout)
  return null
}

const Identify = () => {
  useIdentify()
  return null
}

const HelpWidget = () => {
  const theme = useTheme()
  const helpWidget = helpWidgetService.getWidget(theme.oldColors)
  return helpWidget
}

export default App
