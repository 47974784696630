import React from "react"
import { analytics } from "src/services/analytics"

export const useAnalyticsPage = (pageEvent: string, deps?: React.DependencyList, enabled = true) => {
  React.useEffect(() => {
    if (enabled) {
      analytics.page(pageEvent)
    }
    // eslint-disable-next-line
  }, [enabled, ...(deps || [])])
}

export const useAnalyticsTrack = (
  trackEvent: string,
  trackData?: { [key: string]: any },
  deps?: React.DependencyList,
  enabled = true
) => {
  React.useEffect(() => {
    if (enabled) {
      analytics.track(trackEvent, trackData)
    }
    // eslint-disable-next-line
  }, [enabled, ...(deps || [])])
}
