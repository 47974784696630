import { Grid } from "@mui/material"
import { Flex, Text } from "epsy-ui-react"
import { ReactComponent as Bullet } from "src/images/icons/bullet/bullet.svg"
import { translateText } from "src/services/translations/textTranslations"
import { Maybe } from "src/util/utilityTypes"
import { useTheme } from "styled-components"
import { columnLayoutMap } from "./utils"

type ParameterTableTimeHeaderProps = {
  layout: "1-2" | "2-1"
  initial: { time: Maybe<string>; time2: Maybe<string> }
  final: { time: Maybe<string>; time2: Maybe<string> }
  changed?: boolean
}
export const ParameterTableTimeHeader = ({ layout, initial, final, changed }: ParameterTableTimeHeaderProps) => {
  const { colors } = useTheme()
  const columnLayout = columnLayoutMap[layout]

  return (
    <Grid container mt="30px">
      {layout === "1-2" && (
        <>
          <Grid item xs={columnLayout.label + columnLayout.initial + columnLayout.gap} />
          <Grid item xs={columnLayout.final} paddingLeft={4}>
            <Text color={colors.blue}>{translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS/TIME_HEADER/DAYTIME")}</Text>
          </Grid>
          <Grid item xs={columnLayout.final2} paddingLeft={2}>
            <Text color={colors.blue}>
              {translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS/TIME_HEADER/NIGHTTIME")}
            </Text>
          </Grid>
          <Grid item xs={columnLayout.label + columnLayout.initial + columnLayout.gap} />
          <Grid item xs={columnLayout.final} paddingLeft={4}>
            <Text size={14} color={colors.grey1}>
              {`${final.time} - ${final.time2}`}
            </Text>
          </Grid>
          <Grid item xs={columnLayout.final2} paddingLeft={2}>
            <Flex alignItems="center" gap={6}>
              {changed && <Bullet fill={colors.blue} height={8} width={8} />}
              <Text size={14} color={colors.grey1}>
                {`${final.time2} - ${final.time}`}
              </Text>
            </Flex>
          </Grid>
        </>
      )}
      {layout === "2-1" && (
        <>
          <Grid item xs={columnLayout.label + columnLayout.initial} textAlign="end" paddingRight={2}>
            <Text color={colors.blue}>{translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS/TIME_HEADER/DAYTIME")}</Text>
          </Grid>
          <Grid item xs={columnLayout.initial2} textAlign="end" paddingRight={2}>
            <Text color={colors.blue}>
              {translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS/TIME_HEADER/NIGHTTIME")}
            </Text>
          </Grid>
          <Grid item xs={columnLayout.label + columnLayout.initial} textAlign="end" paddingRight={2}>
            <Text size={14} color={colors.grey1}>
              {`${initial.time} - ${initial.time2}`}
            </Text>
          </Grid>
          <Grid item xs={columnLayout.initial2} paddingRight={2}>
            <Flex alignItems="center" justifyContent={"end"} gap={6}>
              <Bullet visibility={changed ? "visible" : "hidden"} fill={colors.blue} height={8} width={8} />
              <Text size={14} color={colors.grey1}>
                {`${initial.time2} - ${initial.time}`}
              </Text>
            </Flex>
          </Grid>
          <Grid item xs={columnLayout.gap + columnLayout.final} />
        </>
      )}
    </Grid>
  )
}
