/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppUser = /* GraphQL */ `
  query GetAppUser($id: String!) {
    getAppUser(id: $id) {
      createdAt
      dob
      email
      gender
      id
      lastSentAt
      name
      phoneNumber
    }
  }
`;
export const getHcp = /* GraphQL */ `
  query GetHcp($memberId: String!) {
    getHcp(memberId: $memberId) {
      invitation {
        conversions
        createdAt
        expiresAt
        invitationId
        organization {
          addressLine1
          addressLine2
          city
          country
          department
          email
          logo
          name
          organizationId
          phoneNumbers
          state
          zipCode
        }
        organizationId
        recipientEmail
        recipientName
        recipientPhoneCountryCode
        recipientPhoneNumber
        redemptionLimit
        role
        senderUserId
        sentAt
        token
        type
        updatedAt
      }
      invitationId
      memberId
      organizationId
      user {
        email
        id
        name
        npiNumber
        phoneNumber
        role
      }
      userId
    }
  }
`;
export const getHcpUser = /* GraphQL */ `
  query GetHcpUser($id: String!) {
    getHcpUser(id: $id) {
      email
      id
      name
      npiNumber
      phoneNumber
      role
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($invitationId: String!) {
    getInvitation(invitationId: $invitationId) {
      conversions
      createdAt
      expiresAt
      invitationId
      organization {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        phoneNumbers
        state
        zipCode
      }
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const getInvitationBySenderUserIdAndType = /* GraphQL */ `
  query GetInvitationBySenderUserIdAndType(
    $senderUserId: String!
    $type: InvitationType!
  ) {
    getInvitationBySenderUserIdAndType(
      senderUserId: $senderUserId
      type: $type
    ) {
      conversions
      createdAt
      expiresAt
      invitationId
      organization {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        phoneNumbers
        state
        zipCode
      }
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const getInvitationByToken = /* GraphQL */ `
  query GetInvitationByToken($token: String!) {
    getInvitationByToken(token: $token) {
      conversions
      createdAt
      expiresAt
      invitationId
      organization {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        phoneNumbers
        state
        zipCode
      }
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const getInvitationByorganizationIdAndType = /* GraphQL */ `
  query GetInvitationByorganizationIdAndType(
    $organizationId: String!
    $type: InvitationType!
  ) {
    getInvitationByorganizationIdAndType(
      organizationId: $organizationId
      type: $type
    ) {
      conversions
      createdAt
      expiresAt
      invitationId
      organization {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        phoneNumbers
        state
        zipCode
      }
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const getMyOrganization = /* GraphQL */ `
  query GetMyOrganization {
    getMyOrganization {
      addressLine1
      addressLine2
      city
      country
      department
      email
      logo
      name
      organizationId
      phoneNumbers
      state
      zipCode
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($organizationId: String!) {
    getOrganization(organizationId: $organizationId) {
      addressLine1
      addressLine2
      city
      country
      department
      email
      logo
      name
      organizationId
      phoneNumbers
      state
      zipCode
    }
  }
`;
export const getOrganizationStats = /* GraphQL */ `
  query GetOrganizationStats {
    getOrganizationStats {
      lastKnownReading
      lastUploadedDate
      newImplants
      patientsEOSNo
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($memberId: String!) {
    getPatient(memberId: $memberId) {
      assignedHcps {
        invitation {
          conversions
          createdAt
          expiresAt
          invitationId
          organization {
            addressLine1
            addressLine2
            city
            country
            department
            email
            logo
            name
            organizationId
            phoneNumbers
            state
            zipCode
          }
          organizationId
          recipientEmail
          recipientName
          recipientPhoneCountryCode
          recipientPhoneNumber
          redemptionLimit
          role
          senderUserId
          sentAt
          token
          type
          updatedAt
        }
        invitationId
        memberId
        organizationId
        user {
          email
          id
          name
          npiNumber
          phoneNumber
          role
        }
        userId
      }
      invitation {
        conversions
        createdAt
        expiresAt
        invitationId
        organization {
          addressLine1
          addressLine2
          city
          country
          department
          email
          logo
          name
          organizationId
          phoneNumbers
          state
          zipCode
        }
        organizationId
        recipientEmail
        recipientName
        recipientPhoneCountryCode
        recipientPhoneNumber
        redemptionLimit
        role
        senderUserId
        sentAt
        token
        type
        updatedAt
      }
      invitationId
      lastSeizureDate
      medications {
        archivedAt
        compound
        createdAt
        dosage
        frequency
        id
        loggedAt
        parentId
        remindersActive
        scheduledAt {
          key
          time
        }
        updatedAt
        userId
      }
      memberId
      organizationId
      user {
        createdAt
        dob
        email
        gender
        id
        lastSentAt
        name
        phoneNumber
      }
      userId
    }
  }
`;
export const getPatientProfile = /* GraphQL */ `
  query GetPatientProfile($id: String!) {
    getPatientProfile(id: $id) {
      IPGs {
        implantingDate
        implantingHospitalSortName
        model
        replacementReason
        returnDate
        serial
        softwareVersion
      }
      assignedHcps {
        invitation {
          conversions
          createdAt
          expiresAt
          invitationId
          organization {
            addressLine1
            addressLine2
            city
            country
            department
            email
            logo
            name
            organizationId
            phoneNumbers
            state
            zipCode
          }
          organizationId
          recipientEmail
          recipientName
          recipientPhoneCountryCode
          recipientPhoneNumber
          redemptionLimit
          role
          senderUserId
          sentAt
          token
          type
          updatedAt
        }
        invitationId
        memberId
        organizationId
        user {
          email
          id
          name
          npiNumber
          phoneNumber
          role
        }
        userId
      }
      birthDate
      currentIPG {
        implantingDate
        implantingHospitalSortName
        model
        replacementReason
        returnDate
        serial
        softwareVersion
      }
      deathDate
      disDate
      eosCalculation {
        calculationDate
        expectedLifeRemaining
        maximumLifeRemaining
        minimalLifeRemaining
      }
      expDate
      gender
      id
      indication
      lastReading {
        autoStimulationBlackoutTime
        autoStimulationOn
        autoStimulationThreshold
        autoStimulationTimePercent
        beatsPerMin
        chargePerDay
        dutyCycle
        fetDate
        heartbeatDetection
        id
        importDate
        interrogationDate
        ipg {
          implantingDate
          implantingHospitalSortName
          model
          replacementReason
          returnDate
          serial
          softwareVersion
        }
        isEOS
        isNEOS
        leadImpedanceStatus
        leadImpedanceValue
        magnetStimulationTime
        msid
        nightMode
        nightSettings {
          autoStimulationOnTime
          autoStimulationOutputCurrent
          autoStimulationPulseWidth
          endTime
          magnetOutputCurrent
          magnetPulseWidth
          magnetSignalOnTime
          normalFrequency
          normalOutputCurrent
          normalPulseWidth
          normalSignalOffTime
          normalSignalOnTime
          startTime
        }
        normalSettings {
          autoStimulationOnTime
          autoStimulationOutputCurrent
          autoStimulationPulseWidth
          endTime
          magnetOutputCurrent
          magnetPulseWidth
          magnetSignalOnTime
          normalFrequency
          normalOutputCurrent
          normalPulseWidth
          normalSignalOffTime
          normalSignalOnTime
          startTime
        }
        normalStimulationTime
        offTime
        onTime
        organizationId
        programmingMode
        readingMode
        scheduledPrograming
        seizureDetection
        sessionId
        tablet {
          serial
          softwareVersion
        }
        therapyProtocolType
        time
        totalOnTimeHours
        totalOnTimeMinutes
        uniqueOfficeVisit
        wand {
          serial
          softwareVersion
        }
      }
      managingPhysician {
        NPI
        name
      }
      msid
      name
      part
      patientId
      referringPhysician {
        NPI
        name
      }
      surgeon {
        NPI
        name
      }
    }
  }
`;
export const getQuestionnaireResponse = /* GraphQL */ `
  query GetQuestionnaireResponse($responseId: String!) {
    getQuestionnaireResponse(responseId: $responseId) {
      answers {
        answer
        question {
          options {
            id
            label
          }
          questionType
          title
        }
      }
      completedAt
      createdAt
      id
      updatedAt
      user {
        createdAt
        dob
        email
        gender
        id
        lastSentAt
        name
        phoneNumber
      }
      userId
    }
  }
`;
export const getSeizureTypeAlias = /* GraphQL */ `
  query GetSeizureTypeAlias($id: String!, $patient: PatientFilterInput) {
    getSeizureTypeAlias(id: $id, patient: $patient) {
      archivedAt
      aware
      id
      name
      parentId
      seizureType
      userId
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: String!) {
    getSession(id: $id) {
      attendee {
        capabilities {
          audio
          content
          video
        }
        externalUserId
        id
        joinToken
      }
      id
      meeting {
        externalMeetingId
        id
        mediaPlacement {
          audioFallbackUrl
          audioHostUrl
          eventIngestionUrl
          screenDataUrl
          screenSharingUrl
          screenViewingUrl
          signalingUrl
          turnControlUrl
        }
        mediaRegion
        meetingArn
        meetingHostId
        primaryMeetingId
        tenantIds
      }
      programmer {
        auth
        endpoint
      }
    }
  }
`;
export const getSingleDoseMedication = /* GraphQL */ `
  query GetSingleDoseMedication($id: String!, $patient: PatientFilterInput) {
    getSingleDoseMedication(id: $id, patient: $patient) {
      archivedAt
      compound
      createdAt
      dosage
      emergency
      id
      loggedAt
      parentId
      updatedAt
      userId
    }
  }
`;
export const getVnsDevice = /* GraphQL */ `
  query GetVnsDevice($patientProfileId: String!) {
    getVnsDevice(patientProfileId: $patientProfileId) {
      assignedHcps {
        invitation {
          conversions
          createdAt
          expiresAt
          invitationId
          organization {
            addressLine1
            addressLine2
            city
            country
            department
            email
            logo
            name
            organizationId
            phoneNumbers
            state
            zipCode
          }
          organizationId
          recipientEmail
          recipientName
          recipientPhoneCountryCode
          recipientPhoneNumber
          redemptionLimit
          role
          senderUserId
          sentAt
          token
          type
          updatedAt
        }
        invitationId
        memberId
        organizationId
        user {
          email
          id
          name
          npiNumber
          phoneNumber
          role
        }
        userId
      }
      autostimOnTime
      autostimOutputCurrent
      autostimPulseWidth
      autostimThreshold
      blc
      generatorModel
      generatorSerialNumber
      implantedDate
      lastKnownReading
      lowHeartRateThreshold
      magnetOnTime
      magnetOutputCurrent
      magnetPulseWidth
      normalDutyCycle
      normalFrequency
      normalOffTime
      normalOnTime
      normalOutputCurrent
      normalPulseWidth
      patientDob
      patientName
      patientProfileId
      pronePositionDetection
      readings {
        leadImpedanceValue
        mode
        normalFrequency
        normalOffTime
        normalOnTime
        normalOutputCurrent
        normalPulseWidth
        readingDate
        tabletSerialNumber
        tabletSoftwareModel
      }
      tachycardiaDetection
    }
  }
`;
export const getVnsSessionReport = /* GraphQL */ `
  query GetVnsSessionReport(
    $patientProfileId: String!
    $readingDateTime: AWSDateTime!
    $tabletSerialNumber: String!
  ) {
    getVnsSessionReport(
      patientProfileId: $patientProfileId
      readingDateTime: $readingDateTime
      tabletSerialNumber: $tabletSerialNumber
    ) {
      appModelAndVer
      final {
        autostimOnTime
        autostimOutputCurrent
        autostimPulseWidth
        autostimThreshold
        batteryHealth
        batteryPercentage
        daynightProgrammingModeStatus
        heartbeatDetection
        leadImpedanceStatus
        leadImpedanceValue
        lowHeartRate
        lowHeartRateThreshold
        magnetOnTime
        magnetOutputCurrent
        magnetPulseWidth
        nightModeAutoStimOnTime
        nightModeAutoStimOutputCurrent
        nightModeAutoStimPulseWidth
        nightModeEndTime
        nightModeMagnetOutputCurrent
        nightModeMagnetPulseWidth
        nightModeMagnetSignalOnTime
        nightModeNormalDutyCycle
        nightModeNormalFrequency
        nightModeNormalOutputCurrent
        nightModeNormalPulseWidth
        nightModeNormalSignalOffTime
        nightModeNormalSignalOnTime
        nightModeStartTime
        nightModeThresholdForAutoStim
        normalDutyCycle
        normalFrequency
        normalOffTime
        normalOnTime
        normalOutputCurrent
        normalOutputCurrentStatus
        normalPulseWidth
        parametersAutoCorrected
        programmingMode
        pronePositionDetection
        scheduledProgramGuidedPlans {
          guidedStepAutostimOutputCurrent
          guidedStepMagnetOutputCurrent
          guidedStepNormalOutputCurrent
          schedProgStepDate
          stepId
        }
        tachycardiaDetection
        therapyRState
      }
      generatorModel
      generatorSerialNumber
      implantedDate
      initial {
        autostimOnTime
        autostimOutputCurrent
        autostimPulseWidth
        autostimThreshold
        batteryHealth
        batteryPercentage
        daynightProgrammingModeStatus
        heartbeatDetection
        leadImpedanceStatus
        leadImpedanceValue
        lowHeartRate
        lowHeartRateThreshold
        magnetOnTime
        magnetOutputCurrent
        magnetPulseWidth
        nightModeAutoStimOnTime
        nightModeAutoStimOutputCurrent
        nightModeAutoStimPulseWidth
        nightModeEndTime
        nightModeMagnetOutputCurrent
        nightModeMagnetPulseWidth
        nightModeMagnetSignalOnTime
        nightModeNormalDutyCycle
        nightModeNormalFrequency
        nightModeNormalOutputCurrent
        nightModeNormalPulseWidth
        nightModeNormalSignalOffTime
        nightModeNormalSignalOnTime
        nightModeStartTime
        nightModeThresholdForAutoStim
        normalDutyCycle
        normalFrequency
        normalOffTime
        normalOnTime
        normalOutputCurrent
        normalOutputCurrentStatus
        normalPulseWidth
        parametersAutoCorrected
        programmingMode
        pronePositionDetection
        scheduledProgramGuidedPlans {
          guidedStepAutostimOutputCurrent
          guidedStepMagnetOutputCurrent
          guidedStepNormalOutputCurrent
          schedProgStepDate
          stepId
        }
        tachycardiaDetection
        therapyRState
      }
      patientId
      readingDateTime
      sessionEndDateTime
      sessionStartDateTime
      tabletSerialNumber
      tabletSoftwareModel
    }
  }
`;
export const listAuraEvent = /* GraphQL */ `
  query ListAuraEvent($filter: EventFilterInput) {
    listAuraEvent(filter: $filter) {
      items {
        createdAt
        eventAt
        id
        loggedAt
        rescueMedsDosage
        rescueMedsName
        rescueMedsUsed
        source
        updatedAt
        userId
        userNotes
      }
      nextToken
    }
  }
`;
export const listBLCFileUploads = /* GraphQL */ `
  query ListBLCFileUploads($limit: Int, $nextToken: String) {
    listBLCFileUploads(limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        errorMessage
        files {
          key
          source
          status
        }
        id
        organizationId
        overallStatus
        packageName
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const listDomains = /* GraphQL */ `
  query ListDomains($organizationId: String!) {
    listDomains(organizationId: $organizationId) {
      domain
      id
      organizationId
      signatoryUserId
    }
  }
`;
export const listHcp = /* GraphQL */ `
  query ListHcp($limit: Int, $nextToken: String, $organizationId: String!) {
    listHcp(
      limit: $limit
      nextToken: $nextToken
      organizationId: $organizationId
    ) {
      items {
        invitation {
          conversions
          createdAt
          expiresAt
          invitationId
          organization {
            addressLine1
            addressLine2
            city
            country
            department
            email
            logo
            name
            organizationId
            phoneNumbers
            state
            zipCode
          }
          organizationId
          recipientEmail
          recipientName
          recipientPhoneCountryCode
          recipientPhoneNumber
          redemptionLimit
          role
          senderUserId
          sentAt
          token
          type
          updatedAt
        }
        invitationId
        memberId
        organizationId
        user {
          email
          id
          name
          npiNumber
          phoneNumber
          role
        }
        userId
      }
      nextToken
    }
  }
`;
export const listMediaFile = /* GraphQL */ `
  query ListMediaFile(
    $filter: MediaFileFilterInput!
    $limit: Int
    $nextToken: String
  ) {
    listMediaFile(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        androidUrl
        createdAt
        fileStatus
        iOSUrl
        id
        metaData
        mimeType
        ownerModelId
        ownerModelName
        sourceS3Key
        thumbnailUrl
        updatedAt
        userId
        webUrl
      }
      nextToken
    }
  }
`;
export const listMedicalDevice = /* GraphQL */ `
  query ListMedicalDevice($patient: PatientFilterInput) {
    listMedicalDevice(patient: $patient) {
      items {
        archivedAt
        cardPictureBack
        cardPictureFront
        deviceModel
        healthcareContactName
        healthcareContactPhoneNumber
        id
        implantedAt
        lead
        loggedAt
        modelName
        originalMedicalDeviceId
        otherTypeName
        reImplanted
        reImplantedAt
        serialNumber
        type
        userId
      }
      nextToken
    }
  }
`;
export const listMedication = /* GraphQL */ `
  query ListMedication($patient: PatientFilterInput) {
    listMedication(patient: $patient) {
      items {
        archivedAt
        compound
        createdAt
        dosage
        frequency
        id
        loggedAt
        parentId
        remindersActive
        scheduledAt {
          key
          time
        }
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const listMedicationEvent = /* GraphQL */ `
  query ListMedicationEvent($filter: EventFilterInput) {
    listMedicationEvent(filter: $filter) {
      items {
        createdAt
        eventAt
        id
        loggedAt
        medication {
          archivedAt
          compound
          createdAt
          dosage
          frequency
          id
          loggedAt
          parentId
          remindersActive
          scheduledAt {
            key
            time
          }
          updatedAt
          userId
        }
        medicationId
        scheduledAt
        scheduledTimeId
        source
        updatedAt
        userId
        userResponse
      }
      nextToken
    }
  }
`;
export const listMoodEvent = /* GraphQL */ `
  query ListMoodEvent($filter: EventFilterInput) {
    listMoodEvent(filter: $filter) {
      items {
        createdAt
        eventAt
        id
        loggedAt
        mood
        source
        type
        updatedAt
        userId
        userNotes
      }
      nextToken
    }
  }
`;
export const listPatient = /* GraphQL */ `
  query ListPatient($limit: Int, $nextToken: String, $organizationId: String!) {
    listPatient(
      limit: $limit
      nextToken: $nextToken
      organizationId: $organizationId
    ) {
      items {
        assignedHcps {
          invitation {
            conversions
            createdAt
            expiresAt
            invitationId
            organization {
              addressLine1
              addressLine2
              city
              country
              department
              email
              logo
              name
              organizationId
              phoneNumbers
              state
              zipCode
            }
            organizationId
            recipientEmail
            recipientName
            recipientPhoneCountryCode
            recipientPhoneNumber
            redemptionLimit
            role
            senderUserId
            sentAt
            token
            type
            updatedAt
          }
          invitationId
          memberId
          organizationId
          user {
            email
            id
            name
            npiNumber
            phoneNumber
            role
          }
          userId
        }
        invitation {
          conversions
          createdAt
          expiresAt
          invitationId
          organization {
            addressLine1
            addressLine2
            city
            country
            department
            email
            logo
            name
            organizationId
            phoneNumbers
            state
            zipCode
          }
          organizationId
          recipientEmail
          recipientName
          recipientPhoneCountryCode
          recipientPhoneNumber
          redemptionLimit
          role
          senderUserId
          sentAt
          token
          type
          updatedAt
        }
        invitationId
        lastSeizureDate
        medications {
          archivedAt
          compound
          createdAt
          dosage
          frequency
          id
          loggedAt
          parentId
          remindersActive
          scheduledAt {
            key
            time
          }
          updatedAt
          userId
        }
        memberId
        organizationId
        user {
          createdAt
          dob
          email
          gender
          id
          lastSentAt
          name
          phoneNumber
        }
        userId
      }
      nextToken
    }
  }
`;
export const listPatientProfiles = /* GraphQL */ `
  query ListPatientProfiles($limit: Int, $nextToken: String) {
    listPatientProfiles(limit: $limit, nextToken: $nextToken) {
      items {
        IPGs {
          implantingDate
          implantingHospitalSortName
          model
          replacementReason
          returnDate
          serial
          softwareVersion
        }
        assignedHcps {
          invitation {
            conversions
            createdAt
            expiresAt
            invitationId
            organization {
              addressLine1
              addressLine2
              city
              country
              department
              email
              logo
              name
              organizationId
              phoneNumbers
              state
              zipCode
            }
            organizationId
            recipientEmail
            recipientName
            recipientPhoneCountryCode
            recipientPhoneNumber
            redemptionLimit
            role
            senderUserId
            sentAt
            token
            type
            updatedAt
          }
          invitationId
          memberId
          organizationId
          user {
            email
            id
            name
            npiNumber
            phoneNumber
            role
          }
          userId
        }
        birthDate
        currentIPG {
          implantingDate
          implantingHospitalSortName
          model
          replacementReason
          returnDate
          serial
          softwareVersion
        }
        deathDate
        disDate
        eosCalculation {
          calculationDate
          expectedLifeRemaining
          maximumLifeRemaining
          minimalLifeRemaining
        }
        expDate
        gender
        id
        indication
        lastReading {
          autoStimulationBlackoutTime
          autoStimulationOn
          autoStimulationThreshold
          autoStimulationTimePercent
          beatsPerMin
          chargePerDay
          dutyCycle
          fetDate
          heartbeatDetection
          id
          importDate
          interrogationDate
          ipg {
            implantingDate
            implantingHospitalSortName
            model
            replacementReason
            returnDate
            serial
            softwareVersion
          }
          isEOS
          isNEOS
          leadImpedanceStatus
          leadImpedanceValue
          magnetStimulationTime
          msid
          nightMode
          nightSettings {
            autoStimulationOnTime
            autoStimulationOutputCurrent
            autoStimulationPulseWidth
            endTime
            magnetOutputCurrent
            magnetPulseWidth
            magnetSignalOnTime
            normalFrequency
            normalOutputCurrent
            normalPulseWidth
            normalSignalOffTime
            normalSignalOnTime
            startTime
          }
          normalSettings {
            autoStimulationOnTime
            autoStimulationOutputCurrent
            autoStimulationPulseWidth
            endTime
            magnetOutputCurrent
            magnetPulseWidth
            magnetSignalOnTime
            normalFrequency
            normalOutputCurrent
            normalPulseWidth
            normalSignalOffTime
            normalSignalOnTime
            startTime
          }
          normalStimulationTime
          offTime
          onTime
          organizationId
          programmingMode
          readingMode
          scheduledPrograming
          seizureDetection
          sessionId
          tablet {
            serial
            softwareVersion
          }
          therapyProtocolType
          time
          totalOnTimeHours
          totalOnTimeMinutes
          uniqueOfficeVisit
          wand {
            serial
            softwareVersion
          }
        }
        managingPhysician {
          NPI
          name
        }
        msid
        name
        part
        patientId
        referringPhysician {
          NPI
          name
        }
        surgeon {
          NPI
          name
        }
      }
      nextToken
    }
  }
`;
export const listPatientVNSReadingsHistory = /* GraphQL */ `
  query ListPatientVNSReadingsHistory(
    $limit: Int
    $nextToken: String
    $patientProfileId: String!
  ) {
    listPatientVNSReadingsHistory(
      limit: $limit
      nextToken: $nextToken
      patientProfileId: $patientProfileId
    ) {
      items {
        autoStimulationBlackoutTime
        autoStimulationOn
        autoStimulationThreshold
        autoStimulationTimePercent
        beatsPerMin
        chargePerDay
        dutyCycle
        fetDate
        heartbeatDetection
        id
        importDate
        interrogationDate
        ipg {
          implantingDate
          implantingHospitalSortName
          model
          replacementReason
          returnDate
          serial
          softwareVersion
        }
        isEOS
        isNEOS
        leadImpedanceStatus
        leadImpedanceValue
        magnetStimulationTime
        msid
        nightMode
        nightSettings {
          autoStimulationOnTime
          autoStimulationOutputCurrent
          autoStimulationPulseWidth
          endTime
          magnetOutputCurrent
          magnetPulseWidth
          magnetSignalOnTime
          normalFrequency
          normalOutputCurrent
          normalPulseWidth
          normalSignalOffTime
          normalSignalOnTime
          startTime
        }
        normalSettings {
          autoStimulationOnTime
          autoStimulationOutputCurrent
          autoStimulationPulseWidth
          endTime
          magnetOutputCurrent
          magnetPulseWidth
          magnetSignalOnTime
          normalFrequency
          normalOutputCurrent
          normalPulseWidth
          normalSignalOffTime
          normalSignalOnTime
          startTime
        }
        normalStimulationTime
        offTime
        onTime
        organizationId
        programmingMode
        readingMode
        scheduledPrograming
        seizureDetection
        sessionId
        tablet {
          serial
          softwareVersion
        }
        therapyProtocolType
        time
        totalOnTimeHours
        totalOnTimeMinutes
        uniqueOfficeVisit
        wand {
          serial
          softwareVersion
        }
      }
      nextToken
    }
  }
`;
export const listQuestionnaireResponses = /* GraphQL */ `
  query ListQuestionnaireResponses(
    $limit: Int
    $nextToken: String
    $userId: String
  ) {
    listQuestionnaireResponses(
      limit: $limit
      nextToken: $nextToken
      userId: $userId
    ) {
      items {
        answers {
          answer
          question {
            options {
              id
              label
            }
            questionType
            title
          }
        }
        completedAt
        createdAt
        id
        updatedAt
        user {
          createdAt
          dob
          email
          gender
          id
          lastSentAt
          name
          phoneNumber
        }
        userId
      }
      nextToken
    }
  }
`;
export const listSeizureEvent = /* GraphQL */ `
  query ListSeizureEvent($filter: EventFilterInput) {
    listSeizureEvent(filter: $filter) {
      items {
        aware
        convulsive
        createdAt
        duration
        eventAt
        eventLength
        id
        intensity
        intensityNotes
        loggedAt
        recoveryTime
        rescueMedsDosage
        rescueMedsName
        rescueMedsUsed
        seizureType
        seizureTypeAlias {
          archivedAt
          aware
          id
          name
          parentId
          seizureType
          userId
        }
        seizureTypeAliasId
        source
        timePickerUsed
        triggers
        updatedAt
        userId
        userNotes
      }
      nextToken
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions {
    listSessions {
      hcpConnected
      hcpName
      id
      patientConnected
      patientName
    }
  }
`;
export const listSideEffectEvent = /* GraphQL */ `
  query ListSideEffectEvent($filter: EventFilterInput) {
    listSideEffectEvent(filter: $filter) {
      items {
        createdAt
        eventAt
        id
        loggedAt
        sideEffects
        source
        updatedAt
        userId
        userNotes
      }
      nextToken
    }
  }
`;
export const listSingleDoseImplantEvent = /* GraphQL */ `
  query ListSingleDoseImplantEvent($filter: EventFilterInput) {
    listSingleDoseImplantEvent(filter: $filter) {
      items {
        createdAt
        eventAt
        id
        loggedAt
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const listSingleDoseMedicationEvent = /* GraphQL */ `
  query ListSingleDoseMedicationEvent($filter: EventFilterInput) {
    listSingleDoseMedicationEvent(filter: $filter) {
      items {
        compound
        createdAt
        dosage
        emergency
        eventAt
        id
        loggedAt
        medicationId
        updatedAt
        userId
        userNotes
      }
      nextToken
    }
  }
`;
export const listTablets = /* GraphQL */ `
  query ListTablets {
    listTablets {
      items {
        createdAt
        id
        lastInterrogationDate
        nickname
        organizationId
        serialNumber
        totalPatients
        totalReadings
        updatedAt
      }
      nextToken
    }
  }
`;
export const listVnsDevice = /* GraphQL */ `
  query ListVnsDevice {
    listVnsDevice {
      items {
        assignedHcps {
          invitation {
            conversions
            createdAt
            expiresAt
            invitationId
            organization {
              addressLine1
              addressLine2
              city
              country
              department
              email
              logo
              name
              organizationId
              phoneNumbers
              state
              zipCode
            }
            organizationId
            recipientEmail
            recipientName
            recipientPhoneCountryCode
            recipientPhoneNumber
            redemptionLimit
            role
            senderUserId
            sentAt
            token
            type
            updatedAt
          }
          invitationId
          memberId
          organizationId
          user {
            email
            id
            name
            npiNumber
            phoneNumber
            role
          }
          userId
        }
        blc
        generatorModel
        generatorSerialNumber
        implantedDate
        lastKnownReading
        normalOutputCurrent
        normalPulseWidth
        patientDob
        patientName
        patientProfileId
      }
    }
  }
`;
export const listVnsTablet = /* GraphQL */ `
  query ListVnsTablet {
    listVnsTablet {
      items {
        id
        lastReading
        nickname
        patients
        readings
        serialNumber
      }
    }
  }
`;
