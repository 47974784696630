import { Box, Flex, livaNovaColors, Text } from "epsy-ui-react"
import { formatDate } from "src/services/dates"
import { translateText } from "src/services/translations/textTranslations"
import { Maybe } from "src/util/utilityTypes"
import styled, { useTheme } from "styled-components"
import { Column, Mode, Row } from "./types"

type Props = {
  columns: Column[]
  mode: Mode
  rows: Row[]
  stepStartTime: Maybe<string>
}

export const TitrationPlanCard = ({ columns, mode, rows, stepStartTime }: Props) => {
  const { colors } = useTheme()

  return (
    <Box borderTop="8px solid" borderColor={borderColorMap[mode]} borderRadius={6} bg={colors.white} p={32} mx={32}>
      <TitrationPlanHeader mode={mode} stepStartTime={stepStartTime} />
      <Box height={24} />
      <Table>
        <TitrationPlanTableHead columns={columns} />
        <TitrationPlanTableBody columns={columns} mode={mode} rows={rows} />
      </Table>
    </Box>
  )
}

const TitrationPlanHeader = ({ mode, stepStartTime }: Pick<Props, "mode" | "stepStartTime">) => {
  const { colors } = useTheme()

  return (
    <Flex justifyContent="space-between">
      <Text size={18}>{translateText(`VNS/SESSION_REPORTS/TITRATION_CARD/${mode}_TITLE`)}</Text>
      {stepStartTime && (
        <Text bold color={colors.grey1} size={18}>
          {translateText("VNS/SESSION_REPORTS/TITRATION_CARD/STEP_START_TIME")}
          <Text color={colors.grey2} size={18} tag="span">
            {formatDate(stepStartTime, "TIME_ONLY")}
          </Text>
        </Text>
      )}
    </Flex>
  )
}

const TitrationPlanTableHead = ({ columns }: Pick<Props, "columns">) => (
  <thead>
    <tr>
      {columns.map(({ title, unit }) => (
        <TitrationPlanTableHeadCell key={title} title={title} unit={unit} />
      ))}
    </tr>
  </thead>
)

const TitrationPlanTableHeadCell = ({ title, unit }: { title: string; unit?: string }) => {
  const { colors } = useTheme()

  return (
    <TH key={title}>
      <Text bold color={colors.grey1} size={16} tag="span">
        {title}
      </Text>
      <Text color={colors.grey2} size={16} tag="span">
        {unit && ` ${unit}`}
      </Text>
    </TH>
  )
}

const TitrationPlanTableBody = ({ columns, mode, rows }: Pick<Props, "columns" | "mode" | "rows">) => (
  <tbody>
    {rows.map((row) => (
      <tr key={row.step}>
        {columns.map(({ field }) => {
          const isStatusColumn = field === "status"
          const defaultValue = isStatusColumn ? "" : "--"
          const value = row[field] || defaultValue

          return <TitrationPlanTableBodyCell key={field} mode={mode} statusRow={row.status} value={value} />
        })}
      </tr>
    ))}
  </tbody>
)

type TitrationPlanTableBodyCellProps = {
  mode: Mode
  statusRow: Row["status"]
  value: string | number
}
const TitrationPlanTableBodyCell = ({ mode, statusRow, value }: TitrationPlanTableBodyCellProps) => {
  const { colors } = useTheme()

  const isCurrent = value === "CURRENT"
  const isTarget = value === "TARGET"
  const isStatusValue = isCurrent || isTarget

  if (isStatusValue) {
    const bg = isCurrent ? customRowColorMap[mode].bg : colors.success20
    const color = isCurrent ? customRowColorMap[mode].color : colors.success

    return (
      <TD>
        <Box bg={bg} borderRadius={100} px={3} py={1} width="100%">
          <StatusText align="center" bold color={color} size={16}>
            {translateText(`VNS/SESSION_REPORTS/TITRATION_CARD/${value}`)}
          </StatusText>
        </Box>
      </TD>
    )
  }

  const isCurrentStatusRow = statusRow === "CURRENT"
  const statusRowBg = isCurrentStatusRow ? customRowColorMap[mode].bg : colors.success20
  const statusRowColor = isCurrentStatusRow ? customRowColorMap[mode].color : colors.success

  if (statusRow)
    return (
      <TD>
        <Box bg={statusRowBg} pl={3} py={1}>
          <Text color={statusRowColor} size={18}>
            {value}
          </Text>
        </Box>
      </TD>
    )

  return (
    <TD>
      <Box pl={3}>
        <Text color={colors.grey2} size={18}>
          {value}
        </Text>
      </Box>
    </TD>
  )
}

const borderColorMap: Record<Mode, string> = {
  GUIDED: livaNovaColors.primary,
  SCHEDULED: livaNovaColors.magenta
}

const customRowColorMap = {
  GUIDED: { bg: livaNovaColors.grey5, color: livaNovaColors.primary },
  SCHEDULED: { bg: livaNovaColors.magenta5, color: livaNovaColors.magenta }
}

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`

const TH = styled.th`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey4};
  padding: 16px 0;
  padding-left: 16px;
  text-align: left;
`

const TD = styled.td`
  padding: 16px 0;
  text-align: left;

  /* Rounded status row */
  &:first-child > div {
    border-radius: 30px 0 0 30px;
  }
  &:nth-last-child(2) > div {
    border-radius: 0 30px 30px 0;
  }

  /* Spacing between last columns */
  &:nth-last-child(2) {
    padding-right: 4px;
  }
  &:last-child {
    padding-left: 4px;
  }

  /* Makes last, status column to fit-content */
  &:last-child {
    width: 1%;
  }
`

const StatusText = styled(Text)`
  /* Needed for status column to fit-content */
  white-space: nowrap;
`
