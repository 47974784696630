import { Spacer } from "epsy-ui-react"
import React from "react"
import { NavLink } from "react-router-dom"
import { useProfile } from "src/redux/auth/useProfile"
import { translateText } from "src/services/translations/textTranslations"
import styleVars from "src/style/vars"
import styled from "styled-components"
import useNavConfig from "./useNavConfig"

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const StyledLink = styled(NavLink)`
  font-size: 18px;
  line-height: 52px;
  font-weight: bold;
  padding: 13px 12px 7px;
  position: relative;
  transition: 0.2s linear all;

  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${styleVars.navActiveLineHeight}px;
    background-color: transparent;
    border-radius: 2px;
    transition: 0.2s linear all;
  }

  &.active:after {
    background-color: ${(p) => p.theme.oldColors.primary.purple};
  }
`

const linkGutter = <Spacer width={16} shrink />

const TopLevelLinks = () => {
  const { topLevelLinksConfig } = useNavConfig()
  const { profile } = useProfile()
  return profile ? (
    <Container>
      {linkGutter}
      {topLevelLinksConfig.map(({ text, path }) => {
        return (
          <React.Fragment key={path}>
            {linkGutter}
            <StyledLink to={path} children={translateText(text)} />
            {linkGutter}
          </React.Fragment>
        )
      })}
      {linkGutter}
    </Container>
  ) : null
}

export default TopLevelLinks
