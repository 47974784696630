import { Spacer } from "epsy-ui-react"
import React from "react"
import { matchPath, NavLink, useLocation } from "react-router-dom"
import { useProfile } from "src/redux/auth/useProfile"
import { translateText } from "src/services/translations/textTranslations"
import { styleVars } from "src/style/vars"
import { pathTemplate } from "src/util/template"
import styled from "styled-components"
import ViewContainer from "../ViewContainer"
import useNavConfig, { NavConfigItem } from "./useNavConfig"

const Outer = styled.div`
  background-color: ${(p) => p.theme.oldColors.shade[5]};
  border-bottom: ${styleVars.navActiveLineHeight}px ${(p) => p.theme.oldColors.shade[4]} solid;
`

const Container = styled(ViewContainer)`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledLink = styled(NavLink)`
  color: ${(p) => p.theme.oldColors.primary.purple};
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  padding: 16px 16px 15px;
  margin-bottom: ${-styleVars.navActiveLineHeight}px;
  text-decoration: none;
  position: relative;
  color: ${(p) => p.theme.oldColors.shade[2]};
  transition: 0.2s linear all;

  &:hover {
    color: ${(p) => p.theme.oldColors.shade[3]};
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${styleVars.navActiveLineHeight}px;
    background-color: transparent;
    border-radius: 2px;
    transition: 0.2s linear all;
  }

  &.active {
    color: ${(p) => p.theme.oldColors.primary.purple};
  }
  &.active:after {
    background-color: ${(p) => p.theme.oldColors.primary.purple};
  }
`

const linkGutter = <Spacer width={4} shrink />

const Tabs = () => {
  const { profile } = useProfile()
  const location = useLocation()
  const pathname = location.pathname
  const { tabsConfig } = useNavConfig()

  const config = React.useMemo(() => {
    let config: NavConfigItem[] | undefined
    tabsConfig.forEach((configList) => {
      if (!config) {
        configList.forEach((navItem) => {
          const match = matchPath(`${navItem.path}/*`, pathname)
          if (match) {
            config = configList.map((navItem) => {
              return {
                ...navItem,
                path: pathTemplate(navItem.path, match.params)
              }
            })
          }
        })
      }
    })
    return config
  }, [pathname, tabsConfig])

  return profile && config && config.length > 1 ? (
    <Outer>
      <Container>
        {config.map(({ text, path }, index) => {
          return (
            <React.Fragment key={path}>
              {index ? linkGutter : null}
              <StyledLink to={path} children={translateText(text)} />
              {linkGutter}
            </React.Fragment>
          )
        })}
      </Container>
    </Outer>
  ) : null
}

export default Tabs
