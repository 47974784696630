import { Text } from "epsy-ui-react"
import React from "react"
import { analytics } from "src/services/analytics"
import styled from "styled-components"
import { Theme } from "../API"
import Link from "./Link"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  & > a,
  & > span {
    padding: 0 0.25em;
  }
`

const SuggestedText = styled(Text).attrs({
  tag: "span",
  size: 16
})``
const SuggestedLinkText = styled(Link).attrs(({ theme }) => ({
  size: 16,
  color: theme.mode === Theme.LivaNova ? theme.oldColors.text.suggestLink : theme.oldColors.primary.pink,
  bold: true,
  underline: false
}))``

export interface SuggestedLinkProps {
  to: string
  text: string
  linkText: string
  trackEvent?: string
}
const SuggestedLink: React.FC<SuggestedLinkProps> = (props) => {
  const { to, text, linkText, trackEvent } = props

  const handleLink = trackEvent
    ? () => {
        analytics.track(trackEvent)
      }
    : undefined

  return (
    <Container>
      <SuggestedText children={text} />
      <SuggestedLinkText data-cy="suggested-link" to={to} children={linkText} onClick={handleLink} />
    </Container>
  )
}

export default SuggestedLink
