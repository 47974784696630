import { Text, TextProps } from "epsy-ui-react"
import { LinkProps as RouterLinkProps } from "react-router-dom"
import { TextLink, TextLinkProps } from "./TextLink"

type LinkProps = (TextProps | TextLinkProps) & RouterLinkProps & { targetBlank?: boolean }

const Link = (props: LinkProps) => {
  const { to, replace, children, targetBlank, ...rest } = props

  if (typeof to === "string" && (targetBlank || to.startsWith("http"))) {
    const passedProps = {
      tag: "a" as TextProps["tag"],
      href: to,
      target: "_blank",
      rel: "noopener noreferrer",
      children,
      ...rest
    }
    return <Text {...passedProps} />
  } else {
    const passedProps = {
      ...rest,
      to,
      replace,
      children
    }
    return <TextLink {...passedProps} />
  }
}

export default Link
