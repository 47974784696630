import { useQuery } from "@tanstack/react-query"
import { GetVnsSessionReportQueryVariables, VnsSessionReport } from "src/API"
import appsyncApi from "src/services/appsyncApi"

export const useGetVnsSessionReport = ({
  patientProfileId,
  readingDateTime,
  tabletSerialNumber
}: GetVnsSessionReportQueryVariables) =>
  useQuery(
    ["getVnsSessionReport", patientProfileId, readingDateTime, tabletSerialNumber],
    async () => {
      const data = await appsyncApi.getVnsSessionReport({ patientProfileId, readingDateTime, tabletSerialNumber })
      return parseVnsSessionReportResponse(data)
    },
    {
      enabled: !!patientProfileId && !!readingDateTime && !!tabletSerialNumber
    }
  )

const parseVnsSessionReportResponse = (data: VnsSessionReport): VnsSessionReport => ({
  ...data,
  final: {
    ...data.final,
    batteryHealth: data.final.batteryHealth?.toUpperCase(),
    leadImpedanceStatus: data.final.leadImpedanceStatus?.toUpperCase(),
    normalOutputCurrentStatus: data.final.normalOutputCurrentStatus?.toUpperCase()
  },
  initial: {
    ...data.initial,
    batteryHealth: data.initial.batteryHealth?.toUpperCase(),
    leadImpedanceStatus: data.initial.leadImpedanceStatus?.toUpperCase(),
    normalOutputCurrentStatus: data.initial.normalOutputCurrentStatus?.toUpperCase()
  }
})
