import React from "react"
import { BrowserRouter, Navigate, Route } from "react-router-dom"
import { paths } from "src/app/routing/paths"
import ScrollToTop from "src/components/ScrollToTop"
import useFlags from "src/hooks/useFlags"
import Home from "src/views/Home"
import Login from "src/views/Login"
import { FilterContextProviderRoute } from "src/views/VNS/FilterContext"
import VNSSessionReport from "src/views/VNS/VNSSessionReport"
import { FlagsRoute } from "./FlagsRoute"
import { ProtectedPatientRoute } from "./ProtectedPatientRoute"
import { ProtectedRoute } from "./ProtectedRoute"
import { Routes } from "./Routes"
import { SuspenseRoute } from "./SuspenseRoute"

const Account = React.lazy(() => import("src/views/Account"))
const AddPatients = React.lazy(() => import("src/views/AddPatients"))
const AddTeam = React.lazy(() => import("src/views/AddTeam"))
const BAA = React.lazy(() => import("src/views/BAA"))
const ChangePassword = React.lazy(() => import("src/views/ChangePassword"))
const ConnectOrg = React.lazy(() => import("src/views/ConnectOrg"))
const Cookie = React.lazy(() => import("src/views/Cookie"))
const JoinOrg = React.lazy(() => import("src/views/JoinOrg"))
const Logout = React.lazy(() => import("src/views/Logout"))
const NotFound = React.lazy(() => import("src/views/NotFound"))
const PatientList = React.lazy(() => import("src/views/Patients"))
const PatientReport = React.lazy(() => import("src/views/Report"))
const PatientsVideoList = React.lazy(() => import("src/views/PatientsVideos"))
const PatientsVideoPreview = React.lazy(() => import("src/views/PatientsVideoPreview"))
const Privacy = React.lazy(() => import("src/views/Privacy"))
const QuestionnairesResponse = React.lazy(() => import("src/views/Questionnaires/QuestionnairesResponse"))
const QuestionnairesResponseEmpty = React.lazy(() => import("src/views/Questionnaires/QuestionnairesResponseEmpty"))
const QuestionnairesResponseList = React.lazy(() => import("src/views/Questionnaires/QuestionnairesResponseList"))
const ResetPassword = React.lazy(() => import("src/views/ResetPassword"))
const Team = React.lazy(() => import("src/views/Team/Team"))
const Terms = React.lazy(() => import("src/views/Terms"))
const TOMS = React.lazy(() => import("src/views/TOMS"))
const VNSDetails = React.lazy(() => import("src/views/VNS/VNSDetails"))
const VNSList = React.lazy(() => import("src/views/VNS/VNSList/VNSList"))
const VNSResources = React.lazy(() => import("src/views/VNS/VNSResources"))
const VNSTablets = React.lazy(() => import("src/views/VNS/VNSTablets"))
const VNSUpload = React.lazy(() => import("src/views/VNS/VNSUpload"))
const VNSRemoteProgramming = React.lazy(() => import("src/views/VNS/VNSRemoteProgramming"))

export const Router = () => {
  const flags = useFlags()

  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route element={<FlagsRoute />}>
          <Route element={<SuspenseRoute />}>
            <Route path={paths.HOME} element={<Home />} />
            <Route path={paths.LOGIN} element={<Login />} />

            <Route path={paths.CHANGE_PASSWORD} element={<ChangePassword />} />
            <Route path={paths.RESET_PASSWORD} element={<ResetPassword />} />

            <Route path={paths.CONNECT_ORG} element={<ConnectOrg />} />
            <Route path={paths.JOIN_ORG} element={<JoinOrg />} />

            <Route path={paths.BAA} element={<BAA />} />
            <Route path={paths.COOKIE} element={<Cookie />} />
            <Route path={paths.PRIVACY} element={<Privacy />} />
            <Route path={paths.TOMS} element={<TOMS />} />
            <Route path={paths.TERMS} element={<Terms />} />

            <Route path="*" element={<NotFound />} />
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route element={<ProtectedPatientRoute />}>
              {flags.hubPatients ? (
                <>
                  <Route path={paths.PATIENT_LIST} element={<PatientList />} />
                  <Route path={paths.ADD_PATIENTS} element={<AddPatients />} />
                  <Route path={paths.PATIENT_REPORT} element={<PatientReport />} />
                  <Route path={paths.PATIENT_VIDEO_LIST} element={<PatientsVideoList />} />
                  <Route path={paths.PATIENT_VIDEO_PREVIEW} element={<PatientsVideoPreview />} />
                </>
              ) : null}

              {flags.hubQuestionnairesShowSection ? (
                <>
                  <Route path={paths.QUESTIONNAIRES} element={<Navigate to={paths.QUESTIONNAIRES_RESPONSE_LIST} />} />
                  <Route path={paths.QUESTIONNAIRES_RESPONSE_LIST} element={<QuestionnairesResponseList />} />
                  <Route path={paths.QUESTIONNAIRES_RESPONSE} element={<QuestionnairesResponse />} />
                  <Route path={paths.QUESTIONNAIRES_RESPONSE_EMPTY} element={<QuestionnairesResponseEmpty />} />
                </>
              ) : null}

              {flags.hubVnsShowSection ? (
                <Route element={<FilterContextProviderRoute />}>
                  <Route path={paths.VNS} element={<Navigate to={paths.VNS_LIST} />} />
                  <Route path={paths.VNS_DETAILS} element={<VNSDetails />} />
                  {flags.hubShowSessionReport ? (
                    <Route path={paths.VNS_SESSION_REPORT} element={<VNSSessionReport />} />
                  ) : null}
                  <Route path={paths.VNS_RESOURCES} element={<VNSResources />} />
                  <Route path={paths.VNS_LIST} element={<VNSList />} />
                  {flags.hubVnsTablets ? <Route path={paths.VNS_TABLETS} element={<VNSTablets />} /> : null}
                  {flags.hubUploadFiles ? <Route path={paths.VNS_UPLOAD} element={<VNSUpload />} /> : null}
                  {flags.hubRemoveProgrammingSessions ? (
                    <Route path={paths.VNS_REMOTE_PROGRAMMING} element={<VNSRemoteProgramming />} />
                  ) : null}
                </Route>
              ) : null}
            </Route>

            <Route path={paths.TEAM} element={<Team />} />
            <Route path={paths.ADD_TEAM} element={<AddTeam />} />

            <Route path={paths.ACCOUNT} element={<Account />} />
            <Route path={paths.LOGOUT} element={<Logout />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
