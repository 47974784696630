import { Spinner } from "epsy-ui-react"
import { AbsoluteCentred } from "src/components/AbsoluteCentred"
import { useTheme } from "styled-components"

const Loading = () => {
  const theme = useTheme()
  return (
    <AbsoluteCentred data-testid="loading">
      <Spinner size={32} color={theme.oldColors.primary.purple} />
    </AbsoluteCentred>
  )
}

export default Loading
