import { Hcp } from "src/API"
import sentry from "src/services/sentry"
import { Maybe } from "./utilityTypes"

export function existGuard<T>(input: Maybe<T>): input is T {
  return Boolean(input)
}

export function isEpsyAdminEmail(email: string): boolean {
  return !!email.match("^epsyadmin+.*@epsyhealth.com$")
}

export function teamMemberEmailGuard(teamMember: Hcp): boolean {
  const email = teamMember.user?.email || teamMember.invitation?.recipientEmail
  if (!email) {
    sentry.captureException(new Error(`Team member's email is empty.`), { extra: { teamMember } })
    return false
  }
  return !isEpsyAdminEmail(email)
}
