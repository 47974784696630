import { Maybe } from "./utilityTypes"

const allTraillingZerosRegex = /(\.\d*?[1-9])0+$/
export function removeTrailingZeros(value: Maybe<string | number>) {
  if (value === null || value === undefined) return
  const numberValue = typeof value === "string" ? parseFloat(value) : value
  if (numberValue === null || numberValue === undefined || isNaN(numberValue)) return value
  const formattedString = numberValue.toString().replace(allTraillingZerosRegex, "$1")
  return formattedString.endsWith(".") ? formattedString.slice(0, -1) : formattedString
}
