import {
  BrowserTracing,
  captureException,
  init,
  reactRouterV6Instrumentation,
  Replay,
  setUser,
  withProfiler,
  withSentryReactRouterV6Routing
} from "@sentry/react"

import { Integration } from "@sentry/types"
import { useEffect } from "react"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import { environment } from "./environment"

export const sentry = {
  init: () => {
    const tracingOrigins: (string | RegExp)[] = ["localhost", /^\//]
    if (environment.api) tracingOrigins.push(environment.api)
    init({
      dsn: environment.sentryDSN,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      release: environment.version,
      environment: environment.env,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
          tracingOrigins
        }) as Integration,
        new Replay()
      ],
      tracesSampleRate: environment.sentryTracesSampleRate,
      enabled: !environment.isLocalEnv
    })
  },
  captureException,
  setUser,
  withProfiler,
  withSentryRouting: withSentryReactRouterV6Routing
}

export default sentry
