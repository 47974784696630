import React from "react"
import { normalizeButton } from "src/style/mixins"
import styled from "styled-components"

// https://jonsuh.com/hamburgers/

interface BurgerContainerProps {
  size: number
  color?: string
}
const BurgerContainer = styled.div<BurgerContainerProps>`
  ${(props) => `
  .hamburger {
    ${normalizeButton}
    height: ${1.25 * props.size}px;
    width: ${1.25 * props.size}px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: ${props.color || "currentColor"};
  }

  .hamburger-box {
    width: ${props.size}px;
    height: ${0.6 * props.size}px;
    display: block;
    position: relative;
    &:focus {
      outline: none;
    }
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -${0.05 * props.size}px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: ${props.size}px;
    height: ${0.1 * props.size}px;
    background-color: ${props.color || "currentColor"};
    border-radius: ${0.1 * props.size}px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -${0.25 * props.size}px;
  }
  .hamburger-inner::after {
    bottom: -${0.25 * props.size}px;
  }

  .hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }`}
`

interface BurgerProps {
  active?: boolean
  size?: number
  className?: string
  onClick?: (event: React.MouseEvent) => void
  color?: string
}
const Burger = (props: BurgerProps) => {
  const { active, onClick, className, size = 32, color } = props
  let buttonClassName = "hamburger hamburger--squeeze"
  if (active) buttonClassName += " is-active"
  return (
    <BurgerContainer size={size} color={color} className={className}>
      <button className={buttonClassName} type="button" aria-label="Menu" aria-controls="navigation" onClick={onClick}>
        {/* https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press */}
        <span className="hamburger-box" tabIndex={-1}>
          <span className="hamburger-inner"></span>
        </span>
      </button>
    </BurgerContainer>
  )
}

export default Burger
