import { Link } from "react-router-dom"
import { ReactComponent as ChevronDownM } from "src/images/icons/chevron-down/chevron-down-m.svg"
import { ReactComponent as ChevronDownS } from "src/images/icons/chevron-down/chevron-down-s.svg"
import styled from "styled-components"
import Circle from "./Circle"

interface Props {
  to: string
  variant: "FORWARD" | "BACKWARD"
  size: "SM" | "MD"
  className?: string
}
export const ChangeLocationButton = ({ to, className, variant, size }: Props) => (
  <Link {...{ to }}>
    <Container {...{ variant, size: sizeMapping[size], className }}>
      <Arrow $rotate={rotateMapping[variant]} $translate={translateMapping[variant]} as={iconMapping[size]} />
    </Container>
  </Link>
)

const Container = styled(Circle)`
  background-color: ${(p) => p.theme.oldColors.shade[5]};
  &:hover {
    background-color: ${(p) => p.theme.oldColors.shade[4]};
  }
  transition: 0.1s ease-out background-color;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Arrow = styled.svg<{ $rotate: number; $translate: number }>`
  color: ${(p) => p.theme.colors.primary};
  transform: rotate(${(p) => p.$rotate}deg);
  position: relative;
  left: ${(p) => p.$translate}px;
`

const sizeMapping = {
  SM: 24,
  MD: 36
}
const iconMapping = {
  SM: ChevronDownS,
  MD: ChevronDownM
}
const rotateMapping = {
  BACKWARD: 90,
  FORWARD: 270
}
const translateMapping = {
  BACKWARD: -1,
  FORWARD: 1
}
