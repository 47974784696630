/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addDomainToOrganization = /* GraphQL */ `
  mutation AddDomainToOrganization($input: OrganizationDomainInput!) {
    addDomainToOrganization(input: $input) {
      domain
      id
      organizationId
      signatoryUserId
    }
  }
`;
export const assignHcpPatient = /* GraphQL */ `
  mutation AssignHcpPatient($hcpMemberId: String!, $patientMemberId: String!) {
    assignHcpPatient(
      hcpMemberId: $hcpMemberId
      patientMemberId: $patientMemberId
    )
  }
`;
export const assignHcpPatientProfile = /* GraphQL */ `
  mutation AssignHcpPatientProfile(
    $hcpMemberId: String!
    $patientProfileId: String!
  ) {
    assignHcpPatientProfile(
      hcpMemberId: $hcpMemberId
      patientProfileId: $patientProfileId
    )
  }
`;
export const assignHcpVnsDevice = /* GraphQL */ `
  mutation AssignHcpVnsDevice(
    $hcpMemberId: String!
    $patientProfileId: String!
  ) {
    assignHcpVnsDevice(
      hcpMemberId: $hcpMemberId
      patientProfileId: $patientProfileId
    )
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation($input: InvitationInput!) {
    createInvitation(input: $input) {
      conversions
      createdAt
      expiresAt
      invitationId
      organization {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        phoneNumbers
        state
        zipCode
      }
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession($patientId: String!, $patientName: String!) {
    createSession(patientId: $patientId, patientName: $patientName) {
      attendee {
        capabilities {
          audio
          content
          video
        }
        externalUserId
        id
        joinToken
      }
      id
      meeting {
        externalMeetingId
        id
        mediaPlacement {
          audioFallbackUrl
          audioHostUrl
          eventIngestionUrl
          screenDataUrl
          screenSharingUrl
          screenViewingUrl
          signalingUrl
          turnControlUrl
        }
        mediaRegion
        meetingArn
        meetingHostId
        primaryMeetingId
        tenantIds
      }
      programmer {
        auth
        endpoint
      }
    }
  }
`;
export const createTabletZendeskTicket = /* GraphQL */ `
  mutation CreateTabletZendeskTicket($serialNumber: String!) {
    createTabletZendeskTicket(serialNumber: $serialNumber)
  }
`;
export const deleteHcp = /* GraphQL */ `
  mutation DeleteHcp($memberId: String!) {
    deleteHcp(memberId: $memberId) {
      invitation {
        conversions
        createdAt
        expiresAt
        invitationId
        organization {
          addressLine1
          addressLine2
          city
          country
          department
          email
          logo
          name
          organizationId
          phoneNumbers
          state
          zipCode
        }
        organizationId
        recipientEmail
        recipientName
        recipientPhoneCountryCode
        recipientPhoneNumber
        redemptionLimit
        role
        senderUserId
        sentAt
        token
        type
        updatedAt
      }
      invitationId
      memberId
      organizationId
      user {
        email
        id
        name
        npiNumber
        phoneNumber
        role
      }
      userId
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation($invitationId: String!) {
    deleteInvitation(invitationId: $invitationId) {
      conversions
      createdAt
      expiresAt
      invitationId
      organization {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        phoneNumbers
        state
        zipCode
      }
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient($memberId: String!) {
    deletePatient(memberId: $memberId) {
      assignedHcps {
        invitation {
          conversions
          createdAt
          expiresAt
          invitationId
          organization {
            addressLine1
            addressLine2
            city
            country
            department
            email
            logo
            name
            organizationId
            phoneNumbers
            state
            zipCode
          }
          organizationId
          recipientEmail
          recipientName
          recipientPhoneCountryCode
          recipientPhoneNumber
          redemptionLimit
          role
          senderUserId
          sentAt
          token
          type
          updatedAt
        }
        invitationId
        memberId
        organizationId
        user {
          email
          id
          name
          npiNumber
          phoneNumber
          role
        }
        userId
      }
      invitation {
        conversions
        createdAt
        expiresAt
        invitationId
        organization {
          addressLine1
          addressLine2
          city
          country
          department
          email
          logo
          name
          organizationId
          phoneNumbers
          state
          zipCode
        }
        organizationId
        recipientEmail
        recipientName
        recipientPhoneCountryCode
        recipientPhoneNumber
        redemptionLimit
        role
        senderUserId
        sentAt
        token
        type
        updatedAt
      }
      invitationId
      lastSeizureDate
      medications {
        archivedAt
        compound
        createdAt
        dosage
        frequency
        id
        loggedAt
        parentId
        remindersActive
        scheduledAt {
          key
          time
        }
        updatedAt
        userId
      }
      memberId
      organizationId
      user {
        createdAt
        dob
        email
        gender
        id
        lastSentAt
        name
        phoneNumber
      }
      userId
    }
  }
`;
export const hcpAcceptInvitation = /* GraphQL */ `
  mutation HcpAcceptInvitation($input: HcpAcceptInvitationInput!) {
    hcpAcceptInvitation(input: $input) {
      conversions
      createdAt
      expiresAt
      invitationId
      organization {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        phoneNumbers
        state
        zipCode
      }
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const removeDomainFromOrganization = /* GraphQL */ `
  mutation RemoveDomainFromOrganization($domainId: String!) {
    removeDomainFromOrganization(domainId: $domainId)
  }
`;
export const resendInvitation = /* GraphQL */ `
  mutation ResendInvitation($invitationId: String!) {
    resendInvitation(invitationId: $invitationId) {
      conversions
      createdAt
      expiresAt
      invitationId
      organization {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        phoneNumbers
        state
        zipCode
      }
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const unassignHcpPatient = /* GraphQL */ `
  mutation UnassignHcpPatient(
    $hcpMemberId: String!
    $patientMemberId: String!
  ) {
    unassignHcpPatient(
      hcpMemberId: $hcpMemberId
      patientMemberId: $patientMemberId
    )
  }
`;
export const unassignHcpPatientProfile = /* GraphQL */ `
  mutation UnassignHcpPatientProfile(
    $hcpMemberId: String!
    $patientProfileId: String!
  ) {
    unassignHcpPatientProfile(
      hcpMemberId: $hcpMemberId
      patientProfileId: $patientProfileId
    )
  }
`;
export const unassignHcpVnsDevice = /* GraphQL */ `
  mutation UnassignHcpVnsDevice(
    $hcpMemberId: String!
    $patientProfileId: String!
  ) {
    unassignHcpVnsDevice(
      hcpMemberId: $hcpMemberId
      patientProfileId: $patientProfileId
    )
  }
`;
export const updateHcpUser = /* GraphQL */ `
  mutation UpdateHcpUser($id: String!, $input: HcpUserInput) {
    updateHcpUser(id: $id, input: $input) {
      email
      id
      name
      npiNumber
      phoneNumber
      role
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation($input: InvitationInput!) {
    updateInvitation(input: $input) {
      conversions
      createdAt
      expiresAt
      invitationId
      organization {
        addressLine1
        addressLine2
        city
        country
        department
        email
        logo
        name
        organizationId
        phoneNumbers
        state
        zipCode
      }
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization($input: OrganizationInput!) {
    updateOrganization(input: $input) {
      addressLine1
      addressLine2
      city
      country
      department
      email
      logo
      name
      organizationId
      phoneNumbers
      state
      zipCode
    }
  }
`;
export const updateTablet = /* GraphQL */ `
  mutation UpdateTablet($input: UpdateBlcTabletInput!) {
    updateTablet(input: $input) {
      createdAt
      id
      lastInterrogationDate
      nickname
      organizationId
      serialNumber
      totalPatients
      totalReadings
      updatedAt
    }
  }
`;
