import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField as _useFieldFormik } from "formik"
import React from "react"

export const ErrorTranslationContext = React.createContext((key: string) => key)

interface AnalyticsContextValue {
  track: (key: string, data?: { [key: string]: any }) => void
}

const AnalyticsContext = React.createContext<AnalyticsContextValue>({
  track: (key) => {
    console.log(`Analytics track event triggered: "${key}"`)
    console.log(`Use AnalyticsContext.Provider to implement analytics!`)
  }
})

export const useField = <Val = any>(props: any): [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>] => {
  const { trackEvent, name, type } = props
  const { track } = React.useContext(AnalyticsContext)
  const translate = React.useContext(ErrorTranslationContext)

  const [field, meta, helpers] = _useFieldFormik({ name, type })

  const { onBlur, value } = field
  const modifiedOnBlur = React.useMemo(() => {
    if (!trackEvent) return onBlur
    return (e: any) => {
      onBlur(e)
      if (value) {
        track(trackEvent)
      }
    }
  }, [value, onBlur, trackEvent, track])
  const modifiedField = { ...field, onBlur: modifiedOnBlur }

  const { error } = meta
  const modifiedError = error ? translate(error) : error
  const modifiedMeta = { ...meta, error: modifiedError }

  return [modifiedField, modifiedMeta, helpers]
}
