import { Text } from "epsy-ui-react"
import React from "react"
import styled from "styled-components"

const ErrorMessage = styled(Text).attrs(({ theme }) => ({
  size: 24,
  color: theme.oldColors.traffic.alertRed,
  bold: true,
  align: "center"
}))`
  max-width: 32em;
  margin: 0 auto;
`

type Props = { children: React.ReactNode }
const ViewErrorMessage = ({ children }: Props) => <ErrorMessage children={children} />

export default ViewErrorMessage
