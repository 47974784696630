import LoadStatus from "../../util/LoadStatus"
import { AuthAction, AuthState } from "./types"

const defaultState: AuthState = {
  profile: null,
  initialised: false,
  identified: false
}

function authReducer(state = defaultState, action: AuthAction): AuthState {
  if (action.slice === "AUTH") {
    const nextState = { ...state }
    if (action.status === LoadStatus.SUCCESS) {
      if (action.type === "UPDATE_PROFILE") {
        const profileUpdate = action.payload.profileUpdate
        if (nextState.profile && profileUpdate) {
          nextState.profile = {
            ...nextState.profile,
            ...{
              NPI: profileUpdate.NPI,
              name: profileUpdate.name
            }
          }
        }
      } else {
        const profile = action.payload?.profile
        nextState.profile = profile
      }
    }

    if (action.type === "RESTORE" && (action.status === LoadStatus.SUCCESS || action.status === LoadStatus.FAIL)) {
      nextState.initialised = true
    }

    if (action.type === "LOGOUT") {
      nextState.profile = null
    }
    if (action.type === "IDENTIFY") {
      nextState.identified = true
    }
    if (action.type === "DEIDENTIFY") {
      nextState.identified = false
    }

    return nextState
  } else {
    return state
  }
}

export default authReducer
