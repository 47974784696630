import { Text } from "epsy-ui-react"
import React from "react"
import { ReactComponent as CloseSvg } from "src/images/icons/close/close-m.svg"
import styled, { useTheme } from "styled-components"
import { ToastContext, ToastStateProvider } from "./ToastContext"

const ToastBox = styled.div`
  width: 100%;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const TextContainer = styled.div`
  padding: 16px 0;
  flex: 1;
  flex-shrink: 0;
`

const IconContainer = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${(p) => (p.onClick ? "cursor: pointer;" : "")}
`

export interface ToastProps {
  title: string
  body?: string
  icon?: React.ReactNode
  onClose?: () => void
  onClick?: () => void
}
export const Toast: React.FC<ToastProps> = (props) => {
  const { title, body, icon, onClose, onClick } = props
  const theme = useTheme()
  return (
    <ToastBox onClick={onClick}>
      <IconContainer>{icon}</IconContainer>
      <TextContainer>
        <Text color={theme.oldColors.secondary.indigo} size={16} bold children={title} />
        {body ? <Text color={theme.oldColors.secondary.indigo} size={14} children={body} /> : null}
      </TextContainer>
      <IconContainer onClick={onClose}>{onClose ? <CloseSvg /> : null}</IconContainer>
    </ToastBox>
  )
}

const ToastsContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 422px;
  padding: 16px;
  z-index: 2;
`

export const ToastLayer: React.FC<{}> = (props) => {
  const { toasts } = React.useContext(ToastContext)
  return (
    <ToastsContainer>
      {toasts.map((toast) => {
        const { id, title, body, icon, onClick, onClose } = toast
        const toastProps = { title, body, icon, onClick, onClose }
        return <Toast key={id} {...toastProps} />
      })}
    </ToastsContainer>
  )
}

export const ToastProvider: React.FC<{}> = (props) => {
  return (
    <ToastStateProvider>
      {props.children}
      <ToastLayer />
    </ToastStateProvider>
  )
}
