import { useSelector } from "react-redux"
import { paths } from "src/app/routing/paths"
import { authSelector } from "src/redux/auth/selectors"
import { DefaultTheme } from "styled-components"
import { HcpRole, Theme } from "../API"

export const useLandingPage = (): string => {
  const { profile } = useSelector(authSelector)

  if (profile?.role === HcpRole.ITAdmin) return paths.TEAM

  switch (getTheme()) {
    case Theme.Epsy:
      return paths.PATIENT_LIST
    case Theme.LivaNova:
      return paths.VNS
  }
}

export const getTheme = (): Theme => {
  /**
   * Deliberately not using the `environment.ts` or its 'craEnv' function.
   * This prevents accidental usage anywhere else in the codebase since it's
   * purpose is soley to drive this peice of code.
   */
  const envTheme = process.env["REACT_APP_THEME"]
  switch (envTheme) {
    case "epsy":
      return Theme.Epsy
    case "livanova":
      return Theme.LivaNova
    default:
      const defaultLabel: Theme = Theme.LivaNova
      const hostname = window.location.hostname
      if (hostname.includes("cloudfront.net")) {
        return Theme.LivaNova
      }
      return (
        (Object.keys(Theme).find((key) => {
          return hostname.includes(Theme[key as Theme].toLowerCase())
        }) as Theme) || defaultLabel
      )
  }
}

export const themeModeMap: Record<DefaultTheme["mode"], Theme> = {
  Epsy: Theme.Epsy,
  LivaNova: Theme.LivaNova
}

export function getLogo() {
  switch (getTheme()) {
    case Theme.Epsy:
      return "/images/logo.svg"
    case Theme.LivaNova:
      return "/images/vns-therapy.svg"
  }
}
