import React, { ReactNode } from "react"
import { Outlet } from "react-router-dom"

const defaultFilterValues = {
  SEARCH: "",
  LAST_READ_AT: [] as string[],
  YEARS_TO_NEOS: [] as string[],
  IMPLANTED_AT: [] as string[],
  ASSIGNED: [] as string[],
  MODEL: [] as string[],
  NEOS_PATIENTS: null,
  LOW_CURRENT: null,
  SINCE_UPDATE: null
}

type FilterValues = typeof defaultFilterValues

type FilterContextType = {
  value: FilterValues
  setter: React.Dispatch<React.SetStateAction<FilterValues>>
}

const FilterContext = React.createContext<FilterContextType | null>(null)

export const useFilterContext = () => {
  const context = React.useContext(FilterContext)
  if (!context) throw new Error("useFilterContext must be used within a FilterContextProvider")

  return context
}

const FilterContextProvider = ({ children }: { children: ReactNode }) => {
  const [value, setter] = React.useState<FilterValues>(defaultFilterValues)

  return <FilterContext.Provider value={{ value, setter }} children={children} />
}

export const FilterContextProviderRoute = () => (
  <FilterContextProvider>
    <Outlet />
  </FilterContextProvider>
)
