import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { paths } from "src/app/routing/paths"
import { authSelector } from "src/redux/auth/selectors"
import { useLandingPage } from "src/services/whiteLabel"

const Home = () => {
  const landingPage = useLandingPage()
  const { profile } = useSelector(authSelector)

  if (profile) {
    return <Navigate replace to={landingPage} />
  }

  return <Navigate replace to={paths.LOGIN} />
}

export default Home
