import { useQuery } from "@tanstack/react-query"
import { Storage } from "aws-amplify"
import defaultOrgLogoSrc from "src/images/default-org-logo.png"
import { Maybe } from "src/util/utilityTypes"

/**
 * We need to parse image URLs due to handle legacy structure from organization dictionary.
 * We used to store it as `public/organization-id/file-name`,
 * but it is not valid url for the Storage class.
 * Now we're storing it with simpler `organization-id/file-name`, but we have to handle both.
 */
const parseImageUrl = (imageUrl: string) => imageUrl.replace("public/", "")

export const useStorageImage = (imageUrl: Maybe<string>) =>
  useQuery(["storageImage", imageUrl], () => {
    if (!imageUrl) return defaultOrgLogoSrc
    return Storage.get(parseImageUrl(imageUrl))
  })

export const preloadImages = (imageUrls: string[]) => {
  const imagesContainer = document.getElementById("preloaded-images")
  for (let i = 0; i < imageUrls.length; i++) {
    const image = new Image()
    image.src = imageUrls[i]
    imagesContainer?.appendChild(image)
  }
}
