import { Amplify, Auth } from "aws-amplify"
import { constantCase } from "change-case"
import { environment } from "./services/environment"
import mapValues from "./util/mapValues"

let awsExports: any = {}
try {
  awsExports = require(`./aws-exports.${environment.env}`).default
} catch (err) {
  console.error(err)
  throw err
}

const win = window as any
const nodeEnv = process.env.NODE_ENV
const isCypress = Boolean(win?.Cypress)

let flags = {
  interactiveReport: false,
  ukNumbers: false,
  polishNumbers: false,
  cognito: false
}
flags = mapValues(flags, (currentValue, flagName) => {
  if (currentValue) return currentValue
  const envName = `REACT_APP_FLAG_${constantCase(flagName)}`
  const value = process.env[envName]
  return Boolean(value) && value !== "0" && value !== "false"
})

try {
  if (process.env.NODE_ENV !== "test") {
    console.log(environment.env === "prod" ? { version: environment.version } : { environment, awsExports })
  }

  Amplify.configure(awsExports)
  /**
   * Using ID token instead of access token because fine-grained auth requires some custom attributes in the JWT.
   * Taken from here: https://github.com/aws-amplify/amplify-js/issues/3326
   */
  Amplify.configure({
    API: {
      graphql_headers: async () => {
        /**
         * This try catch block is required because making API calls using option "authMode": "AWS_IAM" with a user that is not
         * logged in will cause the call to Amplify.Auth.currentSession() to fail/error and therefore the API call will also
         * fail with error 'User not authenticated' despite expecting the user to be unauthenticated.
         */

        try {
          const session = await Auth.currentSession()
          return {
            Authorization: session.getIdToken().getJwtToken()
          }
        } catch (error) {
          return
        }
      }
    },
    Storage: {
      AWSS3: {
        bucket: awsExports.aws_storage_bucket,
        region: awsExports.aws_project_region
      }
    }
  })
} catch (err) {
  console.error("Amplify", err)
}
const awsClientId = awsExports.aws_user_pools_web_client_id
const appConfig = {
  isCypress,
  awsClientId,
  flags,
  nodeEnv
}

export default appConfig
