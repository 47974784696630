import { Box, Flex, Text } from "epsy-ui-react"
import { ReactComponent as Bullet } from "src/images/icons/bullet/bullet.svg"
import { useTheme } from "styled-components"

export type ParameterChipProps = {
  children: string
  bulletColor?: string
  color?: string
  bg?: string
  bullet?: boolean
}

export const ParameterChip = ({ children, bulletColor, bg, color, bullet }: ParameterChipProps) => {
  const { colors } = useTheme()
  return (
    <Box bg={bg || colors.blue20} px="8px" py="4px" borderRadius="4px" width="fit-content">
      <Flex alignItems="center" gap={4}>
        {bullet && <Bullet height={8} width={8} fill={bulletColor || colors.blue} />}
        <Text align="center" tag="span" size={14} color={color ?? "#585f7e"}>
          {children}
        </Text>
      </Flex>
    </Box>
  )
}
