import {
  Menu as ReachMenu,
  MenuButton as ReachMenuButton,
  MenuItems as ReachMenuItems,
  MenuLink as ReachMenuLink,
  MenuPopover as ReachMenuPopover
} from "@reach/menu-button"
import "@reach/menu-button/styles.css"
import { positionRight } from "@reach/popover"
import { Link } from "react-router-dom"
import { ReactComponent as ChevronSvg } from "src/images/icons/chevron/chevron-m.svg"
import { useProfile } from "src/redux/auth/useProfile"
import { useMyOrganization } from "src/redux/hooks"
import { translateText } from "src/services/translations/textTranslations"
import { normalizeButton } from "src/style/mixins"
import LoadStatus from "src/util/LoadStatus"
import styled from "styled-components"
import { ProfileBox } from "./ProfileBox"
import useNavConfig from "./useNavConfig"

const DesktopMenu = () => {
  const { profile } = useProfile()
  const { status: orgStatus } = useMyOrganization()
  const { accountMenuConfig } = useNavConfig()

  if (!profile || orgStatus === LoadStatus.PENDING) return null

  return (
    <ReachMenu>
      <MenuButton data-cy="nav-menu">
        <ProfileBox>
          <ChevronSvg aria-hidden data-reach-menu-chevron />
        </ProfileBox>
      </MenuButton>
      <ReachMenuPopover position={positionRight}>
        <MenuItems>
          {accountMenuConfig.map(({ text, path }) => (
            <ReachMenuLink as={MenuLink} children={translateText(text)} to={path} key={path} />
          ))}
        </MenuItems>
      </ReachMenuPopover>
    </ReachMenu>
  )
}

export default DesktopMenu

const MenuButton = styled(ReachMenuButton)`
  ${normalizeButton}
  cursor: pointer;
  display: block;
  width: 100%;

  & [data-reach-menu-chevron] {
    transform: rotate(90deg);
  }
  &[aria-expanded] > [data-reach-menu-chevron] {
    transform: rotate(270deg);
  }
`

const MenuItems = styled(ReachMenuItems)`
  margin-top: 8px;
  padding: 0;

  border-radius: 6px;
  border: 0;

  color: ${(p) => p.theme.oldColors.primary.purple};
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`

const MenuLink = styled(Link)`
  display: block;
  text-decoration: none;
  font-size: 16px;
  line-height: 48px;
  font-weight: bold;

  &[data-selected],
  &:hover {
    background-color: ${(p) => p.theme.oldColors.secondary.indigo};
  }
`
