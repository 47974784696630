import luhn from "luhn"
import { boolean, mixed, object, ref, string, ValidationError } from "yup"
import { TypeOfShape } from "yup/lib/object"
import { RequiredStringSchema } from "yup/lib/string"
import { AnyObject } from "yup/lib/types"
import { TextTranslationKey as TK } from "./translations/textTranslations"

const npiValidatorTest = (value: any) => {
  if (!value) {
    return true
  }
  /**
   * https://en.wikipedia.org/wiki/National_Provider_Identifier#Identifier_details
   *
   * Ten-digit NPI numbers may be validated using the Luhn algorithm
   * by prefixing"80840" to the 10-digit number
   */
  const NPI_PREFIX = "80840"
  return luhn.validate(`${NPI_PREFIX}${value}`)
}

export const validators = {
  name: string().required("FIELD/NAME/VALIDATION/REQUIRED" as TK),
  patientName: string().required("FIELD/PATIENT_NAME/VALIDATION/REQUIRED" as TK),
  email: string()
    .email("FIELD/EMAIL/VALIDATION/INVALID" as TK)
    .required("FIELD/EMAIL/VALIDATION/REQUIRED" as TK),
  password: string()
    .required("FIELD/PASSWORD/VALIDATION/REQUIRED" as TK)
    .min(15, "FIELD/PASSWORD/VALIDATION/LENGTH" as TK)
    .matches(/\d+/, "FIELD/PASSWORD/VALIDATION/NUMBER" as TK)
    .matches(/[!@#$%^&*()\-_=+[\]{};:'"|,.<>/?~`§]+/, "FIELD/PASSWORD/VALIDATION/SPECIAL" as TK)
    .matches(/[A-Z]+/, "FIELD/PASSWORD/VALIDATION/UPPERCASE" as TK)
    .matches(/[a-z]+/, "FIELD/PASSWORD/VALIDATION/LOWERCASE" as TK),
  repeatPassword: string()
    .required("FIELD/PASSWORD/VALIDATION/REQUIRED" as TK)
    .min(15, "FIELD/PASSWORD/VALIDATION/LENGTH" as TK)
    .matches(/\d+/, "FIELD/PASSWORD/VALIDATION/NUMBER" as TK)
    .matches(/[!@#$%^&*()\-_=+[\]{};:'"|,.<>/?~`§]+/, "FIELD/PASSWORD/VALIDATION/SPECIAL" as TK)
    .matches(/[A-Z]+/, "FIELD/PASSWORD/VALIDATION/UPPERCASE" as TK)
    .matches(/[a-z]+/, "FIELD/PASSWORD/VALIDATION/LOWERCASE" as TK)
    .oneOf([ref("password")], "FIELD/REPEAT_PASSWORD/VALIDATION/NO_MATCH" as TK),
  notRequired: mixed().notRequired(),
  organization: string().required("FIELD/ORGANIZATION/VALIDATION/REQUIRED" as TK),
  role: string().required("FIELD/ROLE/VALIDATION/REQUIRED" as TK),
  acceptPolicies: boolean().oneOf([true], "FIELD/ACCEPT_POLICIES/VALIDATION/REQUIRED" as TK),
  orgName: string().required("FIELD/ORGANIZATION_NAME/VALIDATION/REQUIRED" as TK),
  phone: object({
    internationalCode: string().required("FIELD/PHONE/VALIDATION/INTERNATIONAL_CODE/REQUIRED"),
    nationalNumber: string().required("FIELD/PHONE/VALIDATION/NATIONAL/REQUIRED")
  }),
  requiredString: (tk: TK) => string().required(tk),
  npiNumber: string()
    .matches(/^\d*$/, "FIELD/NPI/VALIDATION/DIGITS" as TK)
    .length(10, "FIELD/NPI/VALIDATION/LENGTH" as TK)
    .test("npi", "FIELD/NPI/VALIDATION/INVALID" as TK, npiValidatorTest),
  otp: string()
    .matches(/^\d*$/, "FIELD/OTP/VALIDATION/DIGITS" as TK)
    .length(6, "FIELD/OTP/VALIDATION/LENGTH" as TK),

  department: string().required("FIELD/DEPARTMENT/VALIDATION/REQUIRED" as TK),
  addressLine1: string().required("FIELD/ADDRESSLINE1/VALIDATION/REQUIRED" as TK),
  addressLine2: string().required("FIELD/ADDRESSLINE2/VALIDATION/REQUIRED" as TK),
  city: string().required("FIELD/CITY/VALIDATION/REQUIREDD" as TK),
  state: string().required("FIELD/STATE/VALIDATION/REQUIRED" as TK),
  zipCode: string().required("FIELD/ZIPCODE/VALIDATION/REQUIRED" as TK),
  tabletSerialNumber: string()
    .required()
    /**
     * // TODO: Fix workaround for `EntryField` not being able to be used as a controlled component.
     *
     * This regex is not representative of a real serial number.
     * A real serial number would be a combination of uppercase letters and numbers,
     * whereas this regex also allows lowercase letters.
     *
     * The `EntryField` component is using CSS to transform the input to uppercase.
     * and the `EntryField` value is being programmatically transformed to uppercase
     * before being submitted.
     *
     * @see {@link https://linear.app/epsy/issue/FEP-421}
     */
    .matches(/^[a-zA-Z0-9]*$/, "FIELD/TABLET_SERIAL/VALIDATION/REQUIRED" as TK),
  domain: string()
    .required()
    .min(3, "FIELD/DOMAIN/VALIDATION/LENGTH" as TK)
    .matches(/^[^@]*$/, "FIELD/DOMAIN/VALIDATION/AT" as TK)
    .matches(/^[^.].*[^.]$/, "FIELD/DOMAIN/VALIDATION/DOT" as TK)
    .matches(/.*\..*/, "FIELD/DOMAIN/MATCH" as TK),
  tabletNickname: string().required("FIELD/TABLET_NICKNAME/VALIDATION/REQUIRED" as TK)
}

export const testFunctions = {
  uniqueEmailInArray: (
    invites:
      | TypeOfShape<{
          name: RequiredStringSchema<string | undefined, AnyObject>
          email: RequiredStringSchema<string | undefined, AnyObject>
        }>[]
      | undefined
  ) => {
    const emails = invites?.map((invite) => invite.email)
    if (!emails || emails.length === 1) return true
    const validationErrors: ValidationError[] = []
    for (var index = 1; index < emails.length; index++) {
      const previousEmails = emails.slice(0, index)
      if (!previousEmails.includes(emails[index])) continue
      const error = new ValidationError("ADD_TEAM/ERROR/DUPLICATE_EMAILS", undefined, `teamMembers[${index}].email`)
      validationErrors.push(error)
    }
    return validationErrors.length > 0 ? new ValidationError(validationErrors) : true
  }
}
