import Zendesk from "react-zendesk"
import appConfig from "src/appConfig"
import { DefaultTheme } from "styled-components"
import { environment } from "./environment"

const win = window as any

const helpWidget = {
  identify: async (userAttributes: { name: string; email: string } | null) => {
    if (win.zE) {
      if (!userAttributes) {
        win.zE("webWidget", "close")
        win.zE("webWidget", "reset")
      } else if (win.zE.identify) {
        win.zE.identify(userAttributes)
      }
    }
  },
  getWidget: (oldColors: DefaultTheme["oldColors"]) => {
    if (appConfig.isCypress) return null
    const zendeskSettings = {
      webWidget: {
        chat: { connectOnPageLoad: false },
        color: {
          theme: oldColors.primary.purple,
          launcherText: oldColors.shade.white
        }
      }
    }
    return <Zendesk zendeskKey={environment.zendeskKey} {...zendeskSettings} />
  },
  open: () => {
    if (win.zE) {
      win.zE("webWidget", "open")
    }
  }
}

export default helpWidget
