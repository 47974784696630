import React from "react"
import ReactDOM from "react-dom"
import App from "src/App"
import applyPolyfills from "src/polyfills"
import flags from "src/services/flags"

applyPolyfills()
;(async () => {
  try {
    const FlagsProvider = await flags.getProvider()
    ReactDOM.render(
      <>
        <React.StrictMode>
          <FlagsProvider>
            <App />
          </FlagsProvider>
        </React.StrictMode>
      </>,
      document.getElementById("root")
    )
  } catch (err) {
    console.error(err)
  }
})()
