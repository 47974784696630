import { Box, Spacer, Text } from "epsy-ui-react"
import { paths } from "src/app/routing/paths"
import { Button } from "src/components/Button"
import { ChangeLocationButton } from "src/components/ChangeLocationButton"
import { viewContainerMaxWidth } from "src/components/ViewContainer"
import { formatDate } from "src/services/dates"
import { translateText } from "src/services/translations/textTranslations"
import { breakpoints } from "src/style/breakpoints"
import { pathTemplate } from "src/util/template"
import styled, { useTheme } from "styled-components"
import { VnsDeviceSummary, VnsSessionReport } from "../../../API"

export type HeaderProps = Pick<VnsSessionReport, "patientId"> &
  Pick<VnsDeviceSummary, "patientDob" | "patientName"> & {
    onCopySummaryClick?: () => void
    onDownloadPdfClick?: () => void
  }

export const Header = ({ patientId, patientName, patientDob, onCopySummaryClick, onDownloadPdfClick }: HeaderProps) => {
  const { colors } = useTheme()

  return (
    <Container>
      <BackButton
        size="MD"
        variant="BACKWARD"
        to={pathTemplate(paths.VNS_DETAILS, {
          id: patientId || ""
        })}
      />
      <Text size={32} bold>
        {patientName}
      </Text>
      <Spacer width={16} />
      <Box bg={colors.grey5} py={1} px={2} borderRadius={6}>
        <Text bold color={colors.grey1} size={18}>
          {translateText("VNS/SESSION_REPORTS/HEADER/DOB", { date: formatDate(patientDob, "DATE_MONTH_YEAR_SHORT") })}
        </Text>
      </Box>
      <Spacer width={16} />

      <Box flexGrow={1} display="flex" justifyContent="flex-end">
        <Button onClick={onCopySummaryClick} size="L" variant="SECONDARY">
          {translateText("VNS/SESSION_REPORTS/HEADER/COPY")}
        </Button>
        <Spacer width={16} />
        <Button onClick={onDownloadPdfClick} size="L" variant="PRIMARY">
          {translateText("VNS/SESSION_REPORTS/HEADER/DOWNLOAD")}
        </Button>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
  @media (min-width: ${breakpoints.large.min}px) {
    margin-right: 24px;
  }
`

const BackButton = styled(ChangeLocationButton)`
  @media (min-width: ${viewContainerMaxWidth + 100}px) {
    margin-left: -60px;
  }
  margin-right: 24px;
`
