import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Organization, Theme } from "src/API"
import { User } from "src/models"
import { analytics } from "src/services/analytics"
import flags from "src/services/flags"
import helpWidget from "src/services/helpWidget"
import sentry from "src/services/sentry"
import { getTheme } from "src/services/whiteLabel"
import { useDispatch, useMyOrganization } from "../hooks"
import { deidentify as deidentifyAction, identify as identifyAction, restore } from "./actions"
import { authSelector } from "./selectors"

type CustomUserAttributes = {
  organizationId?: string
  whiteLabel: Theme
}

const DOMAINS_EXCLUDED_FROM_ANALYTICS = ["livanova.com", "epsyhealth.com", "bcgdv.com"]
const includeInAnalytics = (email: string) => !DOMAINS_EXCLUDED_FROM_ANALYTICS.includes(email.split("@")[1])

let restoreCount = 0

export function useIdentify() {
  const dispatch = useDispatch()
  const { profile, initialised } = useSelector(authSelector)

  const { data: organization, isLoading } = useMyOrganization()
  const flagsClient = flags.useFlagsClient()

  useEffect(() => {
    dispatch(restore(undefined))
    restoreCount++
    if (restoreCount > 1) {
      console.warn("RESTORE triggered more than once")
    }
  }, [dispatch])

  useEffect(() => {
    const identify = async () => {
      if (!initialised || !flagsClient) return

      if (profile && organization && !isLoading) {
        analyticsIdentify(profile, organization)
        sentry.setUser({ id: profile.id })
        helpWidget.identify({ email: profile.email, name: profile.name || "" })
        await launchDarklyIdentify(profile, flagsClient)

        dispatch(identifyAction())
      } else {
        analytics.reset()
        sentry.setUser(null)
        helpWidget.identify(null)
        await flagsClient.identify({ anonymous: true, custom: { whiteLabel: getTheme() } })

        dispatch(deidentifyAction())
      }
    }

    identify()
  }, [profile, initialised, flagsClient, dispatch, isLoading, organization])
}

const getScreenParams = () => {
  const { screen } = window
  const { availHeight, availWidth, pixelDepth, orientation, colorDepth } = screen

  const screenHeight = screen.height
  const screenWidth = screen.width
  const devicePixelRatio = window.devicePixelRatio

  return { screenHeight, screenWidth, availHeight, availWidth, pixelDepth, orientation, colorDepth, devicePixelRatio }
}

const analyticsIdentify = (profile: User, organization: Organization) => {
  const screen = getScreenParams()
  const { id, organizationId, email, name } = profile

  analytics.identify(id, {
    include_in_analytics: includeInAnalytics(email),
    organization_id: organizationId,
    organization_name: organization.name,
    email,
    name,
    screen
  })
}

const launchDarklyIdentify = async (profile: User, flagsClient: ReturnType<typeof flags.useFlagsClient>) => {
  const { id, email, name } = profile
  const custom: CustomUserAttributes = { whiteLabel: getTheme(), organizationId: profile.organizationId }

  await flagsClient.identify({ key: id, email, name, custom })
}
