import { useParams } from "react-router-dom"
import ViewErrorMessage from "src/components/ViewErrorMessage"
import sentry from "src/services/sentry"
import { translateText } from "src/services/translations/textTranslations"
import Loading from "src/views/Loading"
import { useGetVnsSessionReport } from "../queries"
import { TitrationPlanCard } from "./TitrationPlanCard"
import { columns, getCurrentStep, getStepStartTime, mapStepsToRow, modeMap } from "./utils"

export const TitrationPlan = () => {
  const { patientId = "", readingDate = "", tabletSerialNumber = "" } = useParams()

  const { data, isLoading, error } = useGetVnsSessionReport({
    patientProfileId: patientId,
    readingDateTime: readingDate,
    tabletSerialNumber
  })

  if (isLoading) return <Loading />

  if (error || !data) {
    sentry.captureException(new Error("TitrationPlan should not be rendered when Session Report is not fetched yet"))
    return <ViewErrorMessage>{translateText("COMMON/GENERIC_ERROR")}</ViewErrorMessage>
  }

  const { autostimOutputCurrent, magnetOutputCurrent, normalOutputCurrent } = data.final
  const { programmingMode, scheduledProgramGuidedPlans: steps } = data.final

  if (programmingMode !== "Guided Scheduled" && programmingMode !== "Guided Manual") return null

  const currentStep = getCurrentStep({ autostimOutputCurrent, magnetOutputCurrent, normalOutputCurrent, steps })
  const stepStartTime = getStepStartTime(currentStep, programmingMode)

  const filteredColumns =
    programmingMode === "Guided Manual" ? columns.filter(({ field }) => field !== "date") : columns

  return (
    <TitrationPlanCard
      columns={filteredColumns}
      mode={modeMap[programmingMode]}
      rows={mapStepsToRow(steps, currentStep?.stepId)}
      stepStartTime={stepStartTime}
    />
  )
}
