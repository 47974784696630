import { Spacer } from "epsy-ui-react"
import React from "react"
import { Link } from "react-router-dom"
import { paths } from "src/app/routing/paths"
import { getLogo } from "src/services/whiteLabel"
import { bp } from "src/style/breakpoints"
import { toEdges } from "src/style/mixins"
import styleVars from "src/style/vars"
import styled from "styled-components"
import DesktopMenu from "./DesktopMenu"
import MobileMenu from "./MobileMenu"
import Tabs from "./Tabs"
import TopLevelLinks from "./TopLevelLinks"

const Container = styled.nav`
  color: ${(p) => p.theme.oldColors.primary.purple};
  ${bp.each("height", styleVars.navHeight, "px")}
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  max-width: 100vw;
  border-bottom: ${styleVars.navActiveLineHeight}px ${(p) => p.theme.oldColors.shade[4]} solid;
  a {
    text-decoration: none;
  }
`

const GradientLayer = styled.div`
  ${toEdges}
  background-color: ${(p) => p.theme.colors.white};
  z-index: -1;
  overflow: hidden;

  & > div {
    ${toEdges}
    transform: rotate(-180deg);
  }

  @media print {
    display: none;
  }
`

const Inner = styled.div`
  height: ${() => `${styleVars.navHeight}px`};
  align-items: center;
  display: flex;
  flex-direction: row;
  line-height: 1em;
  margin: 0 auto;
  ${bp.each("padding", styleVars.pageHorizontalMargin, "", (x) => `0 ${x}px`)}
  width: 100%;
  @media print {
    padding: 0 40px;
    & :not(img) {
      display: none;
    }
  }
`

const LogoContainer = styled(Link)`
  display: block !important; // important to override for print
  position: relative;
  top: 1px;
  @media print {
    display: block;
    margin-left: auto;
  }
`

const Logo = styled.img.attrs({
  src: getLogo(),
  alt: "Logo"
})`
  height: 40px;
  width: auto;
  @media print {
    alight-self: left;
  }
`

const MenuContainer = styled.div`
  margin-left: auto;
  @media print {
    display: none;
  }
`

const DesktopMenuContainer = styled(MenuContainer)`
  height: calc(100% - 2px);
  overflow: hidden;

  ${bp.only("mobile")} {
    display: none;
  }
`

const MobileMenuContainer = styled(MenuContainer)`
  margin-left: auto;
  @media print {
    display: none;
  }
  ${bp.min("medium")} {
    display: none;
  }
`

const Nav = () => (
  <>
    <Container data-cy="nav">
      <GradientLayer></GradientLayer>
      <Inner>
        <LogoContainer to={paths.HOME} data-cy="logo">
          <Logo />
        </LogoContainer>
        <TopLevelLinks />
        <MobileMenuContainer>
          <MobileMenu />
        </MobileMenuContainer>
        <DesktopMenuContainer>
          <DesktopMenu />
        </DesktopMenuContainer>
      </Inner>
    </Container>
    <Spacer height={styleVars.navHeight} />
    <Tabs />
  </>
)

export default React.memo(Nav)
