const getInvitationByToken = /* GraphQL */ `
  query GetInvitationByTokenShort($token: String!) {
    getInvitationByToken(token: $token) {
      conversions
      createdAt
      expiresAt
      invitationId
      organizationId
      organization {
        name
      }
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`

const hcpAcceptInvitation = /* GraphQL */ `
  mutation HcpAcceptInvitation($input: HcpAcceptInvitationInput!) {
    hcpAcceptInvitation(input: $input) {
      conversions
      createdAt
      expiresAt
      invitationId
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`
export const customGql = { getInvitationByToken, hcpAcceptInvitation }
