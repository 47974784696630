import dayjs from "dayjs"
import { VnsSessionReport, VnsSessionReportSettings, VnsSessionReportStep } from "src/API"
import { formatDate, formatTime } from "src/services/dates"
import { translateIpgModel } from "src/services/translations/ipgModelTranslations"
import { translateText } from "src/services/translations/textTranslations"
import { Maybe } from "src/util/utilityTypes"
import { getBatteryValue } from "./getBatteryValue"
import { getTherapyStates } from "./Parameters/getTherapyStates"
import { ColumnLayout, getChangeCount, getLayout, getProgrammingModeChipProps } from "./Parameters/utils"
import { getCurrentStep, getStepStartTime, modeMap } from "./Titration/utils"

export const dataToRawText = (report: VnsSessionReport) =>
  [
    headerText(report),
    patientText(report),
    diagnosticsText(report),
    titrationPlanText(report),
    parametersText(report),
    normalChangesText(report),
    autoStimChangesText(report),
    magnetChangesText(report),
    detectionChangesText(report)
  ]
    .filter(Boolean)
    .join("\n---\n")

const headerText = ({ sessionStartDateTime }: VnsSessionReport) => {
  const title = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/TITLE")
  const readingDate = sessionStartDateTime
    ? formatDate(sessionStartDateTime, "TIME_DATE_MONTH_YEAR")
    : translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/UNKNOWN_READING_DATE")

  return [title, readingDate].join("\n")
}

const patientText = (report: VnsSessionReport) => {
  const { patientId, implantedDate, generatorModel, generatorSerialNumber } = report

  const patient = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/PATIENT", {
    patient: patientId || "--"
  })
  const implantDate = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/IMPLANT_DATE", {
    date: implantedDate ? dayjs(implantedDate).format("MMM D, YYYY") : translateText("COMMON/UNKNOWN")
  })
  const ipgModel = `${translateIpgModel(generatorModel)} ${generatorModel}`
  const serialNumber = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/SERIAL_NUMBER", {
    serial: generatorSerialNumber || translateText("COMMON/UNKNOWN")
  })

  return [patient, implantDate, ipgModel, serialNumber].join("\n")
}

const diagnosticsText = (report: VnsSessionReport) => {
  const diagnostics = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/SYSTEM_DIAGNOSTICS")

  const { batteryHealth, batteryPercentage, leadImpedanceValue, leadImpedanceStatus } = report.final
  const { normalOutputCurrent, normalOutputCurrentStatus } = report.final

  const battery = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/BATTERY", {
    value: getBatteryValue({ batteryHealth, batteryPercentage }),
    health: batteryHealth ?? ""
  })

  const impedance = leadImpedanceValue
    ? translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/LEAD_IMPEDANCE", {
        value: leadImpedanceValue,
        status: leadImpedanceStatus ?? ""
      })
    : translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/UNKNOWN_LEAD_IMPEDANCE")

  const outputCurrent = normalOutputCurrent
    ? translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/OUTPUT_CURRENT", {
        value: normalOutputCurrent,
        status: normalOutputCurrentStatus ?? ""
      })
    : translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/UNKNOWN_OUTPUT_CURRENT")

  return [diagnostics, battery, impedance, outputCurrent].join("\n")
}

const titrationPlanText = (report: VnsSessionReport) => {
  const { autostimOutputCurrent, magnetOutputCurrent, normalOutputCurrent } = report.final
  const { programmingMode, scheduledProgramGuidedPlans } = report.final

  if (programmingMode !== "Guided Scheduled" && programmingMode !== "Guided Manual") return

  const mode = modeMap[programmingMode]

  const title = translateText(`VNS/SESSION_REPORTS/TITRATION_CARD/${mode}_TITLE`)

  const currentStep = getCurrentStep({
    autostimOutputCurrent,
    magnetOutputCurrent,
    normalOutputCurrent,
    steps: scheduledProgramGuidedPlans
  })
  const stepStartTime = getStepStartTime(currentStep, programmingMode)
  const stepStartTimeText =
    stepStartTime &&
    `${translateText("VNS/SESSION_REPORTS/TITRATION_CARD/STEP_START_TIME")}${formatDate(stepStartTime, "TIME_ONLY")}`

  const steps = scheduledProgramGuidedPlans.map(stepText(currentStep, scheduledProgramGuidedPlans.length))

  const stepsText = steps.join("\n-\n")

  return [title, stepStartTimeText, "-", stepsText].filter(Boolean).join("\n")
}

const stepText =
  (currentStep: Maybe<VnsSessionReportStep>, stepsLength: number) => (step: VnsSessionReportStep, index: number) => {
    const date = step.schedProgStepDate ? ` - ${formatDate(step.schedProgStepDate, "DATE_MONTH_YEAR")}` : ""

    const isCurrent = currentStep?.stepId === step.stepId
    const isLast = index === stepsLength - 1
    const currentLabel = translateText("VNS/SESSION_REPORTS/TITRATION_CARD/CURRENT").toUpperCase()
    const targetLabel = translateText("VNS/SESSION_REPORTS/TITRATION_CARD/TARGET").toUpperCase()
    const status = isCurrent ? ` (${currentLabel})` : isLast ? ` (${targetLabel})` : ""

    const title = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/STEP", { stepId: step.stepId, date, status })
    const parametersText = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/STEP_TITLE")
    const normalText = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/STEP_NORMAL", {
      value: step.guidedStepNormalOutputCurrent ?? "--"
    })
    const autoStimText = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/STEP_AUTOSTIM", {
      value: step.guidedStepAutostimOutputCurrent ?? "--"
    })
    const magnetText = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/STEP_MAGNET", {
      value: step.guidedStepMagnetOutputCurrent ?? "--"
    })

    return [title, parametersText, normalText, autoStimText, magnetText].join("\n")
  }

const parametersText = (report: VnsSessionReport) => {
  const count = getChangeCount(report)
  const changes = count && translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/PARAMETER_CHANGES", { count })
  const { initialTherapyStateInfo, finalTherapyStateInfo } = getTherapyStates(
    report.initial.therapyRState,
    report.final.therapyRState
  )

  const initialTherapyState = initialTherapyStateInfo.chipProps?.children
  const finalTherapyState = finalTherapyStateInfo.chipProps?.children

  const { children: initial } = getProgrammingModeChipProps(report.initial.programmingMode)
  const { children: final } = getProgrammingModeChipProps(report.final.programmingMode)

  const initialTherapy = initialTherapyState ? `(${initialTherapyState})` : ""
  const finalTherapy = finalTherapyState ? `(${finalTherapyState})` : ""

  const mode = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/PARAMETERS_MODE", {
    initial,
    final,
    initialTherapy,
    finalTherapy
  })

  const { daytime, nightTime } = parseDayAndNightTimeValues(report.initial, report.final)

  return [changes, mode, daytime, nightTime].filter(Boolean).join("\n")
}

const parseDayAndNightTimeValues = (initial: VnsSessionReportSettings, final: VnsSessionReportSettings) => {
  const layout = getLayout(initial.programmingMode, final.programmingMode)
  const daytimeTitle = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/PARAMETER_DAYTIME")
  const nightTimeTitle = translateText("VNS/SESSION_REPORTS/DIALOG/TEXT/PARAMETER_NIGHTTIME")

  const daytimeValuesMap: Record<ColumnLayout, string | undefined> = {
    "1-1": undefined,
    "1-2": `${daytimeTitle} - ${formatTime(final.nightModeEndTime)} - ${formatTime(final.nightModeStartTime)}`,
    "2-1": `${daytimeTitle} - ${formatTime(initial.nightModeEndTime)} - ${formatTime(initial.nightModeStartTime)}`,
    "2-2": `${daytimeTitle} - ${formatTime(initial.nightModeEndTime)} - ${formatTime(
      initial.nightModeStartTime
    )} -> ${formatTime(final.nightModeEndTime)} - ${formatTime(final.nightModeStartTime)}`
  }

  const nightTimeValuesMap: Record<ColumnLayout, string | undefined> = {
    "1-1": undefined,
    "1-2": `${nightTimeTitle} - ${formatTime(final.nightModeStartTime)} - ${formatTime(final.nightModeEndTime)}`,
    "2-1": `${nightTimeTitle} - ${formatTime(initial.nightModeStartTime)} - ${formatTime(initial.nightModeEndTime)}`,
    "2-2": `${nightTimeTitle} - ${formatTime(initial.nightModeStartTime)} - ${formatTime(
      initial.nightModeEndTime
    )} -> ${formatTime(final.nightModeStartTime)} - ${formatTime(final.nightModeEndTime)}`
  }

  return { daytime: daytimeValuesMap[layout], nightTime: nightTimeValuesMap[layout] }
}

const normalChangesText = ({ initial, final }: VnsSessionReport) => {
  const layout = getLayout(initial.programmingMode, final.programmingMode)
  const title = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/NORMAL")

  const {
    initialTherapyStateInfo: { value: initialValue },
    finalTherapyStateInfo: { value: finalValue }
  } = getTherapyStates(initial.therapyRState, final.therapyRState)

  const outputCurrentValues = parseChangeValues(
    initialValue ?? initial.normalOutputCurrent,
    finalValue ?? final.normalOutputCurrent,
    initialValue ?? initial.nightModeNormalOutputCurrent,
    finalValue ?? final.nightModeNormalOutputCurrent,
    layout
  )
  const outputCurrentTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/OUTPUT_CURRENT")
  const outputCurrentText = `${outputCurrentTitle} (${translateText("COMMON/UNIT/MA")}): ${outputCurrentValues}`

  const frequencyValues = parseChangeValues(
    initial.normalFrequency,
    final.normalFrequency,
    initial.nightModeNormalFrequency,
    final.nightModeNormalFrequency,
    layout
  )
  const frequencyTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/FREQUENCY")
  const frequencyText = `${frequencyTitle} (${translateText("COMMON/UNIT/HZ")}): ${frequencyValues}`

  const pulseWidthValues = parseChangeValues(
    initial.normalPulseWidth,
    final.normalPulseWidth,
    initial.nightModeNormalPulseWidth,
    final.nightModeNormalPulseWidth,
    layout
  )
  const pulseWidthTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PULSE_WIDTH")
  const pulseWidthText = `${pulseWidthTitle} (${translateText("COMMON/UNIT/MS")}): ${pulseWidthValues}`

  const dutyCycleValues = parseChangeValues(
    initial.normalDutyCycle,
    final.normalDutyCycle,
    initial.nightModeNormalDutyCycle,
    final.nightModeNormalDutyCycle,
    layout
  )
  const dutyCycleTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE")
  const dutyCycleText = `${dutyCycleTitle} (%): ${dutyCycleValues}`

  const onTimeValues = parseChangeValues(
    initial.normalOnTime,
    final.normalOnTime,
    initial.nightModeNormalSignalOnTime,
    final.nightModeNormalSignalOnTime,
    layout
  )
  const onTimeTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/ON_TIME")
  const onTimeText = `${onTimeTitle} (${translateText("COMMON/UNIT/SEC")}): ${onTimeValues}`

  const offTimeValues = parseChangeValues(
    initial.normalOffTime,
    final.normalOffTime,
    initial.nightModeNormalSignalOffTime,
    final.nightModeNormalSignalOffTime,
    layout
  )
  const offTimeTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/OFF_TIME")
  const offTimeText = `${offTimeTitle} (${translateText("COMMON/UNIT/MIN")}): ${offTimeValues}`

  return [title, outputCurrentText, frequencyText, pulseWidthText, dutyCycleText, onTimeText, offTimeText].join("\n")
}

const autoStimChangesText = ({ initial, final }: VnsSessionReport) => {
  const layout = getLayout(initial.programmingMode, final.programmingMode)
  const title = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/AUTOSTIM")

  const {
    initialTherapyStateInfo: { value: initialValue },
    finalTherapyStateInfo: { value: finalValue }
  } = getTherapyStates(initial.therapyRState, final.therapyRState)

  const outputCurrentValues = parseChangeValues(
    initialValue ?? initial.autostimOutputCurrent,
    finalValue ?? final.autostimOutputCurrent,
    initialValue ?? initial.nightModeAutoStimOutputCurrent,
    finalValue ?? final.nightModeAutoStimOutputCurrent,
    layout
  )
  const outputCurrentTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/OUTPUT_CURRENT")
  const outputCurrentText = `${outputCurrentTitle} (${translateText("COMMON/UNIT/MA")}): ${outputCurrentValues}`

  const pulseWidthValues = parseChangeValues(
    initial.autostimPulseWidth,
    final.autostimPulseWidth,
    initial.nightModeAutoStimPulseWidth,
    final.nightModeAutoStimPulseWidth,
    layout
  )
  const pulseWidthTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PULSE_WIDTH")
  const pulseWidthText = `${pulseWidthTitle} (${translateText("COMMON/UNIT/MS")}): ${pulseWidthValues}`

  const onTimeValues = parseChangeValues(
    initial.autostimOnTime,
    final.autostimOnTime,
    initial.nightModeAutoStimOnTime,
    final.nightModeAutoStimOnTime,
    layout
  )
  const onTimeTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/ON_TIME")
  const onTimeText = `${onTimeTitle} (${translateText("COMMON/UNIT/SEC")}): ${onTimeValues}`

  const autoStimThresholdValues = parseChangeValues(
    initial.autostimThreshold,
    final.autostimThreshold,
    initial.nightModeThresholdForAutoStim,
    final.nightModeThresholdForAutoStim,
    layout
  )
  const autoStimThresholdTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/AUTOSTIM_THRESHOLD")
  const autoStimThresholdText = `${autoStimThresholdTitle} (%): ${autoStimThresholdValues}`

  return [title, outputCurrentText, pulseWidthText, onTimeText, autoStimThresholdText].join("\n")
}

const magnetChangesText = ({ initial, final }: VnsSessionReport) => {
  const layout = getLayout(initial.programmingMode, final.programmingMode)
  const title = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/MAGNET")

  const {
    initialTherapyStateInfo: { value: initialValue },
    finalTherapyStateInfo: { value: finalValue }
  } = getTherapyStates(initial.therapyRState, final.therapyRState)

  const outputCurrentValues = parseChangeValues(
    initialValue ?? initial.magnetOutputCurrent,
    finalValue ?? final.magnetOutputCurrent,
    initialValue ?? initial.nightModeMagnetOutputCurrent,
    finalValue ?? final.nightModeMagnetOutputCurrent,
    layout
  )
  const outputCurrentTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/OUTPUT_CURRENT")
  const outputCurrentText = `${outputCurrentTitle} (${translateText("COMMON/UNIT/MA")}): ${outputCurrentValues}`

  const pulseWidthValues = parseChangeValues(
    initial.magnetPulseWidth,
    final.magnetPulseWidth,
    initial.nightModeMagnetPulseWidth,
    final.nightModeMagnetPulseWidth,
    layout
  )
  const pulseWidthTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PULSE_WIDTH")
  const pulseWidthText = `${pulseWidthTitle} (${translateText("COMMON/UNIT/MS")}): ${pulseWidthValues}`

  const onTimeValues = parseChangeValues(
    initial.magnetOnTime,
    final.magnetOnTime,
    initial.nightModeMagnetSignalOnTime,
    final.nightModeMagnetSignalOnTime,
    layout
  )
  const onTimeTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/DUTY_CYCLE/ON_TIME")
  const onTimeText = `${onTimeTitle} (${translateText("COMMON/UNIT/SEC")}): ${onTimeValues}`

  return [title, outputCurrentText, pulseWidthText, onTimeText].join("\n")
}

const detectionChangesText = ({ initial, final }: VnsSessionReport) => {
  const title = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/TITLE/DETECTION")

  const tachycardiaValues = parseChangeValues(initial.tachycardiaDetection, final.tachycardiaDetection)
  const tachycardiaTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/TACHYCARDIA")
  const tachycardiaText = `${tachycardiaTitle}: ${tachycardiaValues}`

  const heartbeatValues = parseChangeValues(initial.heartbeatDetection, final.heartbeatDetection)
  const heartbeatTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/HEARTBEAT_DETECTION")
  const heartbeatText = `${heartbeatTitle}: ${heartbeatValues}`

  const lowHeartRateValues = parseChangeValues(initial.lowHeartRate, final.lowHeartRate)
  const lowHeartRateTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/LOW_HEART_RATE")
  const lowHeartRateText = `${lowHeartRateTitle}: ${lowHeartRateValues}`

  const lowHeartRateThresholdValues = parseChangeValues(initial.lowHeartRateThreshold, final.lowHeartRateThreshold)
  const lowHeartRateThresholdTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/LHR_THRESHOLD")
  const lowHeartRateThresholdText = `${lowHeartRateThresholdTitle} (${translateText(
    "COMMON/UNIT/BPM"
  )}): ${lowHeartRateThresholdValues}`

  const pronePositionValues = parseChangeValues(initial.pronePositionDetection, final.pronePositionDetection)
  const pronePositionTitle = translateText("VNS/SESSION_REPORTS/TABLE/PARAMETERS_CARD/LABEL/PRONE_POSITION")
  const pronePositionText = `${pronePositionTitle}: ${pronePositionValues}`

  return [title, tachycardiaText, heartbeatText, lowHeartRateText, lowHeartRateThresholdText, pronePositionText].join(
    "\n"
  )
}

const notAvailable = translateText("VNS/SESSION_REPORTS/TABLE/NOT_AVAILABLE")

const parseChangeValues = (
  initialValue: Maybe<string | number> = notAvailable,
  finalValue: Maybe<string | number> = notAvailable,
  nightInitialValue: Maybe<string | number> = notAvailable,
  nightFinalValue: Maybe<string | number> = notAvailable,
  layout: ColumnLayout = "1-1"
) => {
  const layoutValuesMap: Record<ColumnLayout, string> = {
    "1-1": `${initialValue} -> ${finalValue}`,
    "1-2": `${initialValue} -> ${finalValue} - ${nightFinalValue}`,
    "2-1": `${initialValue} - ${nightInitialValue} -> ${finalValue}`,
    "2-2": `${initialValue} - ${finalValue} -> ${nightInitialValue} - ${nightFinalValue}`
  }

  return layoutValuesMap[layout]
}
